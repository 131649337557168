import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Account_Type_Foundational_Details
interface AccountTypeFoundationalDetailsValueProps {
  acctType: string;
  desc: string;
  cardLimit: number;
}
// Defining Intitial Value For Account_Type_Foundational_Details
const accountTypeFoundationDetails: AccountTypeFoundationalDetailsValueProps = {
  acctType: "",
  desc: "",
  cardLimit: 0,
};
// YUP LIBRARY :Defining Schema For validation of Account_Type_Foundational_Details
const accountTypeFoundationDetailSchema = async (optionsSchema: PostSchema) => {
  const fields = ["acctType", "desc", "cardLimit"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  shapeConfig.desc = shapeConfig.desc.trim();
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { AccountTypeFoundationalDetailsValueProps };
// Exporting Validation Schema and Intial value
export {
  accountTypeFoundationDetails,
  accountTypeFoundationDetailSchema as getValidationSchema,
};
