import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

interface RegulatoryAdherenceLimitationFormProps {
  maxLvtPercentageButton?: SplitBtnInputValType;
  maxLtvPct?: string | number;
  isBumpEnabled?: SplitBtnInputValType;
  isRateGuaranteed?: SplitBtnInputValType;
  isFedExempt: SplitBtnInputValType;
  isStateExempt: SplitBtnInputValType;
  isRegD?: SplitBtnInputValType;
  isRegDProdTfr?: SplitBtnInputValType;
  regDProdTrf?: string;
  isNraExempt?: SplitBtnInputValType;
}

const regulatoryAdherenceLimitationSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = [
    "maxLtvPct",
    "isBumpEnabled",
    "isRateGuaranteed",
    "isFedExempt",
    "isStateExempt",
    "isRegD",
    "regDProdTfr",
    "isNraExempt",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};

export type { RegulatoryAdherenceLimitationFormProps };
export { regulatoryAdherenceLimitationSchema as getValidationSchema };
