import { GENERIC_ERROR } from "@/components/data/error-data";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";
// Define the type for the setFieldValue. This will use in entire Form
type SetFieldValueProps = (
  field: string,
  value: any,
  shouldValidate?: boolean
) => Promise<void>;
//Defining Interface For Collateral_Security_Specification
interface securitySpecificationProps {
  secLvl: number;
  secPct: string;
}
// Defining Intitial Value For Collateral_Security_Specification
const securitySpecificationData: securitySpecificationProps = {
  secLvl: -1,
  secPct: "",
};


// YUP LIBRARY :Defining Schema For validation of Collateral_Security_Specification
const securitySpecificationSchema = async (optionsSchema: PostSchema) => {
  const fields = ["secLvl", "secPct"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { securitySpecificationProps, SetFieldValueProps };
// Exporting Validation Schema and Intial value
export {
  securitySpecificationData,
  securitySpecificationSchema as getValidationSchema,
};
