"use client";
import { useContext, useEffect, useMemo, useState } from "react";

import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  CoupleRadioWithDropdown,
  ErrorAlert,
  RadioButtonGroup,
  SplitButton,
} from "@/components/common";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  TermPenaltiesAndAdjustmentProps,
  getValidationSchema,
} from "./TermPenaltiesAndAdjustmentsValidation";
import { useFormik } from "formik";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  addNewComponent,
  createComponentGeneric,
} from "../../product-config-client-service";
import { useToast } from "@chakra-ui/react";
import {
  COMPONENT_CLASS,
  MAIN_FORM_ID,
  TRANSCODE_FLOW_NAME,
} from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  COMPONENT_CLASS_ENUM,
  OptionsSchema,
  PostSchema,
  TermComponentData,
} from "@/components/product-management/model/types";
import { matrixTypeUiSchema } from "@finxact/finxact-shared-ui";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import {
  useEntityIdFromParam,
  useFieldNameFromParam,
  preventMainFormSubmitOnRJSFSubmit,
} from "@/utils/common";
import { Option, RjsfData } from "@/utils/types";

type Props = {
  data: {
    formData: TermComponentData;
    withdrawalPenaltyOptions: Option<string>[];
    transactionCodes: Option<string>[];
    penaltyMatrixOptions: string[];
    termComponentOptionsData: PostSchema;
    addNewMatrixTypeSchema: OptionsSchema;
    addNewMatrixTypeEndpoint: string;
  };
};

const TermPenaltiesAndAdjustments = (props: Props) => {
  const toast = useToast();
  const entityId = useEntityIdFromParam();
  const fieldName = useFieldNameFromParam();

  const onSubmit = async (
    values: TermPenaltiesAndAdjustmentProps,
    actions: any
  ) => {
    if (!dirty && !entityId) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["term"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    const { formData } = props.data;
    if (formData) {
      if (productDetails) {
        const res = await createComponentGeneric({
          productDetails,
          formData: {
            ...formData,

            earlyDrPen: values.earlyDrPen,
            penMatrix: values.penaltyMatrixSelector,
            penTrnCode: values.penTrnCode,
            bumpLeadDays: parseInt(values.bumpLeadDays),
            skipIntPost: values.skipIntPost,
          },
          toastOptions: {
            toast,
            toastId: "trm-pen-adj",
            successMessage: `${configPageTitle} updated.`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
          componentClass: COMPONENT_CLASS_ENUM.componentTd,
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const schema = useMemo(() => {
    const { actions, name } = props.data.addNewMatrixTypeSchema;
    return { name, ...actions.POST };
  }, [props.data.addNewMatrixTypeSchema]);

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.addNewMatrixTypeEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue(`penaltyMatrixSelector`, response.matrixName);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.termComponentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.termComponentOptionsData]);

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    dirty,
    handleBlur,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      earlyDrPen: props.data.formData.earlyDrPen ?? "",
      penMatrix: props.data.formData.penMatrix === "" ? "" : "PRIMARY",
      penaltyMatrixSelector: props.data.formData.penMatrix ?? "",
      penTrnCode:
        fieldName === "penTrnCode" && entityId
          ? entityId
          : (props?.data?.formData?.penTrnCode ?? ""),
      bumpLeadDays: props.data.formData.bumpLeadDays?.toString() ?? "",
      skipIntPost: props.data.formData?.skipIntPost ?? undefined,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  //Handling Form
  const termPenaltiesAndAdjustmentFormHandler =
    (key: keyof TermPenaltiesAndAdjustmentProps) =>
    (value: string | SplitBtnInputValType | number | any[]) => {
      if (key === "penTrnCode" && Array.isArray(value)) {
        setFieldValue(key, value);
      } else {
        setFieldValue(key, value);
      }
    };

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id={MAIN_FORM_ID}
    >
      <CardContainer>
        <div className="app-form-field-container">
          <AppFormLabel
            labelName="What is the early withdrawal penalty?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("earlyDrPen")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.earlyDrPen}
            onChange={termPenaltiesAndAdjustmentFormHandler("earlyDrPen")}
            radioPropList={props.data.withdrawalPenaltyOptions}
            stackDirection={"column"}
            spacing={"1rem"}
          />
          {errors.earlyDrPen && touched.earlyDrPen && (
            <ErrorAlert>
              <span>{errors.earlyDrPen}</span>
            </ErrorAlert>
          )}
        </div>
        <div className="app-form-field-container">
          <AppFormLabel
            labelName="What is the penalty matrix?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("penMatrix")
                ?.tooltip_text_main
            }
            labelFor="term_penalty_matrix"
          />
          <CoupleRadioWithDropdown
            primaryRadioName="Use existing penalty matrix"
            secondaryRadioName="Create new penalty matrix"
            onRadioValueChange={termPenaltiesAndAdjustmentFormHandler(
              "penMatrix"
            )}
            primaryOptionValue={values.penaltyMatrixSelector}
            handlePrimaryOptionSelect={termPenaltiesAndAdjustmentFormHandler(
              "penaltyMatrixSelector"
            )}
            placeHolderName="Select penalty matrix"
            dropdownList={props.data.penaltyMatrixOptions}
            schema={schema}
            uiSchema={matrixTypeUiSchema}
            onAddNewSubmit={addNewOption}
            id="term_penalty_matrix"
            modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
          />
          {errors.penMatrix && touched.penMatrix && (
            <ErrorAlert>
              <span>{errors.penMatrix}</span>
            </ErrorAlert>
          )}
          {errors.penaltyMatrixSelector && touched.penaltyMatrixSelector && (
            <ErrorAlert>
              <span>{errors.penaltyMatrixSelector}</span>
            </ErrorAlert>
          )}
        </div>
        <div className="app-form-field-container">
          <AppFormLabel
            labelName="What is the penalty transaction code?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("penTrnCode")
                ?.tooltip_text_main
            }
            labelFor="term_penalty_tran_code"
          />
          <CoupleRadioWithDropdown
            primaryRadioName={"Select from existing transaction code"}
            primaryOptionValue={values.penTrnCode!}
            secondaryRadioName={"Create new transaction code"}
            handlePrimaryOptionSelect={
              termPenaltiesAndAdjustmentFormHandler("penTrnCode") as any
            }
            dropdownList={props.data.transactionCodes}
            placeHolderName="Select transaction code"
            onBlur={handleBlur}
            addNewFieldType="routeToTransCode"
            addNewFieldQueryParam={`${QUERY_PARAM_KEY["PRODUCT_NAME_KEY"]}=${productDetails?.name}&${ROUTE_PATH.FLOW_NAME_QUERY}${TRANSCODE_FLOW_NAME.termPenAdjust}&${QUERY_PARAM_KEY.FIELD_NAME}=penTrnCode`}
            id="term_penalty_tran_code"
            withDescription
            modelViewUrl={ROUTE_PATH.TC_FOUNDATIONAL_DETAILS}
          />
          {errors.penTrnCode && touched.penTrnCode && (
            <ErrorAlert>
              <span>{errors.penTrnCode}</span>
            </ErrorAlert>
          )}
        </div>
        <div className="app-form-field-container">
          <AppNumberInput
            labelName="Set the number of bump lead days"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("bumpLeadDays")
                ?.tooltip_text_main
            }
            value={values.bumpLeadDays}
            onChange={termPenaltiesAndAdjustmentFormHandler("bumpLeadDays")}
          />
          {errors.bumpLeadDays && touched.bumpLeadDays && (
            <ErrorAlert>
              <span>{errors.bumpLeadDays}</span>
            </ErrorAlert>
          )}
        </div>
        <div className="interest-posting">
          <AppFormLabel
            labelName="Will this component skip interest posting?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("skipIntPost")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.skipIntPost}
            onSelect={termPenaltiesAndAdjustmentFormHandler("skipIntPost")}
          />
          {errors.skipIntPost && touched.skipIntPost && (
            <ErrorAlert>
              <span>{errors.skipIntPost}</span>
            </ErrorAlert>
          )}
        </div>
      </CardContainer>
    </form>
  );
};

export default TermPenaltiesAndAdjustments;
