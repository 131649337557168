import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Accounting segments
interface AccountingSegmentsProps {
  acctgSeg: string;
  desc: string;
}
interface AccountingSegmentsArrayProps {
  idx: number | string;
  acctgSegVal: string;
  acctgSegDesc: string;
}

interface AccountingSegmentValues {
  acctgSeg: string;
  desc: string;
  domainVals: AccountingSegmentsArrayProps[];
}

// Defining Intitial Value For Accounting segments
const accountingSegments: AccountingSegmentsProps = {
  acctgSeg: "",
  desc: "",
};

const accountingSegmentsArray: AccountingSegmentsArrayProps = {
  idx: "",
  acctgSegVal: "",
  acctgSegDesc: "",
};

// YUP LIBRARY :Defining Schema For validation of Accounting segments
const accountingSegmentsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["acctgSeg", "desc", "domainVals"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { AccountingSegmentsArrayProps, AccountingSegmentValues };
// Exporting Validation Schema and Intial value
export {
  accountingSegments,
  accountingSegmentsArray,
  accountingSegmentsSchema as getValidationSchema,
};
