import { ERROR_MESSAGE } from "@/components/data/form-data";
import { GENERIC_ERROR } from "@/components/data/error-data";
import * as Yup from "yup";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import { SplitBtnInputValType } from "@/components/common";

//Defining Interface For Repayment-Prepayment-Direction-changes
interface repaymentPaymentDirectionChagesProps {
  pmtApplMthd: {
    Past?: { direction?: string; dueItemOrder?: string[] };
    Priority?: { direction?: string; dueItemOrder?: string[] };
    Current?: { direction?: string; dueItemOrder?: string[] };
  };
  past: SplitBtnInputValType;
  current: SplitBtnInputValType;
  priority: SplitBtnInputValType;
}

// Defining Intitial Value For  Repayment-Prepayment-Direction-changes
const repaymentPaymentDirectionChagesDetails: repaymentPaymentDirectionChagesProps =
  {
    pmtApplMthd: {},
    past: undefined,
    current: undefined,
    priority: undefined,
  };
// YUP LIBRARY :Defining Schema For validation of  Repayment-Prepayment-Direction-changes
const repaymentPaymentDirectionChagesDetailsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["pmtApplMthd"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { repaymentPaymentDirectionChagesProps };
// Exporting Validation Schema and Intial value
export {
  repaymentPaymentDirectionChagesDetails,
  repaymentPaymentDirectionChagesDetailsSchema as getValidationSchema,
};
