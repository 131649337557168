import * as Yup from "yup";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
//Defining Interface For Financial_Organization_Other_Identification
interface FinancialOrganizationSpecificationsDetailsValueProps {
  rtCat: number | undefined;
}
// Defining Intitial Value For Financial_Organization_Other_Identification
const FinancialOrganizationSpecificationsDetails: FinancialOrganizationSpecificationsDetailsValueProps =
  {
    rtCat: undefined,
  };

// YUP LIBRARY :Defining Schema For validation of Financial_Organization_Other_Identification
const financialOrganizationSpecificationDetailsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["rtCat"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { FinancialOrganizationSpecificationsDetailsValueProps };
// Exporting Validation Schema and Intial value
export {
  FinancialOrganizationSpecificationsDetails,
  financialOrganizationSpecificationDetailsSchema as getValidationSchema,
};
