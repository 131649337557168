import { IFX_ACCOUNT_MAPPING, POSITION_CLASS } from "@/utils/constants";
import { CreateToastFnReturn } from "@chakra-ui/react";
import { ProductType } from "./product-models";
import { Nullable } from "@/utils/types";

export type ProductStatus = "not_started" | "in_progress" | "completed";

export interface FeeComponentResponse {
  data: {
    _vn: number;
    componentName: string;
    version: number;
    earningsAnalysis?: number;
    feeItems?: {
      feeName: string;
      feeTypeOpt: number | undefined;
      isAccum: boolean | undefined;
    }[];
    feeMax?: number;
    feeMaxTrnCode?: string;
    feeMin?: number;
    feeMinTrnCode?: string;
    svcChrgFreq?: string | null;
    svcChrgFreqMatrix?: string;
    trnFees?: {
      additionalProp1: string;
      additionalProp2: string;
      additionalProp3: string;
    };
  }[];
}
export type FeeComponentData = FeeComponentResponse["data"][number];

export interface InterestComponentResponse {
  data: [
    {
      _vn: number;
      componentName: string;
      version: number;
      accrCalcTm?: string;
      accrCarryOverOpt?: number;
      accrMinBal?: number;
      adjTerm?: string;
      balOpt?: number;
      calcMthd?: number;
      disbmtOpt?: number;
      index?: {
        _vn?: number;
        firstDur?: string;
        indexBaseRate?: number;
        indexName?: string;
        isReviewDly?: boolean;
        maxChngFirst?: number;
        maxChngLife?: number;
        maxChngNext?: number;
        maxChngPer?: number;
        maxRate?: number;
        minRate?: number;
        nextChngFreq?: string;
        nextReviewDtm?: string | null;
        nomRate?: number;
        offSet?: string;
        offsetMatrix?: string | null;
        reviewFreq?: string | null;
        round?: string;
      };
      isCompoundDly?: boolean;
      isPostNetInt?: boolean;
      negAccrOpt?: number;
      nomRate?: number;
      postFreq?: string;
      postFreqMatrix?: string;
      postRoundOpt?: number;
      promoDtl?: Nullable<{
        _vn?: number;
        firstDur?: string;
        indexBaseRate?: number;
        indexName?: string | null;
        maxChngFirst?: number;
        maxChngNext?: number;
        maxRate?: number;
        minRate?: number;
        nextChngFreq?: string;
        nomRate?: number;
        offSet?: string;
        offsetMatrix?: string;
        promoCode?: string | null;
        promoTerm?: string;
        round?: string;
      }>;
      intOnlyPeriodDtl?: Nullable<{
        _vn?: number;
        firstDur?: string;
        indexBaseRate?: number;
        indexName?: string | null;
        maxChngFirst?: number;
        maxChngNext?: number;
        maxRate?: number;
        minRate?: number;
        nextChngFreq?: string;
        nomRate?: number;
        offSet?: string;
        offsetMatrix?: string;
        promoCode?: string | null;
        promoTerm?: string;
        round?: string;
      }>;
    },
  ];
}
export type InterestComponentData = InterestComponentResponse["data"][number];

export interface LimitComponentResponse {
  data: [
    {
      _vn: number;
      componentName: string;
      version: number;
      deminimisAmt: number;
      minBal: number;
      maxBal: number;
      minToOpen: number;
      restrictCr?: boolean;
      restrictCrFundExp?: boolean;
      restrictDr?: boolean;
      minBalFee?: string;
      minBalOpt?: number;
      accumTrnLimits: {
        crAmt: number;
        crCnt: number;
        definedBy: number;
        drAmt: number;
        drCnt: number;
        name: string;
        startDtm: string;
        statGroup: string;
        totAmt: number;
        totCnt: number;
        violationAct: number;
        violationFee: string;
      }[];
      perTrnLimits: {
        definedBy: number;
        increCrAmt: number;
        increDrAmt: number;
        maxBal: number;
        maxCrAmt: number;
        maxDrAmt: number;
        minBal: number;
        minCrAmt: number;
        minDrAmt: number;
        name: string;
        trnCodeExcl: string[];
        violationAct: number;
        violationFee: string;
      }[];
    },
  ];
}
export type LimitComponentData = LimitComponentResponse["data"][number];

export interface TermComponentResponse {
  data: [
    {
      _vn: number;
      componentName: string;
      version: number;
      notice: string;
      bumpLeadDays: number;
      crTermExt: string;
      earlyDrPen: number;
      intMatrix: string;
      intRate: number;
      maturityOpt: number;
      penMatrix: string;
      penTrnCode: string;
      rollCrGrace: string;
      rollDrGrace: string;
      rollDrGraceAdj: number;
      rollGraceRate: string;
      rollProd: string;
      skipIntPost?: boolean;
      term: string;
    },
  ];
}
export type TermComponentData = TermComponentResponse["data"][number];

export interface RewardComponentResponse {
  data: [
    {
      _vn: number;
      componentName: string;
      version: number;
      goalAmt?: string | null;
      goalTerm?: string | null;
      payoutOpt?: { programType: number }[] | null;
      relationshipProgram?: { subGroups?: string[]; program: string } | null;
      reward?: string | null;
      rewardIntPrem?: string | null;
      rewardOffset?: {
        reviewFreq: string | null | undefined;
      } | null;
      depInterval?: string | null;
      intervalAmt?: string | null;
      trnRewardProgram?: {
        payoutFreq: string | null | undefined;
        payoutTrnCode: string;
      } | null;
    },
  ];
}
export type RewardComponentData = RewardComponentResponse["data"][number];

export interface NsfComponentResponse {
  data: [
    {
      _vn: number;
      componentName: string;
      version: number;
      isAutoAuth?: boolean;
      isDrCardNsfOptIn?: boolean;
      isNegLimitOptIn?: boolean;
      isWaiveNsfFee?: boolean;
      negBalAlertDays: number;
      negLimitMax: number;
      negLimit: number;
      negLimitMin: number;
      isNegLimitNsfFee?: boolean;
      negLimitStartOpt: number;
      nsfDrRestrictDays: number;
      nsfDrRestrict: string;
      nsfDrRestrictPosBalDays: number;
      negLimitOpenDur: string;
      trnCodeExcl: string[];
      trnCodeIncl: string[];
      negLimitMatrix?: string | null;
      negLimitFreq?: string;
    },
  ];
}
export type NsfComponentData = NsfComponentResponse["data"][number];

export interface RulesComponentResponse {
  data: [
    {
      _vn: number;
      componentName: string;
      ruleGroups: [
        {
          event: number;
          executeAt: number;
          executeFreq: string;
          groupName: string;
          nextExecuteDtm: string;
          ruleGroup: [{ ruleName: string }];
        },
      ];
      version: 1;
    },
  ];
}

export type RulesComponentData = RulesComponentResponse["data"][number];

export interface TransactionComponentResponse {
  data: [
    {
      _vn: number;
      componentName: string;
      inclRoundUpTrnCodes: string[];
      roundUpTrnCode: string;
      version: number;
    },
  ];
}
export type TransactionComponentData =
  TransactionComponentResponse["data"][number];

// Interface for Repayment
export interface RepaymentComponentResponse {
  data: {
    _vn: number;
    componentName: string;
    version: number;
    billCapInt?: boolean;
    isFixAmortize?: boolean;
    isMaturityAutoPmt?: boolean;
    isRevolving?: boolean;
    isRtReceivable?: boolean;
    isUnapplFunds?: boolean;
    payoffVarianceAmt?: number;
    oddDaysIntOpt?: number;
    pmtApplMthd?: any;
    payApplMatrix?: string;
    prePmtMinAmt?: number;
    prePmtMinPct?: number;
    toleranceAmt?: number | null;
    prinMthd?: number;
    fixPrinAmt?: number;
    intMthd?: number;
    fixIntAmt?: number;
    prinBalPct?: number;
    prinBalBase?: number;
    amortizeDur?: string;
    pmtDur?: string;
    pmtFreq?: string;
    pastDueMaturityDays?: number;
    pmtApplAdvance?: number;
    minPmtAmt?: number;
    gracePeriod?: string;
    tolerancePct?: number | null;
    pmtOffset: string;
    pastDue: {
      pastDueTerm: string;
    }[];
    dueItems?: {
      calcMthd?: number;
      description?: string;
      isReceivable?: true;
      itemName?: string;
      subBal?: string;
      toleranceAmt?: number;
      tolerancePct?: number;
    }[];
  }[];
}
export type RepaymentComponentData = RepaymentComponentResponse["data"][number];

// Charge Off Component
export interface ChargeOffComponentResponse {
  data: [
    {
      _vn: number;
      componentName: string;
      version: number;
      chrgOffDays: number;
      chrgOffMin: number;
      chrgOffOpt?: number;
      trnCode: string | null;
      trnCodeMatrix: string | null;
    },
  ];
}
export type ChargeOffComponentData = ChargeOffComponentResponse["data"][number];

export interface CollateralComponentResponse {
  data: [
    {
      _vn: number;
      componentName: string;
      version: number;
      secLvl?: number;
      secPct?: number;
    },
  ];
}
export type CollateralComponentData =
  CollateralComponentResponse["data"][number];

export interface CommonComponentData {
  data: [
    {
      _vn: number;
      componentName: string;
      version: number;
    },
  ];
}

export interface ProductResponse {
  data: [
    {
      _vn: number;
      apy?: number;
      assetClass?: number;
      assetId?: string;
      avlEndDtm?: string;
      avlStartDtm?: string;
      avlStartDtmMatrix?: string;
      ccyCode?: string;
      components?: {
        componentClass: string;
        componentName?: string;
        version?: number;
      }[];
      desc?: string;
      dormantStatusAlertFreq?: string;
      dormantStatusDur?: string;
      dormantStatusFee?: string;
      dormantStatusRestrictCode?: string;
      fundTerm?: string;
      glCat?: number;
      glSetCode?: string;
      glSetMatrixName?: string;
      ifxAcctType?: keyof typeof IFX_ACCOUNT_MAPPING;
      inactiveStatusAlertFreq?: string;
      inactiveStatusDur?: string;
      inactiveStatusFee?: string;
      inactiveStatusRestrictCode?: string;
      interimStmtFreq?: string;
      isACHCorpOrigination?: boolean;
      isBumpEnabled?: boolean;
      isCrBureau?: boolean;
      isFedExempt?: boolean;
      isNraExempt?: boolean;
      isRateGuaranteed?: boolean;
      isRegD?: boolean;
      isStateExempt?: boolean;
      maxLtvPct?: number;
      name: string;
      posnAcctNbrPrefix?: string;
      posnClass?: keyof typeof POSITION_CLASS;
      prodGroup?: string;
      prodSubType?: string;
      prodType?: string;
      regDProdTfr?: string;
      reviewTerm?: string;
      stmtFreq?: string;
      stmtFreqMatrix?: string;
      stmtStartDtmOpt?: number;
      svcs?: {
        svcName?: string;
      }[];
      version?: number;
      zeroBalAlertDays?: number;
      zeroBalCloseDays?: number;
      crBureauFirstRunOpt?: number;
      crBureauCycleFreq?: string;
      svcrId?: string;
      isAccelerator__?: boolean;
    },
  ];
}

export type ProductData = ProductResponse["data"][number];

export interface CommonProductConfigAPIPayload {
  toastOptions: {
    toast: CreateToastFnReturn;
    toastId: string;
    successMessage?: string;
    failureMessage?: string;
  };
  stageName: string;
  isComplete?: boolean;
  productDetails: ProductType;
}

export enum COMPONENT_CLASS_ENUM {
  componentFee = "componentFee",
  componentInt = "componentInt",
  componentLimit = "componentLimit",
  componentNsf = "componentNsf",
  componentDepChrgOff = "componentDepChrgOff",
  componentTd = "componentTd",
  componentCollateral = "componentCollateral",
  componentTrnOpt = "componentTrnOpt",
  componentReward = "componentReward",
  componentRepay = "componentRepay",
  componentRules = "componentRules",
}

export interface ToastConfig {
  toastOptions: {
    toast: CreateToastFnReturn;
    toastId: string;
    successMessage?: string;
    failureMessage?: string;
  };
}
export interface LimitComponentUpdatePayload
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<LimitComponentData>>;
  componentClass: COMPONENT_CLASS_ENUM.componentLimit;
}

export interface FeeComponentUpdatePayload
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<FeeComponentData>>;
  componentClass: COMPONENT_CLASS_ENUM.componentFee;
}

export interface InterestComponentUpdatePayload
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<InterestComponentData>>;
  componentClass: COMPONENT_CLASS_ENUM.componentInt;
}
export interface TransactionComponentUpdatePayload
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<TransactionComponentData>>;
  componentClass: COMPONENT_CLASS_ENUM.componentTrnOpt;
}

export interface TermComponentUpdatePayload
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<TermComponentData>>;
  componentClass: COMPONENT_CLASS_ENUM.componentTd;
}
export interface RewardComponentUpdatePayload
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<RewardComponentData>>;
  componentClass: COMPONENT_CLASS_ENUM.componentReward;
}
export interface NsfComponentUpdatePayload
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<NsfComponentData>>;
  componentClass: COMPONENT_CLASS_ENUM.componentNsf;
}

export interface ChargeOffComponentUpdatePayload
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<ChargeOffComponentData>>;
  componentClass: COMPONENT_CLASS_ENUM.componentDepChrgOff;
}
export interface RepaymentComponentUpdatePayload
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<RepaymentComponentData>>;
  componentClass: COMPONENT_CLASS_ENUM.componentRepay;
}
export interface CollateralComponentUpdatePayload
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<CollateralComponentData>>;
  componentClass: COMPONENT_CLASS_ENUM.componentCollateral;
}

export interface RulesComponentUpdatePayload
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<RulesComponentData>>;
  componentClass: COMPONENT_CLASS_ENUM.componentRules;
}

export type ComponentUpdatePayload =
  | LimitComponentUpdatePayload
  | FeeComponentUpdatePayload
  | InterestComponentUpdatePayload
  | TransactionComponentUpdatePayload
  | TermComponentUpdatePayload
  | RewardComponentUpdatePayload
  | NsfComponentUpdatePayload
  | ChargeOffComponentUpdatePayload
  | RepaymentComponentUpdatePayload
  | CollateralComponentUpdatePayload
  | RulesComponentUpdatePayload;

export interface ComponentCreatePayload extends CommonProductConfigAPIPayload {
  formData: {
    componentName: string;
    version: number;
  };
}

// Used in Product basics
export type AssetCatalogue = {
  data: {
    _vn: number;
    assetClass: number;
    assetId: string;
    desc?: string;
  }[];
};

export type FastDefaultDropdownProps = {
  label: string;
  value: any;
};

export type PostSchema = {
  dbInterface: any;
  definitions: any;
  properties: Record<string, any>;
  required: string[];
  type: string;
};

export type OptionsSchema = {
  actions: {
    POST: PostSchema;
  };
  name: string;
};

export type LoanDelinquencyType = {
  nonAccr?: {
    cutoffDur?: string;
    isAutoTrigger?: boolean | null;
    isPaidToPrin?: boolean | null;
    isReceivable?: boolean | null;
    minDue?: number;
  };
  pastDueNotices?: {
    noticeName?: string;
    pastDueDays?: number;
  }[];
  pastDueRestrictDays?: number;
  pastDueTerms?: {
    term?: string;
  }[];
  name: string;
  needLoanDelinquency?: boolean | null;
};

export interface LoanDelinquencyPayloadType
  extends CommonProductConfigAPIPayload {
  formData: Nullable<Partial<LoanDelinquencyType>>;
}
// matrix type

export interface GetMatrixResponse {}
export interface MatrixTypePayload {
  matrixName: string;
  desc: string;
  valType: string;
  dimensions: MatrixTypeDimenions[];
}

export interface MatrixTypeDimenions {
  propFmt: string;
  propLabel: string;
  propName: string;
  propType: string;
  whenBtwn: number;
}

export interface CreateMatrixPayload {
  matrixes: CreateMatrixMatrixesPayload[];
}

export interface CreateMatrixMatrixesPayload {
  dimKey: string;
  val: string;
  matrixName: string;
  validFromDtm: string;
}

export interface MatrixTypeResponse {
  data: [
    {
      _vn?: number;
      desc: string;
      dimensions: MatrixTypeDimenions[];
      isBlended: boolean;
      matrixName: string;
      valType: string;
    },
  ];
}

export interface MatrixResponse {
  data: [
    {
      _vn?: number;
      dimKey: string;
      matrixName: string;
      val: string;
      validFromDtm: string;
    },
  ];
}

export type LatestComponetVersionType = {
  _vn?: number;
  accrCalcTm?: "23:59:59";
  accrCarryOverOpt?: 1;
  balOpt?: 0;
  componentName?: "testing254910Int";
  postRoundOpt?: 4;
  version?: 1;
};

export interface LatestComponetVersionResponse {
  data: LatestComponetVersionType[];
}
