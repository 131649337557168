"use client";

import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  InputText,
} from "@/components/common";
import "./GeneralSetup.scss";
import { useFormik } from "formik";
import {
  GeneralSetupProps,
  generalSetupData,
  getValidationSchema,
} from "./GeneralSetupValidation";
import { Box, useToast } from "@/components/ChakraUiManager";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { crtSetupModelData } from "../../model";
import { CRT_ENTITY, CRT_STEPS, SIDEBAR_STATUS } from "@/utils/constants";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  createCustomerRelationshipType,
  updateCustomerRelationshipType,
} from "../../crt-config-api-service";
import { createWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { useSelector } from "react-redux";
import { getEnvIsDisable } from "@/store";
import { checkIfICWorflowNeedsToBeCreated } from "@/components/institutional-configuration/ic-utils-service";
import { useQueryParams } from "@/utils/common";

type GeneralSetupConfigurationProps = {
  data: {
    formData: crtSetupModelData | null;
    custRelTypeOptionsData: PostSchema;
  };
};

const GeneralSetupDetails = (props: GeneralSetupConfigurationProps) => {
  const generalSetupFormData = props.data?.formData ?? null;
  const toast = useToast();
  const updateQueryParams = useQueryParams();
  const toastId = "crt-details-toast";
  const isEnvDisabled = useSelector(getEnvIsDisable);

  const moveToNextStep = (custRelType: string | undefined) => {
    if (isEnvDisabled) {
      updateFormStatus?.(SIDEBAR_STATUS.completed);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
      setSideBarCurrentIndex?.(sideBarMainMenuIndex + 1, null);
      navigateTo(
        `${
          ROUTE_PATH.CRT_PARTY_RELATIONSHIPS
        }?${updateQueryParams("entityId", custRelType)}`
      );
      return;
    }
    const isCrtWorkflowComplated = entityWorkflowData?.find(
      (ele: any) => ele.status == CRT_STEPS.party_relationships
    );

    if (!canCheckFormStatus) {
      if (isCrtWorkflowComplated) {
        updateFormStatus?.(SIDEBAR_STATUS.completed);
        updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
        setSideBarCurrentIndex?.(sideBarMainMenuIndex + 1, null);
        navigateTo(
          `${
            ROUTE_PATH.CRT_PARTY_RELATIONSHIPS
          }?${updateQueryParams("entityId", custRelType)}`
        );
      } else {
        navigateTo(
          `${ROUTE_PATH.CRT_INTERSTITAL_SCREEN}?${updateQueryParams("entityId", custRelType)}`
        );
      }
    }
  };

  const onSubmit = async (values: GeneralSetupProps, actions: any) => {
    if (!dirty) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          values.custRelType,
          entityWorkflowData ?? [],
          CRT_ENTITY.general_setup
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.crtConfig,
          key: values.custRelType,
          stage: CRT_ENTITY.general_setup,
          status: CRT_STEPS.general_setup,
        });
      }
      moveToNextStep(props.data?.formData?.custRelType);
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };
    if (!generalSetupFormData) {
      response = await createCustomerRelationshipType({
        formData: {
          custRelType: values.custRelType,
          desc: values.desc,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: "Customer relation type created.",
        },

        stageName: CRT_STEPS.general_setup,
      });
    } else {
      response = await updateCustomerRelationshipType({
        formData: {
          _vn: generalSetupFormData._vn,
          custRelType: generalSetupFormData.custRelType,
          desc: values.desc,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: "",
      });
    }
    if (response) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          values.custRelType,
          entityWorkflowData ?? [],
          CRT_ENTITY.general_setup
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.crtConfig,
          key: values.custRelType,
          stage: CRT_ENTITY.general_setup,
          status: CRT_STEPS.general_setup,
        });
      }
      moveToNextStep(response.custRelType);
    }
  };

  const handleOnChange = (key: string) => (value: string) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.custRelTypeOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.custRelTypeOptionsData]);

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      desc: generalSetupFormData?.desc.toString() ?? generalSetupData.desc,
      custRelType:
        generalSetupFormData?.custRelType ?? generalSetupData.custRelType,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    sideBarMainMenuIndex,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    checkFormStatus,
    setSideBarCurrentIndex,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
      checkFormStatus?.(false);
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the Customer Relationship Type?"
            isRequired={true}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("custRelType")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.custRelType}
            onChange={handleOnChange("custRelType")}
            onBlur={handleBlur}
            label="custRelType"
            disabled={Boolean(props.data.formData?.custRelType)}
          />
          {errors.custRelType && touched.custRelType && (
            <ErrorAlert>
              <span>{errors.custRelType}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box>
          <AppFormLabel
            labelName="What is the Customer Relationship Description?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("desc")?.tooltip_text_main
            }
          />
          <InputText
            value={values.desc}
            onChange={handleOnChange("desc")}
            onBlur={handleBlur}
            label="desc"
          />
          {errors.desc && touched.desc && (
            <ErrorAlert>
              <span>{errors.desc}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default GeneralSetupDetails;
