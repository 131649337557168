import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import { REGEX_PATTERN } from "@/utils/regex-pattern";
import * as Yup from "yup";

//Defining Interface For Promotional_Rate_Offset_Terms
interface PromotionalRateOffsetTermsProps {
  addPromotionalRateOffset: SplitBtnInputValType;
  promoDtl: {
    offsetMatrix: string;
    round: string;
    offSet: string;
  };
}
// Defining Intitial Value For Promotional_Rate_Offset_Terms
const promotionalRateOffsetTerms: PromotionalRateOffsetTermsProps = {
  addPromotionalRateOffset: undefined,
  promoDtl: {
    offsetMatrix: "",
    round: "",
    offSet: "",
  },
};
// YUP LIBRARY :Defining Schema For validation of Promotional_Rate_Offset_Terms
const promotionalRateOffsetTermschema = async (optionsSchema: PostSchema) => {
  const fields = ["promoDtl"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { PromotionalRateOffsetTermsProps };
// Exporting Validation Schema and Intial value
export {
  promotionalRateOffsetTerms,
  promotionalRateOffsetTermschema as getValidationSchema,
};
