"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CoupleRadioWithDropdown,
  DurationInput,
  ErrorAlert,
} from "@/components/common";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  ScraPropertiesProps,
  getValidationSchema,
} from "./ScraPropertiesValidation";
import { FinancialInstitutionUSBankInfoData } from "../../model";
import { updateBankParametersUSBankParams } from "../../ic-config-api-service";
import {
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";
import { IC_ENTITY } from "@/utils/constants";
import { addNewComponent } from "@/components/product-management/product-configuration/product-config-client-service";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { matrixTypeUiSchema } from "@finxact/finxact-shared-ui";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import {
  shouldUpdateWorkflowStatus,
  preventMainFormSubmitOnRJSFSubmit,
} from "@/utils/common";
import { RjsfData } from "@/utils/types";

type ScraPropertiesDataProps = {
  data: {
    rateMatrixOption: string[];
    formData: FinancialInstitutionUSBankInfoData;
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    bankParamOptionsData: PostSchema;
  };
};
const ScraProperties = ({ data }: ScraPropertiesDataProps) => {
  const { bankParamOptionsData } = data;
  const toast = useToast();
  const onSubmit = async (values: ScraPropertiesProps, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId: "Scara properties",
    };
    response = await updateBankParametersUSBankParams({
      formData: {
        _vn: data.formData._vn,
        _Id: data.formData._Id,
        // PATCH Feilds
        scra: {
          extraReliefDur: values.extraReliefDur,
          rateMatrix: values.rateMatrix,
        },
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      navigateToNextPage?.();
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);
      setValidationSchema(yupSchema.fields.bankparamUSBankInfo.fields.scra);
    })();
  }, [bankParamOptionsData]);

  const {
    values,
    dirty,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      extraReliefDur: data?.formData?.scra?.extraReliefDur ?? "",
      rateMatrix: data?.formData?.scra?.rateMatrix ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);
  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  const schema = useMemo(() => {
    const { actions, name } = data.addNewSchema;
    return { name, ...actions.POST };
  }, [data.addNewSchema]);

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      data.addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    setFieldValue(`rateMatrix`, response.matrixName);
  };

  //Handling Form
  const scraPropertiesFormHandler =
    (key: string) => (value: string | number | boolean | string[]) => {
      setFieldValue(key, value);
    };

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id="finxact-form"
      noValidate
    >
      <CardContainer>
        <Box className="app-form-field-container">
          <DurationInput
            labelName={"What is the SCRA Extra Relief Duration?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("extraReliefDur")
                ?.tooltip_text_main
            }
            value={values.extraReliefDur}
            onChange={scraPropertiesFormHandler("extraReliefDur")}
            modalLinkName={"Enter code manually"}
            modalTitle={"Enter SCRA Extra Relief Duration"}
            modalLabelName={"What is the SCRA Extra Relief Duration?"}
            modalLabelTooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("extraReliefDur")
                ?.tooltip_text_main
            }
          />
          {errors.extraReliefDur && touched.extraReliefDur && (
            <ErrorAlert>
              <span>{errors.extraReliefDur}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <AppFormLabel
            labelName={"What is the SCRA rate matrix?"}
            labelFor="matrix_input"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("rateMatrix")
                ?.tooltip_text_main
            }
          />
          <CoupleRadioWithDropdown
            primaryOptionValue={values.rateMatrix}
            primaryRadioName={"Leverage existing scra rate matrix"}
            secondaryRadioName={"Add new scra rate matrix"}
            handlePrimaryOptionSelect={scraPropertiesFormHandler("rateMatrix")}
            onRadioValueChange={scraPropertiesFormHandler("scraRateMatrix")}
            placeHolderName={"Select offset"}
            dropdownList={data.rateMatrixOption}
            onBlur={handleBlur}
            id="matrix"
            schema={schema}
            uiSchema={matrixTypeUiSchema}
            onAddNewSubmit={addNewOption}
          />
          {errors.rateMatrix && touched.rateMatrix && (
            <ErrorAlert>
              <span>{errors.rateMatrix}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default ScraProperties;
