"use client";

import {
  AccordionComponent,
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  ErrorAlert,
  InputText,
  ModalSelector,
  RadioButtonGroup,
  SplitButton,
  StaticText,
} from "@/components/common";
import AddNewButton from "@/components/common/add-new-button/AddNewButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, Stack, useToast } from "@chakra-ui/react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  SingleTransactionLimitConfigProps,
  singleTransactionLimitConfig,
  getValidationSchema,
} from "./SingleTransactionLimitConfigValidation";
import {
  addNewComponent,
  createComponentGeneric,
} from "../../product-config-client-service";
import { COMPONENT_CLASS } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  getFeeCalcDtlUiSchema,
  feeCalcDtlUnRequire,
  deepCopy,
} from "@finxact/finxact-shared-ui";
import {
  COMPONENT_CLASS_ENUM,
  LimitComponentData,
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { preventMainFormSubmitOnRJSFSubmit } from "@/utils/common";
import { Option, RjsfData } from "@/utils/types";

type Props = {
  data: {
    definedByOptions: Option<string>[];
    violationActionOptions: Option<string>[];
    violationFeeOptions: string[];
    transactionCodes: Option<string>[];
    feeCalcSchema: OptionsSchema;
    addNewFeeCalcEndpoint: string;
    limitComponentOptionsData: PostSchema;
    formData: LimitComponentData | null;
  };
};

const SingleTransactionLimitConfig = (props: Props) => {
  const toast = useToast();

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    productDetails,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  // Form Submit Handler
  const onSubmit = async (
    values: { perTrnLimits: SingleTransactionLimitConfigProps[] },
    actions: any
  ) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["limit"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      const res = await createComponentGeneric({
        productDetails,
        formData: {
          ...props.data.formData,

          perTrnLimits: values.perTrnLimits.map((limit) => ({
            definedBy: parseInt(limit.definedBy),
            increCrAmt: parseFloat(limit.increCrAmt),
            increDrAmt: parseFloat(limit.increDrAmt),
            maxBal: parseFloat(limit.maxBal),
            maxCrAmt: parseFloat(limit.maxCrAmt),
            maxDrAmt: parseFloat(limit.maxDrAmt),
            minBal: parseFloat(limit.minBal),
            minCrAmt: parseFloat(limit.minCrAmt),
            minDrAmt: parseFloat(limit.minDrAmt),
            name: limit.name,
            trnCodeExcl: limit.trnCodeExcl,
            violationAct: parseInt(limit.violationAct),
            violationFee: limit.violationFee,
          })),
        },
        toastOptions: {
          toast,
          toastId: "limi-config",
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
        componentClass: COMPONENT_CLASS_ENUM.componentLimit,
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  const feeCalcSchema = useMemo(() => {
    const { actions, name } = props.data.feeCalcSchema;
    return { name, ...actions.POST };
  }, [props.data.feeCalcSchema]);

  const addNewOption = (index: number) => async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.addNewFeeCalcEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue(`perTrnLimits.${index}.violationFee`, response.name);
  };
  // Handler for all the form fields
  const handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`perTrnLimits.${index}.${key}`, value);
  };

  const handleOnBlur = (key: string, index: number) => () => {
    setFieldTouched(`perTrnLimits.${index}.${key}`, true);
  };

  let initialLimits: SingleTransactionLimitConfigProps[] = props.data.formData
    ? props.data.formData?.perTrnLimits?.map((limit, idx) => ({
        idx: idx,
        definedBy: limit.definedBy?.toString() ?? "",
        increCrAmt: limit.increCrAmt?.toString() ?? "",
        increDrAmt: limit.increDrAmt?.toString() ?? "",
        maxBal: limit.maxBal?.toString() ?? "",
        maxCrAmt: limit.maxCrAmt?.toString() ?? "",
        maxDrAmt: limit.maxDrAmt?.toString() ?? "",
        minBal: limit.minBal?.toString() ?? "",
        minCrAmt: limit.minCrAmt?.toString() ?? "",
        minDrAmt: limit.minDrAmt?.toString() ?? "",
        name: limit.name,
        trnCodeExcl: limit.trnCodeExcl ?? [],
        violationAct: limit.violationAct?.toString() ?? "",
        feeViolated: Boolean(limit.violationFee),
        violationFee: limit.violationFee === "" ? "" : limit.violationFee,
        feeForViolationOption: limit.violationFee !== "" ? "PRIMARY" : "",
      }))
    : [];

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.limitComponentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.limitComponentOptionsData]);

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: { perTrnLimits: initialLimits },
  });
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    setFieldValue,
    setFieldTouched,
    dirty,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);
  const errors: any = formik.errors;

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
      checkFormStatus?.(false);
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <FormikProvider value={formik}>
      <form
        onSubmit={preventMainFormSubmitOnRJSFSubmit(formik.handleSubmit)}
        id="finxact-form"
        noValidate
      >
        <FieldArray
          name="perTrnLimits"
          render={({ insert, remove, push }) => (
            <>
              {values.perTrnLimits?.map((limit: any, index) => (
                <CardContainer key={limit.idx}>
                  <AccordionComponent
                    accordionTitle={`Single Transaction Limit ${index + 1}`}
                    limitName={limit.name}
                    deleteIcon
                    onDeleteHandler={() => remove(index)}
                    isExpand={0}
                  >
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the limit name?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("name")
                            ?.tooltip_text_main
                        }
                      />
                      <InputText
                        value={limit.name}
                        onChange={handleOnChange("name", index)}
                        onBlur={handleOnBlur("name", index)}
                      />
                      {touched.perTrnLimits &&
                        touched.perTrnLimits[index]?.name &&
                        errors.perTrnLimits?.[index]?.name && (
                          <ErrorAlert>
                            <span>{errors.perTrnLimits[index].name}</span>
                          </ErrorAlert>
                        )}
                    </Box>

                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="Who is the limit defined by?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("definedBy")
                            ?.tooltip_text_main
                        }
                      />

                      <RadioButtonGroup
                        value={limit.definedBy}
                        onChange={handleOnChange("definedBy", index)}
                        isBoxedRadio={true}
                        radioPropList={props.data.definedByOptions}
                        stackDirection="column"
                        spacing="1rem"
                        onBlur={handleOnBlur("definedBy", index)}
                      />
                      {touched.perTrnLimits &&
                        touched.perTrnLimits[index]?.definedBy &&
                        errors.perTrnLimits?.[index]?.definedBy && (
                          <ErrorAlert>
                            <span>{errors.perTrnLimits[index].definedBy}</span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel labelName="What is the minimum required balance?" />
                      <AppNumberInput
                        labelName="Min"
                        value={limit.minBal}
                        onChange={handleOnChange("minBal", index)}
                        valuePrefix="$"
                        onBlur={handleOnBlur("minBal", index)}
                      />
                      {touched.perTrnLimits &&
                        touched.perTrnLimits[index]?.minBal &&
                        errors.perTrnLimits?.[index]?.minBal && (
                          <ErrorAlert>
                            <span>{errors.perTrnLimits[index].minBal}</span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel labelName="What is the maximum balance?" />
                      <AppNumberInput
                        labelName="Max"
                        value={limit.maxBal}
                        onChange={handleOnChange("maxBal", index)}
                        valuePrefix="$"
                        onBlur={handleOnBlur("maxBal", index)}
                      />
                      {touched.perTrnLimits &&
                        touched.perTrnLimits[index]?.maxBal &&
                        errors.perTrnLimits?.[index]?.maxBal && (
                          <ErrorAlert>
                            <span>{errors.perTrnLimits[index].maxBal}</span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What are the minimum and maximum allowable amount per credit transaction?"
                        tooltipDesc={
                          (tooltipFlyoutDetails?.tooltipsMap?.get("minCrAmt")
                            ?.tooltip_text_main || "") +
                          (tooltipFlyoutDetails?.tooltipsMap?.get("maxCrAmt")
                            ?.tooltip_text_main || "")
                        }
                      />
                      <Stack direction="row" width="60%" spacing="32px">
                        <Box flexBasis="50%">
                          <AppNumberInput
                            labelName="Min"
                            value={limit.minCrAmt}
                            onChange={handleOnChange("minCrAmt", index)}
                            valuePrefix="$"
                            onBlur={handleOnBlur("minCrAmt", index)}
                          />
                          {touched.perTrnLimits &&
                            touched.perTrnLimits[index]?.minCrAmt &&
                            errors.perTrnLimits?.[index]?.minCrAmt && (
                              <ErrorAlert>
                                <span>
                                  {errors.perTrnLimits[index].minCrAmt}
                                </span>
                              </ErrorAlert>
                            )}
                        </Box>
                        <Box flexBasis="50%">
                          <AppNumberInput
                            labelName="Max"
                            value={limit.maxCrAmt}
                            onChange={handleOnChange("maxCrAmt", index)}
                            valuePrefix="$"
                            onBlur={handleOnBlur("maxCrAmt", index)}
                          />
                          {touched.perTrnLimits &&
                            touched.perTrnLimits[index]?.maxCrAmt &&
                            errors.perTrnLimits?.[index]?.maxCrAmt && (
                              <ErrorAlert>
                                <span>
                                  {errors.perTrnLimits[index].maxCrAmt}
                                </span>
                              </ErrorAlert>
                            )}
                        </Box>
                      </Stack>
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What are the minimum and maximum allowable amount per debit transaction?"
                        tooltipDesc={
                          (tooltipFlyoutDetails?.tooltipsMap?.get("minDrAmt")
                            ?.tooltip_text_main || "") +
                          (tooltipFlyoutDetails?.tooltipsMap?.get("maxDrAmt")
                            ?.tooltip_text_main || "")
                        }
                      />
                      <Stack direction="row" width="60%" spacing="32px">
                        <Box flexBasis="50%">
                          <AppNumberInput
                            labelName="Min"
                            value={limit.minDrAmt}
                            onChange={handleOnChange("minDrAmt", index)}
                            valuePrefix="$"
                            onBlur={handleOnBlur("minDrAmt", index)}
                          />
                          {touched.perTrnLimits &&
                            touched.perTrnLimits[index]?.minDrAmt &&
                            errors.perTrnLimits?.[index]?.minDrAmt && (
                              <ErrorAlert>
                                <span>
                                  {errors.perTrnLimits[index].minDrAmt}
                                </span>
                              </ErrorAlert>
                            )}
                        </Box>
                        <Box flexBasis="50%">
                          <AppNumberInput
                            labelName="Max"
                            value={limit.maxDrAmt}
                            onChange={handleOnChange("maxDrAmt", index)}
                            valuePrefix="$"
                            onBlur={handleOnBlur("maxDrAmt", index)}
                          />
                          {touched.perTrnLimits &&
                            touched.perTrnLimits[index]?.maxDrAmt &&
                            errors.perTrnLimits?.[index]?.maxDrAmt && (
                              <ErrorAlert>
                                <span>
                                  {errors.perTrnLimits[index].maxDrAmt}
                                </span>
                              </ErrorAlert>
                            )}
                        </Box>
                      </Stack>
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the incremental per transaction debit amount?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("increDrAmt")
                            ?.tooltip_text_main
                        }
                      />
                      <AppNumberInput
                        valuePrefix="$"
                        value={limit.increDrAmt}
                        onChange={handleOnChange("increDrAmt", index)}
                        onBlur={handleOnBlur("increDrAmt", index)}
                      />
                      {touched.perTrnLimits &&
                        touched.perTrnLimits[index]?.increDrAmt &&
                        errors.perTrnLimits?.[index]?.increDrAmt && (
                          <ErrorAlert>
                            <span>
                              {errors.perTrnLimits[index].debitAmount}
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the incremental per transaction credit amount?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("increCrAmt")
                            ?.tooltip_text_main
                        }
                      />
                      <AppNumberInput
                        valuePrefix="$"
                        value={limit.increCrAmt}
                        onChange={handleOnChange("increCrAmt", index)}
                        onBlur={handleOnBlur("increCrAmt", index)}
                      />
                      {touched.perTrnLimits &&
                        touched.perTrnLimits[index]?.increCrAmt &&
                        errors.perTrnLimits?.[index]?.increCrAmt && (
                          <ErrorAlert>
                            <span>{errors.perTrnLimits[index].increCrAmt}</span>
                          </ErrorAlert>
                        )}
                    </Box>

                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="If violated, what is the violation action?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("violationAct")
                            ?.tooltip_text_main
                        }
                        labelFor="violation_action"
                      />
                      <SelectDropdown
                        value={limit.violationAct}
                        onChange={handleOnChange("violationAct", index)}
                        dropdownList={props.data.violationActionOptions}
                        onBlur={handleOnBlur("violationAct", index)}
                        id="violation_action"
                      />
                      {touched.perTrnLimits &&
                        touched.perTrnLimits[index]?.violationAct &&
                        errors.perTrnLimits?.[index]?.violationAct && (
                          <ErrorAlert>
                            <span>
                              {errors.perTrnLimits[index].violationAct}
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="Do you want to charge a fee for violating the single transaction limit?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("feeViolated")
                            ?.tooltip_text_main
                        }
                      />
                      <SplitButton
                        value={limit.feeViolated}
                        onSelect={handleOnChange("feeViolated", index)}
                        leftBtnName="Yes"
                        rightBtnName="No"
                        onBlur={handleOnBlur("feeViolated", index)}
                      />
                      {touched.perTrnLimits &&
                        touched.perTrnLimits[index]?.feeViolated &&
                        errors.perTrnLimits?.[index]?.feeViolated && (
                          <ErrorAlert>
                            <span>
                              {errors.perTrnLimits[index].feeViolated}
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>

                    {limit.feeViolated && (
                      <Box className="app-form-field-container">
                        <AppFormLabel
                          labelName="What is the fee for a violation?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get(
                              "violationFee"
                            )?.tooltip_text_main
                          }
                          labelFor="violation"
                        />
                        <CoupleRadioWithDropdown
                          primaryRadioName="Use existing violation fee"
                          secondaryRadioName="Add new violation fee"
                          primaryOptionValue={limit.violationFee}
                          handlePrimaryOptionSelect={handleOnChange(
                            "violationFee",
                            index
                          )}
                          onRadioValueChange={handleOnChange(
                            "feeForViolationOption",
                            index
                          )}
                          placeHolderName="Select"
                          dropdownList={props.data.violationFeeOptions}
                          onBlur={handleOnBlur("violationFee", index)}
                          id="violation"
                          uiSchema={getFeeCalcDtlUiSchema()}
                          unRequire={feeCalcDtlUnRequire}
                          schema={feeCalcSchema}
                          onAddNewSubmit={addNewOption(index)}
                        />
                        {touched.perTrnLimits &&
                          touched.perTrnLimits[index]?.violationFee &&
                          errors.perTrnLimits?.[index]?.violationFee && (
                            <ErrorAlert>
                              <span>
                                {errors.perTrnLimits[index].violationFee}
                              </span>
                            </ErrorAlert>
                          )}
                        {touched.perTrnLimits &&
                          touched.perTrnLimits[index]?.feeForViolationOption &&
                          errors.perTrnLimits?.[index]
                            ?.feeForViolationOption && (
                            <ErrorAlert>
                              <span>
                                {
                                  errors.perTrnLimits[index]
                                    .feeForViolationOption
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                    )}
                    <Box>
                      <AppFormLabel
                        labelName="What transaction codes are excluded?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("trnCodeExcl")
                            ?.tooltip_text_main
                        }
                      />
                      <ModalSelector
                        linkName="+ Select transaction code(s)"
                        modalTitle="Select the transaction code"
                        optionList={deepCopy(props.data.transactionCodes)}
                        onChange={handleOnChange("trnCodeExcl", index)}
                        isSingleSelect={false}
                        value={limit.trnCodeExcl}
                      />
                      {touched.perTrnLimits &&
                        touched.perTrnLimits[index]?.trnCodeExcl &&
                        errors.perTrnLimits?.[index]?.trnCodeExcl && (
                          <ErrorAlert>
                            <span>
                              {errors.perTrnLimits[index].trnCodeExcl}
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                  </AccordionComponent>
                </CardContainer>
              ))}
              <Box>
                <AddNewButton
                  label={
                    !values.perTrnLimits?.length
                      ? "Set up a single transaction limit"
                      : "Set up another single transaction limit"
                  }
                  onClick={() => {
                    push({
                      ...singleTransactionLimitConfig,
                      idx: Math.random(),
                    });
                  }}
                />
              </Box>
            </>
          )}
        />
      </form>
    </FormikProvider>
  );
};

export default SingleTransactionLimitConfig;
