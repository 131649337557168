import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Accumulated_Transaction_Limit_Configuration
interface SingleTransactionLimitConfigProps {
  idx: number | string;
  name: string;
  definedBy: string;
  minCrAmt: string;
  maxCrAmt: string;
  minDrAmt: string;
  maxDrAmt: string;
  increCrAmt: string;
  increDrAmt: string;
  violationAct: string;
  feeViolated: SplitBtnInputValType;
  violationFee: string;
  feeForViolationOption: string;
  trnCodeExcl: string[];
  maxBal: string;
  minBal: string;
}
// Defining Intitial Value For Accumulated_Transaction_Limit_Configuration
const singleTransactionLimitConfig: SingleTransactionLimitConfigProps = {
  idx: "",
  name: "",
  definedBy: "",
  minCrAmt: "",
  maxCrAmt: "",
  minDrAmt: "",
  maxDrAmt: "",
  increCrAmt: "",
  increDrAmt: "",
  violationAct: "",
  feeViolated: undefined,
  violationFee: "",
  feeForViolationOption: "",
  trnCodeExcl: [],
  maxBal: "",
  minBal: "",
};
// YUP LIBRARY :Defining Schema For validation of Accumulated_Transaction_Limit_Configuration
const singleTransactionLimitConfigSchema = async (
  optionsSchema: PostSchema
) => {
// declare top level fields specific to this form
  const fields = ["perTrnLimits"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { SingleTransactionLimitConfigProps };
// Exporting Validation Schema and Intial value
export {
  singleTransactionLimitConfig,
  singleTransactionLimitConfigSchema as getValidationSchema,
};
