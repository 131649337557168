import { ROUTE_PATH } from "@/route-config/route-path";
import { FinancialInstitutionData } from "../../model";
import { PRIMARY_RADIO_VALUE, SECONDARY_RADIO_VALUE } from "@/utils/constants";
import { IC_ENTITY } from "@/utils/constants";

import {
  FinOrgCorrespondingNetworkDestValueProps,
  finOrgSpecificParamCorrespondingDestInitialValue,
} from "./corresponding-networks/financial-organziation-destination-network/FinOrgCorrespondingDestinationNetworkValidation";
import { deepCopy } from "@finxact/finxact-shared-ui";

export function constructFinOrgUrlWithParam(
  path: string,
  finOrgId: string,
  corrsIndex?: string
): string {
  const corrsParam = `${corrsIndex ? `${ROUTE_PATH["IC_CORRS_INDEX_QUERY"]}${corrsIndex}` : ""}`;
  return `${path}${ROUTE_PATH["IC_FIN_ORG_ID_QUERY"]}${finOrgId}&entityType=${IC_ENTITY.financial_organization}${corrsParam}`;
}

export function getDescOriginNetworkKey(
  isDestinationNetwork: boolean
): "dest" | "origin" {
  return isDestinationNetwork ? "dest" : "origin";
}

export function constructDescNetworkFormInitialValues(
  formData: FinancialInstitutionData,
  isDestinationNetwork: boolean,
  corrsIndex: string,
  addressOptions: any[]
): FinOrgCorrespondingNetworkDestValueProps {
  const defaultValues = deepCopy(
    finOrgSpecificParamCorrespondingDestInitialValue
  );
  if (corrsIndex && formData?.corrs?.length) {
    const networkKey = getDescOriginNetworkKey(isDestinationNetwork);
    const currCorrsNetworkDetails: FinOrgCorrespondingNetworkDestValueProps | null =
      formData.corrs?.[corrsIndex as any]?.[networkKey]
        ? deepCopy(formData.corrs?.[corrsIndex as any]?.[networkKey]!)
        : null;
    if (!currCorrsNetworkDetails) {
      return defaultValues;
    }
    currCorrsNetworkDetails.partyAddr.addrIdRadio = currCorrsNetworkDetails
      .partyAddr?._Id
      ? PRIMARY_RADIO_VALUE
      : addressOptions?.length
        ? ""
        : SECONDARY_RADIO_VALUE;
    return {
      ...defaultValues,
      ...currCorrsNetworkDetails,
    };
  }
  return defaultValues;
}
