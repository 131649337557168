"use client";

import {
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  ErrorAlert,
  InputText,
  StaticText,
} from "@/components/common";

import { Box, Heading, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  AccountsProps,
  accountsData,
  getValidationSchema,
} from "./AccountsValidation";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { createAccounts, updateAccounts } from "../gl-config-api-service";
import { AccountsData } from "../model";

import "./Accounts.scss";
import {
  GL_CONFIG_ORDER,
  GL_ENTITY,
  GL_STEPS,
  NEW_IC_ENTITY_ID,
} from "@/utils/constants";
import { ROUTE_PATH } from "@/route-config/route-path";
import { useSearchParams } from "next/navigation";
import {
  GLWorkflowResponse,
  WorkflowResponseUnion,
  createWorkflowGeneric,
  getAllWorkflowGeneric,
  updateWorkflowGeneric,
} from "@/api-config/api-service";
import { getGLCopyFlagInStorage } from "../general-ledger-util-service/general-ledger-util-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { getGeneralLedgerAccountGroup } from "@/utils/common";

type AccountProps = {
  data: {
    accountGroupsOption: { label: string; value: number }[];
    glCategoryOptions: { label: string; value: number }[];
    closeReasonOptions: { label: string; value: number }[];
    formData: AccountsData | null;
    accountsOptionsData: PostSchema;
  };
};

const Accounts = (props: AccountProps) => {
  const toast = useToast();
  const toastId = "accounts-toast";
  const accountsDatas = props.data?.formData ?? null;
  const [workFlowData, setWorkFlowData] = useState<
    WorkflowResponseUnion[] | null
  >(null);

  const { accountsOptionsData } = props.data;

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const params = useSearchParams();

  const moveToNextStep = () => {
    if (params?.get("initialFlow")) {
      navigateTo(
        `${ROUTE_PATH.GL_INTERSTITIAL_SCREEN}?entityId=${values.acctGroup}~${values.acctNbr}&entityType=${GL_ENTITY.accounts}&initialFlow=true`
      );
    } else {
      navigateTo(
        `${ROUTE_PATH.GL_COMPLETION_SCREEN}?entityId=${values.acctGroup}~${values.acctNbr}&entityType=${GL_ENTITY.accounts}`
      );
    }
  };

  // Form Submit Handler
  const onSubmit = async (values: AccountsProps) => {
    if (!dirty) {
      if (
        workFlowData?.some(
          (data: any) =>
            data.modelKey === `${values.acctGroup}~${values.acctNbr}`
        )
      ) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
          key: `${values.acctGroup}~${values.acctNbr}`,
          stage: GL_ENTITY.accounts,
          status: GL_STEPS.accounts,
        });
      } else {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
          key: `${values.acctGroup}~${values.acctNbr}`,
          stage: GL_ENTITY.accounts,
          status: GL_STEPS.accounts,
        });
      }

      moveToNextStep();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };
    if (!accountsDatas || getGLCopyFlagInStorage()) {
      response = await createAccounts({
        formData: {
          acctGroup: values.acctGroup,
          acctNbr: values.acctNbr,
          acctTitle: values.acctTitle,
          desc: values.desc,
          glCat: values.glCat,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `GL account created.`,
        },
        stageName: GL_STEPS.accounts,
      });
    } else {
      response = await updateAccounts({
        formData: {
          _vn: accountsDatas._vn,
          acctGroup: values.acctGroup,
          acctNbr: values.acctNbr,
          acctTitle: values.acctTitle,
          desc: values.desc,
          glCat: values.glCat,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: GL_STEPS.accounts,
        updateWorkFlow: workFlowData?.some(
          (data) => data.modelKey === `${values.acctGroup}~${values.acctNbr}`
        )
          ? true
          : false,
      });
    }
    if (response) {
      moveToNextStep();
    }
  };

  const handleOnChange = (key: string) => (value: Date | string | number) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(accountsOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [accountsOptionsData]);

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      acctGroup: accountsDatas?.acctGroup
        ? accountsDatas.acctGroup
        : getGeneralLedgerAccountGroup(
            props.data.accountGroupsOption,
            accountsData.acctGroup
          ),
      acctTitle: accountsDatas?.acctTitle ?? accountsData.acctTitle,
      desc: accountsDatas?.desc ?? accountsData.desc,
      acctNbr: getGLCopyFlagInStorage()
        ? accountsData.acctNbr
        : accountsDatas?.acctNbr ?? accountsData.acctNbr,
      glCat: accountsDatas?.glCat ?? accountsData.glCat,
    },
  });
  const { values, errors, handleSubmit, dirty, touched, setFieldValue } =
    formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    (async () => {
      // Get all GL workflows to determine what page to display
      const [response] = await Promise.all([
        getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.generalLedgerConfig),
      ]);
      // Sort before storing
      response.sort(
        (entityA: GLWorkflowResponse, entityB: GLWorkflowResponse) => {
          return (
            GL_CONFIG_ORDER[entityA.stage] - GL_CONFIG_ORDER[entityB.stage]
          );
        }
      );
      setWorkFlowData(response);
    })();
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
      checkFormStatus?.(false);
    }
    // Find the correct entityId from workflow data for going back
    if (isGoingBack) {
      const backEntityId = entityWorkflowData?.find(
        (entity) => entity.stage === GL_ENTITY.accounting_segments
      )?.modelKey;
      navigateTo(
        `${ROUTE_PATH.GL_ACCOUNTING_SEGMENTS}?entityId=${backEntityId ?? NEW_IC_ENTITY_ID}`
      );
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={formik.handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <Heading className="app-form-field-container-small app-form-field-header">
            General Account Information
          </Heading>
          <StaticText
            textValue={
              "Essential account information including group, description & account number"
            }
            className="app-form-field-sub-header"
          />
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the account group?"
            isRequired
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("acctGroup")
                ?.tooltip_text_main
            }
            labelFor="account-group"
          />
          <Box
            cursor={
              Boolean(accountsDatas?.acctNbr && !getGLCopyFlagInStorage())
                ? "not-allowed"
                : "auto"
            }
          >
            <SelectDropdown
              value={values.acctGroup}
              onChange={handleOnChange("acctGroup")}
              dropdownList={props.data.accountGroupsOption}
              id="account-group"
              disable={Boolean(
                accountsDatas?.acctNbr && !getGLCopyFlagInStorage()
              )}
              clearable={!Boolean(
                accountsDatas?.acctNbr && !getGLCopyFlagInStorage()
              )}
            />
          </Box>
          {errors.acctGroup && touched.acctGroup && (
            <ErrorAlert>
              <span>{errors.acctGroup}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the account title?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("acctTitle")
                ?.tooltip_text_main
            }
            isRequired
          />

          <InputText
            value={values.acctTitle}
            onChange={handleOnChange("acctTitle")}
            label="account title"
          />

          {touched.acctTitle && errors.acctTitle && (
            <ErrorAlert>
              <span>{errors.acctTitle}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the GL account description?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("desc")?.tooltip_text_main
            }
          />
          <InputText
            value={values.desc}
            onChange={handleOnChange("desc")}
            label="GL account description"
          />
          {touched.desc && errors.desc && (
            <ErrorAlert>
              <span>{errors.desc}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the GL category?"
            isRequired
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("glCat")?.tooltip_text_main
            }
            labelFor="gl-category"
          />
          <SelectDropdown
            value={values.glCat}
            onChange={handleOnChange("glCat")}
            dropdownList={props.data.glCategoryOptions}
            placeholder="Select GL category"
            id="gl-category"
          />
          {errors.glCat && touched.glCat && (
            <ErrorAlert>
              <span>{errors.glCat}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the account number?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("acctNbr")
                ?.tooltip_text_main
            }
            isRequired
          />
          <InputText
            value={values.acctNbr}
            onChange={handleOnChange("acctNbr")}
            disabled={Boolean(
              accountsDatas?.acctNbr && !getGLCopyFlagInStorage()
            )}
            label="account number"
          />
          {touched.acctNbr && errors.acctNbr && (
            <ErrorAlert>
              <span>{errors.acctNbr}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default Accounts;
