"use client";
import { TopBannerContainer } from "@/components/common";
import { Box, Text, Show, Hide } from "@/components/ChakraUiManager";
import { usePathname, useSearchParams } from "next/navigation";
import "./AlgoliaSearch.scss";

import { Search } from "@finxact/finxact-shared-ui";
import { useSelector } from "react-redux";
import { getAppConfig } from "@/store";
import { useUserAuthenticated } from "@/app/providers";

export default function AlgoliaSearch() {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const searchQueryRaw = searchParams?.get("searchQuery") || "";
  const slugQuery = searchParams?.get("slug") || "";
  const appConfig = useSelector(getAppConfig);
  const isAuthenticated = useUserAuthenticated();

  return (
    <>
      <TopBannerContainer domId="#algoliaTopBanner">
        <Show above="md">
          <Text as="h2">Global Search Results</Text>
        </Show>
        <Hide above="md">
          <Text as="h3">Global Search Results</Text>
        </Hide>
      </TopBannerContainer>
      <Box className="algolia-global-search-container">
        <Box
          width={{ base: "100%", md: "70%", xl: "50%" }}
          margin={{ base: 0, md: "0 auto" }}
          marginTop={{ base: 12.8, md: 12.8 }}
        >
          <Search
            pathName={pathname}
            consoleConfig={appConfig}
            searchQuery={searchQueryRaw || ""}
            siteFunction="global"
            isAuthenticated={isAuthenticated}
            contextSlug={slugQuery}
          />
        </Box>
      </Box>
    </>
  );
}
