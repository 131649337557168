"use client";

import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  SplitButton,
} from "@/components/common";

import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Box, useToast } from "@chakra-ui/react";

import {
  AccountTypeRelationshipAdditionalSelectionsValueProps,
  accountTypeRelationshipAdditionalSelections,
  getValidationSchema,
} from "./AccountTypeRelationshipAdditionalSelectionsValidation";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { updateAccountType } from "../../ic-config-api-service";
import { AccountTypeData } from "../../model";
import { IC_ENTITY } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { QUERY_PARAM_KEY } from "@/route-config/route-path";
import { constructRelData } from "../../ic-utils-service";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { shouldUpdateWorkflowStatus } from "@/utils/common";

type AccountTypeFoundationalDetailsProps = {
  data: {
    formData: AccountTypeData | null;
    acctTypeOptionsData: PostSchema;
  };
};

const AccountTypeRelationshipAdditionalSelections = (
  props: AccountTypeFoundationalDetailsProps
) => {
  const accntTypRelAddSel = props.data?.formData ?? null;
  const toast = useToast();
  const toastId = "account-type-rel-add-sel";
  //context API

  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    getQueryParam,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const currEntriesIndex = getQueryParam?.(QUERY_PARAM_KEY.ACCT_TYPE_REL_INDEX)
    ? Number(getQueryParam?.(QUERY_PARAM_KEY.ACCT_TYPE_REL_INDEX))
    : 0;
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.acctTypeOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.acctTypeOptionsData]);
  const onSubmit = async (
    values: AccountTypeRelationshipAdditionalSelectionsValueProps,
    actions: any
  ) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?.acctType!,
          stage: IC_ENTITY.account_type,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const relData: any = constructRelData(
      props.data.formData,
      {
        hasAccessRights: values.hasAccessRights,
        isBillSuppressed: values.isBillSuppressed,
        isStmtSuppressed: values.isStmtSuppressed,
        isTaxOwner: values.isTaxOwner,
      },
      currEntriesIndex
    );
    const response = await updateAccountType({
      formData: {
        _vn: props.data.formData?._vn,
        acctType: props.data.formData?.acctType,
        rels: relData,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      navigateToNextPage?.();
    }
  };

  const _handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  // using useFormik hook from Formik Library

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: accntTypRelAddSel
      ? {
          hasAccessRights:
            accntTypRelAddSel.rels?.[currEntriesIndex]?.hasAccessRights ??
            undefined,
          isBillSuppressed:
            accntTypRelAddSel.rels?.[currEntriesIndex]?.isBillSuppressed ??
            undefined,
          isStmtSuppressed:
            accntTypRelAddSel.rels?.[currEntriesIndex]?.isStmtSuppressed ??
            undefined,
          isTaxOwner:
            accntTypRelAddSel.rels?.[currEntriesIndex]?.isTaxOwner ?? undefined,
        }
      : accountTypeRelationshipAdditionalSelections,
  });
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: accntTypRelAddSel
      ? {
          hasAccessRights:
            accntTypRelAddSel.rels?.[currEntriesIndex]?.hasAccessRights ??
            undefined,
          isBillSuppressed:
            accntTypRelAddSel.rels?.[currEntriesIndex]?.isBillSuppressed ??
            undefined,
          isStmtSuppressed:
            accntTypRelAddSel.rels?.[currEntriesIndex]?.isStmtSuppressed ??
            undefined,
          isTaxOwner:
            accntTypRelAddSel.rels?.[currEntriesIndex]?.isTaxOwner ?? undefined,
        }
      : accountTypeRelationshipAdditionalSelections,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  const displayHasAccessRightsTypeOptionError = errors?.hasAccessRights &&
    touched?.hasAccessRights && (
      <ErrorAlert>
        <span>{errors.hasAccessRights}</span>
      </ErrorAlert>
    );

  const displayBillSuppressedTypeOptionError = errors?.isBillSuppressed &&
    touched?.isBillSuppressed && (
      <ErrorAlert>
        <span>{errors.isBillSuppressed}</span>
      </ErrorAlert>
    );

  const displayStmtSuppressed = errors?.isStmtSuppressed &&
    touched?.isStmtSuppressed && (
      <ErrorAlert>
        <span>{errors.isStmtSuppressed}</span>
      </ErrorAlert>
    );
  const displayTaxOwner = errors?.isTaxOwner && touched?.isTaxOwner && (
    <ErrorAlert>
      <span>{errors.isTaxOwner}</span>
    </ErrorAlert>
  );
  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Does the account have access rights?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("hasAccessRights")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="Access rights"
            onSelect={_handleOnChange("hasAccessRights")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.hasAccessRights}
          />
          {displayHasAccessRightsTypeOptionError}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Do you want to suppress bill delivery?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isBillSuppressed")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="Bill suppressed"
            onSelect={_handleOnChange("isBillSuppressed")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isBillSuppressed}
          />
          {displayBillSuppressedTypeOptionError}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Do you want to suppress statement generation?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isStmtSuppressed")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="Statement suppressed"
            onSelect={_handleOnChange("isStmtSuppressed")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isStmtSuppressed}
          />
          {displayStmtSuppressed}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is the account type a tax owner?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isTaxOwner")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="Tax owner"
            onSelect={_handleOnChange("isTaxOwner")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isTaxOwner}
          />
          {displayTaxOwner}
        </Box>
      </CardContainer>
    </form>
  );
};

export default AccountTypeRelationshipAdditionalSelections;
