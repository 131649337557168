"use client";

import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  InputText,
  ModalSelector,
} from "@/components/common";
import "./OriginAndClassification.scss";
import { Box, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import {
  OriginAndClassificationProps,
  getValidationSchema,
  originAndClassification,
} from "./OriginAndClassificationValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useContext, useEffect, useState } from "react";
import { shouldUpdateWorkflowStatus } from "@/utils/common";
import { updateAssetCatalog } from "../../ic-config-api-service";
import { AssetCatalogData } from "../../model";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { IC_ENTITY } from "@/utils/constants";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { Option } from "@/utils/types";

type OriginAndClassificationsProps = {
  data: {
    naicsCode: Option<string>[];
    formData: AssetCatalogData | null;
    assetCatalogueOptionsData: PostSchema;
  };
};

const OriginAndClassification = (props: OriginAndClassificationsProps) => {
  const toast = useToast();
  const toastId = "asset-catalog-toast";
  const assetCatalogData = props.data?.formData ?? null;

  const selectedModalOptions = (option: string) => {
    setFieldValue("naics", option);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.assetCatalogueOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.assetCatalogueOptionsData]);

  const onSubmit = async (
    values: OriginAndClassificationProps,
    actions: any
  ) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: `${props.data.formData?.assetClass}~${props.data.formData?.assetId}`,
          stage: IC_ENTITY.asset_catalog,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }

    const res = await updateAssetCatalog({
      formData: {
        _vn: assetCatalogData?._vn,
        assetClass: assetCatalogData?.assetClass,
        assetId: assetCatalogData?.assetId,
        // PATCH Fields
        naics: values.naics || null,
        issueDesc: values.issueDesc,
        issuerDesc: values.issuerDesc,
      },
      toastOptions: {
        toast: toast,
        toastId: "asset-origin-and-classification",
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (res) {
      navigateToNextPage?.();
    }
  };
  const handleOnChange = (key: string) => (value: string) => {
    setFieldValue(key, value);
  };
  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: assetCatalogData
      ? {
          naics: assetCatalogData?.naics ?? "",
          issueDesc: assetCatalogData?.issueDesc,
          issuerDesc: assetCatalogData?.issuerDesc,
        }
      : originAndClassification,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the NAICS code?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("naics")?.tooltip_text_main
            }
          />
          <ModalSelector
            linkName="+ Select a NAICS code"
            modalTitle="Select a NAICS code"
            optionList={props.data.naicsCode}
            isClearable={false}
            onChange={selectedModalOptions}
            value={values.naics}
          />

          {errors.naics &&
            touched.naics &&
            typeof errors.naics === "string" && (
              <ErrorAlert>
                <span>{errors.naics}</span>
              </ErrorAlert>
            )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the issue description?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("issueDesc")
                ?.tooltip_text_main
            }
            labelFor="issue-description"
          />
          <InputText
            id="issue-description"
            value={values.issueDesc}
            onChange={handleOnChange("issueDesc")}
            onBlur={handleBlur}
          />
          {errors.issueDesc && touched.issueDesc && (
            <ErrorAlert>
              <span>{errors.issueDesc}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the issuer description?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("issuerDesc")
                ?.tooltip_text_main
            }
            labelFor="issuer-description"
          />
          <InputText
            id="issuer-description"
            value={values.issuerDesc}
            onChange={handleOnChange("issuerDesc")}
            onBlur={handleBlur}
          />
          {errors.issuerDesc && touched.issuerDesc && (
            <ErrorAlert>
              <span>{errors.issuerDesc}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default OriginAndClassification;
