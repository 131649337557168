"use client";

import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, useToast } from "@chakra-ui/react";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  LimitConfigProps,
  limitConfig,
  getValidationSchema,
} from "./LimitConfigValidation";
import {
  addNewComponent,
  createComponentGeneric,
} from "../../product-config-client-service";
import { COMPONENT_CLASS, MAIN_FORM_ID } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  getFeeCalcDtlUiSchema,
  feeCalcDtlUnRequire,
  trnStatGroupUiSchema,
} from "@finxact/finxact-shared-ui";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { COMPONENT_CLASS_ENUM } from "@/components/product-management/model/types";
import { preventMainFormSubmitOnRJSFSubmit } from "@/utils/common";
import { RjsfData } from "@/utils/types";

const LimitConfig = (props: { data: any }) => {
  const toast = useToast();
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    productDetails,
    getCurrentStageStatusName,
    sideBarSubMenuIndex,
    sideBarMenuList,
    sideBarMainMenuIndex,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  // Form Submit Handler
  const onSubmit = async (values: any, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["limit"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      const res = await createComponentGeneric({
        productDetails,
        formData: {
          ...props.data.formData,

          accumTrnLimits: values.limits.map((limit: any) => ({
            crAmt: parseFloat(limit.crAmt) ?? null,
            crCnt: parseInt(limit.crCnt) ?? null,
            definedBy: limit.definedBy,
            drAmt: parseFloat(limit.drAmt) ?? null,
            drCnt: parseInt(limit.drCnt) ?? null,
            name: limit.name,
            statGroup: limit.statGroup,
            totAmt: parseFloat(limit.totAmt) ?? null,
            totCnt: parseInt(limit.totCnt) ?? null,
            violationAct: limit.violationAct,
            violationFee: limit.violationFee,
          })),
        },
        toastOptions: {
          toast,
          toastId: "limit-config",
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
        componentClass: COMPONENT_CLASS_ENUM.componentLimit,
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };
  // Handler for all the form fields
  const handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`limits.${index}.${key}`, value);
  };
  const schema = useMemo(() => {
    const { actions, name } = props.data.addNewSchema;
    return { name, ...actions.POST };
  }, [props.data.addNewSchema]);

  const addNewOption = (index: number) => async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue(`limits.${index}.statGroup`, response.statGroup);
  };

  const feeSchema = useMemo(() => {
    const { actions, name } = props.data.addNewFeeSchema;
    return { name, ...actions.POST };
  }, [props.data.addNewFeeSchema]);

  const addNewViolationFeeOption =
    (index: number) => async (rjsfData: RjsfData) => {
      const response = await addNewComponent(
        props.data.addNewOptionEndpointViolationAction,
        rjsfData.formData,
        toast
      );
      if (!response) {
        throw new Error("Error");
      }
      setFieldValue(`limits.${index}.violationFee`, response.name);
    };

  let initialLimits: LimitConfigProps[] =
    props.data.formData?.accumTrnLimits?.length > 0
      ? props.data.formData.accumTrnLimits.map((limit: any, idx: number) => ({
          idx: idx,
          name: limit.name,
          definedBy: limit.definedBy,
          statGroup: limit.statGroup,
          feeOption: limit.statGroup !== "" ? "PRIMARY" : "",
          crAmt: limit.crAmt ?? "",
          crCnt: limit.crCnt ?? "",
          drAmt: limit.drAmt ?? "",
          drCnt: limit.drCnt ?? "",
          totAmt: limit.totAmt ?? "",
          totCnt: limit.totCnt ?? "",
          violationAct: limit.violationAct,
          feeViolated: limit.violationFee === "" ? "selectRight" : "selectLeft",
          violationFee: limit.violationFee === "" ? "" : limit.violationFee,
          feeForViolationOption: limit.violationFee !== "" ? "PRIMARY" : "",
        }))
      : [];

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.limitComponentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.limitComponentOptionsData]);
  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: { limits: initialLimits },
  });
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    dirty,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
      checkFormStatus?.(false);
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <FormikProvider value={formik}>
      <form
        onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
        id={MAIN_FORM_ID}
        noValidate
      >
        <FieldArray
          name="limits"
          render={({ insert, remove, push }) => (
            <>
              {values.limits?.map((limit: any, index: any) => (
                <CardContainer key={limit.idx}>
                  <AccordionComponent
                    accordionTitle={`Accumulated Transaction Limit ${
                      index + 1
                    }`}
                    limitName={limit.name}
                    deleteIcon
                    onDeleteHandler={() => remove(index)}
                    isExpand={0}
                  >
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the limit name?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("name")
                            ?.tooltip_text_main
                        }
                      />
                      <InputText
                        value={limit.name}
                        onChange={handleOnChange("name", index)}
                      />
                      {touched.limits &&
                        touched.limits[index]?.name &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.name && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.name
                              }
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>

                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="Who is the limit defined by?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("definedBy")
                            ?.tooltip_text_main
                        }
                      />

                      <RadioButtonGroup
                        value={limit.definedBy}
                        onChange={handleOnChange("definedBy", index)}
                        isBoxedRadio={true}
                        radioPropList={props.data.definedByOptions}
                        stackDirection="column"
                        spacing="1rem"
                      />
                      {touched.limits &&
                        touched.limits[index]?.definedBy &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.definedBy && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.definedBy
                              }
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the accumulation group?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("statGroup")
                            ?.tooltip_text_main
                        }
                        labelFor="acc_group"
                      />
                      <CoupleRadioWithDropdown
                        primaryRadioName="Use existing accumulation group"
                        secondaryRadioName="Add new accumulation group"
                        placeHolderName="Select"
                        primaryOptionValue={limit.statGroup}
                        handlePrimaryOptionSelect={handleOnChange(
                          "statGroup",
                          index
                        )}
                        onRadioValueChange={handleOnChange("feeOption", index)}
                        dropdownList={props.data.accumulationGroupOptions}
                        id="acc_group"
                        schema={schema}
                        uiSchema={trnStatGroupUiSchema}
                        onAddNewSubmit={addNewOption(index)}
                      />
                      {touched.limits &&
                        touched.limits[index]?.statGroup &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.statGroup && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.statGroup
                              }
                            </span>
                          </ErrorAlert>
                        )}
                      {touched.limits &&
                        touched.limits[index]?.feeOption &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.feeOption && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.feeOption
                              }
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the maximum credit amount for the period?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("crAmt")
                            ?.tooltip_text_main
                        }
                      />
                      <AppNumberInput
                        valuePrefix="$"
                        value={limit.crAmt}
                        onChange={handleOnChange("crAmt", index)}
                      />
                      {touched.limits &&
                        touched.limits[index]?.crAmt &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.crAmt && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.crAmt
                              }
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the maximum credit count for the period?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("crCnt")
                            ?.tooltip_text_main
                        }
                      />
                      <AppNumberInput
                        value={limit.crCnt}
                        onChange={handleOnChange("crCnt", index)}
                      />
                      {touched.limits &&
                        touched.limits[index]?.crCnt &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.crCnt && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.crCnt
                              }
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the maximum debit amount for the period?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("drAmt")
                            ?.tooltip_text_main
                        }
                      />
                      <AppNumberInput
                        valuePrefix="$"
                        value={limit.drAmt}
                        onChange={handleOnChange("drAmt", index)}
                      />
                      {touched.limits &&
                        touched.limits[index]?.drAmt &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.drAmt && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.drAmt
                              }
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the maximum debit count for the period?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("drCnt")
                            ?.tooltip_text_main
                        }
                      />
                      <AppNumberInput
                        value={limit.drCnt}
                        onChange={handleOnChange("drCnt", index)}
                      />
                      {touched.limits &&
                        touched.limits[index]?.drCnt &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.drCnt && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.drCnt
                              }
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the maximum total amount for the period?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("totAmt")
                            ?.tooltip_text_main
                        }
                      />
                      <AppNumberInput
                        valuePrefix="$"
                        value={limit.totAmt}
                        onChange={handleOnChange("totAmt", index)}
                      />
                      {touched.limits &&
                        touched.limits[index]?.totAmt &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.totAmt && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.totAmt
                              }
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the maximum total transaction count for the period?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("totCnt")
                            ?.tooltip_text_main
                        }
                      />
                      <AppNumberInput
                        value={limit.totCnt}
                        onChange={handleOnChange("totCnt", index)}
                      />
                      {touched.limits &&
                        touched.limits[index]?.totCnt &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.totCnt && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.totCnt
                              }
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="If violated, what is the violation action?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("violationAct")
                            ?.tooltip_text_main
                        }
                        labelFor="violation_action"
                      />
                      <SelectDropdown
                        value={limit.violationAct}
                        onChange={handleOnChange("violationAct", index)}
                        dropdownList={props.data.violationActionOptions}
                        id="violation_action"
                      />
                      {touched.limits &&
                        touched.limits[index]?.violationAct &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.violationAct && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.violationAct
                              }
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box
                      className={`${
                        limit.feeViolated ? "app-form-field-container" : ""
                      }`}
                    >
                      <AppFormLabel
                        labelName="Do you want to charge a fee for violating the accumulated transaction limit?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("feeViolated")
                            ?.tooltip_text_main
                        }
                      />
                      <SplitButton
                        value={limit.feeViolated}
                        onSelect={handleOnChange("feeViolated", index)}
                        leftBtnName="Yes"
                        rightBtnName="No"
                      />
                      {touched.limits &&
                        touched.limits[index]?.feeViolated &&
                        errors.limits &&
                        (errors.limits[index] as FormikErrors<LimitConfigProps>)
                          ?.feeViolated && (
                          <ErrorAlert>
                            <span>
                              {
                                (
                                  errors.limits[
                                    index
                                  ] as FormikErrors<LimitConfigProps>
                                )?.feeViolated
                              }
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>

                    {limit.feeViolated && (
                      <Box>
                        <AppFormLabel
                          labelName="What is the fee for a violation?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get(
                              "violationFee"
                            )?.tooltip_text_main
                          }
                          labelFor="violation"
                        />
                        <CoupleRadioWithDropdown
                          primaryRadioName="Use existing violation fee"
                          secondaryRadioName="Add new violation fee"
                          primaryOptionValue={limit.violationFee}
                          handlePrimaryOptionSelect={handleOnChange(
                            "violationFee",
                            index
                          )}
                          onRadioValueChange={handleOnChange(
                            "feeForViolationOption",
                            index
                          )}
                          placeHolderName="Select"
                          dropdownList={props.data.violationFeeOptions}
                          multiselectValue={limit.violationFee}
                          id="violation"
                          schema={feeSchema}
                          uiSchema={getFeeCalcDtlUiSchema()}
                          unRequire={feeCalcDtlUnRequire}
                          onAddNewSubmit={addNewViolationFeeOption(index)}
                        />
                        {touched.limits &&
                          touched.limits[index]?.feeForViolationOption &&
                          errors.limits &&
                          (
                            errors.limits[
                              index
                            ] as FormikErrors<LimitConfigProps>
                          )?.feeForViolationOption && (
                            <ErrorAlert>
                              <span>
                                <span>
                                  {
                                    (
                                      errors.limits[
                                        index
                                      ] as FormikErrors<LimitConfigProps>
                                    )?.feeForViolationOption
                                  }
                                </span>
                              </span>
                            </ErrorAlert>
                          )}
                        {touched.limits &&
                          touched.limits[index]?.violationFee &&
                          errors.limits &&
                          (
                            errors.limits[
                              index
                            ] as FormikErrors<LimitConfigProps>
                          )?.violationFee && (
                            <ErrorAlert>
                              <span>
                                {
                                  (
                                    errors.limits[
                                      index
                                    ] as FormikErrors<LimitConfigProps>
                                  ).violationFee
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                    )}
                  </AccordionComponent>
                </CardContainer>
              ))}
              <Box>
                <AddNewButton
                  label={
                    !values.limits.length
                      ? "Set up an accumulated  transaction limit"
                      : "Set up another accumulated  transaction limit"
                  }
                  onClick={() => {
                    push({ ...limitConfig, idx: Math.random() });
                  }}
                />
              </Box>
            </>
          )}
        />
      </form>
    </FormikProvider>
  );
};

export default LimitConfig;
