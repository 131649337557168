import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import { Option } from "@/utils/types";
import * as Yup from "yup";

export const PRODUCT_AVAILABILITY_OPTIONS: Record<
  string,
  BasicProductFormProps["productAvailability"]
> = {
  customDate: "Enter a custom date",
  matrix: "Leverage an existing matrix",
};

//Defining Interface For Limit_Account_Balance_Configuration
interface BasicProductFormProps {
  // helper fields
  productAvailability:
    | "Enter a custom date"
    | "Select a known date"
    | "Leverage an existing matrix"
    | "";
  isGLMatrixRequired: SplitBtnInputValType;
  // A/C Details
  assetId: string;
  assetClass: number | undefined;
  // GL
  glSetMatrixName: string;
  glSetCode: string;
  // Product operational dates
  avlStartDtmMatrix: string;
  stmtStartDtmOpt: number;
  operationalEndSectionTime: string;
  operationalStartSectionTime: string;
  operationalEndSectionDate: Date | null;
  operationalStartSectionDate: Date | null;
  posnAcctNbrPrefix: string;
  prodGroup: string;
  prodSubType: string;
  prodType: string;
  version: number;
}

const basicProductConfigurationSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "assetClass",
    "assetId",
    "glSetMatrixName",
    "glSetCode",
    "stmtStartDtmOpt",
    "avlStartDtm",
    "avlStartDtmMatrix",
    "avlEndDtm",
    "prodGroup",
    "prodSubType",
    "prodType",
    "version",
    "posnAcctNbrPrefix",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

export type { BasicProductFormProps };
export { basicProductConfigurationSchema as getValidationSchema };
