import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Promotional_Rate_Review_Frequency_&_Rounding
interface PromotionalRateFrequencyProps {
  disbmtOpt: number | undefined;
  isPostNetInt: SplitBtnInputValType;
  postFreq: string;
  postRoundOpt: number | null;
  postFreqMatrix: string;
}
// Defining Intitial Value For Promotional_Rate_Review_Frequency_&_Rounding
const postFrequencyRounding: PromotionalRateFrequencyProps = {
  disbmtOpt: undefined,
  isPostNetInt: undefined,
  postFreq: "",
  postFreqMatrix: "",
  postRoundOpt: null,
};
// YUP LIBRARY :Defining Schema For validation of Promotional_Rate_Review_Frequency_&_Rounding
const postFrequencyRoundingSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "disbmtOpt",
    "isPostNetInt",
    "postFreq",
    "postFreqMatrix",
    "postRoundOpt",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { PromotionalRateFrequencyProps };
// Exporting Validation Schema and Intial value
export {
  postFrequencyRounding,
  postFrequencyRoundingSchema as getValidationSchema,
};
