import { ERROR_MESSAGE } from "@/components/data/form-data";
import * as Yup from "yup";
import { PostSchema } from "../../model/types";
import { generateYupSchema } from "@/utils/common";
// Define the type for the setFieldValue. This will use in entire Form
type SetFieldValueProps = (
  field: string,
  value: any,
  shouldValidate?: boolean
) => Promise<void>;
interface commonTitleProps {
  name: string;
}
//Defining Interface For Fee_General_Feature
interface GeneralFeatureProps {
  componentName: string;
}
// Defining Intitial Value For Fee_General_Feature
const GeneralSetup: GeneralFeatureProps = {
  componentName: "",
};
// YUP LIBRARY :Defining Schema For validation of Fee_General_Feature
const generalFeatureSchema = async (optionsSchema: PostSchema) => {
  const fields = ["componentName"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { commonTitleProps, GeneralFeatureProps, SetFieldValueProps };
// Exporting Validation Schema and Intial value
export { GeneralSetup, generalFeatureSchema as getValidationSchema };
