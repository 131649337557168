import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Lending_Party_Phone
interface LendingPartyPhoneProps {
  phoneType: number | undefined
  cntryCallCode: string;
  data: string;
  extension: string;
  label: string;
  isPreferred: SplitBtnInputValType;
  fromDate: Date | null;
  fromTime: string;
  throughDate: Date | null;
  throughTime: string;
  verifiedDate: Date | null;
  verifiedTime: string;
}
interface lndingPrtyPhnProps {
  phones: LendingPartyPhoneProps[];
}

const lppSortModuleValue: LendingPartyPhoneProps = {
  phoneType: undefined,
  cntryCallCode: "",
  data: "",
  extension: "",
  label: "",
  isPreferred: undefined,
  fromDate: null,
  fromTime: "",
  throughDate: null,
  throughTime: "",
  verifiedDate: null,
  verifiedTime: "",
};
const lendingPartyPhone: lndingPrtyPhnProps = {
  phones: [lppSortModuleValue],
};


const LendingPartyPhoneSchema = async (optionsSchema: PostSchema) => {
  const fields = ["bankparamUSBankInfo"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig) as any;
};
// Exporting Interface
export type { LendingPartyPhoneProps, lndingPrtyPhnProps };
// Exporting Validation Schema and Intial value
export {
  lppSortModuleValue,
  lendingPartyPhone,
  LendingPartyPhoneSchema as getValidationSchema,
};
