import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Account_Type_Relationship_Party_Specifications
interface AccountTypeRelationshipPartySpecificationsValueProps {
  minParties: string;
  maxParties: string;
}
// Defining Intitial Value For Account_Type_Relationship_Party_Specifications
const accountTypeRelationshipPartySpecifications: AccountTypeRelationshipPartySpecificationsValueProps =
  {
    minParties: "",
    maxParties: "",
  };
// YUP LIBRARY :Defining Schema For validation of Account_Type_Relationship_Party_Specifications
const accountTypeRelationshipPartySpecificationsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["rels"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { AccountTypeRelationshipPartySpecificationsValueProps };
// Exporting Validation Schema and Intial value
export {
  accountTypeRelationshipPartySpecifications,
  accountTypeRelationshipPartySpecificationsSchema as getValidationSchema,
};
