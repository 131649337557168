"use client";
import {
  AppFormLabel,
  CardContainer,
  CoupleRadioWithDropdown,
  CommonTitle,
  SplitButton,
  RadioButtonGroup,
  ErrorAlert,
  AddNewButton,
  AccordionComponent,
} from "@/components/common";
import { Box, useToast } from "@/components/ChakraUiManager";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import {
  FeeGeneralConfigurationProps,
  feeConfiguration,
  getValidationSchema,
} from "./FeeConfigurationValidation";
import {
  addNewComponent,
  createComponentGeneric,
} from "../../product-config-client-service";
import {
  COMPONENT_CLASS_ENUM,
  FeeComponentData,
  OptionsSchema,
  PostSchema,
} from "../../../model/types";
import { Option, RjsfData } from "@/utils/types";
import { COMPONENT_CLASS, MAIN_FORM_ID } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  getFeeCalcDtlUiSchema,
  feeCalcDtlUnRequire,
} from "@finxact/finxact-shared-ui";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { preventMainFormSubmitOnRJSFSubmit } from "@/utils/common";

type FeeConfigurationProps = {
  depositName: string;
  featureName: string;
  version: string;
  mainTitle: string;
  subText: string;
  data: {
    feeTypeOptions: Option<number>[];
    feeDetailList: string[];
    formData?: FeeComponentData | null;
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    feeComponentOptionsData: PostSchema;
  };
};

const FeeConfiguration = (props: FeeConfigurationProps) => {
  // Destructuring The Props
  const { depositName, featureName, version, mainTitle, subText, data } = props;
  const toast = useToast();

  const schema = useMemo(() => {
    const { actions, name } = data.addNewSchema;
    return { name, ...actions.POST };
  }, [data.addNewSchema]);

  // Form Submit Handler
  const onSubmit = async (values: FeeGeneralConfigurationProps) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["fee"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    const { formData } = data;
    if (formData && productDetails) {
      const res = await createComponentGeneric({
        productDetails,
        formData: {
          ...formData,

          feeItems: values.feeItems,
        },
        toastOptions: {
          toast,
          toastId: "fee-config",
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
        componentClass: COMPONENT_CLASS_ENUM.componentFee,
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  const addNewOption = (index: number) => async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      data.addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue(`feeItems[${index}].feeName`, response.name);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(data.feeComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [data.feeComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      feeItems:
        data.formData?.feeItems?.map((item, idx) => ({ ...item, idx })) ?? [],
    },
  });

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  /**
   * Component Handler For
   * Fee_Option and Toggle_Button
   */
  const _handleFeeItemChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`feeItems[${index}].${key}`, value);
  };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  // Validating Fee_Detail
  const formikErrors = (
    errors.feeItems as FormikErrors<FeeGeneralConfigurationProps["feeItems"]>
  )?.[0];
  const touchedFields = touched.feeItems?.[0];
  const dispalyFeeDetailError = formikErrors?.feeName &&
    touchedFields?.feeName && (
      <ErrorAlert>
        <span>{formikErrors.feeName}</span>
      </ErrorAlert>
    );
  // Validating Fee_Type_Option
  const displayFeeTypeOptionError = formikErrors?.feeTypeOpt &&
    touchedFields?.feeTypeOpt && (
      <ErrorAlert>
        <span>{formikErrors.feeTypeOpt}</span>
      </ErrorAlert>
    );
  // Validating Fee_Accumulated
  const displayFeeAccumulated = formikErrors?.isAccum &&
    touchedFields?.isAccum && (
      <ErrorAlert>
        <span>{formikErrors.isAccum}</span>
      </ErrorAlert>
    );

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id={MAIN_FORM_ID}
    >
      <FormikProvider value={formik}>
        <FieldArray
          name="feeItems"
          render={({ remove, push }) => (
            <>
              {values.feeItems.map((item, index) => (
                <CardContainer key={item.idx}>
                  <AccordionComponent
                    accordionTitle={`Fee item ${index + 1}`}
                    deleteIcon
                    onDeleteHandler={() => remove(index)}
                    isExpand={0}
                  >
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the fee detail?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("feeItems")
                            ?.tooltip_text_main
                        }
                        labelFor="fee-detail"
                      />
                      <CoupleRadioWithDropdown
                        handlePrimaryOptionSelect={_handleFeeItemChange(
                          "feeName",
                          index
                        )}
                        primaryOptionValue={item.feeName}
                        primaryRadioName={"Use an existing fee"}
                        secondaryRadioName={"Add new fee"}
                        dropdownList={props.data.feeDetailList}
                        onBlur={handleBlur}
                        id={"fee-detail"}
                        schema={schema}
                        uiSchema={getFeeCalcDtlUiSchema()}
                        unRequire={feeCalcDtlUnRequire}
                        onAddNewSubmit={addNewOption(index)}
                        modelViewUrl={API_ROUTE_CONFIGURATION.feeDetails}
                      />
                      {/* we are displaying Fee_Detail_Error based on condition*/}
                      {dispalyFeeDetailError}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the fee type option?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("feeTypeOpt")
                            ?.tooltip_text_main
                        }
                      />
                      <RadioButtonGroup
                        value={item.feeTypeOpt}
                        isBoxedRadio={true}
                        radioPropList={props.data.feeTypeOptions}
                        stackDirection={"column"}
                        spacing={"1rem"}
                        onChange={_handleFeeItemChange("feeTypeOpt", index)}
                      />
                      {/* we are displaying Fee_Type_Option_Error based on condition */}
                      {displayFeeTypeOptionError}
                    </Box>
                    <Box>
                      <AppFormLabel
                        labelName="Would you like to accumulate the fee(s)?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("isAccum")
                            ?.tooltip_text_main
                        }
                      />
                      <SplitButton
                        name="feeConfigAccumulated"
                        onSelect={_handleFeeItemChange("isAccum", index)}
                        leftBtnName="Yes"
                        rightBtnName="No"
                        value={item.isAccum}
                      />
                      {/* we are displaying Fee_Accumulated_Error based on condition*/}
                      {displayFeeAccumulated}
                    </Box>
                  </AccordionComponent>
                </CardContainer>
              ))}
              <Box>
                <AddNewButton
                  label={
                    !values.feeItems?.length
                      ? "Add a fee item"
                      : "Add another fee item"
                  }
                  onClick={() => {
                    push({
                      ...feeConfiguration.feeItems[0],
                      idx: Math.random(),
                    });
                  }}
                />
              </Box>
            </>
          )}
        />
      </FormikProvider>
    </form>
  );
};
export default FeeConfiguration;
