import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface 
interface LoanPenaltyRateProps {
  indexName: string;
  minRate: string;
  maxRate: string;
  offsetMatrix: string;
  round: string;
}
// Defining Intitial Value 
const LoanPenaltyRateDetails: LoanPenaltyRateProps = {
  indexName: "",
  minRate: "",
  maxRate: "",
  offsetMatrix: "",
  round: "",
};

const LoanPenaltySchema = async (optionsSchema: PostSchema) => {
  const fields = ["lnPenRate"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
 
  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { LoanPenaltyRateProps };
// Exporting Validation Schema and Intial value
export { LoanPenaltyRateDetails, LoanPenaltySchema as getValidationSchema };
