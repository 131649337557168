import { GENERIC_ERROR } from "@/components/data/error-data";
import * as Yup from "yup";
import { Option } from "@/utils/types";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
//Defining Interface For Asset_General_Ledger_Details

interface GLMatrixValue extends Option<string> {
  isSelected: boolean;
}
interface GeneralLedgerDetailsProps {
  exchGlAcctGroup: number | undefined;
  exchGlAcctNbr: string;
  unrealGainAcctGroup: number | undefined;
  unrealGainAcctNbr: string;
  unrealLossAcctGroup: number | undefined;
  unrealLossAcctNbr: string;
}

// Defining Intitial Value For Asset_General_Ledger_Details
const generalLedgerDetails: GeneralLedgerDetailsProps = {
  exchGlAcctGroup: undefined,
  exchGlAcctNbr: "",
  unrealGainAcctGroup: undefined,
  unrealGainAcctNbr: "",
  unrealLossAcctGroup: undefined,
  unrealLossAcctNbr: "",
};

// YUP LIBRARY : Defining Schema For Validation Of Asset_General_Ledger_Details
const generalLedgerDetailsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["exchGlAcctGroup","exchGlAcctNbr","unrealGainAcctGroup","unrealGainAcctNbr","unrealLossAcctGroup","unrealLossAcctNbr"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { GeneralLedgerDetailsProps };

// Exporting Validation Schema and Intial value
export { generalLedgerDetails, generalLedgerDetailsSchema as getValidationSchema };
