import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { ApiClient } from "@/api-config/api-client";
import {
  getCoreModelEndpoint,
  createComponent,
  updateComponent,
  updateWorkflowGeneric,
  createWorkflowGeneric,
  updateComponentWithPut,
} from "@/api-config/api-service";
import {
  AccountGroupComponentCreatePayload,
  AccountGroupComponentUpdatePayload,
  AccountGroupResponse,
  AccountTypeComponentCreatePayload,
  AccountTypeComponentUpdatePayload,
  AccountTypeResponse,
  AssetCatalogComponentCreatePayload,
  AssetCatalogComponentUpdatePayload,
  AssetCatalogResponse,
  FinOrgComponentCreatePayload,
  FinancialCalendarComponentCreatePayload,
  FinancialCalendarComponentUpdatePayload,
  FinancialCalendarResponse,
  FinancialInstitutionCreatePayload,
  FinancialInstitutionUSBankInfoCreatePayload,
  FinancialInstitutionUSBankInfoUpdatePayload,
  FinancialInstitutionUpdatePayload,
  FinOrgTypeComponentUpdatePayload,
  FinancialInstitutionResponse,
  FinancialOrgFedwireCreatePayload,
  FinancialOrgFedwireUpdatePayload,
  PartyUSBankInfoCreatePayload,
  PartyUSBankInfoUpdatePayload,
} from "./model";
import {
  ACCOUNT_GROUP_STEPS,
  ACCOUNT_TYPE_STEPS,
  ASSET_CATALOG_STEPS,
  DUPLICATE_CONFIG,
  FINANCIAL_CALENDAR_STEPS,
  FINANCIAL_ORGANIZATION_STEPS,
  IC_ENTITY,
} from "@/utils/constants";

export const getAccountGroup = async (
  accountGroupName: string
): Promise<AccountGroupResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`${API_ROUTE_CONFIGURATION.accountGroup}/${accountGroupName}`);
};

export const getAssetCatalog = async (
  assetClass: string,
  assetId: string
): Promise<AssetCatalogResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(
    `${API_ROUTE_CONFIGURATION.assetCatalogue}/${assetClass}/${assetId}`
  );
};

export const getFinancialCalendar = async (
  calendarName: string
): Promise<FinancialCalendarResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`${API_ROUTE_CONFIGURATION.FICalendar}/${calendarName}`);
};

export const getFinancialInstitution = async (
  institutionId: string
): Promise<FinancialInstitutionResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`${API_ROUTE_CONFIGURATION.bankParams}/${institutionId}`);
};

export const getFinancialInstitutionUSBankInfo = async (
  institutionId: string
): Promise<FinancialInstitutionResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(
    `${API_ROUTE_CONFIGURATION.bankParamsUSBankInfo}/${institutionId}`
  );
};

export const getFinancialInstitutionParyUSBankInfo = async (
  institutionId: string
): Promise<FinancialInstitutionResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`${API_ROUTE_CONFIGURATION.partyUSBankInfo}/${institutionId}`);
};

//Get Financial Institution Address
export const getFinancialInstitutionFedwire = async (
  institutionId: string
): Promise<FinancialInstitutionResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`${API_ROUTE_CONFIGURATION.partyOrgFedwire}/${institutionId}`);
};

export const getAccountType = async (
  accountTypeName: string
): Promise<AccountTypeResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`${API_ROUTE_CONFIGURATION.accountType}/${accountTypeName}`);
};
export const getFinancialOrg = async (
  genPartyParamId: string
): Promise<FinancialInstitutionResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api
    .get(`${API_ROUTE_CONFIGURATION.partyOrgFininst}/${genPartyParamId}`)
    .then((res) => res);
};
// Create Account Type
export const createAccountType = async (
  config: AccountTypeComponentCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.accountType}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await createWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.institutionalConfig,
        key: res.acctType,
        stage: IC_ENTITY.account_type,
        status:
          stageName === DUPLICATE_CONFIG
            ? ACCOUNT_TYPE_STEPS.acct_type_summary
            : stageName,
      });
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create Account Group
export const createAccountGroup = async (
  config: AccountGroupComponentCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.accountGroup}`,
    body: JSON.stringify(formData),
  })
    .then(
      async (
        res: Pick<AccountGroupComponentCreatePayload["formData"], "acctGroup">
      ) => {
        if (!toast.isActive(toastId)) {
          toast({
            id: toastId,
            description: successMessage,
            status: "success",
          });
        }
        await createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: res.acctGroup,
          stage: IC_ENTITY.account_group,
          status:
            stageName === DUPLICATE_CONFIG
              ? ACCOUNT_GROUP_STEPS.acc_grp_advanced_config
              : stageName,
        });
        return res;
      }
    )
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create Asset Catalog
export const createAssetCatalog = async (
  config: AssetCatalogComponentCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.assetCatalogue}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: AssetCatalogComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await createWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.institutionalConfig,
        key: `${res.assetClass}~${res.assetId}`,
        stage: IC_ENTITY.asset_catalog,
        status:
          stageName === DUPLICATE_CONFIG
            ? ASSET_CATALOG_STEPS.asset_cat_gen_ledger
            : stageName,
      });
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create Financial Calendar
export const createFinancialCalendar = async (
  config: FinancialCalendarComponentCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.FICalendar}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: FinancialCalendarComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await createWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.institutionalConfig,
        key: res.calendarName,
        stage: IC_ENTITY.financial_calendar,
        status:
          stageName === DUPLICATE_CONFIG
            ? FINANCIAL_CALENDAR_STEPS.fin_cal_holiday_config
            : stageName,
      });
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create Bank Parameters
export const createBankParameters = async (
  config: FinancialInstitutionCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.bankParams}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await createWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.institutionalConfig,
        key: res._Id,
        stage: IC_ENTITY.bank_parameters,
        status: stageName,
      });
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create US Bank Parameters
export const createUSBankParameters = async (
  config: FinancialInstitutionUSBankInfoCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.bankParamsUSBankInfo}`,
    body: JSON.stringify(formData),
  }).catch((e) => {
    let message = e;
    if (typeof e === "object") {
      message = e.errors?.[0]?.errorDesc;
    }
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        description: message,
        status: "error",
      });
    }
    return null;
  });
};

//Create General Party Parameter
export const createFinOrg = async (config: FinOrgComponentCreatePayload) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.partyOrgFininst}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await createWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.institutionalConfig,
        key: res._Id,
        stage: IC_ENTITY.financial_organization,
        status:
          stageName === DUPLICATE_CONFIG
            ? FINANCIAL_ORGANIZATION_STEPS.fin_org_specific_params
            : stageName,
      });
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};
// Create Party US Bank Info
export const createPartyUSBankInfo = async (
  config: PartyUSBankInfoCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.partyUSBankInfo}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }

      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};
// Create Financial Org Address
export const createFinancialOrgFedwire = async (
  config: FinancialOrgFedwireCreatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.partyOrgFedwire}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }

      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Update Financial Calendar
export const updateFinancialCalendar = async (
  config: FinancialCalendarComponentUpdatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.FICalendar}/${formData.calendarName}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: res.calendarName,
          stage: IC_ENTITY.financial_calendar,
          status: stageName,
        });
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Update Account Type
export const updateAccountType = async (
  config: AccountTypeComponentUpdatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.accountType}/${formData.acctType}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: res.acctType,
          stage: IC_ENTITY.account_type,
          status: stageName,
        });
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const updateAccountTypeWithPut = async (
  config: AccountTypeComponentUpdatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;

  return updateComponentWithPut({
    url: `${API_ROUTE_CONFIGURATION.accountType}/${formData.acctType}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: res.acctType,
          stage: IC_ENTITY.account_type,
          status: stageName,
        });
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Update Account Group
export const updateAccountGroup = async (
  config: AccountGroupComponentUpdatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.accountGroup}/${formData.acctGroup}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: res.acctGroup,
          stage: IC_ENTITY.account_group,
          status: stageName,
        });
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Update Asset Catalog
export const updateAssetCatalog = async (
  config: AssetCatalogComponentUpdatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.assetCatalogue}/${formData.assetClass}/${formData.assetId}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: `${res.assetClass}~${res.assetId}`,
          stage: IC_ENTITY.asset_catalog,
          status: stageName,
        });
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Update Bank Parameters
export const updateBankParameters = async (
  config: FinancialInstitutionUpdatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.bankParams}/${formData._Id}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: res._Id,
          stage: IC_ENTITY.bank_parameters,
          status: stageName,
        });
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Update Bank Parameters
export const updateBankParametersUSBankParams = async (
  config: FinancialInstitutionUSBankInfoUpdatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.bankParamsUSBankInfo}/${formData._Id}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: res._Id,
          stage: IC_ENTITY.bank_parameters,
          status: stageName,
        });
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};
// Update General Party Parameter
export const updateFinOrg = async (
  config: FinOrgTypeComponentUpdatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.partyOrgFininst}/${formData._Id}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageName) {
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: res._Id,
          stage: IC_ENTITY.financial_organization,
          status: stageName,
        });
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Update Bank Parameters
export const updatePartyUSBankInfo = async (
  config: PartyUSBankInfoUpdatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.partyUSBankInfo}/${formData._Id}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

//Update Financial Org Address
export const updateFinancialOrgFedwire = async (
  config: FinancialOrgFedwireUpdatePayload
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
  } = config;

  return updateComponent({
    url: `${API_ROUTE_CONFIGURATION.partyOrgFedwire}/${formData.addr?._Id}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};
