import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

interface FinancialOrganizationIdentificationValueProps {
  finInstAba: string;
  finInstBic: string;
}

const financialOrganizationIdentification: FinancialOrganizationIdentificationValueProps =
  {
    finInstAba: "",
    finInstBic: "",
  };

const financialOrganizationIdentificationSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["finInstAba", "finInstBic"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { FinancialOrganizationIdentificationValueProps };
// Exporting Validation Schema and Intial value
export {
  financialOrganizationIdentification,
  financialOrganizationIdentificationSchema as getValidationSchema,
};
