import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Limit_Account_Balance_Configuration
interface ProductEstablishFormProps {
  isCrBureau: SplitBtnInputValType;
  reviewTerm: string;
  svcrId: string;
  crBureauCycleFreq: string;
  crBureauFirstRunOpt: number | undefined;
}

const ProductEstablishFormDetails: ProductEstablishFormProps = {
  crBureauFirstRunOpt: undefined,
  isCrBureau: undefined,
  reviewTerm: "",
  svcrId: "",
  crBureauCycleFreq: "",
};

const basicProductConfigurationSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "isCrBureau",
    "reviewTerm",
    "crBureauFirstRunOpt",
    "crBureauCycleFreq",
    "svcrId",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};
export type { ProductEstablishFormProps };
export {
  ProductEstablishFormDetails,
  basicProductConfigurationSchema as getValidationSchema,
};
