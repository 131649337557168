import { deepCopy } from "@finxact/finxact-shared-ui";
import {
  AddedComponentTypeValue,
  ExistingComponentMap,
} from "../add-component-input/AddComponentInput";

interface AdditionalComponentType {
  componentClass: string;
  componentName: string;
}
interface AdditionalFeaturesState {
  allComponentsForProductType: AdditionalComponentType[];
  allExistingComponents: ExistingComponentMap;
  prodComponents: Record<string, AddedComponentTypeValue>;
}
const initialState: AdditionalFeaturesState = {
  allComponentsForProductType: [],
  allExistingComponents: {},
  prodComponents: {},
};

const additionalFeatureReducer = (
  state: AdditionalFeaturesState,
  action: any
): AdditionalFeaturesState => {
  const { type, payload } = action;
  switch (type) {
    case "ADD_TO_COMPONENTS":
    case "UPDATE_COMPONENT":
      return {
        ...state,
        prodComponents: {
          ...state.prodComponents,
          [payload.addedComponent.componentClass]: payload.addedComponent,
        },
      };

    case "REMOVE_FROM_COMPONENTS": {
      const prodComponents = deepCopy(state.prodComponents);
      delete prodComponents[payload.componentClass];
      return {
        ...state,
        prodComponents,
      };
    }
    case "SET_PRODUCT_COMPONENTS":
      return {
        ...state,
        prodComponents: payload.data,
      };
    case "SET_COMPONENTS_FOR_PROD_TYPE":
      return {
        ...state,
        allComponentsForProductType: payload.data,
      };
    default:
      return state;
  }
};

export { additionalFeatureReducer as reducer, initialState };
export type {
  AdditionalFeaturesState,
  AdditionalComponentType,
  AddedComponentTypeValue as AddedComponentType,
};
