"use client";

import {
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  DragDropContainer,
  ErrorAlert,
  StaticText,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

import { Box, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  InternationalTransferIntentValueProps,
  getValidationSchema,
} from "./InternationalTransferIntentValidation";
import { ROUTE_PATH } from "@/route-config/route-path";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { FIN_ORG_SUB_STEPS, IC_ENTITY } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { Option } from "@/utils/types";
import { useQueryParams, shouldUpdateWorkflowStatus } from "@/utils/common";

interface InternationalTransferIntentProps {
  data: {
    country: Option<string>[];
    formData: FinancialInstitutionData | null;
    partyOrgFinistOptionsData: PostSchema;
  };
}

const InternationalTransferIntent = (
  props: InternationalTransferIntentProps
) => {
  const toast = useToast();
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.partyOrgFinistOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.partyOrgFinistOptionsData]);
  const toastId = "internationalTransferIntent";
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const updateQueryParams = useQueryParams();

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_IDENTIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  const cntryMap = useMemo(() => {
    return props.data.country.reduce<Record<string, string>>((acc, cntry) => {
      acc[cntry.value] = cntry.label;
      return acc;
    }, {});
  }, [props.data.country]);

  // Form Submit Handler
  const onSubmit = async (
    values: InternationalTransferIntentValueProps,
    actions: any
  ) => {
    const currentStage =
      FIN_ORG_SUB_STEPS.gen_party_international_transfer_intent;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: props.data.formData?._vn,
        _Id: props.data.formData?._Id,
        name: props.data.formData?.name,
        intrntlTfrIntent: values.intrntlTfrIntent,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };
  const handleMultiSelectChange = (value: string[]) => {
    setDragSelectionList((prevItem: string[]) => {
      if (value.length > prevItem.length) {
        const newItem: string = value[value?.length - 1];
        return [...prevItem, newItem];
      } else {
        const extraItem = prevItem.filter((item) => !value.includes(item));
        return prevItem.filter((item) => !extraItem.includes(item));
      }
    });
  };
  // Handler for all the form fields
  const handleOnChange = (key: string) => (value: string[]) => {
    setFieldValue(key, value);
    const valForDragdrop = value.map((val) => cntryMap[val]);
    handleMultiSelectChange(valForDragdrop);
  };
  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      intrntlTfrIntent: props.data.formData?.intrntlTfrIntent ?? [],
    },
  });
  const {
    values,
    handleSubmit,
    handleBlur,
    touched,
    setFieldValue,
    dirty,
    errors,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const [dragSelectionList, setDragSelectionList] = useState<string[]>(
    values.intrntlTfrIntent.map((cntryCode) => cntryMap[cntryCode])
  );
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_LOCATION_CONTACT_INFORMATION"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <StaticText heading headingName={"International transfer intent"} />
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the international transfer intent?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("intrntlTfrIntent")
                ?.tooltip_text_main
            }
            labelFor="international-transfer-intent"
          />
          <SelectDropdown
            isMulti
            id="international-transfer-intent"
            value={values.intrntlTfrIntent}
            onChange={handleOnChange("intrntlTfrIntent")}
            dropdownList={props.data.country}
            onBlur={handleBlur}
          />
          {errors.intrntlTfrIntent && touched.intrntlTfrIntent && (
            <ErrorAlert>
              <span>{errors.intrntlTfrIntent as string}</span>
            </ErrorAlert>
          )}
        </Box>
        {values.intrntlTfrIntent?.length > 1 && (
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Drag the selections to prioritize the list."
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("dragPriority")
                  ?.tooltip_text_main
              }
            />
            <DragDropContainer
              items={dragSelectionList}
              setItems={setDragSelectionList}
              displayIndex={true}
            />
          </Box>
        )}
      </CardContainer>
    </form>
  );
};

export default InternationalTransferIntent;
