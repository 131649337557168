import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

interface InternationalTransferIntentValueProps {
  intrntlTfrIntent: string[];
}

const internationalTransferIntents: InternationalTransferIntentValueProps = {
  intrntlTfrIntent: [],
};

const internationalTransferIntentsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["intrntlTfrIntent"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { InternationalTransferIntentValueProps };
// Exporting Validation Schema and Intial value
export {
  internationalTransferIntents,
  internationalTransferIntentsSchema as getValidationSchema,
};
