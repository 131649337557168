"use client";
import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, Text, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  TaxSpecificationsProps,
  getValidationSchema,
  taxSpecificationsInformation,
} from "./TaxSpecificationValidation";
import "./TaxSpecification.scss";
import { ROUTE_PATH } from "@/route-config/route-path";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import { FIN_ORG_SUB_STEPS, IC_ENTITY } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { useQueryParams, shouldUpdateWorkflowStatus } from "@/utils/common";
import { Option } from "@/utils/types";

type TaxSpecProps = {
  data: {
    taxIdOptions: Option<number>[];
    formData: FinancialInstitutionData | null;
    partyOrgFinistOptionsData: PostSchema;
  };
};
const TaxSpecification = ({ data }: TaxSpecProps) => {
  const toast = useToast();
  const toastId = "gpp-tax-specification-details";
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const updateQueryParams = useQueryParams();
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        data.partyOrgFinistOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [data.partyOrgFinistOptionsData]);
  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_GENERAL_PARTY_SPECIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  // Form Submit Handler
  const onSubmit = async (values: TaxSpecificationsProps, actions: any) => {
    const currentStage = FIN_ORG_SUB_STEPS.gen_party_tax_specification;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: data.formData?._vn,
        _Id: data.formData?._Id,
        tinType: values.tinType,
        tin: values.tin,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };
  // Handler for all the form fields
  const handleOnChange = (key: string) => (value: number | string) => {
    setFieldValue(key, value);
  };

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      tinType: data.formData?.tinType ?? taxSpecificationsInformation.tinType,
      tin: data.formData?.tin ?? taxSpecificationsInformation.tin,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_PARTY_PHONE_DETAILS"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <Text className="content-box-title" as="h3">
            {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
              ?.section_title || "Tax specifications"}
          </Text>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel labelName="What is the tax ID type?" />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.tinType}
            onChange={handleOnChange("tinType")}
            radioPropList={data.taxIdOptions}
            stackDirection={"row"}
            spacing={"1rem"}
          />
          {errors.tinType && touched.tinType && (
            <ErrorAlert>
              <span>{errors.tinType}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel labelName="What is the tax ID number?" isRequired />
          <InputText value={values.tin} onChange={handleOnChange("tin")} />
          {errors.tin && touched.tin && (
            <ErrorAlert>
              <span>{errors.tin}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};
export default TaxSpecification;
