import * as Yup from "yup";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
//Defining Interface For corresponding-network-identification
interface CorrespondingNetworkIdentificationValueProps {
  correspondentNetwork: SplitBtnInputValType;
  correspondentIdentifier: string;
  clearingGlPosnId: string;
  inProcessGlPosnId: string;
  network: string;
}
// Defining Intitial Value For corresponding-network-identification
const correspondingNetworkIdentificationDetails: CorrespondingNetworkIdentificationValueProps =
  {
    correspondentNetwork: undefined,
    correspondentIdentifier: "",
    clearingGlPosnId: "",
    inProcessGlPosnId: "",
    network: "",
  };
// YUP LIBRARY :Defining Schema For validation of corresponding-network-identification


const correspondingNetworkIdentificationDetailsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["corrs"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { CorrespondingNetworkIdentificationValueProps };
// Exporting Validation Schema and Intial value
export {
  correspondingNetworkIdentificationDetails,
  correspondingNetworkIdentificationDetailsSchema as getValidationSchema,
};
