"use client";

import {
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  ErrorAlert,
  InputText,
} from "@/components/common";
import "./FinancialCalenderFoundationalDetails.scss";
import { useFormik } from "formik";
import { Box, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  FinancialCalenderFoundationDetailsProps,
  financialCalenderFoundationDetails,
  getValidationSchema,
} from "./FinancialCalenderFoundationalDetailsValidation";
import { FinancialCalendarData } from "../../model";
import {
  createFinancialCalendar,
  updateFinancialCalendar,
} from "../../ic-config-api-service";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  DUPLICATE_CONFIG,
  IC_ENTITY,
  NEW_IC_ENTITY_ID,
  SIDEBAR_STATUS,
} from "@/utils/constants";
import {
  checkIfICWorflowNeedsToBeCreated,
  getIcCopyFlagInStorage,
  setIcCopyFlagInStorage,
} from "../../ic-utils-service";
import { createWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { useQueryParams, useEntityId } from "@/utils/common";

type FinancialCalendarConfigurationProps = {
  data: {
    institutionTimezone: any;
    institutionalCalendarOptions: [];
    formData: FinancialCalendarData | null;
    calendarOptionsData: PostSchema;
  };
};

const FinancialCalenderFoundationalDetails = (
  props: FinancialCalendarConfigurationProps
) => {
  const toast = useToast();
  const toastId = "financial-calendar";
  const updateQueryParams = useQueryParams();
  const financialCalendarData = props.data?.formData ?? null;
  const entityId = useEntityId();
  const isDuplicateConfig = getIcCopyFlagInStorage();

  const { calendarOptionsData } = props.data;

  const onSubmit = async (
    values: FinancialCalenderFoundationDetailsProps,
    actions: any
  ) => {
    if (!dirty) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.financial_calendar
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: IC_ENTITY.financial_calendar,
          status: getCurrentStageStatusName?.() ?? "",
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };
    if (!financialCalendarData || isDuplicateConfig) {
      response = await createFinancialCalendar({
        formData: {
          ...props.data?.formData,
          calendarName: values.calendarName,
          tmZoneCode: values.tmZoneCode,
          refCalendar: values.refCalendar.map((obj) => ({
            refCalendarName: obj.value,
          })),
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: "Financial calendar created.",
        },

        stageName: isDuplicateConfig
          ? DUPLICATE_CONFIG
          : getCurrentStageStatusName?.() ?? "",
      });
    } else {
      response = await updateFinancialCalendar({
        formData: {
          _vn: financialCalendarData._vn,
          calendarName: values.calendarName,
          tmZoneCode: values.tmZoneCode,
          refCalendar: values.refCalendar.map((obj) => ({
            refCalendarName: obj.value,
          })),
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${configPageTitle} updated.`,
        },
        // Don't udpate workflow for first step of each entity as it will be handled while creating the Entity
        stageName: "",
      });
    }
    if (response) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.financial_calendar
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: IC_ENTITY.financial_calendar,
          status: getCurrentStageStatusName?.() ?? "",
        });
      }
      setIcCopyFlagInStorage(false);
      updateFormStatus?.(SIDEBAR_STATUS.completed);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
      navigateTo(
        `${
          ROUTE_PATH.IC_FINANCIAL_CALENDER_BUSINESS_DAY_CONFIG
        }?${updateQueryParams("entityId", response.calendarName)}`
      );
    }
  };
  const handleOnChange =
    (key: string) => (value: string | Record<string, string>[]) => {
      setFieldValue(key, value);
    };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(calendarOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [calendarOptionsData]);
  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      calendarName: isDuplicateConfig
        ? financialCalenderFoundationDetails.calendarName
        : financialCalendarData?.calendarName ??
          financialCalenderFoundationDetails.calendarName,
      tmZoneCode:
        financialCalendarData?.tmZoneCode ??
        financialCalenderFoundationDetails.tmZoneCode,
      refCalendar:
        financialCalendarData?.refCalendar?.map((obj) => ({
          value: obj.refCalendarName,
          label: obj.refCalendarName,
        })) ?? [],
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      const backEntityId = entityWorkflowData?.find(
        (entity) => entity.stage === IC_ENTITY.account_type
      )?.modelKey;
      navigateTo(
        `${ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_SUMMARY}?entityId=${backEntityId ?? NEW_IC_ENTITY_ID}`
      );
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the calendar name?"
            isRequired={true}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("calenderName")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.calendarName}
            onChange={handleOnChange("calendarName")}
            onBlur={handleBlur}
            disabled={Boolean(
              props.data.formData?.calendarName && !isDuplicateConfig
            )}
          />
          {errors.calendarName && touched.calendarName && (
            <ErrorAlert>
              <span>{errors.calendarName}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the calendar timezone?"
            isRequired={true}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("tmZoneCode")
                ?.tooltip_text_main
            }
            labelFor="calendar_timezone"
          />
          <SelectDropdown
            value={values.tmZoneCode}
            onChange={handleOnChange("tmZoneCode")}
            dropdownList={props.data.institutionTimezone}
            placeholder="Select"
            id="calendar_timezone"
          />
          {errors.tmZoneCode && touched.tmZoneCode && (
            <ErrorAlert>
              <span>{errors.tmZoneCode}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Would you like to use a reference calendar?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("refCalender")
                ?.tooltip_text_main
            }
            labelFor="reference_calendar"
          />
          <SelectDropdown
            isMulti
            valueType="object"
            value={values.refCalendar}
            onChange={handleOnChange("refCalendar")}
            dropdownList={props.data.institutionalCalendarOptions.filter(
              (ele: any) => ele.label !== props.data.formData?.calendarName
            )}
            placeholder="Select"
            id="reference_calendar"
          />
          {errors.refCalendar && touched.refCalendar && (
            <ErrorAlert>
              <span>{errors.refCalendar as string}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default FinancialCalenderFoundationalDetails;
