"use client";
import {
  Box,
  Table,
  Th,
  Thead,
  Tr,
  useToast,
  Text,
  Tbody,
  Td,
} from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CardContainer,
  CoupleRadioWithDropdown,
  DurationInput,
  ErrorAlert,
  FrequencyModule,
  RadioButtonGroup,
} from "@/components/common";
import SplitButton, {
  SplitBtnInputValType,
} from "@/components/common/split-button/SplitButton";
import { useContext, useEffect, useMemo, useState } from "react";
import NegativeStyles from "./NegativeLimitDetails.module.scss";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  NegativeLimitDetailsProps,
  getValidationSchema,
  negativeLimitDetails,
} from "./NegativeLimitDetailsValidation";
import { useFormik } from "formik";
import { COMPONENT_CLASS } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import {
  addNewComponent,
  createComponentGeneric,
} from "../../product-config-client-service";
import {
  COMPONENT_CLASS_ENUM,
  NsfComponentResponse,
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { exceptRuleUiSchema } from "@finxact/finxact-shared-ui";
import { preventMainFormSubmitOnRJSFSubmit } from "@/utils/common";
import { Option, RjsfData } from "@/utils/types";

type NSFConfigurationProps = {
  data: {
    negativeLimitOptions: string[];
    nsfDrRestrictOpt: Option<string>[];
    negLimitStartOption: Option<string>[];
    formData?: NsfComponentResponse["data"][number] | null;
    negativeLimitDetailsOptionsData: PostSchema;
    addNewExceptRuleEndpoint: string;
    exceptRuleSchema: OptionsSchema;
  };
};
const EXISTING_AND_NEW = [
  {
    label: "Select from existing overdraft limit matrix",
    value: "leverage_existing",
  },
  {
    label: "Add a new fixed overdraft limit",
    value: "add_new",
  },
];
const NegativeLimitDetails = (props: NSFConfigurationProps) => {
  const { formData, addNewExceptRuleEndpoint, exceptRuleSchema } = props.data;
  const toast = useToast();

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.negativeLimitDetailsOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.negativeLimitDetailsOptionsData]);
  const onSubmit = async (values: NegativeLimitDetailsProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["nsf"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      const res = await createComponentGeneric({
        productDetails,
        formData: {
          ...formData,
          negLimitMatrix:
            values.negLimitOpt === EXISTING_AND_NEW[0].value
              ? values.negLimitMatrix
              : null,
          negLimitFreq:
            values.negLimitOpt === EXISTING_AND_NEW[0].value
              ? values.negLimitFreq
              : null,
          negLimitMax:
            values.negLimitOpt === EXISTING_AND_NEW[0].value
              ? parseFloat(values.negLimitMax)
              : parseFloat(""),
          negLimitMin:
            values.negLimitOpt === EXISTING_AND_NEW[0].value
              ? parseFloat(values.negLimitMin)
              : parseFloat(""),
          negLimit:
            values.negLimitOpt === EXISTING_AND_NEW[1].value
              ? parseFloat(values.negLimit)
              : parseFloat(""),
          negLimitStartOpt: values.negLimitStartOpt,
          negLimitOpenDur:
            values.negLimitStartOpt === 3 ? values.negLimitOpenDur : "",
          isNegLimitOptIn: values.isNegLimitOptIn,
          isAutoAuth: values.isAutoAuth,
          isWaiveNsfFee: values.isWaiveNsfFee,
          isNegLimitNsfFee: values.isNegLimitNsfFee,
          negBalAlertDays: parseInt(values.negBalAlertDays),
          nsfDrRestrictDays: parseInt(values.nsfDrRestrictDays),
          nsfDrRestrict: values.nsfDrRestrict ? values.nsfDrRestrict : null,
          nsfDrRestrictPosBalDays: parseInt(values.nsfDrRestrictPosBalDays),
        },
        toastOptions: {
          toast,
          toastId: "nfs-negative-limit-details",
          successMessage: `${configPageTitle} updated.`,
        },

        stageName: getWorflowStatusToBeUpdated?.() ?? "",
        componentClass: COMPONENT_CLASS_ENUM.componentNsf,
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  // using useFormik hook from Formik Library
  const { values, handleSubmit, errors, touched, setFieldValue, dirty } =
    useFormik({
      onSubmit,
      validationSchema,
      initialValues: {
        negLimitOpt:
          props.data?.formData?.negLimitMatrix?.length! > 0
            ? EXISTING_AND_NEW[0].value
            : props.data?.formData?.negLimit
              ? EXISTING_AND_NEW[1].value
              : undefined,
        negLimitMatrix: props.data?.formData?.negLimitMatrix ?? "",
        negLimitFreq:
          formData?.negLimitFreq ?? negativeLimitDetails.negLimitFreq,
        negLimitMin: formData?.negLimitMin?.toString() ?? "",
        negLimitMax: formData?.negLimitMax?.toString() ?? "",
        negLimit: formData?.negLimit?.toString() ?? "",
        negLimitStartOpt: props.data?.formData?.negLimitStartOpt ?? -1,
        negLimitOpenDur: formData?.negLimitOpenDur ?? "",
        isNegLimitOptIn: formData?.isNegLimitOptIn ?? undefined,
        isAutoAuth: formData?.isAutoAuth ?? undefined,
        isWaiveNsfFee: formData?.isWaiveNsfFee ?? undefined,
        isNegLimitNsfFee: formData?.isNegLimitNsfFee ?? undefined,
        negBalAlertDays: formData?.negBalAlertDays?.toString() ?? "",
        nsfDrRestrictDays: formData?.nsfDrRestrictDays?.toString() ?? "",
        nsfDrRestrict: formData?.nsfDrRestrict ?? "",
        nsfDrRestrictPosBalDays:
          formData?.nsfDrRestrictPosBalDays?.toString() ?? "",
      },
    });

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  //Handling Form
  const negativeLimitFormHandler =
    (key: string) =>
    (value: string | SplitBtnInputValType | string[] | boolean | number) => {
      setFieldValue(key, value);
    };

  const exceptRuleOptionsSchema = useMemo(() => {
    const { actions, name } = exceptRuleSchema;
    return { name, ...actions.POST };
  }, [exceptRuleSchema]);

  const addNewExceptRule = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewExceptRuleEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("nsfDrRestrict", response.code);
  };

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id="finxact-form"
      noValidate
    >
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName={"What is the overdraft limit?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("negLimitOpt")
                ?.tooltip_text_main
            }
            labelFor="negative_limitExisting_dropdown_input"
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.negLimitOpt}
            onChange={negativeLimitFormHandler("negLimitOpt")}
            radioPropList={EXISTING_AND_NEW}
            stackDirection={"row"}
            spacing={12}
          />
          {errors.negLimitOpt && touched.negLimitOpt && (
            <ErrorAlert>
              <span>{errors.negLimitOpt}</span>
            </ErrorAlert>
          )}
        </Box>
        {values.negLimitOpt === EXISTING_AND_NEW[0].value && (
          <>
            <Box
              className={`dashed-left-border ${NegativeStyles["neg-limit-drop"]}`}
            >
              <SelectDropdown
                id="negative_limitExisting_dropdown"
                value={values.negLimitMatrix}
                onChange={negativeLimitFormHandler("negLimitMatrix")}
                dropdownList={props.data.negativeLimitOptions}
                modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
              />
              {errors.negLimitMatrix && touched.negLimitMatrix && (
                <ErrorAlert>
                  <span>{errors.negLimitMatrix}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <FrequencyModule
                value={values.negLimitFreq}
                onChange={negativeLimitFormHandler("negLimitFreq")}
                frequencyOutputContent="A new statement will be available every"
                frequencyQuestionTitle={
                  "How often would you like negative limit frequency balance checks to occur?"
                }
                monthDropdownLabel="On what date should the frequency be implemented?"
                frequencyQuestionDescription={
                  tooltipFlyoutDetails?.tooltipsMap?.get("negLimitFreq")
                    ?.tooltip_text_main
                }
                monthDropdownTooltip={
                  tooltipFlyoutDetails?.tooltipsMap?.get("negLimitFreqMon")
                    ?.tooltip_text_main
                }
                notBusinessDayTooltip={
                  tooltipFlyoutDetails?.tooltipsMap?.get(
                    "negLimitFreqNotBusiness"
                  )?.tooltip_text_main
                }
                notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the negative limit be evaluated?"
              />
              {errors.negLimitFreq && touched.negLimitFreq && (
                <ErrorAlert>
                  <span>{errors.negLimitFreq}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box>
              <Box className={"app-form-field-container"}>
                <AppNumberInput
                  value={values.negLimitMin}
                  labelName="What is the minimum overdraft amount?"
                  onChange={negativeLimitFormHandler("negLimitMin")}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("negLimitMin")
                      ?.tooltip_text_main
                  }
                />
                {errors.negLimitMin && touched.negLimitMin && (
                  <ErrorAlert>
                    <span>{errors.negLimitMin}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className={"app-form-field-container"}>
                <AppNumberInput
                  value={values.negLimitMax}
                  labelName="What is the maximum overdraft amount?"
                  onChange={negativeLimitFormHandler("negLimitMax")}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("negLimitMax")
                      ?.tooltip_text_main
                  }
                />
                {errors.negLimitMax && touched.negLimitMax && (
                  <ErrorAlert>
                    <span>{errors.negLimitMax}</span>
                  </ErrorAlert>
                )}
              </Box>
            </Box>
          </>
        )}
        {values.negLimitOpt === EXISTING_AND_NEW[1].value && (
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"What is the fixed overdraft limit amount?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("negLimit")
                  ?.tooltip_text_main
              }
            />

            <AppNumberInput
              value={values.negLimit}
              onChange={negativeLimitFormHandler("negLimit")}
            />
            {errors.negLimit && touched.negLimit && (
              <ErrorAlert>
                <span>{errors.negLimit}</span>
              </ErrorAlert>
            )}
          </Box>
        )}
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName={"When should overdraft protection start?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("negLimitStartOpt")
                ?.tooltip_text_main
            }
          />
          {props.data.negLimitStartOption.length <= 4 ? (
            <RadioButtonGroup
              isBoxedRadio={true}
              value={values.negLimitStartOpt}
              onChange={negativeLimitFormHandler("negLimitStartOpt")}
              radioPropList={props.data.negLimitStartOption}
              stackDirection={"column"}
              spacing={3.5}
            />
          ) : (
            <SelectDropdown
              id="negLimitStartDropdown"
              value={values.negLimitStartOpt}
              onChange={negativeLimitFormHandler("negLimitStartOpt")}
              dropdownList={props.data.negLimitStartOption}
            />
          )}
          {errors.negLimitStartOpt && touched.negLimitStartOpt && (
            <ErrorAlert>
              <span>{errors.negLimitStartOpt}</span>
            </ErrorAlert>
          )}
        </Box>
        {values.negLimitStartOpt === 3 && (
          <Box className="app-form-field-container">
            <DurationInput
              labelName={"Set the duration from open date"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("negLimitOpenDur")
                  ?.tooltip_text_main
              }
              modalLinkName={"Enter code manually"}
              modalTitle={"Enter the duration"}
              modalLabelName={"What is the duration?"}
              modalLabelTooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("negLimitOpenDur")
                  ?.tooltip_text_main
              }
              onChange={negativeLimitFormHandler("negLimitOpenDur")}
              value={values.negLimitOpenDur}
              showDashedLeftBorder
            />
            {errors.negLimitOpenDur && touched.negLimitOpenDur && (
              <ErrorAlert>
                <span>{errors.negLimitOpenDur}</span>
              </ErrorAlert>
            )}
          </Box>
        )}
        <Box className="app-form-field-container">
          <Table className={NegativeStyles["table"]} variant="unstyled">
            <Thead className={NegativeStyles["custom-table-header"]}>
              <Tr className={NegativeStyles["custom-table-tr"]}>
                <Th className={NegativeStyles["custom-table-th"]}>
                  <Text>Enable any of the following</Text>
                </Th>
                <Th className={NegativeStyles["custom-table-th-right"]}>
                  <Text className={NegativeStyles["custom-table-th-header"]}>
                    TH Header
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr
                className={NegativeStyles["custom-table-data-row-us-specific"]}
              >
                <Td className={NegativeStyles["custom-table-padding"]}>
                  <AppFormLabel
                    labelName={
                      "Should customers be automatically opted-in for overdraft protection?"
                    }
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("isNegLimitOptIn")
                        ?.tooltip_text_main
                    }
                  />
                </Td>
                <Td className={NegativeStyles["custom-table-padding"]}>
                  <SplitButton
                    onSelect={negativeLimitFormHandler("isNegLimitOptIn")}
                    leftBtnName="Yes"
                    rightBtnName="No"
                    value={values.isNegLimitOptIn}
                  />
                  {errors.isNegLimitOptIn && touched.isNegLimitOptIn && (
                    <ErrorAlert>
                      <span>{errors.isNegLimitOptIn}</span>
                    </ErrorAlert>
                  )}
                </Td>
              </Tr>
              <Tr
                className={NegativeStyles["custom-table-data-row-us-specific"]}
              >
                <Td className={NegativeStyles["custom-table-padding"]}>
                  <AppFormLabel
                    labelName={"Automate overdraft approvals?"}
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("isAutoAuth")
                        ?.tooltip_text_main
                    }
                  />
                </Td>
                <Td className={NegativeStyles["custom-table-padding"]}>
                  <SplitButton
                    onSelect={negativeLimitFormHandler("isAutoAuth")}
                    leftBtnName="Yes"
                    rightBtnName="No"
                    value={values.isAutoAuth}
                  />
                  {errors.isAutoAuth && touched.isAutoAuth && (
                    <ErrorAlert>
                      <span>{errors.isAutoAuth}</span>
                    </ErrorAlert>
                  )}
                </Td>
              </Tr>
              <Tr className="custom-table-data-row-us-specific">
                <Td className="custom-table-padding">
                  <AppFormLabel
                    labelName={"Is the NSF fee waived?"}
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("isWaiveNsfFee")
                        ?.tooltip_text_main
                    }
                  />
                </Td>
                <Td className="custom-table-padding">
                  <SplitButton
                    onSelect={negativeLimitFormHandler("isWaiveNsfFee")}
                    leftBtnName="Yes"
                    rightBtnName="No"
                    value={values.isWaiveNsfFee}
                  />
                  {errors.isWaiveNsfFee && touched.isWaiveNsfFee && (
                    <ErrorAlert>
                      <span>{errors.isWaiveNsfFee}</span>
                    </ErrorAlert>
                  )}
                </Td>
              </Tr>
              <Tr className="custom-table-data-last-row">
                <Td className="custom-table-padding">
                  <AppFormLabel
                    labelName={
                      "Will an NSF fee be charged if within the negative limit? "
                    }
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("isNegLimitNsfFee")
                        ?.tooltip_text_main
                    }
                  />
                </Td>
                <Td className="custom-table-padding">
                  <SplitButton
                    onSelect={negativeLimitFormHandler("isNegLimitNsfFee")}
                    leftBtnName="Yes"
                    rightBtnName="No"
                    value={values.isNegLimitNsfFee}
                  />
                  {errors.isNegLimitNsfFee && touched.isNegLimitNsfFee && (
                    <ErrorAlert>
                      <span>{errors.isNegLimitNsfFee}</span>
                    </ErrorAlert>
                  )}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName={"What are the negative balance alert days?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("negBalAlertDays")
                ?.tooltip_text_main
            }
          />

          <AppNumberInput
            value={values.negBalAlertDays}
            onChange={negativeLimitFormHandler("negBalAlertDays")}
          />
          {errors.negBalAlertDays && touched.negBalAlertDays && (
            <ErrorAlert>
              <span>{errors.negBalAlertDays}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName={
              "How many days can a position have non-sufficient fund status before debit restrictions kick in?"
            }
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("nsfDrRestrictDays")
                ?.tooltip_text_main
            }
          />

          <AppNumberInput
            value={values.nsfDrRestrictDays}
            onChange={negativeLimitFormHandler("nsfDrRestrictDays")}
          />
          {errors.nsfDrRestrictDays && touched.nsfDrRestrictDays && (
            <ErrorAlert>
              <span>{errors.nsfDrRestrictDays}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What restriction is placed on the position?"
            labelFor="restrictionCode"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("nsfDrRestrict")
                ?.tooltip_text_main
            }
          />
          <CoupleRadioWithDropdown
            id="restrictionCode"
            primaryOptionValue={values.nsfDrRestrict}
            primaryRadioName={"Select an existing restriction code"}
            secondaryRadioName={"Add a new restriction code"}
            handlePrimaryOptionSelect={negativeLimitFormHandler(
              "nsfDrRestrict"
            )}
            placeHolderName="Select existing restriction code"
            dropdownList={props.data.nsfDrRestrictOpt}
            schema={exceptRuleOptionsSchema}
            uiSchema={exceptRuleUiSchema}
            onAddNewSubmit={addNewExceptRule}
            modelViewUrl={API_ROUTE_CONFIGURATION.exceptRule}
          />
          {errors.nsfDrRestrict && touched.nsfDrRestrict && (
            <ErrorAlert>
              <span>{errors.nsfDrRestrict}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box>
          <AppFormLabel
            labelName={
              "How many days must an position have a positive balance for before the NSF debit restriction is lifted?"
            }
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("nsfDrRestrictPosBalDays")
                ?.tooltip_text_main
            }
          />

          <AppNumberInput
            value={values.nsfDrRestrictPosBalDays}
            onChange={negativeLimitFormHandler("nsfDrRestrictPosBalDays")}
          />
          {errors.nsfDrRestrictPosBalDays &&
            touched.nsfDrRestrictPosBalDays && (
              <ErrorAlert>
                <span>{errors.nsfDrRestrictPosBalDays}</span>
              </ErrorAlert>
            )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default NegativeLimitDetails;
