"use client";

import { Box, Flex, Heading, useToast } from "@/components/ChakraUiManager";
import {
  AccordionComponent,
  AddNewButton,
  CardContainer,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { ROUTE_PATH } from "@/route-config/route-path";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import Image from "next/image";
import { IconEdit } from "public/assets";
import "./FinOrgCorrespondingSummary.scss";
import { constructFinOrgUrlWithParam } from "../../fin-org-specific-param-util-service";
import {
  CorrespondingNetworkDetailsProps,
  FinancialInstitutionResponse,
} from "@/components/institutional-configuration/model";
import {
  getFinancialOrg,
  updateFinOrg,
} from "@/components/institutional-configuration/ic-config-api-service";
import {
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  SIDEBAR_STATUS,
} from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  useEntityId,
  useQueryParams,
  shouldUpdateWorkflowStatus,
} from "@/utils/common";

export default function FinOrgCorrespondingSummary() {
  const [finOrgData, setFinOrgData] = useState<
    FinancialInstitutionResponse["data"][number] | null
  >(null);
  const toast = useToast();
  const toastId = "finOrg-corresponding-network-summary";
  const finOrgId = useEntityId();
  const updateQueryParams = useQueryParams();

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_RULES"]}?${updateQueryParams()}`
      );
    }
    return;
  };

  const onSubmit = (values: any, actions: any) => {
    const currentStage = FIN_ORG_SUB_STEPS.fin_org_network_detail_summary;
    // Update workflow if required
    if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.institutionalConfig,
        key: finOrgData?._Id!,
        stage: IC_ENTITY.financial_organization,
        status: currentStage,
      });
    }
    updateFormStatus?.(SIDEBAR_STATUS.completed);
    updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
    moveToNextStep();
  };

  // using useFormik hook from Formik Library
  const { handleSubmit } = useFormik({
    onSubmit,
    initialValues: "",
  });

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  async function getFinOrgData() {
    const [financialOrgData] = await Promise.all([
      getFinancialOrg(finOrgId),
    ]).then((data) => data);

    if (financialOrgData?.data?.[0]) {
      setFinOrgData(financialOrgData.data[0] as any);
    }
  }

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      if (finOrgData?.corrs?.length === 0) {
        navigateTo(
          `${ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_IDENTIFICATION"]}?${updateQueryParams()}`
        );
      } else {
        navigateTo(
          `${ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_ORIGIN_NETWORK"]}?${updateQueryParams()}`
        );
      }
    }
  }, [isGoingBack, canCheckFormStatus]);

  function deleteCorrsNetwork(index: number) {
    if (finOrgData?.corrs?.[index]) {
      finOrgData.corrs.splice(index, 1);
      updateFinOrgDetails([...finOrgData.corrs]);
    }
  }

  async function updateFinOrgDetails(
    corrsPayload: CorrespondingNetworkDetailsProps[]
  ) {
    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: finOrgData?._vn,
        _Id: finOrgData?._Id,
        name: finOrgData?.name,
        corrs: corrsPayload,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: "Corresponding Network is deleted successfully.",
      },

      stageName: "",
    });
    if (response) {
      getFinOrgData();
    }
  }

  function navigateWithParams(path: string, index?: number, isNewFlow = false) {
    let corrsIndex = index?.toString() || "";
    if (isNewFlow) {
      corrsIndex = finOrgData?.corrs?.length
        ? finOrgData.corrs.length.toString()
        : "0";
    }
    const url = constructFinOrgUrlWithParam(
      path,
      finOrgData?._Id || "",
      corrsIndex
    );
    navigateTo(url);
  }

  useEffect(() => {
    getFinOrgData();
  }, []);

  return (
    <form
      className="fin-org-corresponding-summary-form"
      onSubmit={handleSubmit}
      id="finxact-form"
      noValidate
    >
      {finOrgData?.corrs?.length
        ? finOrgData.corrs.map((corrItem, index) => (
            <>
              <CardContainer
                key={`finOrgSummaryContainer${index}`}
                customClass="app-form-container"
              >
                <AccordionComponent
                  key={`finOrgSummaryAccContainer${index}`}
                  accordionTitle={`Corresponding network details ${index + 1}`}
                  isExpand={0}
                  deleteIcon={true}
                  onDeleteHandler={() => deleteCorrsNetwork(index)}
                >
                  {corrItem.dest ? (
                    <Flex
                      key={`finOrgSummaryAccDestContainer${index}`}
                      className="fin-org-summary-list-item"
                      justifyContent={"space-between"}
                    >
                      <Heading as="h5">Destination network origination</Heading>
                      <Box
                        cursor={"pointer"}
                        onClick={() =>
                          navigateWithParams(
                            ROUTE_PATH[
                              "IC_FIN_ORG_SPEC_PARAM_CORRS_DESTINATION_NETWORK"
                            ],
                            index
                          )
                        }
                      >
                        <Image src={IconEdit} alt="edit icon" />
                      </Box>
                    </Flex>
                  ) : (
                    ""
                  )}
                  {corrItem.origin ? (
                    <Flex
                      key={`finOrgSummaryAccOriginContainer${index}`}
                      className="fin-org-summary-list-item"
                      justifyContent={"space-between"}
                    >
                      <Heading as="h5">
                        Origin network origination identification
                      </Heading>
                      <Box
                        cursor={"pointer"}
                        onClick={() =>
                          navigateWithParams(
                            ROUTE_PATH[
                              "IC_FIN_ORG_SPEC_PARAM_CORRS_ORIGIN_NETWORK"
                            ],
                            index
                          )
                        }
                      >
                        <Image src={IconEdit} alt="edit icon" />
                      </Box>
                    </Flex>
                  ) : (
                    ""
                  )}
                </AccordionComponent>
              </CardContainer>
            </>
          ))
        : ""}
      <Box pt={10} w="85.5%">
        <AddNewButton
          className="fin-org-summary-add-btn"
          label="Add another corresponding network"
          onClick={() =>
            navigateWithParams(
              `${ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_IDENTIFICATION"]}`,
              0,
              true
            )
          }
        />
      </Box>
    </form>
  );
}
