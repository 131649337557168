"use client";

import { Box, useToast } from "@/components/ChakraUiManager";
import {
  CardContainer,
  AppFormLabel,
  SelectDropdown,
  AppNumberInput,
  CommonTitle,
  ErrorAlert,
  DurationInput,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { INTEREST_DEPOSIT_LABELS } from "@/components/data/component-config-label";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  FoundationalInterestProps,
  foundationalInterest,
  getValidationSchema,
} from "./FoundationalInterestValidation";
import { Option } from "@/utils/types";
import {
  COMPONENT_CLASS_ENUM,
  InterestComponentData,
  PostSchema,
} from "../../../model/types";
import { createComponentGeneric } from "../../product-config-client-service";
import { COMPONENT_CLASS } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { REGEX_PATTERN } from "@/utils/regex-pattern";

type Props = {
  data: {
    calcMethodOptions: Option<number>[];
    balanceOptions: Option<number>[];
    indexNameOptions: string[];
    formData: InterestComponentData | null;
    interestComponentOptionsData: PostSchema;
  };
};

export default function FoundationalInterestDeposit(props: Props) {
  const { formData, interestComponentOptionsData } = props.data;
  const {
    WHAT_IS_CALCULATION_METHOD,
    BALANCE_LIKE_TO_USE_TO_CALCULATE_ACCURACY,
    WHAT_IS_MINIMUM_BALANCE,
    FOUNDATIONAL_INTEREST,
    FOUNDATIONAL_INTEREST_DETAILS,
    WHAT_RATE_ADJUSTMENT_TERM,
  } = INTEREST_DEPOSIT_LABELS;
  const toast = useToast();

  // Form Submit Handler
  const onSubmit = async (values: FoundationalInterestProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData) {
      if (productDetails) {
        const res = await createComponentGeneric({
          productDetails,
          formData: {
            // NOTE: _vn, componentName, version is required to do a PATCH update
            ...formData,

            balOpt: values.balOpt,
            calcMthd: values.calcMthd,
            accrMinBal: parseFloat(values.accrMinBal),
            adjTerm: values.adjTerm ? values.adjTerm : "",
          },
          toastOptions: {
            toast,
            toastId: "foundational-int",
            successMessage: `${configPageTitle} updated.`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
          componentClass: COMPONENT_CLASS_ENUM.componentInt,
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      balOpt: formData?.balOpt ?? foundationalInterest.balOpt,
      calcMthd: formData?.calcMthd ?? foundationalInterest.calcMthd,
      accrMinBal:
        formData?.accrMinBal?.toString() ?? foundationalInterest.accrMinBal,
      adjTerm: formData?.adjTerm ?? foundationalInterest.adjTerm,
    },
  });

  // using useFormik hook from Formik Library
  const { values, handleSubmit, errors, touched, setFieldValue, dirty } =
    formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Handling Form
  const foundationalInterestFormHandler =
    (key: string) => (value: string | number) => {
      setFieldValue(key, value);
    };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  // Validating Calculation_Method

  const dispalycalculationMethod = errors.calcMthd && touched.calcMthd && (
    <ErrorAlert>
      <span>{errors.calcMthd}</span>
    </ErrorAlert>
  );
  // Validating Calculate_Accrual
  const displayCalculateAccrual = errors.balOpt && touched.balOpt && (
    <ErrorAlert>
      <span>{ERROR_MESSAGE}</span>
    </ErrorAlert>
  );
  // Validating Minimum_Balance
  const displayMinimumBalace = errors.accrMinBal && touched.accrMinBal && (
    <ErrorAlert>
      <span>{errors.accrMinBal}</span>
    </ErrorAlert>
  );

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("calcMthd")
                ?.tooltip_text_main
            }
            labelName={WHAT_IS_CALCULATION_METHOD}
            labelFor="calculation_method"
          />
          <SelectDropdown
            value={values.calcMthd ?? ""}
            onChange={foundationalInterestFormHandler("calcMthd")}
            placeholder="Select calulation method"
            dropdownList={props.data.calcMethodOptions}
            id="calculation_method"
          />
          {/* we are displaying Calculation_Method_Error based on condition*/}
          {dispalycalculationMethod}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("balOpt")
                ?.tooltip_text_main
            }
            labelName={BALANCE_LIKE_TO_USE_TO_CALCULATE_ACCURACY}
            labelFor="calculation_accural"
          />
          <SelectDropdown
            value={values.balOpt ?? ""}
            onChange={foundationalInterestFormHandler("balOpt")}
            placeholder="Select calulation accural"
            dropdownList={props.data.balanceOptions}
            id="calculation_accural"
          />
          {/* we are displaying Calculate_Accrual_Error based on condition*/}
          {displayCalculateAccrual}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            value={values.accrMinBal}
            onChange={foundationalInterestFormHandler("accrMinBal")}
            valuePrefix={"$"}
            labelName={WHAT_IS_MINIMUM_BALANCE}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("accrMinBal")
                ?.tooltip_text_main
            }
          />
          {/* we are displaying MinimumBalace_Error based on condition*/}
          {displayMinimumBalace}
        </Box>
        <Box className="app-form-field-container">
          <DurationInput
            labelName={WHAT_RATE_ADJUSTMENT_TERM}
            value={values.adjTerm}
            onChange={(value) => setFieldValue("adjTerm", value)}
            modalLinkName={"Enter code manually"}
            modalTitle={"Enter adjustment term"}
            modalLabelName={WHAT_RATE_ADJUSTMENT_TERM}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("adjTerm")
                ?.tooltip_text_main
            }
            regex={REGEX_PATTERN.RESTRICT_DECIMAL}
            modalLabelTooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("adjTerm")
                ?.tooltip_text_main
            }
          />
          {errors.adjTerm && touched.adjTerm && (
            <Box className="app-form-field-container">
              <ErrorAlert>
                <span>{errors.adjTerm}</span>
              </ErrorAlert>
            </Box>
          )}
        </Box>
      </CardContainer>
    </form>
  );
}
