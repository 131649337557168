"use client";
import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  ErrorAlert,
  FrequencyModule,
} from "@/components/common";
import { Box, Stack, useToast } from "@/components/ChakraUiManager";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  RateAdjustmentRangesProps,
  rateAdjustmentRangesDetails,
  getValidationSchema,
} from "./RateAdjustmentRangeValidation";
import { useFormik } from "formik";
import {
  COMPONENT_CLASS_ENUM,
  InterestComponentData,
  PostSchema,
} from "../../../model/types";
import { createComponentGeneric } from "../../product-config-client-service";
import { COMPONENT_CLASS } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  depositName?: string;
  data: {
    formData: InterestComponentData | null;
    interestComponentOptionsData: PostSchema;
  };
};

const RateAdjustRange = (props: Props) => {
  const { formData, interestComponentOptionsData } = props.data;
  const toast = useToast();

  // Form Submit Handler
  const onSubmit = async (values: RateAdjustmentRangesProps, actions: any) => {
    // Update only if component data exists and form has been touched
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && dirty) {
      if (productDetails) {
        const res = await createComponentGeneric({
          productDetails,
          formData: {
            ...formData,

            index: {
              ...formData.index,
              minRate: parseFloat(values.index.minRate),
              maxRate: parseFloat(values.index.maxRate),
              maxChngFirst: parseFloat(values.index.maxChngFirst),
              maxChngNext: parseFloat(values.index.maxChngNext),
              maxChngLife: parseFloat(values.index.maxChngLife),
              maxChngPer: parseFloat(values.index.maxChngPer),
              reviewFreq: values.index.reviewFreq,
            },
          },
          toastOptions: {
            toast,
            toastId: "index-rate-adjust-ranges",
            successMessage: `${configPageTitle} updated.`,
          },

          stageName: getWorflowStatusToBeUpdated?.() ?? "",
          componentClass: COMPONENT_CLASS_ENUM.componentInt,
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      index: {
        minRate:
          formData?.index?.minRate?.toString() ??
          rateAdjustmentRangesDetails.index.minRate,
        maxRate:
          formData?.index?.maxRate?.toString() ??
          rateAdjustmentRangesDetails.index.maxRate,
        maxChngFirst:
          formData?.index?.maxChngFirst?.toString() ??
          rateAdjustmentRangesDetails.index.maxChngFirst,
        maxChngNext:
          formData?.index?.maxChngNext?.toString() ??
          rateAdjustmentRangesDetails.index.maxChngNext,
        maxChngLife:
          formData?.index?.maxChngLife?.toString() ??
          rateAdjustmentRangesDetails.index.maxChngLife,
        maxChngPer:
          formData?.index?.maxChngPer?.toString() ??
          rateAdjustmentRangesDetails.index.maxChngPer,
        reviewFreq: formData?.index?.reviewFreq ?? "",
      },
    },
  });

  const { values, handleSubmit, errors, touched, setFieldValue, dirty } =
    formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Handling Form
  const rateAdjustFormHandler = (key: string) => (value: string) => {
    setFieldValue("index", { ...values.index, [key]: value });
  };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <AppFormLabel
          labelName="What are the minimum and maximum rates?"
          tooltipDesc={`${tooltipFlyoutDetails?.tooltipsMap?.get("minRate")?.tooltip_text_main || ""}
                ${tooltipFlyoutDetails?.tooltipsMap?.get("maxRate")?.tooltip_text_main || ""}`}
        />
        <Box display="flex" className="app-form-field-container">
          <Stack flexBasis="35%" marginRight={4}>
            <AppFormLabel labelName="Min" />

            <AppNumberInput
              value={values.index.minRate}
              onChange={rateAdjustFormHandler("minRate")}
              valueSuffix="%"
              placeholder="0%"
            />
            {errors.index?.minRate && touched.index?.minRate && (
              <ErrorAlert>
                <span>{errors.index?.minRate}</span>
              </ErrorAlert>
            )}
          </Stack>
          <Stack flexBasis="35%">
            <AppFormLabel labelName="Max" />

            <AppNumberInput
              value={values.index.maxRate}
              onChange={rateAdjustFormHandler("maxRate")}
              valueSuffix="%"
              placeholder="0%"
            />
            {errors.index?.maxRate && touched.index?.maxRate && (
              <ErrorAlert>
                <span>{errors.index?.maxRate}</span>
              </ErrorAlert>
            )}
          </Stack>
        </Box>

        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the maximum allowable rate adjustment during the initial adjustment period? "
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("maxChngFirst")
                ?.tooltip_text_main
            }
          />
          <AppNumberInput
            value={values.index.maxChngFirst}
            onChange={rateAdjustFormHandler("maxChngFirst")}
            valueSuffix="%"
            placeholder="0%"
          />
          {errors.index?.maxChngFirst && touched.index?.maxChngFirst && (
            <ErrorAlert>
              <span>{errors.index?.maxChngFirst}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the maximum allowable rate adjustment during the subsequent rate adjustment? "
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("maxChngNext")
                ?.tooltip_text_main
            }
          />
          <AppNumberInput
            value={values.index.maxChngNext}
            onChange={rateAdjustFormHandler("maxChngNext")}
            valueSuffix="%"
            placeholder="0%"
          />
          {errors.index?.maxChngNext && touched.index?.maxChngNext && (
            <ErrorAlert>
              <span>{errors.index?.maxChngNext}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the maximum rate change (ceiling rate) at each adjustment over the life of the product? "
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("maxChngLife")
                ?.tooltip_text_main
            }
          />
          <AppNumberInput
            value={values.index.maxChngLife}
            onChange={rateAdjustFormHandler("maxChngLife")}
            valueSuffix="%"
            placeholder="0%"
          />
          {errors.index?.maxChngLife && touched.index?.maxChngLife && (
            <ErrorAlert>
              <span>{errors.index?.maxChngLife}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <AppFormLabel labelName="What is the maximum change per instance?" />
          <AppNumberInput
            value={values.index.maxChngPer}
            onChange={rateAdjustFormHandler("maxChngPer")}
            valueSuffix="%"
            placeholder="0%"
          />
          {errors.index?.maxChngPer && touched.index?.maxChngPer && (
            <ErrorAlert>
              <span>{errors.index?.maxChngPer}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <FrequencyModule
            value={values.index.reviewFreq}
            onChange={rateAdjustFormHandler("reviewFreq")}
            frequencyQuestionTitle="How often should a rate adjustment be applied? "
            frequencyQuestionDescription={
              tooltipFlyoutDetails?.tooltipsMap?.get("reviewFreq")
                ?.tooltip_text_main
            }
            monthDropdownLabel="On what date should the frequency be implemented?"
            monthDropdownTooltip={
              tooltipFlyoutDetails?.tooltipsMap?.get("reviewFreqMon")
                ?.tooltip_text_main
            }
            notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the adjustment be applied?  "
            notBusinessDayTooltip={
              tooltipFlyoutDetails?.tooltipsMap?.get("reviewFreqNotBusiness")
                ?.tooltip_text_main
            }
          />
          {errors.index?.reviewFreq && touched.index?.reviewFreq && (
            <ErrorAlert>
              <span>{errors.index?.reviewFreq}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};
export default RateAdjustRange;
