"use client";
import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  InputText,
  ModalSelector,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import {
  SIDEBAR_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS,
  TC_STAGE_STATUS,
  WORKFLOW_STATUS,
} from "@/utils/constants";
import { Box, useToast } from "@chakra-ui/react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  constructTcEntryPayload,
  getEntryStageStatus,
  modifyTransCodeTemplate,
} from "../../transaction-code-api-service";
import { TransCodeResponse } from "../../models";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useQueryParams, generateYupSchema } from "@/utils/common";
import { Option } from "@/utils/types";

interface TemplateProps {
  idx?: number;
  channel: string;
  templateName: string;
  language: string;
  templateEngine: string;
  script: string;
}

export const templateInitialValues: TemplateProps = {
  channel: "",
  templateName: "",
  language: "",
  templateEngine: "",
  script: "",
};

type TemplateFor = "create" | "update" | "delete";

interface templatesEntries {
  trnCode?: string;
  templates: TemplateProps[];
}

const templateEntriesValues: templatesEntries = {
  templates: [],
};

interface TcTemplatesProps {
  data: {
    clientLanguages: Option<string>[];
    formData: TransCodeResponse;
    tranCodeOptionsSchema: PostSchema;
  };
}
const Templates = (props: TcTemplatesProps) => {
  const { data } = props;
  const {
    isGoingBack,
    canCheckFormStatus,
    entityId,
    navigateToNextPage,
    navigateTo,
    entityWorkflowData,
    getQueryParam,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    sideBarSubMenuIndex,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const entrySetupStatus = entityWorkflowData?.filter(
    (wfd) =>
      wfd.stage === TC_STAGE_STATUS.entry_setup &&
      wfd.status === WORKFLOW_STATUS.completed
  )?.[0]?.status;

  const toast = useToast();
  const toastId = "tc-templates";
  const updateQueryParams = useQueryParams();
  const currEntriesIndex =
    Number(getQueryParam?.(QUERY_PARAM_KEY.TC_ENTRY_INDEX)) || 0;

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateToNextPage?.();
    }
  };

  const onSubmit = async (values: templatesEntries, actions: any) => {
    if (!dirty) {
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.tcConfig,
        key: data?.formData?.trnCode!,
        stage: TC_STAGE_STATUS.entry_setup,
        status:
          entityId && !entityWorkflowData?.length
            ? ""
            : getEntryStageStatus(
                TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_template,
                entityWorkflowData
              ),
      });
      moveToNextStep();
      return;
    }

    const commonToastOptions = {
      toast,
      toastId,
    };
    let constructedVal = {
      templates: values.templates?.map((template) => ({
        ...template,
        channel: template.channel,
        templateName: template.templateName,
        language: template.language,
        templateEngine: template.templateEngine,
        script: template.script,
      })),
    };

    let payload = constructTcEntryPayload(
      data.formData!,
      constructedVal,
      Number(currEntriesIndex)
    );

    const templatesToBeDeleted = generateTemplateOnSubmit(
      constructedFormValues.templates,
      values.templates,
      "delete"
    );
    const templatesToBeCreated = generateTemplateOnSubmit(
      constructedFormValues.templates,
      values.templates,
      "create"
    );
    const templatesToBeUpdated = generateTemplateOnSubmit(
      constructedFormValues.templates,
      values.templates,
      "update"
    );

    const response = await modifyTransCodeTemplate({
      code: payload?.entries?.[Number(currEntriesIndex) ?? 0].trnCode ?? "",
      entryIndex: Number(currEntriesIndex) ?? 0,
      templatesToBeDeleted,
      templatesToBeCreated,
      templatesToBeUpdated,
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: TC_STAGE_STATUS.entry_setup,
      stageStatus:
        entityId && !entityWorkflowData?.length
          ? ""
          : entrySetupStatus
            ? entrySetupStatus
            : getEntryStageStatus(
                TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_template,
                entityWorkflowData
              ),
    });
    if (response) {
      moveToNextStep();
    }
  };

  const generateTemplateOnSubmit = (
    initialTemplate: any,
    updatedTemplates: any,
    templateFor: TemplateFor
  ) => {
    switch (templateFor) {
      case "delete": {
        return initialTemplate.filter((template: any) => {
          const indexTemplate = updatedTemplates.find(
            (savedTemplate: any) => savedTemplate.idx === template.idx
          );
          if (indexTemplate) {
            return (
              indexTemplate.channel !== template.channel ||
              indexTemplate.templateName !== template.templateName ||
              indexTemplate.language !== template.language
            );
          } else {
            return true;
          }
        });
      }
      case "create": {
        return updatedTemplates.filter((template: any) => {
          const indexTemplate = initialTemplate.find(
            (savedTemplate: any) => savedTemplate.idx === template.idx
          );
          if (indexTemplate) {
            return (
              indexTemplate.channel !== template.channel ||
              indexTemplate.templateName !== template.templateName ||
              indexTemplate.language !== template.language
            );
          } else {
            return true;
          }
        });
      }
      case "update": {
        return updatedTemplates.filter((template: any) => {
          const indexTemplate = initialTemplate.find(
            (savedTemplate: any) => savedTemplate.idx === template.idx
          );
          if (indexTemplate) {
            return (
              indexTemplate.channel === template.channel &&
              indexTemplate.templateName === template.templateName &&
              indexTemplate.language === template.language
            );
          } else {
            return false;
          }
        });
      }
      default: {
        return updatedTemplates;
      }
    }
  };

  const savedCurrEntries =
    data.formData?.entries?.[(currEntriesIndex as any) ?? 0];
  let constructedFormValues: templatesEntries = {
    trnCode: savedCurrEntries?.trnCode,
    ...templateEntriesValues,
  };

  if (savedCurrEntries?.templates) {
    constructedFormValues = {
      ...(savedCurrEntries as templatesEntries),
      templates: savedCurrEntries?.templates?.map(
        (template: any, idx: number) => ({
          ...template,
          idx: idx,
          channel: template.channel,
          templateName: template.templateName,
          language: template.language,
          templateEngine: template.templateEngine,
          script: template.script,
        })
      ),
    };
  }

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const fields = ["entries"];
      const yupSchema = await generateYupSchema(
        fields,
        data.tranCodeOptionsSchema
      );
      setValidationSchema(yupSchema.entries.innerType);
    })();
  }, [data.tranCodeOptionsSchema]);

  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      ...constructedFormValues,
    },
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
    setFieldTouched,
  } = formikConfigs;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`templates[${index}].${key}`, value);
  };

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      navigateTo(
        `${ROUTE_PATH.TC_TRANSACTION_ENTRY_ACCOUNTING_SEGMENTS}?${updateQueryParams()}`
      );
    }
  }, [isGoingBack, canCheckFormStatus]);

  useEffect(() => {
    if (
      sideBarSubMenuIndex ===
      TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_template
    ) {
      updateFormStatus?.(SIDEBAR_STATUS.in_progress);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.in_progress);
    }
  }, [sideBarSubMenuIndex]);

  const handleOnBlur = (key: string, index: number) => () => {
    setFieldTouched(`templates[${index}].${key}`, true);
  };

  //Form_Error_Handler
  const _errorHandler = (key: keyof TemplateProps, index: number) => {
    return (
      <>
        {touched.templates?.[index]?.[key] &&
          (errors.templates?.[index] as any)?.[key] && (
            <ErrorAlert>
              <span>{(errors.templates?.[index] as any)[key]}</span>
            </ErrorAlert>
          )}
      </>
    );
  };

  return (
    <FormikProvider value={formikConfigs}>
      <form onSubmit={handleSubmit} noValidate id="finxact-form">
        <FieldArray
          name="templates"
          render={({ insert, remove, push }) => (
            <Box className="app-form-field-array-container">
              {values?.templates.map((template, index: number) => (
                <CardContainer key={`template${template.idx}`}>
                  <AccordionComponent
                    accordionTitle={`Template ${index + 1}`}
                    deleteIcon
                    onDeleteHandler={() => remove(index)}
                    isExpand={values.templates.length !== index + 1 ? 1 : 0}
                  >
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the channel application?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("channel")
                            ?.tooltip_text_main
                        }
                      />
                      <InputText
                        value={template.channel}
                        onChange={handleOnChange("channel", index)}
                        onBlur={handleOnBlur("channel", index)}
                      />
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the template name?"
                        isRequired
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("templateName")
                            ?.tooltip_text_main
                        }
                      />
                      <InputText
                        value={template.templateName}
                        onChange={handleOnChange("templateName", index)}
                        onBlur={handleOnBlur("templateName", index)}
                      />
                      {_errorHandler("templateName", index)}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the client language?"
                        isRequired
                      />
                      <ModalSelector
                        linkName="+ Select Language"
                        modalTitle="Select Language"
                        optionList={data.clientLanguages}
                        optionsDescTitle="Languages"
                        optionsTitle="Language code"
                        onChange={handleOnChange("language", index)}
                        value={template.language}
                        showSelection="value - description"
                        isClearable={false}
                      />
                      {_errorHandler("language", index)}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the scripting language?"
                        isRequired
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get(
                            "templateEngine"
                          )?.tooltip_text_main
                        }
                      />
                      <InputText
                        value={template.templateEngine}
                        onChange={handleOnChange("templateEngine", index)}
                        onBlur={handleOnBlur("templateEngine", index)}
                      />
                      {_errorHandler("templateEngine", index)}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the template code?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("script")
                            ?.tooltip_text_main
                        }
                      />
                      <InputText
                        value={template.script}
                        onChange={handleOnChange("script", index)}
                        onBlur={handleOnBlur("script", index)}
                      />
                    </Box>
                  </AccordionComponent>
                </CardContainer>
              ))}
              <AddNewButton
                label={
                  !values.templates?.length
                    ? "Add a template"
                    : "Add another template"
                }
                onClick={() => {
                  push({
                    ...templateInitialValues,
                    trnCode: savedCurrEntries?.trnCode,
                    idx: values.templates.length + 1,
                  });
                }}
              />
            </Box>
          )}
        ></FieldArray>
      </form>
    </FormikProvider>
  );
};

export default Templates;
