"use client";
import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  CardContainer,
  DatePicker,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
  StaticText,
  TimeField,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, Stack, useToast } from "@chakra-ui/react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import {
  PartyEmail,
  PartyEmailDetailProps,
  getValidationSchema,
} from "./PartyEmailsDetailsValidation";
import { ROUTE_PATH } from "@/route-config/route-path";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import {
  makeDateTime,
  useQueryParams,
  shouldUpdateWorkflowStatus,
} from "@/utils/common";
import { FIN_ORG_SUB_STEPS, IC_ENTITY } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type PartyEmailProps = {
  data: {
    EmailTypeOptions: { label: string; value: string }[];
    formData: FinancialInstitutionData | null;
    partyOrgFinistOptionsData: PostSchema;
  };
};

const PartyEmailsDetails = (props: PartyEmailProps) => {
  const { EmailTypeOptions: emailOption } = props.data;
  const toast = useToast();
  const toastId = "gpp-party-email-details";
  const activeAccordionRef = useRef<any>();
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.partyOrgFinistOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.partyOrgFinistOptionsData]);
  //context API

  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const updateQueryParams = useQueryParams();

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_PARTY_PHONE_DETAILS"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  const onSubmit = async (values: any, actions: any) => {
    const currentStage = FIN_ORG_SUB_STEPS.gen_party_party_emails_detail;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: props.data.formData?._vn,
        _Id: props.data.formData?._Id,
        name: props.data.formData?.name,
        emails: values.emails.map((email: any, index: number) => ({
          _Id: props.data.formData?._Id,
          data: email.data,
          emailType: parseInt(email.emailType),
          isPreferred: email.isPreferred,
          label: email.label,
          validFromDtm:
            email.fromDate !== null && email.fromTime !== "Invalid Date"
              ? makeDateTime(email.fromDate as Date, email.fromTime)
              : null,
          validToDtm:
            email.throughDate !== null && email.throughTime !== "Invalid Date"
              ? makeDateTime(email.throughDate as Date, email.throughTime)
              : null,
          verifyDtm:
            email.verifiedDate !== null && email.verifiedTime !== "Invalid Date"
              ? makeDateTime(email.verifiedDate as Date, email.verifiedTime)
              : null,
        })),
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };
  // Handler for all the form fields
  const _handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`emails.${index}.${key}`, value);
  };

  const _handleOnBlur = (key: string, index: number) => () => {
    setFieldTouched(`PartyAddress.${index}.${key}`, true);
  };
  let initialPartyEmail: PartyEmailDetailProps[] =
    props.data.formData?.emails.map((emailData: any, idx: number) => ({
      emailType: emailData.emailType,
      data: emailData.data,
      label: emailData.label,
      isPreferred: emailData.isPreferred ?? undefined,
      fromDate: new Date(emailData.validFromDtm),
      fromTime: new Date(emailData.validFromDtm).toLocaleTimeString("it-IT"),
      throughDate: new Date(emailData.validToDtm),
      throughTime: new Date(emailData.validToDtm).toLocaleTimeString("it-IT"),
      verifiedDate: new Date(emailData.verifyDtm),
      verifiedTime: new Date(emailData.verifyDtm).toLocaleTimeString("it-IT"),
    })) ?? [];

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: { emails: initialPartyEmail },
  });

  const {
    values,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    dirty,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_IDENTIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
      checkFormStatus?.(false);
    }
  }, [canCheckFormStatus, isGoingBack]);

  //Form_Error_Handler
  const _errorHandler = (key: string, index: number) => {
    return (
      <>
        {touched.emails &&
          touched.emails[index] &&
          (touched.emails[index] as any)[key] &&
          errors.emails &&
          errors.emails[index] &&
          (errors.emails[index] as any)[key] && (
            <ErrorAlert>
              <span>{(errors.emails[index] as any)[key]}</span>
            </ErrorAlert>
          )}
      </>
    );
  };

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit} id="finxact-form">
          <FieldArray
            name={"emails"}
            render={({ insert, remove, push }) => (
              <Box className="app-form-field-array-container">
                {values.emails.map(
                  (emailDetl: PartyEmailDetailProps, index: any) => (
                    <Box key={index} ref={activeAccordionRef}>
                      <CardContainer styleProps={{ mb: "24px" }}>
                        <AccordionComponent
                          accordionTitle={`Party emails details ${index + 1}`}
                          limitName={""}
                          deleteIcon
                          onDeleteHandler={() => remove(index)}
                          isExpand={values.emails.length !== index + 1 ? 1 : 0}
                        >
                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="What is the party identifier?"
                              isRequired
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get("_Id")
                                  ?.tooltip_text_main
                              }
                            />
                            <StaticText textValue={props.data.formData?._Id} />
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="What is the email type?"
                              isRequired
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get(
                                  "emailType"
                                )?.tooltip_text_main
                              }
                            />
                            <RadioButtonGroup
                              isBoxedRadio
                              value={emailDetl.emailType}
                              onChange={_handleOnChange("emailType", index)}
                              onBlur={_handleOnBlur("emailType", index)}
                              radioPropList={emailOption}
                              stackDirection={"row"}
                              spacing={"1rem"}
                            />
                            {_errorHandler("emailType", index)}
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="What is the email address?"
                              isRequired
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get(
                                  "emailAddress"
                                )?.tooltip_text_main
                              }
                            />
                            <InputText
                              value={emailDetl.data}
                              onChange={_handleOnChange("data", index)}
                              onBlur={_handleOnBlur("data", index)}
                            />
                            {_errorHandler("data", index)}
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="Enter a recognizable label for the email"
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get(
                                  "emailLabel"
                                )?.tooltip_text_main
                              }
                            />
                            <InputText
                              value={emailDetl.label}
                              onChange={_handleOnChange("label", index)}
                              onBlur={_handleOnBlur("label", index)}
                            />
                            {_errorHandler("label", index)}
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="Is this the preferred email?"
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get(
                                  "isPreferred"
                                )?.tooltip_text_main
                              }
                            />
                            <SplitButton
                              value={emailDetl.isPreferred}
                              onSelect={_handleOnChange("isPreferred", index)}
                              onBlur={_handleOnBlur("isPreferred", index)}
                              leftBtnName="Yes"
                              rightBtnName="No"
                            />
                            {_errorHandler("isPreferred", index)}
                          </Box>

                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="When is the email valid from?"
                              labelFor="form_date"
                            />
                            <Box display="flex" gap="2rem">
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Date"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "fromDate"
                                    )?.tooltip_text_main
                                  }
                                />
                                <DatePicker
                                  ariaLabel="email-valid-from-date"
                                  value={emailDetl.fromDate}
                                  onUpdate={_handleOnChange("fromDate", index)}
                                  onBlur={_handleOnBlur("fromDate", index)}
                                  placeholder="Select"
                                  id="form_date"
                                />
                                {_errorHandler("fromDate", index)}
                              </Stack>
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Time"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "fromTime"
                                    )?.tooltip_text_main
                                  }
                                />
                                <TimeField
                                  value={emailDetl.fromTime}
                                  onChange={_handleOnChange("fromTime", index)}
                                  onBlur={_handleOnBlur("fromTime", index)}
                                  ariaLabel="time-for-email-valid-sfrom"
                                />
                                {_errorHandler("fromDate", index)}
                              </Stack>
                            </Box>
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel labelName="When is the email valid through?" />
                            <Box display="flex" gap="2rem">
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Date"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "throughDate"
                                    )?.tooltip_text_main
                                  }
                                  labelFor="through_date"
                                />
                                <DatePicker
                                  ariaLabel="email-valid-through-date"
                                  value={emailDetl.throughDate}
                                  onUpdate={_handleOnChange(
                                    "throughDate",
                                    index
                                  )}
                                  onBlur={_handleOnBlur("throughDate", index)}
                                  placeholder="Select"
                                  id="through_date"
                                />
                                {_errorHandler("throughDate", index)}
                              </Stack>
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Time"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "throughTime"
                                    )?.tooltip_text_main
                                  }
                                />
                                <TimeField
                                  value={emailDetl.throughTime}
                                  onChange={_handleOnChange(
                                    "throughTime",
                                    index
                                  )}
                                  onBlur={_handleOnBlur("throughTime", index)}
                                  ariaLabel="time-for-email-valid-through"
                                />
                                {_errorHandler("throughTime", index)}
                              </Stack>
                            </Box>
                          </Box>
                          <Box className="app-form-field-container">
                            <AppFormLabel labelName="When was the email address verified?" />
                            <Box display="flex" gap="2rem">
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Date"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "verifiedDate"
                                    )?.tooltip_text_main
                                  }
                                  labelFor="verified_date"
                                />
                                <DatePicker
                                  ariaLabel="email-verified-date"
                                  value={emailDetl.verifiedDate}
                                  onUpdate={_handleOnChange(
                                    "verifiedDate",
                                    index
                                  )}
                                  onBlur={_handleOnBlur("verifiedDate", index)}
                                  placeholder="Select"
                                  id="verified_date"
                                />
                                {_errorHandler("verifiedDate", index)}
                              </Stack>
                              <Stack flexBasis="50%">
                                <AppFormLabel
                                  labelName="Time"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "verifiedTime"
                                    )?.tooltip_text_main
                                  }
                                />
                                <TimeField
                                  value={emailDetl.verifiedTime}
                                  onChange={_handleOnChange(
                                    "verifiedTime",
                                    index
                                  )}
                                  onBlur={_handleOnBlur("verifiedTime", index)}
                                  ariaLabel="time-for-email-verified"
                                />
                                {_errorHandler("verifiedTime", index)}
                              </Stack>
                            </Box>
                          </Box>
                        </AccordionComponent>
                      </CardContainer>
                    </Box>
                  )
                )}
                <AddNewButton
                  label={
                    !values.emails.length
                      ? "Add party email"
                      : "Add another party email"
                  }
                  onClick={() => {
                    push({ ...PartyEmail, idx: Math.random() });
                  }}
                />
              </Box>
            )}
          ></FieldArray>
        </form>
      </FormikProvider>
    </>
  );
};
export default PartyEmailsDetails;
