import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Interest_Accrual_Details
interface InterestAccrualDetailsProps {
  negAccrOpt: number | undefined;
  accrCalcTm: string;
  isCompoundDly: SplitBtnInputValType;
  accrCarryOverOpt: number | undefined;
}
// Defining Intitial Value For Interest_Accrual_Details
const interestAccrual: InterestAccrualDetailsProps = {
  negAccrOpt: undefined,
  accrCalcTm: "",
  isCompoundDly: undefined,
  accrCarryOverOpt: undefined,
};
// YUP LIBRARY :Defining Schema For validation of Interest_Accrual_Details
const interestAccrualSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "negAccrOpt",
    "accrCalcTm",
    "isCompoundDly",
    "accrCarryOverOpt",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { InterestAccrualDetailsProps };
// Exporting Validation Schema and Intial value
export { interestAccrual, interestAccrualSchema as getValidationSchema };
