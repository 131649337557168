"use client";

import { ProgressBar } from "@/components/common";
import { formatDuration } from "@/utils/common";
import { TimeIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/react";

type BadgeProgressType = {
  duration: number | undefined;
  courseProgress: number | undefined;
};

export default function BadgeProgress({
  duration,
  courseProgress,
}: BadgeProgressType) {
  return (
    <Box className="progress-box">
      <Box className="progress-box-time">
        <TimeIcon mr={2} />
        {formatDuration(duration ?? 0, true)}
      </Box>
      <Box className="progress-box-bar">
        <Box w={"95%"}>
          <ProgressBar value={courseProgress ?? 0} hoverEffect={false} />
        </Box>
        <Text as="span">{courseProgress ?? 0}%</Text>
      </Box>
    </Box>
  );
}
