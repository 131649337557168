import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

interface TaxPartyPhoneContactProps {
  phoneType: number | undefined;
  cntryCallCode: string;
  data: string;
  extension: string;
  label: string;
  isPreferred: SplitBtnInputValType;
  phoneNumberValidFromDate: Date | string;
  phoneNumberValidFromTime: string;
  phoneNumberValidThroughDate: Date | string;
  phoneNumberValidThroughTime: string;
  phoneNumberVerifiedOnDate: Date | string;
  phoneNumberVerifiedOnTime: string;
}
interface taxPrtyPhnProps {
  phones: TaxPartyPhoneContactProps[];
}

const defaultTaxPartyPhoneContact: TaxPartyPhoneContactProps = {
  phoneType: undefined,
  cntryCallCode: "",
  data: "",
  extension: "",
  label: "",
  isPreferred: undefined,
  phoneNumberValidFromDate: "",
  phoneNumberValidFromTime: "",
  phoneNumberValidThroughDate: "",
  phoneNumberValidThroughTime: "",
  phoneNumberVerifiedOnDate: "",
  phoneNumberVerifiedOnTime: "",
};
const taxPartyPhone: taxPrtyPhnProps = {
  phones: [defaultTaxPartyPhoneContact],
};

const taxPartyPhoneContactSchema = async (optionsSchema: PostSchema) => {
  const fields = ["taxPartyContact"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { TaxPartyPhoneContactProps, taxPrtyPhnProps };
// Exporting Validation Schema and Intial value
export {
  defaultTaxPartyPhoneContact,
  taxPartyPhone,
  taxPartyPhoneContactSchema as getValidationSchema,
};
