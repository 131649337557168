"use client";
import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  CardContainer,
  CoupledFieldWithExpr,
  ErrorAlert,
  InputText,
  SplitButton,
} from "@/components/common";
import { DefaultFormDetails } from "@/components/institutional-configuration/model";
import { TransCodeEntries, TransCodeResponse } from "../../models";
import { Box, Flex, Heading, useToast } from "@chakra-ui/react";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS,
  TC_STAGE_STATUS,
  SIDEBAR_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER,
  WORKFLOW_STATUS,
} from "@/utils/constants";
import { QUERY_PARAM_KEY } from "@/route-config/route-path";
import {
  FieldArray,
  FormikErrors,
  FormikProvider,
  FormikTouched,
  useFormik,
} from "formik";
import {
  getEntryStageStatus,
  updateTransCode,
} from "../../transaction-code-api-service";
import {
  FastDefaultDropdownProps,
  PostSchema,
} from "@/components/product-management/model/types";
import { FlyoutAndTooltipType } from "@/models/global-models";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { dateTimeFormat, generateYupSchema } from "@/utils/common";
import { deepCopy } from "@finxact/finxact-shared-ui";

interface TcHoldSpecificationsProp {
  data: {
    formData: TransCodeResponse | null;
    accGrps: FastDefaultDropdownProps[];
    holdTypes: FastDefaultDropdownProps[];
    tranCodeOptionsSchema: PostSchema;
  };
}
interface TcHoldSpecificationsFormProp {
  trnCode?: string;
  addHold: {
    authRef: string;
    holdType: number | null;
    reason: string;
    note: string;
    holdAmt: number | null;
    acctGroup: number | null;
    posnAcctNbr: string;
    posnId: string;
    startDtm: string | null;
    endDtm: string | null;
    cancelDtm: string | null;
    dur: string;
    interval: string;
    isFullBusinessDay: boolean;
    slices: string[];
    authRefExpr: string;
    holdTypeExpr: string;
    reasonExpr: string;
    noteExpr: string;
    holdAmtExpr: string;
    acctGroupExpr: string;
    posnIdExpr: string;
    startDtmExpr: string;
    endDtmExpr: string;
    cancelDtmExpr: string;
    durExpr: string;
    intervalExpr: string;
    slicesExpr: string[];
  };
}

const initialFormValues: TcHoldSpecificationsFormProp = {
  addHold: {
    authRef: "",
    holdType: null,
    reason: "",
    note: "",
    holdAmt: null,
    acctGroup: null,
    posnAcctNbr: "",
    posnId: "",
    startDtm: null,
    endDtm: null,
    cancelDtm: null,
    dur: "",
    interval: "",
    isFullBusinessDay: false,
    slices: [],
    authRefExpr: "",
    holdTypeExpr: "",
    reasonExpr: "",
    noteExpr: "",
    holdAmtExpr: "",
    acctGroupExpr: "",
    posnIdExpr: "",
    startDtmExpr: "",
    endDtmExpr: "",
    cancelDtmExpr: "",
    durExpr: "",
    intervalExpr: "",
    slicesExpr: [],
  },
};
const HoldSpecifications = (props: TcHoldSpecificationsProp) => {
  const {
    data: { formData, holdTypes, accGrps, tranCodeOptionsSchema },
  } = props;
  const {
    isGoingBack,
    canCheckFormStatus,
    entityId,
    navigateToNextPage,
    getQueryParam,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    entityWorkflowData,
    sideBarSubMenuIndex,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const toast = useToast();
  const toastId = "tc-hold-specifications";
  const currEntriesIndex: number =
    Number(getQueryParam?.(QUERY_PARAM_KEY.TC_ENTRY_INDEX)) || 0;
  const entrySetupStatus = entityWorkflowData?.filter(
    (wfd) =>
      wfd.stage === TC_STAGE_STATUS.entry_setup &&
      wfd.status === WORKFLOW_STATUS.completed
  )?.[0]?.status;

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateToNextPage?.();
    }
  };

  const onSubmit = async (
    values: TcHoldSpecificationsFormProp,
    actions: any
  ) => {
    const existingFormData = deepCopy(formData);
    if (!dirty) {
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.tcConfig,
        key: formData?.trnCode!,
        stage: TC_STAGE_STATUS.entry_setup,
        status:
          entityId && !entityWorkflowData?.length
            ? ""
            : getEntryStageStatus(
                TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_hold_specification,
                entityWorkflowData
              ),
      });
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };

    let constructedVal = {
      ...values,
      trnCode: existingFormData?.trnCode,
    };
    if (typeof constructedVal.addHold.holdAmt === "number")
      constructedVal.addHold.holdAmt = Number(constructedVal.addHold.holdAmt);

    if (constructedVal.addHold.cancelDtm) {
      constructedVal.addHold.cancelDtm = dateTimeFormat(
        constructedVal.addHold.cancelDtm
      );
    }
    if (existingFormData?.entries?.length) {
      if (addHold)
        existingFormData.entries[currEntriesIndex].addHold =
          constructedVal.addHold;
      else delete existingFormData.entries[currEntriesIndex].addHold;
    } else {
      if (existingFormData)
        existingFormData.entries = [{ ...constructedVal } as TransCodeEntries];
    }
    const response = await updateTransCode({
      formData: {
        ...existingFormData,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: TC_STAGE_STATUS.entry_setup,
      stageStatus:
        entityId && !entityWorkflowData?.length
          ? ""
          : entrySetupStatus
            ? entrySetupStatus
            : getEntryStageStatus(
                TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_hold_specification,
                entityWorkflowData
              ),
    });

    if (response) {
      moveToNextStep();
    }
  };

  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  useEffect(() => {
    if (Number(currEntriesIndex) >= 0) {
      const savedCurrEntries = formData?.entries?.[currEntriesIndex as any];
      if (savedCurrEntries?.trnCode) {
        if (savedCurrEntries?.addHold) {
          setAddHold(true);
        }
      }
    }
  }, [currEntriesIndex]);

  const savedCurrEntries = formData?.entries?.[currEntriesIndex as any];
  let constructedFormValues: TcHoldSpecificationsFormProp = {
    trnCode: savedCurrEntries?.trnCode,
    ...initialFormValues,
  };

  if (savedCurrEntries?.trnCode) {
    constructedFormValues = {
      trnCode: savedCurrEntries?.trnCode,
      addHold: {
        ...initialFormValues.addHold,
        ...savedCurrEntries.addHold,
      },
    };
  }
  const [addHold, setAddHold] = useState<SplitBtnInputValType>(false);

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const fields = ["entries"];
      const yupSchema = await generateYupSchema(fields, tranCodeOptionsSchema);
      setValidationSchema(yupSchema.entries.innerType);
    })();
  }, [tranCodeOptionsSchema]);

  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    initialValues: constructedFormValues,
    validationSchema,
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setValues,
    dirty,
  } = formikConfigs;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (
      sideBarSubMenuIndex ===
      TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_hold_specification
    ) {
      updateFormStatus?.(SIDEBAR_STATUS.in_progress);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.in_progress);
    }
  }, [sideBarSubMenuIndex]);

  return (
    <>
      <FormikProvider value={formikConfigs}>
        <form onSubmit={handleSubmit} noValidate id="finxact-form">
          <CardContainer>
            <Heading as="h4" my={13}>
              {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                ?.section_title || "Foundational details"}
            </Heading>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Do you want to add a hold?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("addHold")
                    ?.tooltip_text_main
                }
              />
              <SplitButton
                name="Add Hold"
                onSelect={setAddHold}
                leftBtnName="Yes"
                rightBtnName="No"
                value={addHold}
              />
            </Box>
            {addHold && (
              <AddHoldForm
                errors={errors.addHold}
                touched={touched.addHold}
                values={values}
                handleOnChange={handleOnChange}
                handleBlur={handleBlur}
                holdTypes={holdTypes}
                accGrps={accGrps}
                tooltipFlyoutDetails={tooltipFlyoutDetails}
              />
            )}
          </CardContainer>
          {addHold && (
            <AddDatesAndSlicedetails
              values={values}
              errors={errors.addHold}
              touched={touched.addHold}
              handleOnChange={handleOnChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              tooltipFlyoutDetails={tooltipFlyoutDetails}
            />
          )}
        </form>
      </FormikProvider>
    </>
  );
};

const _renderAddHoldError = (
  key: keyof TcHoldSpecificationsFormProp["addHold"],
  errors: FormikErrors<TcHoldSpecificationsFormProp["addHold"] | undefined>,
  touched: FormikTouched<TcHoldSpecificationsFormProp["addHold"] | undefined>
) => {
  return (
    errors?.[key] &&
    touched?.[key] && (
      <ErrorAlert>
        <span>{errors[key] as string}</span>
      </ErrorAlert>
    )
  );
};

const AddHoldForm = ({
  values,
  errors,
  touched,
  handleOnChange,
  handleBlur,
  accGrps,
  holdTypes,
  tooltipFlyoutDetails,
}: any) => {
  const { addHold } = values;
  return (
    <>
      <Heading as="h6" className="app-heading-box">
        Enable any of the following
      </Heading>
      <Box className="app-form-field-container app-heading-box-contents">
        <AppFormLabel
          labelName="What is the authorization reference?"
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("authRef")?.tooltip_text_main
          }
        />
        <InputText
          value={addHold.authRef}
          onChange={handleOnChange("addHold.authRef")}
          onBlur={handleBlur}
          label={"authRef"}
          name={"addHold.authRef"}
          placeholder={"Enter"}
        />
        {_renderAddHoldError("authRef", errors, touched)}
      </Box>
      <CoupledFieldWithExpr
        customClass="app-heading-box-contents"
        expOnValue={addHold.holdTypeExpr}
        expOnFormKey={"holdTypeExpr"}
        expOffValue={addHold.holdType}
        expOffFormKey={"holdType"}
        labelName={"What is the hold type code?"}
        tooltipDesc={
          tooltipFlyoutDetails?.tooltipsMap?.get("holdType")?.tooltip_text_main
        }
        onExpOnChange={handleOnChange("addHold.holdTypeExpr")}
        onExpOffChange={handleOnChange("addHold.holdType")}
        onExpOffBlur={handleBlur}
        dropdownList={holdTypes}
        expOffFormType={"singleSelect"}
        errors={
          _renderAddHoldError("holdTypeExpr", errors, touched) ||
          _renderAddHoldError("holdType", errors, touched)
        }
      />
      <CoupledFieldWithExpr
        customClass="app-heading-box-contents"
        expOnValue={addHold.reasonExpr}
        expOnFormKey={"addHold.reasonExpr"}
        expOffValue={addHold.reason}
        expOffFormKey={"addHold.reason"}
        labelName={"What is the hold reason code?"}
        tooltipDesc={
          tooltipFlyoutDetails?.tooltipsMap?.get("reason")?.tooltip_text_main
        }
        onExpOnChange={handleOnChange("addHold.reasonExpr")}
        onExpOffChange={handleOnChange("addHold.reason")}
        errors={
          _renderAddHoldError("reasonExpr", errors, touched) ||
          _renderAddHoldError("reason", errors, touched)
        }
      />
      <CoupledFieldWithExpr
        customClass="app-heading-box-contents"
        expOnValue={addHold.noteExpr}
        expOnFormKey={"addHold.noteExpr"}
        expOffValue={addHold.note}
        expOffFormKey={"addHold.note"}
        labelName={"What is the hold note?"}
        tooltipDesc={
          tooltipFlyoutDetails?.tooltipsMap?.get("note")?.tooltip_text_main
        }
        onExpOnChange={handleOnChange("addHold.noteExpr")}
        onExpOffChange={handleOnChange("addHold.note")}
        errors={
          _renderAddHoldError("noteExpr", errors, touched) ||
          _renderAddHoldError("note", errors, touched)
        }
      />
      <CoupledFieldWithExpr
        customClass="app-heading-box-contents"
        expOnValue={addHold.holdAmtExpr}
        expOnFormKey={"addHold.holdAmtExpr"}
        expOffValue={addHold.holdAmt}
        expOffFormKey={"addHold.holdAmt"}
        labelName={"What is the hold amount?"}
        tooltipDesc={
          tooltipFlyoutDetails?.tooltipsMap?.get("holdAmt")?.tooltip_text_main
        }
        onExpOnChange={handleOnChange("addHold.holdAmtExpr")}
        onExpOffChange={handleOnChange("addHold.holdAmt")}
        expOffFormType="inputNumber"
        numberInputPrefix="$"
        errors={
          _renderAddHoldError("holdAmtExpr", errors, touched) ||
          _renderAddHoldError("holdAmt", errors, touched)
        }
      />
      {_renderAddHoldError("holdAmtExpr", errors, touched)}
      {_renderAddHoldError("holdAmt", errors, touched)}
      <CoupledFieldWithExpr
        customClass="app-heading-box-contents"
        expOnValue={addHold.acctGroupExpr}
        expOnFormKey={"addHold.acctGroupExpr"}
        expOffValue={addHold.acctGroup}
        expOffFormKey={"addHold.acctGroup"}
        labelName={"What is the account group?"}
        tooltipDesc={
          tooltipFlyoutDetails?.tooltipsMap?.get("acctGroup")?.tooltip_text_main
        }
        onExpOnChange={handleOnChange("addHold.acctGroupExpr")}
        onExpOffChange={handleOnChange("addHold.acctGroup")}
        dropdownList={accGrps}
        expOffFormType={"singleSelect"}
        errors={
          _renderAddHoldError("acctGroupExpr", errors, touched) ||
          _renderAddHoldError("acctGroup", errors, touched)
        }
      />
      <Box className="app-form-field-container app-heading-box-contents">
        <AppFormLabel
          labelName="What is the position identifier?"
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("posnId")?.tooltip_text_main
          }
        />
        <InputText
          value={addHold.posnId}
          onChange={handleOnChange("addHold.posnId")}
          onBlur={handleBlur}
          label={"posnId"}
          name={"addHold.posnId"}
          placeholder={"Enter"}
        />
        {_renderAddHoldError("posnId", errors, touched)}
      </Box>
      <Box className="app-form-field-container app-heading-box-contents last-item">
        <AppFormLabel
          labelName="What is the position account number?"
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("posnAcctNbr")
              ?.tooltip_text_main
          }
        />
        <InputText
          value={addHold.posnAcctNbr}
          onChange={handleOnChange("addHold.posnAcctNbr")}
          onBlur={handleBlur}
          name={"posnAcctNbr"}
          placeholder={"Enter"}
        />
        {_renderAddHoldError("posnAcctNbr", errors, touched)}
      </Box>
    </>
  );
};
const AddDatesAndSlicedetails = ({
  values,
  errors,
  touched,
  handleOnChange,
  handleBlur,
  setFieldValue,
  tooltipFlyoutDetails,
}: {
  values: TcHoldSpecificationsFormProp;
  errors: any;
  touched: any;
  handleOnChange: any;
  handleBlur: any;
  setFieldValue: any;
  tooltipFlyoutDetails?: FlyoutAndTooltipType | null;
}) => {
  const sliceExprDeleteHandler = (index: number) => {
    let newArr = [...addHold.slicesExpr];
    newArr.splice(index, 1);
    return setFieldValue(`addHold.slicesExpr`, newArr);
  };
  const sliceExprAddHandler = () => {
    let slicesExpr = [...addHold.slicesExpr, ""];
    return setFieldValue(`addHold.slicesExpr`, slicesExpr);
  };
  const { addHold } = values;
  return (
    <>
      <CardContainer>
        <Heading as="h4" my={13}>
          {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
            ?.section_title || "Dates & durations"}
        </Heading>
        <Heading as="h6" className="app-heading-box">
          Enable any of the following
        </Heading>
        <CoupledFieldWithExpr
          customClass="app-heading-box-contents"
          expOnValue={addHold.cancelDtmExpr}
          expOnFormKey={"addHold.cancelDtmExpr"}
          expOffValue={addHold.cancelDtm}
          expOffFormKey={"addHold.cancelDtm"}
          labelName={"What is the hold cancellation date?"}
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("cancelDtm")
              ?.tooltip_text_main
          }
          onExpOnChange={handleOnChange("addHold.cancelDtmExpr")}
          onExpOffChange={handleOnChange("addHold.cancelDtm")}
          onExpOffBlur={handleBlur}
          expOffFormType="dateTimePicker"
          returnExprOnEmpty
          errors={
            _renderAddHoldError("cancelDtmExpr", errors, touched) ||
            _renderAddHoldError("cancelDtm", errors, touched)
          }
        />
        <CoupledFieldWithExpr
          customClass="app-heading-box-contents"
          expOnValue={addHold.durExpr}
          expOnFormKey={"addHold.durExpr"}
          expOffValue={addHold.dur}
          expOffFormKey={"addHold.dur"}
          labelName={"How long will the hold apply for?"}
          durationModalTitle={"Enter hold duration"}
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("dur")?.tooltip_text_main
          }
          onExpOnChange={handleOnChange("addHold.durExpr")}
          onExpOffChange={handleOnChange("addHold.dur")}
          onExpOffBlur={handleBlur}
          expOffFormType="durationInput"
          errors={
            _renderAddHoldError("durExpr", errors, touched) ||
            _renderAddHoldError("dur", errors, touched)
          }
        />
        <Flex
          className="app-form-field-container app-heading-box-contents"
          justifyContent={"space-between"}
        >
          <AppFormLabel
            labelName="Is this a full business day?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isFullBusinessDay")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="addHold.isFullBusinessDay"
            onSelect={(value) =>
              setFieldValue("addHold.isFullBusinessDay", value)
            }
            leftBtnName="Yes"
            rightBtnName="No"
            value={addHold.isFullBusinessDay}
          />
          {_renderAddHoldError("isFullBusinessDay", errors, touched)}
        </Flex>
        <CoupledFieldWithExpr
          customClass="app-heading-box-contents last-item"
          expOnValue={addHold.intervalExpr}
          expOnFormKey={"addHold.intervalExpr"}
          expOffValue={addHold.interval}
          expOffFormKey={"addHold.interval"}
          labelName={"What is the slice interval?"}
          durationModalTitle={"Enter slice interval"}
          tooltipDesc={
            tooltipFlyoutDetails?.tooltipsMap?.get("interval")
              ?.tooltip_text_main
          }
          onExpOnChange={handleOnChange("addHold.intervalExpr")}
          onExpOffChange={handleOnChange("addHold.interval")}
          onExpOffBlur={handleBlur}
          expOffFormType="durationInput"
          errors={
            _renderAddHoldError("intervalExpr", errors, touched) ||
            _renderAddHoldError("interval", errors, touched)
          }
        />
      </CardContainer>
      <FieldArray
        name="addHold.slices"
        render={({ insert, remove, push }) => (
          <Box className="app-form-field-array-container">
            {addHold.slices?.map((slice: string, index: number) => (
              <CardContainer key={index}>
                <AccordionComponent
                  accordionTitle={`Hold slice details ${index + 1}`}
                  limitName={slice}
                  deleteIcon
                  onDeleteHandler={() => {
                    remove(index);
                    sliceExprDeleteHandler(index);
                  }}
                  isExpand={0}
                >
                  <CoupledFieldWithExpr
                    customClass="app-heading-box-contents"
                    expOnValue={addHold.slicesExpr[index]}
                    expOnFormKey={`addHold.slicesExpr[${index}]`}
                    expOffValue={slice}
                    expOffFormKey={`addHold.slices[${index}]`}
                    labelName={"What is the amount of this hold slice?"}
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("sliceAmt")
                        ?.tooltip_text_main
                    }
                    onExpOnChange={handleOnChange(
                      `addHold.slicesExpr.${index}`
                    )}
                    onExpOffChange={handleOnChange(`addHold.slices[${index}]`)}
                    onExpOffBlur={handleBlur}
                    expOffFormType="inputNumber"
                    errors={
                      _renderAddHoldError(
                        `slices.${index}` as any,
                        errors,
                        touched
                      ) ||
                      _renderAddHoldError(
                        `slicesExpr.${index}` as any,
                        errors,
                        touched
                      )
                    }
                  />
                </AccordionComponent>
              </CardContainer>
            ))}
            <AddNewButton
              label={
                addHold.slices.length
                  ? "Add another hold slice"
                  : "Add a hold slice"
              }
              onClick={() => {
                push("");
                sliceExprAddHandler();
              }}
            />
          </Box>
        )}
      ></FieldArray>
    </>
  );
};

export default HoldSpecifications;
