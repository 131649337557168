"use client";
import {
  AppFormLabel,
  CardContainer,
  InputText,
  StaticText,
  ErrorAlert,
} from "@/components/common";
import { Box, useToast } from "@/components/ChakraUiManager";
import { useContext, useEffect, useState } from "react";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";

import {
  GeneralFeatureProps,
  GeneralSetup,
  commonTitleProps,
  getValidationSchema,
} from "./GeneralFeatureValidation";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { PRODUCT_CLASS_NAME_WITH_COMPONENT } from "@/utils/constants";
import { COMPONENT_CLASS_ENUM, PostSchema } from "../../model/types";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { createComponentGeneric } from "../product-config-client-service";
import { ValueOf } from "@/utils/types";

interface Props extends commonTitleProps {
  componentClass: ValueOf<typeof COMPONENT_CLASS_ENUM>;
  data: {
    formData: {
      componentName: string;
      version: number;
    } | null;
    generalComponentOptionsData: PostSchema;
  };
}

const GeneralFeature = (props: Props) => {
  const {
    data: { formData: featureData, generalComponentOptionsData },
    componentClass,
  } = props;
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    tooltipFlyoutDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    getWorflowStatusToBeUpdated,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const toast = useToast();
  const toastId = "general-feature-toast";

  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.generalComponentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.generalComponentOptionsData]);

  // Submit form
  const onSubmit = async (values: GeneralFeatureProps, actions: any) => {
    // If component already exists, navigate to next page, since componentName is read only, no update required.
    if (!dirty && featureData) {
      navigateToNextPage?.();

      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: componentClass as string,
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      const response = await createComponentGeneric({
        productDetails,
        formData: {
          componentName: values.componentName,
          version: 1,
        },
        toastOptions: {
          toast,
          toastId,
          successMessage: `${PRODUCT_CLASS_NAME_WITH_COMPONENT[componentClass]} created.`,
        },
        stageName: getWorflowStatusToBeUpdated?.()!,
        componentClass,
      });
      // Navigate only when there is a successful response
      if (response && productDetails?.name) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    initialValues: featureData ?? GeneralSetup,
    validationSchema,
    onSubmit,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  const handleInputChange = (value: string) => {
    setFieldValue("componentName", value);
  };

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (canCheckFormStatus) handleSubmit();
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className={"app-form-field-container"}>
          <AppFormLabel
            labelName="What is the component name?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("componentName")
                ?.tooltip_text_main
            }
            isRequired
          />
          <InputText
            disabled={Boolean(props.data.formData?.componentName)}
            name="componentName"
            value={values.componentName}
            onChange={handleInputChange}
            onBlur={handleBlur}
            placeholder="[Component_name]"
          />
          {errors.componentName && touched.componentName && (
            <ErrorAlert>
              <span>{errors.componentName}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className={"app-form-field-container"}>
          <AppFormLabel
            labelName="Version"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("version")
                ?.tooltip_text_main
            }
            isRequired
          />
          <StaticText textValue={featureData?.version || "1"} />
        </Box>
      </CardContainer>
    </form>
  );
};
export default GeneralFeature;
