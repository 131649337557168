import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

interface LocationContactInfoProps {
  region: string;
  webSiteURL: string;
  emailDomain: string;
}

const locationContactInfoInitialValues: LocationContactInfoProps = {
  region: "",
  webSiteURL: "",
  emailDomain: "",
};

const locationContactInfoSchema = async (optionsSchema: PostSchema) => {
  const fields = ["region", "webSiteURL", "emailDomain"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { LocationContactInfoProps };

// Exporting Validation Schema and Intial value
export {
  locationContactInfoSchema as getValidationSchema,
  locationContactInfoInitialValues,
};
