import {
  COMPONENT_CLASS,
  IFX_ACCT_TYPE,
  IFX_PRODUCT_SUB_TYPE_VAL,
} from "@/utils/constants";

const PRODUCT_CATALOG = Object.freeze({
  retailProducts: {
    categoryName: "Retail products",
    catogeryType: "retail",
    tooltipDesc: "Explore the collection of retail products you can configure",
    productList: [
      {
        productId: "100",
        productName: "Savings",
        prodType: "Deposit",
        prodSubType: IFX_PRODUCT_SUB_TYPE_VAL.personal,
        prodGroup: "SAV",
        ifxAcctType: IFX_ACCT_TYPE.SDA,
        productDescription:
          "Offer robust savings solutions with our customizable savings product, enabling you to set access parameters, interest rates, and balance prerequisites to precisely meet your clients' saving necessities",
        productDetailTitle: "What to expect from a Savings personal product",
        componentConfigurationList: [
          {
            configName: "Fee component (deposit)",
            configDesc:
              "Encompasses any charges or costs associated with maintaining the account, such as monthly maintenance fees, transaction fees, or penalties for early withdrawals.",
            componentClass: COMPONENT_CLASS["fee"],
          },
          {
            configName: "Interest component (deposit)",
            componentClass: COMPONENT_CLASS["interest"],
            configDesc:
              "Provide incentives to save with your institution with an interest component.",
          },
          {
            configName: "Limit component (deposit)",
            configDesc:
              "Serves as the restriction or cap component of your product to ensure compliance with regulatory requirements or manage liquidity.",
            componentClass: COMPONENT_CLASS["limit"],
          },
          {
            configName: "Rewards component (deposit)",
            componentClass: COMPONENT_CLASS["reward"],
            configDesc:
              "Offering of additional benefits or incentives to account holders.",
          },
        ],
        additionalProductDetails: [
          {
            configName: "View additional details",
            configDesc:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          },
        ],
      },
      {
        productId: "101",
        productName: "Transactional",
        prodType: "Deposit",
        prodSubType: IFX_PRODUCT_SUB_TYPE_VAL.personal,
        prodGroup: "DDA",
        ifxAcctType: IFX_ACCT_TYPE.DDA,
        productDescription:
          "Transactional accounts, with options to customize components like fee structures, transaction limits, and additional services, catering to diverse banking needs.",
        productDetailTitle: "What to expect from a Checking retail product",
        componentConfigurationList: [
          {
            configName: "Fee component (deposit)",
            componentClass: COMPONENT_CLASS["fee"],
            configDesc:
              "Encompasses any charges or costs associated with maintaining the account, such as monthly maintenance fees, transaction fees, or penalties for early withdrawals",
          },
          {
            configName: "Interest component (deposit)",
            componentClass: COMPONENT_CLASS["interest"],
            configDesc:
              "Provide incentives to save with your institution with an interest component",
          },
          {
            configName: "Limit component (deposit)",
            componentClass: COMPONENT_CLASS["limit"],
            configDesc:
              "Serves as the restriction or cap component of your product to ensure compliance with regulatory requirements or manage liquidity",
          },
          {
            configName: "NSF component (deposit)",
            componentClass: COMPONENT_CLASS["nsf"],
            configDesc:
              "Assess a fee or penalty when there is an attempt to make a payment or withdrawal that exceeds the available balance in the account",
          },
          {
            configName: "Transaction component (deposit)",
            componentClass: COMPONENT_CLASS["transactionDeposit"],
            configDesc:
              "Configure the recording and processing of various types of financial activities, providing account holders with the ability to manage their funds and engage in day-to-day financial transactions",
          },
          {
            configName: "Charge-off component (deposit)",
            componentClass: COMPONENT_CLASS["chargeOff"],
            configDesc:
              "Determine the process and amount at which a debt is declared as uncollectible and removed from the books as a loss",
          },
        ],
        additionalProductDetails: [
          {
            configName: "View additional details",
            configDesc:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          },
        ],
      },
      {
        productId: "102",
        productName: "Certificate of Deposit",
        prodType: "Deposit",
        prodSubType: IFX_PRODUCT_SUB_TYPE_VAL.personal,
        prodGroup: "CD",
        ifxAcctType: IFX_ACCT_TYPE.CDA,
        productDetailTitle: "What to expect from a CD retail product",
        productDescription:
          "Fixed-term, flexible interest investments, with flexibility in deposit terms, maturity options, and interest disbursement to support diverse financial strategies.",
        componentConfigurationList: [
          {
            configName: "Fee component (deposit)",
            componentClass: COMPONENT_CLASS["fee"],
            configDesc:
              "Encompasses any charges or costs associated with maintaining the account, such as monthly maintenance fees, transaction fees, or penalties for early withdrawals",
          },
          {
            configName: "Interest component (deposit)",
            componentClass: COMPONENT_CLASS["interest"],
            configDesc:
              "Provide incentives to save with your institution with an interest component",
          },
          {
            configName: "Limit component (deposit)",
            componentClass: COMPONENT_CLASS["limit"],
            configDesc:
              "Serves as the restriction or cap component of your product to ensure compliance with regulatory requirements or manage liquidity",
          },
          {
            configName: "Term component (deposit)",
            componentClass: COMPONENT_CLASS["term"],
            configDesc:
              "Determine the length of time for which the funds are deposited, during which the account holder cannot withdraw the funds without incurring penalties and additional rate offers to keep funds locked in for the specified term",
          },
        ],
        additionalProductDetails: [
          {
            configName: "View additional details",
            configDesc:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          },
        ],
      },
      {
        productId: "103",
        productName: "Revolving Lines of Credit",
        prodType: "Loan",
        prodSubType: IFX_PRODUCT_SUB_TYPE_VAL.personal,
        prodGroup: "Revolving",
        ifxAcctType: IFX_ACCT_TYPE.LOC,
        productDescription:
          "Provide continuous credit lines and adjustable credit limits, interest rates, and repayment options, providing account holders with tailored financial flexibility.",
        productDetailTitle: "What to expect from a Revolving retail product",
        componentConfigurationList: [
          {
            configName: "Interest component (loan)",
            componentClass: COMPONENT_CLASS["interest"],
            configDesc:
              "Entails the cost charged to the borrowing party for utilizing the available credit, serving as your institution’s compensation for lending the funds",
          },
          {
            configName: "Repayment component (loan)",
            componentClass: COMPONENT_CLASS["repay"],
            configDesc:
              "Principal and interest payment schedule made by the borrowing party to gradually reduce the outstanding balance",
          },
          {
            configName: "Fee component (loan)",
            componentClass: COMPONENT_CLASS["fee"],
            configDesc:
              "Encompasses any charges or costs associated with maintaining the account, such as monthly maintenance fees, annual fees, or transaction fees",
          },
        ],
        additionalProductDetails: [
          {
            configName: "View additional details",
            configDesc:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          },
        ],
      },
      {
        productId: "104",
        productName: "Installment loans",
        prodType: "Loan",
        prodSubType: IFX_PRODUCT_SUB_TYPE_VAL.personal,
        prodGroup: "Install",
        ifxAcctType: IFX_ACCT_TYPE.ILA,
        productDescription:
          "Structured loans repaid in installments, customizable in loan amounts, repayment periods, and interest rates, accommodating various consumer financial situations.",
        productDetailTitle: "What to expect from a Installment loan",
        componentConfigurationList: [
          {
            configName: "Interest component (loan)",
            componentClass: COMPONENT_CLASS["interest"],
            configDesc:
              "Entails the cost charged to the borrowing party for utilizing the available credit, serving as your institution’s compensation for lending the funds",
          },
          {
            configName: "Repayment component (loan)",
            componentClass: COMPONENT_CLASS["repay"],
            configDesc:
              "Principal and interest payment schedule made by the borrowing party to gradually reduce the outstanding balance",
          },
          {
            configName: "Fee component (loan)",
            componentClass: COMPONENT_CLASS["fee"],
            configDesc:
              "Encompasses any charges or costs associated with maintaining the account, such as monthly maintenance fees, annual fees, or transaction fees",
          },
        ],
        additionalProductDetails: [
          {
            configName: "View additional details",
            configDesc:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          },
        ],
      },
    ],
  },
  commercialProducts: {
    categoryName: "Commercial products",
    catogeryType: "commercial",
    tooltipDesc:
      "Explore the collection of commercial products you can configure",
    productList: [
      {
        productId: "100",
        productName: "Savings",
        prodType: "Deposit",
        prodSubType: IFX_PRODUCT_SUB_TYPE_VAL.business,
        prodGroup: "SAV",
        ifxAcctType: IFX_ACCT_TYPE.SDA,
        productDescription:
          "Offer businesses robust savings solutions with our customizable accounts, enabling you to set access parameters, interest rates, and balance prerequisites to precisely meet your clients' commercial saving necessities",
        productDetailTitle: "What to expect from a Savings commercial product",
        componentConfigurationList: [
          {
            configName: "Fee component (deposit)",
            configDesc:
              "Encompasses any charges or costs associated with maintaining the account, such as monthly maintenance fees, transaction fees, or penalties for early withdrawals",
            componentClass: COMPONENT_CLASS["fee"],
          },
          {
            configName: "Interest component (deposit)",
            componentClass: COMPONENT_CLASS["interest"],
            configDesc:
              "Provide incentives to save with your institution with an interest component",
          },
          {
            configName: "Limit component (deposit)",
            configDesc:
              "Serves as the restriction or cap component of your product to ensure compliance with regulatory requirements or manage liquidity",
            componentClass: COMPONENT_CLASS["limit"],
          },
          {
            configName: "Rewards component (deposit)",
            configDesc:
              "Offering of additional benefits or incentives to account holders",
            componentClass: COMPONENT_CLASS["reward"],
          },
        ],
        additionalProductDetails: [
          {
            configName: "View additional details",
            configDesc:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          },
        ],
      },
      {
        productId: "101",
        productName: "Transactional",
        prodType: "Deposit",
        prodSubType: IFX_PRODUCT_SUB_TYPE_VAL.business,
        prodGroup: "DDA",
        ifxAcctType: IFX_ACCT_TYPE.DDA,
        productDescription:
          "Transactional accounts for businesses, with options to customize components like fee structures, transaction limits, and additional services, catering to everyday operational requirements.",
        productDetailTitle: "What to expect from a Checking commercial product",
        componentConfigurationList: [
          {
            configName: "Fee component (deposit)",
            componentClass: COMPONENT_CLASS["fee"],
            configDesc:
              "Encompasses any charges or costs associated with maintaining the account, such as monthly maintenance fees, transaction fees, or penalties for early withdrawals",
          },
          {
            configName: "Interest component (deposit)",
            componentClass: COMPONENT_CLASS["interest"],
            configDesc:
              "Provide incentives to save with your institution with an interest component",
          },
          {
            configName: "Limit component (deposit)",
            componentClass: COMPONENT_CLASS["limit"],
            configDesc:
              "Serves as the restriction or cap component of your product to ensure compliance with regulatory requirements or manage liquidity",
          },
          {
            configName: "NSF component (deposit)",
            componentClass: COMPONENT_CLASS["nsf"],
            configDesc:
              "Assess a fee or penalty when a business attempts to make a payment or withdrawal that exceeds the available balance in their account",
          },
          {
            configName: "Transaction component (deposit)",
            componentClass: COMPONENT_CLASS["transactionDeposit"],
            configDesc:
              "Configure the recording and processing of various types of financial activities, providing businesses with the ability to manage their funds and engage in day-to-day financial transactions",
          },
          {
            configName: "Charge-off component (deposit)",
            componentClass: COMPONENT_CLASS["chargeOff"],
            configDesc:
              "Determine the process and amount at which your institution declares a debt as uncollectible and removing it from the books as a loss",
          },
        ],
        additionalProductDetails: [
          {
            configName: "View additional details",
            configDesc:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          },
        ],
      },
      {
        productId: "102",
        productName: "Certificate of deposit",
        prodType: "Deposit",
        prodSubType: IFX_PRODUCT_SUB_TYPE_VAL.business,
        prodGroup: "CD",
        ifxAcctType: IFX_ACCT_TYPE.CDA,
        productDescription:
          "Fixed-term, flexible interest investments for businesses, with flexibility in deposit terms, maturity options, and interest disbursement to support diverse financial strategies.",
        productDetailTitle: "What to expect from a CD commercial product",
        componentConfigurationList: [
          {
            configName: "Fee component (deposit)",
            componentClass: COMPONENT_CLASS["fee"],
            configDesc:
              "Encompasses any charges or costs associated with maintaining the account, such as monthly maintenance fees, transaction fees, or penalties for early withdrawals",
          },
          {
            configName: "Interest component (deposit)",
            componentClass: COMPONENT_CLASS["interest"],
            configDesc:
              "Provide incentives to save with your institution with an interest component",
          },
          {
            configName: "Limit component (deposit)",
            componentClass: COMPONENT_CLASS["limit"],
            configDesc:
              "Serves as the restriction or cap component of your product to ensure compliance with regulatory requirements or manage liquidity",
          },
          {
            configName: "Term component (deposit)",
            componentClass: COMPONENT_CLASS["term"],
            configDesc:
              "Determine the length of time for which the funds are deposited, during which the account holder cannot withdraw the funds without incurring penalties and additional rate offers to keep funds locked in for the specified term",
          },
        ],
        additionalProductDetails: [
          {
            configName: "View additional details",
            configDesc:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          },
        ],
      },
      {
        productId: "103",
        productName: "Revolving lines of credit",
        prodType: "Loan",
        prodSubType: IFX_PRODUCT_SUB_TYPE_VAL.business,
        prodGroup: "Revolving",
        ifxAcctType: IFX_ACCT_TYPE.LOC,
        productDescription:
          "Provide a continuous commercial credit lines, adjustable in credit limits, interest rates, and repayment options, providing businesses with tailored financial flexibility.",
        productDetailTitle:
          "What to expect from a Revolving commercial product",
        componentConfigurationList: [
          {
            configName: "Interest component (loan)",
            componentClass: COMPONENT_CLASS["interest"],
            configDesc:
              "Entails the cost charged to the borrowing party for utilizing the available credit, serving as your institution’s compensation for lending the funds",
          },
          {
            configName: "Repayment component (loan)",
            componentClass: COMPONENT_CLASS["repay"],
            configDesc:
              "Principal and interest payment schedule made by the borrowing party to gradually reduce the outstanding balance",
          },
          {
            configName: "Fee component (loan)",
            componentClass: COMPONENT_CLASS["fee"],
            configDesc:
              "Encompasses any charges or costs associated with maintaining the account, such as monthly maintenance fees, annual fees, or transaction fees",
          },
        ],
        additionalProductDetails: [
          {
            configName: "View additional details",
            configDesc:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          },
        ],
      },
      {
        productId: "104",
        productName: "Installment loans",
        prodType: "Loan",
        prodSubType: IFX_PRODUCT_SUB_TYPE_VAL.business,
        prodGroup: "Install",
        ifxAcctType: IFX_ACCT_TYPE.ILA,
        productDescription:
          "Structured loans repaid in installments, customizable in loan amounts, repayment periods, and interest rates, accommodating various commercial financial situations.",
        productDetailTitle: "What to expect from a Installment loan",
        componentConfigurationList: [
          {
            configName: "Interest component (loan)",
            componentClass: COMPONENT_CLASS["interest"],
            configDesc:
              "Entails the cost charged to the borrowing party for utilizing the available credit, serving as your institution’s compensation for lending the funds",
          },
          {
            configName: "Repayment component (loan)",
            componentClass: COMPONENT_CLASS["repay"],
            configDesc:
              "Principal and interest payment schedule made by the borrowing party to gradually reduce the outstanding balance",
          },
          {
            configName: "Fee component (loan)",
            componentClass: COMPONENT_CLASS["fee"],
            configDesc:
              "Encompasses any charges or costs associated with maintaining the account, such as monthly maintenance fees, annual fees, or transaction fees",
          },
        ],
        additionalProductDetails: [
          {
            configName: "View additional details",
            configDesc:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          },
        ],
      },
    ],
  },
});

const PRODUCT_LIST_TABLE_COLUMN_REF = [
  {
    header: "Name",
    accessorKey: "name",
    enableSorting: true,
    sortingFn: "basic",
  },
  { header: "Description", accessorKey: "desc", enableSorting: false },
  { header: "Status", accessorKey: "status", enableSorting: true },
  { header: "Type", accessorKey: "prodType", enableSorting: true },
  { header: "Subtype", accessorKey: "prodSubType", enableSorting: true },
  { header: "Group", accessorKey: "prodGroup", enableSorting: true },
  { header: "", accessorKey: "action", enableSorting: false },
];

const RECENT_PRODUCT_LIST_TABLE_COLUMN_REF = [
  { header: "Name", accessorKey: "name", enableSorting: true },
  { header: "Description", accessorKey: "desc", enableSorting: false },
  { header: "Status", accessorKey: "status", enableSorting: true },
  { header: "", accessorKey: "action", enableSorting: false },
];

export {
  PRODUCT_CATALOG,
  PRODUCT_LIST_TABLE_COLUMN_REF,
  RECENT_PRODUCT_LIST_TABLE_COLUMN_REF,
};
