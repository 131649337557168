import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface
interface CutoffsLimitsThresholdsProps {
  finCutOffTm: string;
  maxExtAcct: string | number;
  largeOdLimit: string | number;
  repeatODDuration: string;
  taxIntThld: string | number;
  taxDistThld: string | number;
}
// Defining Intitial Value
const defaultCutoffsLimitsThresholds: CutoffsLimitsThresholdsProps = {
  finCutOffTm: "",
  maxExtAcct: "",
  largeOdLimit: "",
  repeatODDuration: "",
  taxIntThld: "",
  taxDistThld: "",
};

const cutoffsLimitsThresholdsSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "finCutOffTm",
    "maxExtAcct",
    "largeOdLimit",
    "repeatODDuration",
    "taxIntThld",
    "taxDistThld",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { CutoffsLimitsThresholdsProps };
// Exporting Validation Schema and Intial value
export {
  defaultCutoffsLimitsThresholds,
  cutoffsLimitsThresholdsSchema as getValidationSchema,
};
