"use client";

import { SliceZone } from "@prismicio/react";
import { components } from "@/slices";
import { Grid, GridItem, Show, VStack } from "@chakra-ui/react";
import { Box, Stack, Link } from "@/components/ChakraUiManager";
import "./APIDetailPage.scss";
import { useAPILibSideMenuData } from "@/app/apis/providers";
import { Key, useEffect, useState, createRef, useMemo, useRef } from "react";
import NextApiReference from "@/slices/NextApiReference";
import { computeDocReadTime } from "@/utils/common";
import { CardContainer, FooterWidgetIframe } from "@/components/common";
import APILibNotLoggedInFooter from "../not-logged-in-footer/APILibNotLoggedInFooter";
import DocCenterPageHeader from "@/components/documentcenter/doc-detail-page/doc-center-page-header/DocCenterPageHeader";
import { fetchUrlForResources } from "@/prismic-api/common-service";
import OverviewHeader from "@/components/tutorials/overview-header/OverviewHeader";
import { DocumentTypes } from "@/utils/constants";

function APIDetailPage(props: any) {
  const uid = props.home.uid;
  const type = props.home.type;
  const [breadCrumb, setBreadcrumb] = useState<string[]>([]);
  const { loading, breadCrumbs, nextDocumentItems } = useAPILibSideMenuData();
  const ID_TOP_OF_THE_PAGE = "top-of-the-page";
  const dateString = props.home.firstPublicationDate;
  const [activeSection, setActiveSection] =
    useState<string>(ID_TOP_OF_THE_PAGE);
  const documentTitle = props.home?.title;

  const resourceSlice = props.home.slices.filter((slice: any) => {
    return slice.type === "resources_links_section";
  });
  const additionalResources = resourceSlice.length ? resourceSlice[0].data : [];
  const topRef = createRef<HTMLDivElement>();
  const docBodyRef = useRef<HTMLDivElement | null>(null);

  const readTime = useMemo(() => {
    if (docBodyRef.current) {
      if (docBodyRef.current) {
        return computeDocReadTime(docBodyRef.current);
      }
    }
    return 0;
    // Re-calculate time to read when inner text changes
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [docBodyRef.current?.innerText]);

  useEffect(() => {
    if (!loading) {
      const breadCrumb = breadCrumbs;
      if (breadCrumb[type][uid]) {
        setBreadcrumb(breadCrumb[type][uid].split(","));
      }
    }
  }, [loading, uid]);

  const getNextDocument = () => {
    const indexForCurrentItem = nextDocumentItems.findIndex(
      (item) => item.uid === uid
    );
    const nextItem = nextDocumentItems[indexForCurrentItem + 1];
    if (nextItem)
      return (
        <NextApiReference
          title={nextItem.title}
          type={nextItem.type}
          uid={nextItem.uid}
          path={nextItem.path}
        />
      );
    return null;
  };
  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  };
  const observer = new IntersectionObserver((entries) => {
    const id = entries.filter((entry) => entry.isIntersecting)[0]?.target.id;
    if (id) setActiveSection(id);
  }, observerOptions);

  const filteredPageIndexes = useMemo(
    () => props.home.pageIndex.filter((pI: any) => pI.content.length > 0),
    [props.home.pageIndex]
  );

  // Function to handle the activation of a link.
  const handleSetActive = (to: string) => {
    setActiveSection(to);
  };

  useEffect(() => {
    if (!loading) {
      const breadCrumb = breadCrumbs;
      if (breadCrumb[type][uid]) {
        setBreadcrumb(breadCrumb[type][uid].split(","));
      }
      topRef.current?.scrollTo(0, 0);
    }
  }, [loading, uid]);

  useEffect(() => {
    if (!loading && filteredPageIndexes) {
      const pageIdsWithTopOfThePage = [
        ...filteredPageIndexes,
        { content: ID_TOP_OF_THE_PAGE },
      ];
      setTimeout(() => {
        pageIdsWithTopOfThePage.map((pageIndex: any, index: number) => {
          const id = String(pageIndex.content.replaceAll(" ", "-"));
          let target = document.getElementById(id);
          if (target) {
            observer.observe(target);
          }
        });
        // As we do not not how long it takes to load all the prismic slices
        // After that we can add observer on that.
      }, 2000);
    }
  }, [loading, props.home.pageIndex]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (e.currentTarget.scrollTop === 0) {
      handleSetActive(ID_TOP_OF_THE_PAGE);
    }
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      handleSetActive(id);
    }
  };

  const getJumpLink = (key: Key, id: string, content: string) => {
    return (
      <Link
        key={key}
        onClick={(event: React.SyntheticEvent) => {
          event.preventDefault();
          scrollTo(id);
        }}
        className={`jump-link ${id === activeSection ? `active` : ``}`}
      >
        {content}
      </Link>
    );
  };

  return (
    <VStack
      id="doc-center-overview"
      align="stretch"
      overflowY={{ base: "auto", md: "hidden" }}
      className="api-detail"
      gap={0}
    >
      <OverviewHeader documentTitle={documentTitle} breadcrumbs={breadCrumb} />
      <Box height="100%" overflowY={{ base: "visible", md: "auto" }}>
        <Box
          ref={topRef}
          margin={{ base: 6.4, md: 12.8 }}
          marginBottom={{ md: 0 }}
        >
          {type === DocumentTypes.ApiLibrary ? (
            <Stack gap={12.8}>
              <SliceZone components={components} slices={props.home?.slices} />
            </Stack>
          ) : (
            <Box
              className="document-page"
              id="doc-center-page"
              onScroll={handleScroll}
              ref={topRef}
            >
              <Grid className="grid" id={ID_TOP_OF_THE_PAGE}>
                <GridItem className="doc-center-body">
                  <CardContainer customClass="documents">
                    <Stack gap={8} ref={docBodyRef}>
                      <DocCenterPageHeader
                        documentTitle={documentTitle}
                        dateString={dateString}
                        readTime={readTime}
                      />
                      <Box
                        display={{ base: "block", md: "none" }}
                        className="on-this-page"
                      >
                        <div className="header">On this page</div>
                        <Stack as="nav">
                          {getJumpLink(-1, ID_TOP_OF_THE_PAGE, documentTitle)}
                          {filteredPageIndexes.map(
                            (pageIndex: any, index: number) => {
                              const id = String(
                                pageIndex.content.replaceAll(" ", "-")
                              );
                              return getJumpLink(index, id, pageIndex.content);
                            }
                          )}
                        </Stack>
                      </Box>
                      <SliceZone
                        components={components}
                        slices={props.home?.slices}
                      />
                    </Stack>
                  </CardContainer>
                </GridItem>
                <Show above="md">
                  <GridItem colSpan={1}>
                    <Stack className="sticky">
                      <Box className="on-this-page">
                        <div className="header">On this page</div>
                        <Stack as="nav">
                          {getJumpLink(-1, ID_TOP_OF_THE_PAGE, documentTitle)}
                          {filteredPageIndexes.map(
                            (pageIndex: any, index: number) => {
                              const id = String(
                                pageIndex.content.replaceAll(" ", "-")
                              );
                              return getJumpLink(index, id, pageIndex.content);
                            }
                          )}
                        </Stack>
                      </Box>
                      {additionalResources?.length > 0 && (
                        <Box className="additional-resources">
                          <div className="header">Additional resources</div>
                          <Stack>
                            {additionalResources.map(
                              (additionalResource: any, key: any) => {
                                return (
                                  <Box className="resource-box" key={key}>
                                    <Link
                                      className="app-btn-link"
                                      href={fetchUrlForResources(
                                        additionalResource
                                      )}
                                    >
                                      {additionalResource.link_title}
                                    </Link>
                                  </Box>
                                );
                              }
                            )}
                          </Stack>
                        </Box>
                      )}
                    </Stack>
                  </GridItem>
                </Show>
              </Grid>
              {additionalResources?.length > 0 && (
                <Box
                  display={{ base: "block", md: "none" }}
                  className="additional-resources mobile"
                >
                  <hr />
                  <div className="header">Additional resources</div>
                  <Stack>
                    {additionalResources.map(
                      (additionalResource: any, key: any) => {
                        return (
                          <Box className="resource-box" key={key}>
                            <Link
                              className="app-btn-link"
                              href={fetchUrlForResources(additionalResource)}
                            >
                              {additionalResource.link_title}
                            </Link>
                          </Box>
                        );
                      }
                    )}
                  </Stack>
                </Box>
              )}
            </Box>
          )}
          {getNextDocument()}
        </Box>
        <FooterWidgetIframe />
        <Show above="md">
          <APILibNotLoggedInFooter />
        </Show>
      </Box>
    </VStack>
  );
}

export default APIDetailPage;
