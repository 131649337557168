import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import {
  PostSchema,
  RepaymentComponentData,
} from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

type ComponentDueItems = Required<RepaymentComponentData>["dueItems"][number];

interface DueItems extends ComponentDueItems {
  idx: number;
}

interface RepayPastDueConfigProps {
  dueItems: DueItems[];
}
// Defining Intitial Value For Promotional_Rate_Foundational_Details
const repayPastDueConfigAndTracking: RepayPastDueConfigProps = {
  dueItems: [
    {
      idx: 0,
      calcMthd: undefined,
      description: "",
      isReceivable: undefined,
      itemName: "",
      subBal: "",
      toleranceAmt: 0,
      tolerancePct: 0,
    },
  ],
};

// YUP LIBRARY :Defining Schema For validation of Promotional_Rate_Foundational_Details
const paymentDueConfigSchema = async (optionsSchema: PostSchema) => {
  // declare top level fields specific to this form
  const fields = ["dueItems"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { RepayPastDueConfigProps };
// Exporting Validation Schema and Intial value
export {
  repayPastDueConfigAndTracking,
  paymentDueConfigSchema as getValidationSchema,
};
