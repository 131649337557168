"use client";

import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  RadioButtonGroup,
  SplitButton,
} from "@/components/common";
import "./NumberingIdentification.scss";
import { useFormik } from "formik";
import { AppRadioProps } from "@/components/common/form-fields/radio-button-group/RadioButtonGroup";
import { Box, useToast } from "@/components/ChakraUiManager";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  defaultNumberingIdentification,
  getValidationSchema,
} from "./NumberingIdentificationValidation";
import { AccountGroupData } from "../../model";
import { updateAccountGroup } from "../../ic-config-api-service";
import { IC_ENTITY } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { shouldUpdateWorkflowStatus } from "@/utils/common";

type NumberingIdentificationProps = {
  data: {
    chkDigitMthdOptions: AppRadioProps<number>[];
    formData: AccountGroupData | null;
    accountGroupOptionsData: PostSchema;
  };
};

const NumberingIdentification = (props: NumberingIdentificationProps) => {
  const toast = useToast();
  const numberingData = props.data?.formData ?? null;
  const { accountGroupOptionsData } = props.data;

  const onSubmit = async (values: any, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?.acctGroup!,
          stage: IC_ENTITY.account_group,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    const res = await updateAccountGroup({
      formData: {
        _vn: props.data.formData?._vn,
        acctGroup: props.data.formData?.acctGroup,
        // PATCH Feilds
        isProdPrefix: values.isProdPrefix,
        isAcctNbrRandom: values.isAcctNbrRandom,
        chkDigitMthd: values.chkDigitMthd,
      },
      toastOptions: {
        toast: toast,
        toastId: "numbering-identification",
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (res) {
      navigateToNextPage?.();
    }
  };
  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(accountGroupOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [accountGroupOptionsData]);

  // using useFormik hook from Formik Library
  const { values, handleSubmit, errors, dirty, touched, setFieldValue } =
    useFormik({
      onSubmit,
      validationSchema,
      initialValues: numberingData
        ? {
            isProdPrefix: numberingData?.isProdPrefix ?? undefined,
            isAcctNbrRandom: numberingData?.isAcctNbrRandom ?? undefined,
            chkDigitMthd:
              numberingData?.chkDigitMthd ??
              defaultNumberingIdentification.chkDigitMthd,
          }
        : defaultNumberingIdentification,
    });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Use product Prefix?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("productPrefix")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="isProdPrefix"
            onSelect={handleOnChange("isProdPrefix")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isProdPrefix}
          />
          {errors.isProdPrefix && touched.isProdPrefix && (
            <ErrorAlert>
              <span>{errors.isProdPrefix}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Random Account Number Generation?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get(
                "randomAccntNumberGeneration"
              )?.tooltip_text_main
            }
          />
          <SplitButton
            name="isAcctNbrRandom"
            onSelect={handleOnChange("isAcctNbrRandom")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isAcctNbrRandom}
          />
          {errors.isAcctNbrRandom && touched.isAcctNbrRandom && (
            <ErrorAlert>
              <span>{errors.isAcctNbrRandom}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What check digit method would you like to use for account numbers?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("checkDigitMethod")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.chkDigitMthd}
            onChange={handleOnChange("chkDigitMthd")}
            radioPropList={props.data.chkDigitMthdOptions}
            stackDirection={"row"}
            spacing={"3rem"}
          />
          {errors.chkDigitMthd && touched.chkDigitMthd && (
            <ErrorAlert>
              <span>{errors.chkDigitMthd}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default NumberingIdentification;
