import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For transaction-code-specifications-setup
interface TransactionCodeSpecificationsProps {
  roundUpTrnCode: string;
  inclRoundUpTrnCodes: string[];
}
// Defining Intitial Value For transaction-code-specifications-setup
const transactionCodeSpecificationsDetails: TransactionCodeSpecificationsProps =
  {
    roundUpTrnCode: "",
    inclRoundUpTrnCodes: [],
  };

// YUP LIBRARY :Defining Schema For validation of transaction-code-specifications-setup
const transactionCodeSpecificationsDetailsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["roundUpTrnCode", "inclRoundUpTrnCodes"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { TransactionCodeSpecificationsProps };
// Exporting Validation Schema and Intial value
export {
  transactionCodeSpecificationsDetails,
  transactionCodeSpecificationsDetailsSchema as getValidationSchema,
};
