import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import * as Yup from "yup";
import { Option } from "@/utils/types";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";

interface TaxSpecificationsProps {
  isTaxExempt: SplitBtnInputValType;
  taxExemptType: number | undefined;
}

const taxSpecificationsInitailValues: TaxSpecificationsProps = {
  isTaxExempt: undefined,
  taxExemptType: undefined,
};

const TaxSpecificationsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["isTaxExempt", "taxExemptType"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

//exporting Inteface
export type { TaxSpecificationsProps };
export {
  TaxSpecificationsSchema as getValidationSchema,
  taxSpecificationsInitailValues,
};
