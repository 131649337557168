"use client";

import {
  Box,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Text,
  Button,
  Image,
} from "@chakra-ui/react";
import "./CourseCard.scss";
import Rating from "react-rating-stars-component";
import { StyleTwoTone } from "../../my-learning-profile/course-profile/course-profile-box/icons/StyleTwoTone/StyleTwoTone";
import { useState } from "react";
import { Badges } from "@/components/common";
import {
  formatDate,
  getBadgeByState,
  getDetailsPath,
} from "../../learning-util-service";
import { LearningObjectDetails } from "../../../../models/academy-models";
import { shareIcon, bookmarkedIcon, bookmarkIcon } from "public/assets";
import { deleteBookmark, addBookmark } from "@/client-api-manager/alm-api";
import React from "react";
import styleVars from "@/styles/_export.module.scss";
import { ROUTE_PATH } from "@/route-config/route-path";
import Link from "next/link";
import { StarIcon } from "@chakra-ui/icons";
import { DATE_FORMAT } from "@/utils/constants";

const { appRatingColor, appRatingActiveColor } = styleVars;

export default function CourseCard({
  course,
  updateCatalogItem,
}: {
  course: LearningObjectDetails;
  updateCatalogItem: any;
}) {

  const [hover, setHover] = useState(false);
  const handleBookmarkClick = async () => {
    if (course.isBookmarked) {
      await deleteBookmark(course.learnerObjectId);
    } else {
      await addBookmark(course.learnerObjectId);
    }
    updateCatalogItem({ ...course, isBookmarked: !course.isBookmarked });
  };

  const getCourseType = () => {
    if (course.type === "learningProgram") {
      return "Badge path";
    } else if (course.format?.toLowerCase() === "classroom") {
      return "Instructor-led Sessions";
    }
    return course.type;
  };
  return (
    <Box
      className="course-card-container"
      style={{
        backgroundImage: `linear-gradient(rgba(38, 41, 46, 0.4), rgba(38, 41, 46, 0.4)), url("${course.imageUrl}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box className="course-action">
        <Badges
          size="lg"
          {...getBadgeByState({
            status: course.state,
            progressPercent: course.progressPercent,
            dateCreated:
              course.format?.toLowerCase() === "classroom"
                ? formatDate(course.completionDeadline, DATE_FORMAT.shortDate)
                : "",
          })}
        ></Badges>

        <Menu>
          <MenuButton aria-label="view more">
            <StyleTwoTone
              className="ellipsis-icon"
              color={hover ? "black" : "white"}
            />
          </MenuButton>
          <MenuList className="menu-list-container">
            <MenuItem
              className="dropdown-menu-item"
              onClick={handleBookmarkClick}
            >
              <Image
                src={
                  course.isBookmarked ? bookmarkedIcon.src : bookmarkIcon.src
                }
                alt="Bookmark"
                className="menu-image"
              />
              <span className="menu-text">
                {course.isBookmarked ? "Saved" : "Save"}
              </span>
            </MenuItem>
            <MenuItem className="dropdown-menu-item">
              <Image src={shareIcon.src} alt="Share" className="menu-image" />
              <span className="menu-text">Share URL</span>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <Box className="course-details">
        <Text className="course-details-title" as="h3">
          {course.name}
        </Text>
        <Text className="course-details-type" textTransform={"capitalize"}>
          {getCourseType()}
        </Text>
        {hover && (
          <>
            <Text as="p" className="course-details-description">
              {course.description}
            </Text>
            <Box className="course-details-action">
              <Link
                href={`${getDetailsPath(course.type)}?id=${
                  course.learnerObjectId
                }&from=catalog`}
                className="app-btn-link"
              >
                View
              </Link>
              {course.type !== "certification" && (
                <div className="group">
                  <Rating
                    value={course.rating}
                    size={24}
                    isHalf={false}
                    edit={false}
                    classNames="custom-rating"
                    usingIcons={true}
                    emptyIcon={<StarIcon color={appRatingColor} />}
                    filledIcon={<StarIcon color={appRatingActiveColor} />}
                  />
                </div>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
