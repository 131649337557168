import { ERROR_MESSAGE } from "@/components/data/form-data";
import * as Yup from "yup";
import { minMaxError } from "@/components/common";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
//Defining Interface For Promotion_Rate_Adjustment_Range
interface PromotionRateAdjustmentRangesProps {
  promoDtl: {
    minRate: string;
    maxRate: string;
    maxChngFirst: string;
    maxChngNext: string;
    nextChngFreq: string;
  };
}
// Defining Intitial Value For Promotion_Rate_Adjustment_Range
const promotionRateAdjustmentRangesDetails: PromotionRateAdjustmentRangesProps =
  {
    promoDtl: {
      minRate: "",
      maxRate: "",
      maxChngFirst: "",
      maxChngNext: "",
      nextChngFreq: "",
    },
  };
// YUP LIBRARY :Defining Schema For validation of Promotion_Rate_Adjustment_Range
const promotionRateAdjustmentRangesDetailSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["promoDtl"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { PromotionRateAdjustmentRangesProps };
// Exporting Validation Schema and Intial value
export {
  promotionRateAdjustmentRangesDetails,
  promotionRateAdjustmentRangesDetailSchema as getValidationSchema,
};
