import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Fee_General_Configuration
interface FeeGeneralConfigurationProps {
  feeItems: {
    idx: number;
    feeName: string;
    feeTypeOpt: number | undefined;
    isAccum: SplitBtnInputValType;
  }[];
}
// Defining Intitial Value For Fee_General_Configuration
const feeConfiguration: FeeGeneralConfigurationProps = {
  feeItems: [
    {
      idx: 0,
      feeName: "",
      feeTypeOpt: undefined,
      isAccum: undefined,
    },
  ],
};

//YUP LIBRARY : Defining Schema For Validation Of Fee_General_Configuration

const feeConfigurationSchema = async (optionsSchema: PostSchema) => {
  const fields = ["feeItems"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { FeeGeneralConfigurationProps };
// Exporting Validation Schema and Intial value
export { feeConfiguration, feeConfigurationSchema as getValidationSchema };
