import { GENERIC_ERROR } from "@/components/data/error-data";
import { TransCodeResponse } from "../models";
import * as Yup from "yup";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  FastDefaultDropdownProps,
  PostSchema,
} from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";

export interface TransCodeFoundationalDetailsForm
  extends Partial<
    Omit<TransCodeResponse, "networkIncl" | "trnLimits" | "isDisputable">
  > {
  networkIncl: string[];
  trnLimits: string[];
  isDisputable: SplitBtnInputValType;
}

export const initialTcFoundationalValues: TransCodeFoundationalDetailsForm = {
  trnCode: "",
  desc: "",
  networkIncl: [],
  trnLimits: [],
  isDisputable: false,
};

export const tcFoundationalDetailsValidationSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = [
    "trnCode",
    "desc",
    "networkIncl",
    "trnLimits",
    "isDisputable",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
