import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";
import { PostSchema } from "@/components/product-management/model/types";

//Defining Interface For Repayment-PrincipalAndInterestProps
interface RepaymentPrincipalAndInterestProps {
  prinMthd: number | undefined;
  fixPrinAmt: string;
  intMthd: number | string | undefined;
  fixIntAmt: string;
  prinBalPct: string;
  prinBalBase: number | undefined;
  amortizeDur: string;
  oddDaysIntOpt: number | undefined;
}
// Defining Intitial Value For General-Component-Setup
const repaymentPrincipalAndInterestDetails: RepaymentPrincipalAndInterestProps =
  {
    prinMthd: undefined,
    fixPrinAmt: "",
    intMthd: "",
    fixIntAmt: "",
    prinBalPct: "",
    prinBalBase: undefined,
    amortizeDur: "",
    oddDaysIntOpt: undefined,
  };
// YUP LIBRARY :Defining Schema For validation of General-Component-Setup
const repaymentPrincipalAndInterestDetailsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = [
    "fixPrinAmt",
    "intMthd",
    "fixIntAmt",
    "prinBalPct",
    "prinBalBase",
    "amortizeDur",
    "oddDaysIntOpt",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { RepaymentPrincipalAndInterestProps };
// Exporting Validation Schema and Intial value
export {
  repaymentPrincipalAndInterestDetails,
  repaymentPrincipalAndInterestDetailsSchema as getValidationSchema,
};
