"use client";
import { useContext, useEffect } from "react";
import SplitButton, {
  SplitBtnInputValType,
} from "@/components/common/split-button/SplitButton";
import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  StaticText,
} from "@/components/common";
import { Box, CardBody, useToast } from "@chakra-ui/react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { RegAdhLimProps } from "../regulatory-adherence-limitation-wrapper/RegulatoryAdherenceLimitationWrapper";
import { RegulatoryAdherenceLimitationFormProps } from "../regulatory-adherence-limitation-wrapper/RegulatoryAdherenceLimitationValidation";
import { updateProduct, updateWorkflowGeneric } from "@/api-config/api-service";
import { useFormik } from "formik";
import { ProductType } from "@/components/product-management/model/product-models";
import {
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS,
  DASHBOARD_ACCORDION_STATUS,
} from "@/utils/constants";
import { PRODUCT_STATUS } from "@/components/data/form-data";
import { ROUTE_PATH } from "@/route-config/route-path";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

const CheckingRegulatoryAdherenceLimitation = (props: RegAdhLimProps) => {
  const toast = useToast();
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    navigateWithProductNameParam,
    tooltipFlyoutDetails,
    getWorflowStatusToBeUpdated,
    checkFormStatus,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const { formData } = props.data;

  const handleRegulatoryAdherenceData =
    (key: string) => (value: SplitBtnInputValType) => {
      setFieldValue(key, value);
    };

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateWithProductNameParam(
        `${ROUTE_PATH["PRODUCT_CONFIG_INTERSTITIAL_SCREEN"]}`
      );
    }
    return;
  };

  // Update product
  const onSubmit = async (values: RegulatoryAdherenceLimitationFormProps) => {
    if (!dirty) {
      moveToNextStep();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage:
            ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS[
              "regulatoryAdherenceLimitations"
            ],
          status: PRODUCT_STATUS.completed,
        });
      return;
    }

    if (formData) {
      const res = await updateProduct({
        productName: formData.name,
        body: JSON.stringify({
          // NOTE: _vn is required to do a PATCH update
          _vn: formData?._vn,
          // PATCH fields
          isFedExempt: values.isFedExempt,
          isStateExempt: values.isStateExempt,
          isNraExempt: values.isNraExempt,
        } as ProductType),
      });
      if (res && productDetails?.name) {
        if (!toast.isActive("regulatory-adherence-limitations")) {
          toast({
            id: "regulatory-adherence-limitations",
            description: `${configPageTitle} updated.`,
            status: "success",
          });
        }
        getExistingProduct?.(productDetails.name, true);
        if (productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed) {
          await updateWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.product,
            key: productDetails.name,
            stage:
              ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS[
                "regulatoryAdherenceLimitations"
              ],
            status: PRODUCT_STATUS.completed,
          });
        }
        moveToNextStep();
      }
    }
  };

  const formik = useFormik({
    onSubmit,
    validationSchema: props.data.prodBkOptionsSchema,
    initialValues: {
      isFedExempt: formData?.isFedExempt ?? undefined,
      isStateExempt: formData?.isStateExempt ?? undefined,
      isNraExempt: formData?.isNraExempt ?? undefined,
    },
  });
  const { values, handleSubmit, errors, touched, setFieldValue, dirty } =
    formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <StaticText
            className="additional-description-area"
            textValue={
              "Determine the regulatory rules the product must follow and set specific constraints to ensure your product remains compliant"
            }
          />
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is federal withholding exempt?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isFedExempt")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isFedExempt}
            onSelect={handleRegulatoryAdherenceData("isFedExempt")}
          />
          {errors.isFedExempt && touched.isFedExempt && (
            <ErrorAlert>
              <span>{errors.isFedExempt as string}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is state withholding exempt?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isStateExempt")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isStateExempt}
            onSelect={handleRegulatoryAdherenceData("isStateExempt")}
          />
          {errors.isStateExempt && touched.isStateExempt && (
            <ErrorAlert>
              <span>{errors.isStateExempt as string}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is this an NRA withholding exempt product?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isNraExempt")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isNraExempt}
            onSelect={handleRegulatoryAdherenceData("isNraExempt")}
          />
          {errors.isNraExempt && touched.isNraExempt && (
            <ErrorAlert>
              <span>{errors.isNraExempt as string}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default CheckingRegulatoryAdherenceLimitation;
