"use client";

import { Box, Heading, useToast } from "@/components/ChakraUiManager";
import {
  AccordionComponent,
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CardContainer,
  ErrorAlert,
  InputText,
  StaticText,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  FinOrgCorrespondingNetworkDestValueProps,
  finOrgSpecificParamCorrespondingDestValidationSchema as getValidationSchema,
} from "./FinOrgCorrespondingDestinationNetworkValidation";
import {
  FastDefaultDropdownProps,
  PostSchema,
} from "@/components/product-management/model/types";
import { FIN_ORG_SUB_STEPS, IC_ENTITY } from "@/utils/constants";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import {
  CorrespondingNetworkDetailsProps,
  FinancialInstitutionData,
} from "@/components/institutional-configuration/model";
import { useSearchParams } from "next/navigation";
import {
  constructDescNetworkFormInitialValues,
  getDescOriginNetworkKey,
} from "../../fin-org-specific-param-util-service";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import Style from "./FinOrgCorrespondingDestinationNetwork.module.scss";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useQueryParams, shouldUpdateWorkflowStatus } from "@/utils/common";
import { deepCopy } from "@finxact/finxact-shared-ui";
type FinOrgCorrDestinationNetworkProps = {
  isDestinationNetwork: boolean;
  data: {
    addrTypeOptions: FastDefaultDropdownProps[];
    otherIdTypeOptions: FastDefaultDropdownProps[];
    countryOptions: FastDefaultDropdownProps[];
    regioCodeOptions: FastDefaultDropdownProps[];
    addIdOptions: FastDefaultDropdownProps[];
    formData: FinancialInstitutionData;
    partyOrgFininstOptionsData: PostSchema;
  };
};

export default function FinOrgCorrespondingDestinationNetwork(
  props: FinOrgCorrDestinationNetworkProps
) {
  const {
    isDestinationNetwork,
    data: {
      addrTypeOptions,
      otherIdTypeOptions,
      countryOptions,
      regioCodeOptions,
      addIdOptions,
      formData,
      partyOrgFininstOptionsData,
    },
  } = props;
  const queryParams = useSearchParams();
  const toast = useToast();
  const toastId = "finOrg-corresponding-destination-network";
  const updateQueryParams = useQueryParams();

  function getCorrsIndexFromParam() {
    return (
      queryParams?.get(QUERY_PARAM_KEY["FIN_ORG_CORRS_INDEX"])?.toString() ||
      "0"
    );
  }

  function getNextRoutePath() {
    return isDestinationNetwork
      ? `${ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_ORIGIN_NETWORK"]}`
      : `${ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_SUMMARY"]}`;
  }

  function getBackRoutePath() {
    return isDestinationNetwork
      ? `${ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_IDENTIFICATION"]}`
      : `${ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_DESTINATION_NETWORK"]}`;
  }

  const moveToNextStep = () => {
    const url = `${getNextRoutePath()}?${updateQueryParams()}`;
    if (!canCheckFormStatus) {
      navigateTo(url);
    }
    return;
  };

  const onSubmit = async (
    values: FinOrgCorrespondingNetworkDestValueProps,
    actions: any
  ) => {
    const currentStage = isDestinationNetwork
      ? FIN_ORG_SUB_STEPS.fin_org_destination_network_origin
      : FIN_ORG_SUB_STEPS.fin_org_origin_network_origin;
    let corrsPayload: CorrespondingNetworkDetailsProps[] = [];
    const corrsIndex = getCorrsIndexFromParam();
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }

    if (
      corrsIndex &&
      formData?.corrs?.length &&
      formData.corrs[corrsIndex as any]
    ) {
      corrsPayload = constructNeworkReqPayload(corrsIndex, values);
      const response = await updateFinOrg({
        formData: {
          _vn: formData?._vn,
          _Id: formData?._Id,
          name: formData?.name,
          corrs: corrsPayload,
        },
        toastOptions: {
          toast,
          toastId,
          successMessage:
            "Financial Organization Corresponding Network updated successfully.",
        },
        stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
          ? currentStage
          : "",
      });
      if (response) {
        moveToNextStep();
      }
    }
  };

  function constructNeworkReqPayload(
    corrsIndex: any,
    values: FinOrgCorrespondingNetworkDestValueProps
  ): CorrespondingNetworkDetailsProps[] {
    if (values.partyAddr?.addrIdRadio) {
      delete values.partyAddr.addrIdRadio;
    }
    const networkKey = getDescOriginNetworkKey(isDestinationNetwork);
    const currCorrsNetworkDetails: FinOrgCorrespondingNetworkDestValueProps | null =
      formData?.corrs?.[corrsIndex]?.[networkKey]
        ? deepCopy(formData?.corrs?.[corrsIndex]?.[networkKey]!)
        : null;
    if (values?.partyAddr?.geoLoc) {
      values.partyAddr.geoLoc.alt = values.partyAddr.geoLoc.alt
        ? Number(values.partyAddr.geoLoc.alt)
        : 0;
      values.partyAddr.geoLoc.lon = values.partyAddr.geoLoc.lon
        ? Number(values.partyAddr.geoLoc.lon)
        : 0;
      values.partyAddr.geoLoc.lat = values.partyAddr.geoLoc.lat
        ? Number(values.partyAddr.geoLoc.lat)
        : 0;
    }
    if (formData.corrs[corrsIndex][networkKey] && currCorrsNetworkDetails) {
      formData.corrs[corrsIndex][networkKey] = {
        ...currCorrsNetworkDetails,
        ...values,
      };
      return [...formData.corrs];
    } else {
      formData.corrs[corrsIndex][networkKey] = { ...values };
      return [...formData.corrs];
    }
  }

  const handleOnChange = (key: string) => (value: any) => {
    let selectedObject: any,
      addrId: string = "";
    switch (key) {
      case "partyAddr.cntry":
        selectedObject =
          countryOptions?.find(
            (obj: { label: string; value: string }) => obj.label === value
          ) || [];
        break;
      case "otherIdType":
        selectedObject = otherIdTypeOptions?.find(
          (obj: { label: string; value: number }) => obj.label === value
        );
        break;
      case "partyAddr.addrType":
        selectedObject =
          addrTypeOptions?.find(
            (obj: { label: string; value: number }) => obj.label === value
          ) || [];
        break;
      case "partyAddr.region":
      case "partyAddr.region2":
      case "partyAddr.region3":
      case "partyAddr.region4":
        selectedObject =
          regioCodeOptions?.find(
            (obj: { label: string; value: number }) => obj.label === value
          ) || [];
        break;
    }
    if (addrId) {
      setFieldValue("partyAddr._Id", addrId);
    }
    if (selectedObject?.value) {
      setFieldValue(key, selectedObject?.value || "");
    } else {
      setFieldValue(key, value);
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(partyOrgFininstOptionsData);

      const validations = yupSchema.fields.corrs.innerType.fields.origin;

      setValidationSchema(validations);
    })();
  }, []);

  let formInitialValues = constructDescNetworkFormInitialValues(
    formData,
    isDestinationNetwork,
    getCorrsIndexFromParam(),
    addIdOptions
  );

  const formikConfig = useFormik({
    onSubmit,
    validationSchema,
    initialValues: formInitialValues,
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formikConfig;

  const { partyAddr: partyAddrErrors } = errors;
  const { partyAddr: partyAddrTouched } = touched;

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      navigateTo(`${getBackRoutePath()}?${updateQueryParams()}`);
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <Heading className={Style["fin-org-corspndng-dest-net-2-3"]} as="h2">
        {`Corresponding Network Details - ${isDestinationNetwork ? "2" : "3"} of 3`}
      </Heading>
      <CardContainer>
        <AccordionComponent
          accordionTitle={`${
            isDestinationNetwork ? "Destination" : "Origin"
          } network origination`}
          isExpand={0}
          maxTitleSize
        >
          <Box>
            <Heading
              className={Style["fin-org-corspndng-dest-frst-card-dynamc-titl"]}
              as="h4"
            >
              {`${
                isDestinationNetwork
                  ? "Destination network origination identification"
                  : "General institution information"
              }`}
            </Heading>
          </Box>
          <Box className={Style["sub-section-read-only-title"]}>
            <StaticText
              textValue={
                isDestinationNetwork
                  ? "Capture essential identification information for the destination network"
                  : "Capture essential identification information for the origin network"
              }
              className={Style["static-text-gray"]}
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the correspondent institution's name?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("name")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.name}
              onChange={handleOnChange("name")}
              onBlur={handleBlur}
              label="What is the institution name?"
              name="institutionName"
            />

            {errors.name && touched.name && (
              <ErrorAlert>
                <span>{errors.name}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the DBA name?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("dbaName")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.dbaName}
              onChange={handleOnChange("dbaName")}
              onBlur={handleBlur}
              label="What is the DBA name?"
              name="dbaName"
            />

            {errors.dbaName && touched.dbaName && (
              <ErrorAlert>
                <span>{errors.dbaName}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the correspondent institution’s company entry description?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("desc")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.companyEntryDesc}
              onChange={handleOnChange("companyEntryDesc")}
              onBlur={handleBlur}
              label="What is the party organization description?"
              name="companyEntryDesc"
            />

            {errors.companyEntryDesc && touched.companyEntryDesc && (
              <ErrorAlert>
                <span>{errors.companyEntryDesc}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the ID number?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("idNbr")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.idNbr}
              onChange={handleOnChange("idNbr")}
              onBlur={handleBlur}
              label="What is the ID number?"
              name="idNbr"
            />

            {errors.idNbr && touched.idNbr && (
              <ErrorAlert>
                <span>{errors.idNbr}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container app-form-field-sub-heading">
            <AppFormLabel labelName={"Other identification details"} />
            <StaticText
              textValue={
                "Specify additional identification and their type classification"
              }
              className="static-text-gray"
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the other ID type?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("otherIdType")
                  ?.tooltip_text_main
              }
              labelFor="other-id-type_input"
            />
            <SelectDropdown
              value={
                otherIdTypeOptions?.find(
                  (obj: { label: string; value: string }) =>
                    obj.value === values.otherIdType
                )?.label || ""
              }
              onChange={handleOnChange("otherIdType")}
              dropdownList={
                otherIdTypeOptions?.map((d: { label: string }) => d.label) || []
              }
              onBlur={() => setFieldTouched("otherIdType")}
              placeholder="What is the other ID type?"
              id="other-id-type"
            />

            {errors.otherIdType && touched.otherIdType && (
              <ErrorAlert>
                <span>{errors.otherIdType}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the other ID?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("otherId")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.otherId}
              onChange={handleOnChange("otherId")}
              onBlur={handleBlur}
              label="What is the other ID?"
              name="otherId"
            />

            {errors.otherId && touched.otherId && (
              <ErrorAlert>
                <span>{errors.otherId}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container app-form-field-sub-heading">
            <AppFormLabel labelName={"Address Details"} />
            <StaticText
              textValue={
                "Provide location details for the destination network "
              }
              className="static-text-gray"
            />
          </Box>

          {/* Address Section */}
          <Box className={`app-form-field-addr-section `}>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the address type?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("addrType")
                    ?.tooltip_text_main
                }
                labelFor="address-type_input"
              />
              <SelectDropdown
                value={
                  addrTypeOptions?.find(
                    (obj: { label: string; value: string }) =>
                      obj.value === values.partyAddr?.addrType
                  )?.label || ""
                }
                onChange={handleOnChange("partyAddr.addrType")}
                dropdownList={
                  addrTypeOptions?.map((d: { label: string }) => d.label) || []
                }
                onBlur={() => setFieldTouched("partyAddr.addrType")}
                placeholder="Work Office"
                id="address-type"
              />

              {partyAddrErrors?.addrType && partyAddrTouched?.addrType && (
                <ErrorAlert>
                  <span>{partyAddrErrors.addrType}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Address street"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("street")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.street}
                onChange={handleOnChange("partyAddr.street")}
                onBlur={handleBlur}
                label="Address street"
                name="partyStreet"
              />

              {partyAddrErrors?.street && partyAddrTouched?.street && (
                <ErrorAlert>
                  <span>{partyAddrErrors.street}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Address street line 2"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("street2")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.street2}
                onChange={handleOnChange("partyAddr.street2")}
                onBlur={handleBlur}
                label="Address street line 2"
                name="partyStreet2"
              />

              {partyAddrErrors?.street2 && partyAddrTouched?.street2 && (
                <ErrorAlert>
                  <span>{partyAddrErrors.street2}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Address street line 3"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("street3")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.street3}
                onChange={handleOnChange("partyAddr.street3")}
                onBlur={handleBlur}
                label="Address street line 3"
                name="partyStreet3"
              />

              {partyAddrErrors?.street3 && partyAddrTouched?.street3 && (
                <ErrorAlert>
                  <span>{partyAddrErrors.street3}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="Address street line 4"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("street4")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.street4}
                onChange={handleOnChange("partyAddr.street4")}
                onBlur={handleBlur}
                label="Address street line 4"
                name="partyStreet4"
              />

              {partyAddrErrors?.street4 && partyAddrTouched?.street4 && (
                <ErrorAlert>
                  <span>{partyAddrErrors.street4}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the room identifier?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("premiseRoom")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.premiseRoom}
                onChange={handleOnChange("partyAddr.premiseRoom")}
                onBlur={handleBlur}
                label="Room identifier"
                name="premiseRoom"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the department? "
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("dept")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.dept}
                onChange={handleOnChange("partyAddr.dept")}
                onBlur={handleBlur}
                label="Department"
                name="dept"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the sub department?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("subDept")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.subDept}
                onChange={handleOnChange("partyAddr.subDept")}
                onBlur={handleBlur}
                label="Sub-Department"
                name="subDept"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the floor number?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("premiseFloor")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.premiseFloor}
                onChange={handleOnChange("partyAddr.premiseFloor")}
                onBlur={handleBlur}
                label="Floor #"
                name="premiseFloor"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the building number?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("premiseNbr")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.premiseNbr}
                onChange={handleOnChange("partyAddr.premiseNbr")}
                onBlur={handleBlur}
                label="Building #"
                name="premiseNbr"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the premise?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("premise")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.premise}
                onChange={handleOnChange("partyAddr.premise")}
                onBlur={handleBlur}
                label="Premise"
                name="premise"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the district?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("district")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.district}
                onChange={handleOnChange("partyAddr.district")}
                onBlur={handleBlur}
                label="District"
                name="district"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the city?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("city")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.city}
                onChange={handleOnChange("partyAddr.city")}
                onBlur={handleBlur}
                label="City"
                name="city"
              />

              {partyAddrErrors?.city && partyAddrTouched?.city && (
                <ErrorAlert>
                  <span>{partyAddrErrors.city}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the city location?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("cityLctn")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.cityLctn}
                onChange={handleOnChange("partyAddr.cityLctn")}
                onBlur={handleBlur}
                label="City location"
                name="cityLctn"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the region?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("region")
                    ?.tooltip_text_main
                }
                labelFor="region_input"
              />
              <SelectDropdown
                value={
                  regioCodeOptions?.find(
                    (obj: { label: string; value: string }) =>
                      obj.value === values.partyAddr?.region
                  )?.label || ""
                }
                onChange={handleOnChange("partyAddr.region")}
                dropdownList={
                  regioCodeOptions?.map((d: { label: string }) => d.label) || []
                }
                onBlur={() => setFieldTouched("partyAddr.region")}
                placeholder="Work Office"
                id="region"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the region 2?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("region2")
                    ?.tooltip_text_main
                }
                labelFor="region-2_input"
              />
              <SelectDropdown
                value={
                  regioCodeOptions?.find(
                    (obj: { label: string; value: string }) =>
                      obj.value === values.partyAddr?.region2
                  )?.label || ""
                }
                onChange={handleOnChange("partyAddr.region2")}
                dropdownList={
                  regioCodeOptions?.map((d: { label: string }) => d.label) || []
                }
                onBlur={() => setFieldTouched("partyAddr.region2")}
                placeholder="Work Office"
                id="region-2"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the region 3?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("region3")
                    ?.tooltip_text_main
                }
                labelFor="region-3_input"
              />
              <SelectDropdown
                value={
                  regioCodeOptions?.find(
                    (obj: { label: string; value: string }) =>
                      obj.value === values?.partyAddr?.region3
                  )?.label || ""
                }
                onChange={handleOnChange("partyAddr.region3")}
                dropdownList={
                  regioCodeOptions?.map((d: { label: string }) => d.label) || []
                }
                onBlur={() => setFieldTouched("partyAddr.region3")}
                placeholder="Work Office"
                id="region-3"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the region 4?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("region4")
                    ?.tooltip_text_main
                }
                labelFor="region-4_input"
              />
              <SelectDropdown
                value={
                  regioCodeOptions?.find(
                    (obj: { label: string; value: string }) =>
                      obj.value === values.partyAddr?.region4
                  )?.label || ""
                }
                onChange={handleOnChange("partyAddr.region4")}
                dropdownList={
                  regioCodeOptions?.map((d: { label: string }) => d.label) || []
                }
                onBlur={() => setFieldTouched("partyAddr.region4")}
                placeholder="Work Office"
                id="region-4"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the country?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("cntry")
                    ?.tooltip_text_main
                }
                labelFor="country_input"
              />
              <SelectDropdown
                value={
                  countryOptions?.find(
                    (obj: { label: string; value: string }) =>
                      obj.value === values.partyAddr?.cntry
                  )?.label || ""
                }
                onChange={handleOnChange("partyAddr.cntry")}
                dropdownList={
                  countryOptions?.map((d: { label: string }) => d.label) || []
                }
                onBlur={() => setFieldTouched("partyAddr.cntry")}
                placeholder="Work Office"
                id="country"
              />

              {partyAddrErrors?.cntry && partyAddrTouched?.cntry && (
                <ErrorAlert>
                  <span>{partyAddrErrors.cntry}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the postal code?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("postCode")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.partyAddr?.postCode}
                onChange={handleOnChange("partyAddr.postCode")}
                onBlur={handleBlur}
                label="Postal Code"
                name="postCode"
              />

              {partyAddrErrors?.postCode && partyAddrTouched?.postCode && (
                <ErrorAlert>
                  <span>{partyAddrErrors.postCode}</span>
                </ErrorAlert>
              )}
            </Box>
          </Box>
        </AccordionComponent>
      </CardContainer>
      {/* Geo Location */}
      <Box>
        <CardContainer>
          <AccordionComponent
            accordionTitle={`Address Geolocation`}
            isExpand={0}
            medTitleSize
          >
            <Box className="app-form-field-container" mt={-5}>
              <StaticText
                textValue={
                  "Provide additional geospatial data for precise mapping of the provided address"
                }
                className="static-text-gray"
              />
            </Box>
            <Box className="app-form-field-container">
              <AppNumberInput
                labelName="What is the latitude?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("lat")
                    ?.tooltip_text_main
                }
                value={values.partyAddr?.geoLoc?.lat || ""}
                onChange={handleOnChange("partyAddr.geoLoc.lat")}
                onBlur={handleBlur}
                label="Latitude"
              />
              {partyAddrErrors?.geoLoc?.lat &&
                partyAddrTouched?.geoLoc?.lat && (
                  <ErrorAlert>
                    <span>{partyAddrErrors.geoLoc?.lat}</span>
                  </ErrorAlert>
                )}
            </Box>
            <Box className="app-form-field-container">
              <AppNumberInput
                labelName="What is the longitude?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("lon")
                    ?.tooltip_text_main
                }
                value={values.partyAddr?.geoLoc?.lon || ""}
                onChange={handleOnChange("partyAddr.geoLoc.lon")}
                onBlur={handleBlur}
                label="Longitude"
              />

              {partyAddrErrors?.geoLoc?.lon &&
                partyAddrTouched?.geoLoc?.lon && (
                  <ErrorAlert>
                    <span>{partyAddrErrors.geoLoc?.lon}</span>
                  </ErrorAlert>
                )}
            </Box>
            <Box className="app-form-field-container">
              <AppNumberInput
                labelName="What is the altitude?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("alt")
                    ?.tooltip_text_main
                }
                value={values.partyAddr?.geoLoc?.alt || ""}
                onChange={handleOnChange("partyAddr.geoLoc.alt")}
                onBlur={handleBlur}
                label="Altitude"
              />

              {partyAddrErrors?.geoLoc?.alt &&
                partyAddrTouched?.geoLoc?.alt && (
                  <ErrorAlert>
                    <span>{partyAddrErrors.geoLoc?.alt}</span>
                  </ErrorAlert>
                )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the Google Place ID?"
                isRequired={false}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("geoplace")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values?.partyAddr?.geoLoc?.geoplace as string}
                onChange={handleOnChange("partyAddr.geoLoc.geoplace")}
                onBlur={handleBlur}
                label="Google Place ID"
                name="geoplace"
              />

              {partyAddrErrors?.geoLoc?.geoplace &&
                partyAddrTouched?.geoLoc?.geoplace && (
                  <ErrorAlert>
                    <span>{partyAddrErrors.geoLoc?.geoplace}</span>
                  </ErrorAlert>
                )}
            </Box>
          </AccordionComponent>
        </CardContainer>
      </Box>
      {/* External Reference */}
      <CardContainer>
        <AccordionComponent
          accordionTitle={"External References"}
          isExpand={0}
          medTitleSize
        >
          <Box className="app-form-field-container" mt={-5}>
            <StaticText
              textValue={
                "Identify the bank ABA number and BIC identifier number for this network "
              }
              className="static-text-gray"
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the bank ABA number?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("finInstAba")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.finInstAba}
              onChange={handleOnChange("finInstAba")}
              onBlur={handleBlur}
              label="What is the bank ABA number?"
              name="finInstAba"
            />

            {errors?.finInstAba && touched?.finInstAba && (
              <ErrorAlert>
                <span>{errors.finInstAba}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the BIC identifier number?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("finInstBic")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.finInstBic}
              onChange={handleOnChange("finInstBic")}
              onBlur={handleBlur}
              label="What is the BIC identifier number?"
              name="finInstBic"
            />

            {errors?.finInstBic && touched?.finInstBic && (
              <ErrorAlert>
                <span>{errors.finInstBic}</span>
              </ErrorAlert>
            )}
          </Box>
        </AccordionComponent>
      </CardContainer>
    </form>
  );
}
