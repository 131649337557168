import * as Yup from "yup";
import { generateYupSchema } from "@/utils/common";
import { PostSchema } from "@/components/product-management/model/types";

//Defining Interface For Sets
interface SetsProps {
  balAcctGroup: number;
  balAcctNbr: string;
  glSetCode: string;
  desc: string;
}
interface SetsArrayProps {
  idx: number | string;
  additionalProp: string;
  acctGroup: number;
  acctNbr: string;
  expAcctNbr: string;
  incAcctNbr: string;
}
interface SetsValuesProps {
  balAcctGroup: number;
  balAcctNbr: string;
  glSetCode: string;
  desc: string;
  subBalAccts: SetsArrayProps[];
}

// Defining Intitial Value For Sets
const sets: SetsProps = {
  balAcctGroup: 2,
  balAcctNbr: "",
  glSetCode: "",
  desc: "",
};

const setsArray: SetsArrayProps = {
  idx: "",
  additionalProp: "",
  acctGroup: 2,
  acctNbr: "",
  expAcctNbr: "",
  incAcctNbr: "",
};

// YUP LIBRARY :Defining Schema For validation of Sets
const setsSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "balAcctGroup",
    "balAcctNbr",
    "glSetCode",
    "desc",
    "subBalAccts",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  shapeConfig.subBalAccts = Yup.array();

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { SetsArrayProps, SetsValuesProps };
// Exporting Validation Schema and Intial value
export { sets, setsArray, setsSchema as getValidationSchema };
