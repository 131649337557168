"use client";
import { AppFormLabel, CardContainer, InputText } from "@/components/common";

import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Box, Heading, useToast } from "@chakra-ui/react";

import {
  FinancialOrganizationIdentificationValueProps,
  getValidationSchema,
} from "./FinancialOrganizationIdentificationValidation";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

import { ROUTE_PATH } from "@/route-config/route-path";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import { FIN_ORG_SUB_STEPS, IC_ENTITY } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { useQueryParams, shouldUpdateWorkflowStatus } from "@/utils/common";

type IdentificationDetailsProps = {
  data: {
    formData: FinancialInstitutionData | null;
    partyOrgFininstOptionsData: PostSchema;
  };
};
const FinancialOrganizationIdentification = (
  props: IdentificationDetailsProps
) => {
  const toast = useToast();
  const toastId = "fosp-finOrg-identification";
  const updateQueryParams = useQueryParams();

  const { partyOrgFininstOptionsData } = props.data;

  const {
    data: { formData },
  } = props;

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_OTHER_IDENTIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  const onSubmit = async (
    values: FinancialOrganizationIdentificationValueProps,
    actions: any
  ) => {
    const currentStage = FIN_ORG_SUB_STEPS.fin_org_identification;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: formData?._vn,
        _Id: formData?._Id,
        name: formData?.name,
        finInstAba: values.finInstAba,
        finInstBic: values.finInstBic,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };

  const handleOnChange = (key: string) => (value: string) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(partyOrgFininstOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [partyOrgFininstOptionsData]);

  // using useFormik hook from Formik Library
  const { values, handleSubmit, handleBlur, setFieldValue, dirty } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      finInstAba: formData?.finInstAba || "",
      finInstBic: formData?.finInstBic || "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_LOCATION_AND_CONTACT"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <Heading className="font-size-24px" as="h3">
            Financial organization identification
          </Heading>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the Bank ABA number?"
            isRequired={false}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("finInstAba")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.finInstAba}
            onChange={handleOnChange("finInstAba")}
            onBlur={handleBlur}
            label="Bank ABA number"
            name="finInstAba"
          />
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the BIC identifier number?"
            isRequired={false}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("finInstBic")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.finInstBic}
            onChange={handleOnChange("finInstBic")}
            onBlur={handleBlur}
            label="BIC identifier number?"
            name="finInstBic"
          />
        </Box>
      </CardContainer>
    </form>
  );
};

export default FinancialOrganizationIdentification;
