"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  CoupleRadioWithDropdown,
  ErrorAlert,
  InputText,
} from "@/components/common";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  BaseSavingsRateProps,
  getValidationSchema,
} from "./BaseSavingsRateValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import { FinancialInstitutionData } from "../../model";
import { updateBankParameters } from "../../ic-config-api-service";
import {
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";
import { IC_ENTITY } from "@/utils/constants";
import { addNewComponent } from "@/components/product-management/product-configuration/product-config-client-service";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { matrixTypeUiSchema } from "@finxact/finxact-shared-ui";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { shouldUpdateWorkflowStatus } from "@/utils/common";
import { RjsfData } from "@/utils/types";

type Props = {
  data: {
    indexNameOptions: string[];
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    formData: FinancialInstitutionData | null;
    bankParamOptionsData: PostSchema;
  };
};

const BaseSavingsRate = (props: Props) => {
  const {
    indexNameOptions,
    formData,
    addNewSchema,
    addNewOptionEndpoint,
    bankParamOptionsData,
  } = props.data;
  const toast = useToast();

  const onSubmit = async (values: BaseSavingsRateProps, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    if (formData) {
      const res = await updateBankParameters({
        formData: {
          _vn: formData._vn,
          _Id: formData._Id,
          // PATCH Feilds
          baseSavRate: {
            indexName: values.indexName,
            minRate: parseFloat(values.minRate),
            maxRate: parseFloat(values.maxRate),
            offsetMatrix: values.offsetMatrix,
            round: values.round,
          },
        },
        toastOptions: {
          toast: toast,
          toastId: "base-sav-rate",
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
          ? currentStage
          : "",
      });
      if (res) {
        navigateToNextPage?.();
      }
    }
  };

  // Schema to pass into RJSF (Add New flow)
  const schema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("offsetMatrix", response.matrixName);
  };

  const indexSchema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const addIndexNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("indexName", response.matrixName);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);
      const nestedValidationSchema = yupSchema.fields.baseSavRate;
      setValidationSchema(nestedValidationSchema);
    })();
  }, [bankParamOptionsData]);

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      indexName: formData?.baseSavRate?.indexName ?? "",
      maxRate: formData?.baseSavRate?.maxRate?.toString() ?? "",
      minRate: formData?.baseSavRate?.minRate?.toString() ?? "",
      offsetMatrix: formData?.baseSavRate?.offsetMatrix ?? "",
      round: formData?.baseSavRate?.round ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  // component handler
  const baseSavingsRateHandler =
    (key: string) => (value: string | string[]) => {
      setFieldValue(key, value);
    };
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName={"What is the index name?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("indexName")
                ?.tooltip_text_main
            }
          />
          <CoupleRadioWithDropdown
            primaryOptionValue={values.indexName}
            primaryRadioName={"Select an existing index"}
            secondaryRadioName={"Add a new index"}
            placeHolderName="Select an existing index"
            dropdownList={indexNameOptions}
            onBlur={handleBlur}
            handlePrimaryOptionSelect={baseSavingsRateHandler("indexName")}
            schema={indexSchema}
            uiSchema={matrixTypeUiSchema}
            onAddNewSubmit={addIndexNewOption}
          />
          {errors.indexName && touched.indexName && (
            <ErrorAlert>
              <span>{errors.indexName}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            value={values.maxRate}
            onChange={baseSavingsRateHandler("maxRate")}
            labelName={"What is the maximum rate?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("maxRate")
                ?.tooltip_text_main
            }
            placeholder="%"
            label="number"
            valueSuffix="%"
          />
          {errors.maxRate && touched.maxRate && (
            <ErrorAlert>
              <span>{errors.maxRate}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <AppNumberInput
            value={values.minRate}
            onChange={baseSavingsRateHandler("minRate")}
            labelName={"What is the minimum rate?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("minRate")
                ?.tooltip_text_main
            }
            placeholder="%"
            valueSuffix="%"
          />
          {errors.minRate && touched.minRate && (
            <ErrorAlert>
              <span>{errors.minRate}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName={"What is the rate offset value?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("offsetMatrix")
                ?.tooltip_text_main
            }
          />
          <CoupleRadioWithDropdown
            primaryOptionValue={values.offsetMatrix}
            primaryRadioName={"Use an existing rate offset value"}
            secondaryRadioName={"Add a new rate offset value"}
            handlePrimaryOptionSelect={baseSavingsRateHandler("offsetMatrix")}
            placeHolderName="Select offset"
            dropdownList={indexNameOptions}
            onBlur={handleBlur}
            schema={schema}
            onAddNewSubmit={addNewOption}
            uiSchema={matrixTypeUiSchema}
          />
          {errors.offsetMatrix && touched.offsetMatrix && (
            <ErrorAlert>
              <span>{errors.offsetMatrix}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the rounding expression?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("round")?.tooltip_text_main
            }
          />
          <InputText
            value={values.round}
            onChange={baseSavingsRateHandler("round")}
            label="round"
          />
          {errors.round && touched.round && (
            <ErrorAlert>
              <span>{errors.round}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default BaseSavingsRate;
