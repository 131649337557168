import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

interface FinancialCalenderFoundationDetailsProps {
  calendarName: string;
  tmZoneCode: string;
  refCalendar: Record<string, string>[];
}

const financialCalenderFoundationDetails: FinancialCalenderFoundationDetailsProps =
  {
    calendarName: "",
    tmZoneCode: "",
    refCalendar: [],
  };

const financialCalenderFoundationDetailSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["calendarName", "tmZoneCode", "refCalendar"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { FinancialCalenderFoundationDetailsProps };
// Exporting Validation Schema and Intial value
export {
  financialCalenderFoundationDetails,
  financialCalenderFoundationDetailSchema as getValidationSchema,
};
