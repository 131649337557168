import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { GL_ENTITY, IC_ENTITY, NEW_IC_ENTITY_ID } from "@/utils/constants";

interface flowTitleMappingInterface {
  [key: string]: string;
}

interface flowFinalDescriptionMappingInterface {
  [key: string]: {
    next: string;
    current: string;
  };
}

export const IC_INTERSTITAL_SCREEN_STEPS = [
  {
    id: 1,
    title: "Establish Account Group",
    description:
      "Segment accounts for streamlined policy implementation and management",
    back: "",
    continue: "",
    addNewEntity: "Add new account group",
    addNewHref: `${ROUTE_PATH.IC_ACCOUNT_GROUP_FOUNDATIONAL_DETAILS}?entityId=${NEW_IC_ENTITY_ID}&entityType=${IC_ENTITY.account_group}&initialFlow=true`,
  },
  {
    id: 2,
    title: "Configure the asset catalog",
    description:
      "Define asset classes and identifiers to establish transactional currencies and maintain regulatory compliance",
    back: `${ROUTE_PATH["IC_ACCOUNT_GROUP_FOUNDATIONAL_DETAILS"]}`,
    continue: `${ROUTE_PATH["IC_ASSET_CATALOG_FOUNDATIONAL_DETAILS"]}?entityId=${NEW_IC_ENTITY_ID}`,
    addNewEntity: "Add new asset catalog",
    addNewHref: `${ROUTE_PATH.IC_ASSET_CATALOG_FOUNDATIONAL_DETAILS}?entityId=${NEW_IC_ENTITY_ID}&entityType=${IC_ENTITY.asset_catalog}&initialFlow=true`,
  },
  {
    id: 3,
    title: "Establish account types",
    description:
      "Customize account types to enhance product offerings and ensure compliance",
    back: `${ROUTE_PATH["IC_ASSET_CATALOG_FOUNDATIONAL_DETAILS"]}`,
    continue: `${ROUTE_PATH["IC_ACCOUNT_TYPES_FOUNDATIONAL_DETAILS"]}?entityId=${NEW_IC_ENTITY_ID}`,
    addNewEntity: "Add new account types",
    addNewHref: `${ROUTE_PATH.IC_ACCOUNT_TYPES_FOUNDATIONAL_DETAILS}?entityId=${NEW_IC_ENTITY_ID}&entityType=${IC_ENTITY.account_type}&initialFlow=true`,
  },
  {
    id: 4,
    title: "Set up financial calendars",
    description:
      "Align events with operational and fiscal cycles for compliance and efficiency",
    back: ROUTE_PATH.IC_ACCOUNT_TYPES_FOUNDATIONAL_DETAILS,
    continue: `${ROUTE_PATH.IC_FINANCIAL_CALENDER_FOUNDATIONAL_DETAILS}?entityId=${NEW_IC_ENTITY_ID}`,
    addNewEntity: "Add new financial calendars",
    addNewHref: `${ROUTE_PATH.IC_FINANCIAL_CALENDER_FOUNDATIONAL_DETAILS}?entityId=${NEW_IC_ENTITY_ID}&entityType=${IC_ENTITY.financial_calendar}&initialFlow=true`,
  },
  {
    id: 5,
    title: "Configure your financial institution",
    description:
      "Define key attributes for your institution for smooth operations and regulatory compliance",
    back: ROUTE_PATH.IC_FINANCIAL_CALENDER_FOUNDATIONAL_DETAILS,
    continue: `${ROUTE_PATH.GENERAL_PARTY_PARAMETER_IDENTIFICATION}?entityId=${NEW_IC_ENTITY_ID}`,
    addNewEntity: "Add new financial institution",
    addNewHref: `${ROUTE_PATH.GENERAL_PARTY_PARAMETER_IDENTIFICATION}?entityId=${NEW_IC_ENTITY_ID}&entityType=${IC_ENTITY.financial_organization}&initialFlow=true`,
  },

  {
    id: 6,
    title: "Configure financial institution (bank) parameters",
    description:
      "Configure operational parameters to meet institutional needs and goals",
    back: ROUTE_PATH.GENERAL_PARTY_PARAMETER_IDENTIFICATION,
    continue: `${ROUTE_PATH.IC_BANK_PARAMS_FOUNDATION_DETAILS}?entityId=${NEW_IC_ENTITY_ID}`,
    addNewEntity: "Add new financial institution (bank) parameters",
    addNewHref: `${ROUTE_PATH.IC_BANK_PARAMS_FOUNDATION_DETAILS}?entityId=${NEW_IC_ENTITY_ID}&entityType=${IC_ENTITY.bank_parameters}&initialFlow=true`,
  },
];

export const GL_INTERSTITAL_SCREEN_STEPS = [
  {
    id: 1,
    title: "Accounting segments",
    description:
      "Define the hierarchical structure of your general ledger and enhance reporting",
    back: "",
    continue: "",
    addNewEntity: "Add new accounting segments",
    addNewHref: `${ROUTE_PATH.GL_ACCOUNTING_SEGMENTS}?entityId=${NEW_IC_ENTITY_ID}&entityType=${GL_ENTITY.accounting_segments}&initialFlow=true`,
  },
  {
    id: 2,
    title: "Accounts",
    description:
      "Establish the chart of accounts that tracks money movement according to your institutional accounting process",
    back: `${ROUTE_PATH["GL_ACCOUNTING_SEGMENTS"]}`,
    continue: `${ROUTE_PATH["GL_ACCOUNTS"]}?entityId=${NEW_IC_ENTITY_ID}`,
    addNewEntity: "Add new accounts",
    addNewHref: `${ROUTE_PATH.GL_ACCOUNTS}?entityId=${NEW_IC_ENTITY_ID}&entityType=${GL_ENTITY.accounts}&initialFlow=true`,
  },
  {
    id: 3,
    title: "System accounts",
    description: "Establish accounts used for system processing",
    back: `${ROUTE_PATH["GL_ACCOUNTS"]}`,
    continue: `${ROUTE_PATH["GL_SYSTEM_ACCOUNTS"]}?entityId=${NEW_IC_ENTITY_ID}`,
    addNewEntity: "Add new system accounts",
    addNewHref: `${ROUTE_PATH.GL_SYSTEM_ACCOUNTS}?entityId=${NEW_IC_ENTITY_ID}&entityType=${GL_ENTITY.system_accounts}&initialFlow=true`,
  },
  {
    id: 4,
    title: "Sets",
    description:
      "Manage the transactional and balance-related activities of a particular position",
    back: ROUTE_PATH["GL_SYSTEM_ACCOUNTS"],
    continue: `${ROUTE_PATH["GL_SETS"]}?entityId=${NEW_IC_ENTITY_ID}`,
    addNewEntity: "Add new sets",
    addNewHref: `${ROUTE_PATH.GL_SETS}?entityId=${NEW_IC_ENTITY_ID}&entityType=${GL_ENTITY.sets}&initialFlow=true`,
  },
];

export const TC_INTERSTITAL_SCREEN_STEPS = [
  {
    id: 1,
    title: "Foundational details",
    description:
      "Establish key identifiers, included networks, and transaction limits",
    back: "",
    continue: "",
  },
  {
    id: 2,
    title: "Transaction tag setup",
    description:
      "Define an easily identifiable tag for tracking and organization purposes",
    back: `${ROUTE_PATH["TC_FOUNDATIONAL_DETAILS"]}`,
    continue: `${ROUTE_PATH["TC_TAG_SETUP"]}`,
  },
  {
    id: 3,
    title: "Transaction entry setup",
    description:
      "Establish how transactions are documented in the system of record",
    back: `${ROUTE_PATH["TC_TAG_SETUP"]}`,
    continue: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_FOUNDATIONAL_DETAILS"]}`,
  },
];

export const FLOW_TITLE_MAPPING: flowTitleMappingInterface = {
  "institutional-configuration": "Institutional Configuration",
  "general-ledger": "General ledger",
  "transaction-code": "Transaction Code Setup",
  "customer-relationship-types": "Customer Relationship Types",
};

export const FLOW_FINAL_DESCRIPTION_MAPPING: flowFinalDescriptionMappingInterface =
  {
    "institutional-configuration": {
      next: "General Ledger Setup",
      current: "Institutional Configuration hub",
    },
    "general-ledger": {
      next: "Transaction Code Setup",
      current: "General Ledger hub",
    },
    "transaction-code": {
      next: "Transaction Code Setup",
      current: "Transaction Code hub",
    },
    "customer-relationship-types": {
      next: "General Ledger Setup",
      current: "Customer Relationship Types",
    },
  };

export const FLOW_CONTINUE_BUTTON_TEXT_MAPPING: flowTitleMappingInterface = {
  "institutional-configuration": "Continue to general ledger",
  "general-ledger": "Continue to transaction codes",
  "transaction-code": "Create another transaction code",
  "customer-relationship-types": "Continue to product configuration",
};
export const CRT_INTERSTITAL_SCREEN_STEPS = [
  {
    id: 1,
    title: "General setup",
    description:
      "Identify and articulate the nature of customer relationships for reporting and data validation purposes",
    back: "",
    continue: "",
  },
  {
    id: 2,
    title: "Party relationships",
    description:
      "Set relationship types, roles, and party limits to structure how parties relate within the system, enhancing relationship management",
    back: ROUTE_PATH.CRT_GENERAL_SETUP,
    continue: ROUTE_PATH.CRT_PARTY_RELATIONSHIPS,
  },
];
