"use client";
import { Box, Button, Divider, Text, useToast } from "@chakra-ui/react";
import { useState, useEffect, useContext } from "react";

import "./IcSummaryLandingPage.scss";
import { CardContainer } from "@/components/common";
import Image from "next/image";
import { solidRightArrow, warningCloseIcon, warningIcon } from "public/assets";

import ReadyToStart from "./ready-to-start-page/ReadyToStart";
import UnmetPreRequisites from "./unmet-pre-requisites-page/UnmetPreRequisites";
import InProgress from "./in-progress-page/InProgress";
import {
  IC_PROGRESS_STATUS_JSON,
  RESOURCE_CONTENT,
} from "@/components/data/form-data";
import {
  ENTITLEMENT_STATUS,
  SUMMARY_LANDING_PAGE_TYPES,
  SUMMARY_LANDING_SCREEN_STATUS,
} from "@/utils/constants";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import Link from "next/link";
import CompletedPage from "./completed-page/CompletedPage";
import { ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import { getHelpfulResourcesConfig } from "@/prismic-api/prismic-api-service";
import { ResourcesLinksSectionSliceDefaultItem } from "../../../../prismicio-types";
import { ResourceSectionProps } from "@/models/global-models";
import { HELPFUL_RESOURCES_SLUG } from "@/utils/constants";
import { buildURL } from "@/prismic-api/common-service";
import { ENTITLEMENT_MESSAGE } from "@/components/data/error-data";
import { getAppPrismicClient, getEntitlement } from "@/store";
import { useSelector } from "react-redux";
interface IcSummaryLandingPageProps {
  pageName: SUMMARY_LANDING_SCREEN_STATUS;
  nameOfStep?: string; //In Ic Summary Landing unmet page name of the step is required in toast
  componentInProgress?: number; //In IC Summary Landing inprogress page we need to provide which step in progress Numbers are [1-6].
  resumeLink?: string; //In IC Summary Landing inprogress page there is a resume button which requires a link of page
}

const IcSummaryLandingPage = (props: IcSummaryLandingPageProps) => {
  const prismicClient = useSelector(getAppPrismicClient);
  const [closeWarning, setCloseWarning] = useState<boolean>(false);
  const [resources, setResources] = useState<ResourceSectionProps>({});
  const LEFT_BADGE = "Jumpstart";
  const RIGHT_BADGE = "General Ledger Setup";
  const TITLE = SUMMARY_LANDING_PAGE_TYPES.ic;
  const toast = useToast();
  const entitlement = useSelector(getEntitlement);

  let content;
  switch (props.pageName) {
    case SUMMARY_LANDING_SCREEN_STATUS.unmet:
      content = (
        <UnmetPreRequisites
          configurationProps={{
            title: TITLE,
            leftBadgeLabel: LEFT_BADGE,
            rightBadgeLabel: RIGHT_BADGE,
            headingText:
              "Come back after finishing Jumpstart to set up your institution",
            paragraphText:
              "You’ll configure specific institutional parameters and account information. Institutional Configuration completion will support General Ledger setup.",
          }}
        />
      );
      break;
    case SUMMARY_LANDING_SCREEN_STATUS.in_progress:
      content = (
        <InProgress
          data={IC_PROGRESS_STATUS_JSON}
          progressIndex={props.componentInProgress}
          resumeLink={props.resumeLink}
          title={TITLE}
          leftBadgeLabel={LEFT_BADGE}
          rightBadgeLabel={RIGHT_BADGE}
          headingText={"Your initial institutional setup is in-progress"}
          paragraphText={
            "Complete the following for institutional configuration and prepare for general ledger setup"
          }
        />
      );
      break;
    case SUMMARY_LANDING_SCREEN_STATUS.not_started:
      content = (
        <ReadyToStart
          configurationProps={{
            title: TITLE,
            leftBadgeLabel: LEFT_BADGE,
            rightBadgeLabel: RIGHT_BADGE,
            headingText: "It’s time to set up your financial institution",
            paragraphText:
              "You’ll configure specific institutional parameters and account information. Institutional configuration completion will support General Ledger setup.",
            link: `${ROUTE_PATH.INSTITUTIONAL_WALKTHROUGH}`,
          }}
        />
      );
      break;
    case SUMMARY_LANDING_SCREEN_STATUS.completed:
      content = (
        <CompletedPage
          type={TITLE}
          leftBadgeLabel={LEFT_BADGE}
          rightBadgeLabel={RIGHT_BADGE}
          headingText="Your initial institutional configuration is complete"
          paragraphText="Now that you have established your financial institution and set the foundation you can continue to general ledger setup."
          infoText="Update your institutional configuration"
          infoSubText="Review your configurations so far and update as needed"
        />
      );
      break;
    default:
      throw Error("Unknown selection: " + props.pageName);
  }

  async function getIcSummaryResources() {
    const resourceData = await getHelpfulResourcesConfig(
      prismicClient,
      HELPFUL_RESOURCES_SLUG.ic,
    );
    await Promise.all([resourceData]).then((values) => {
      setResources(values[0] as ResourceSectionProps);
    });
  }

  useEffect(() => {
    if (!resources?.resourcesLinks?.length) {
      getIcSummaryResources();
    }
  }, []);

  //Check for entitlement
  useEffect(() => {
    const userEntitlement = entitlement;
    if (userEntitlement?.ic === ENTITLEMENT_STATUS.noAccess) {
      if (!toast.isActive("entitlement"))
        toast({
          id: "entitlement",
          description: ENTITLEMENT_MESSAGE.noAccess,
          status: "error",
          duration: null,
        });
    } else if (toast.isActive("entitlement")) {
      toast.close("entitlement");
    }
  }, [entitlement]);

  return (
    <Box className="ic-summary-landing-page">
      <>
        {!closeWarning &&
          props.pageName === SUMMARY_LANDING_SCREEN_STATUS.unmet && (
            <CardContainer customClass="toast-conatiner">
              <Box className="toast-icon">
                <Image src={warningIcon} alt="warning" />
              </Box>
              <Box className="toast-text">
                <Text as="h5">Outstanding pre-requisites</Text>
                <Text>Complete your {props.nameOfStep} to proceed below.</Text>
              </Box>
              <Box className="toast-link">
                <Link href={ROUTE_BASE_URL["DASHBOARD_PATH"]}>
                  <Button className="app-btn-link">Update</Button>
                </Link>
              </Box>
              <Box className="toast-close">
                <CloseIcon
                  onClick={() => setCloseWarning(true)}
                  className="close-icon"
                />
              </Box>
            </CardContainer>
          )}
      </>
      {content}

      <div
        className={`${props.pageName === SUMMARY_LANDING_SCREEN_STATUS.in_progress ? "" : "product-image"}`}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Divider className="bottom-divider" />
          <Text as="h4" className="resource-heading">
            {resources?.sectionTitle ||
              "Helpful institutional configuration resources"}
          </Text>
          {resources?.resourcesLinks &&
            resources?.resourcesLinks.map((res: any, index: number) => (
              <CardContainer
                key={`resourceContainer${index}`}
                customClass="resource-container"
              >
                <div>
                  <Text className="resource-title">{res.link_title}</Text>
                  <Text className="resource-caption">{res.link_caption}</Text>
                </div>
                <Box className="resource-img">
                  {res.link_type === "prismic link" ? (
                    <Link
                      href={buildURL(
                        res?.prismic_link?.type,
                        res?.prismic_link?.uid,
                      )}
                    >
                      <ArrowForwardIcon className="resource-img-icon" />
                    </Link>
                  ) : res.link_type === "academy" ? (
                    <Link
                      href={
                        `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}/?id=` +
                        `${res?.academy_course_link}`
                      }
                    >
                      <Image src={solidRightArrow} alt="Right arrow" />
                    </Link>
                  ) : (
                    <a
                      href={
                        res?.external_link?.link_type === "Document"
                          ? buildURL(
                              res?.external_link?.type,
                              res?.external_link?.uid,
                            )
                          : res?.external_link?.url
                      }
                    >
                      <ArrowForwardIcon className="resource-img-icon" />
                    </a>
                  )}
                </Box>
              </CardContainer>
            ))}
          <Box h={2}></Box>
        </Box>
      </div>
    </Box>
  );
};

export default IcSummaryLandingPage;
