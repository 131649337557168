"use client";

import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "next/navigation";
import { Grid, GridItem } from "@chakra-ui/react";
import { getLearnerObjectsByIds } from "@/client-api-manager/alm-api";
import { constructLearningObject } from "../../learning-util-service";
import { LearningObjectDetails } from "@/models/academy-models";
import "./BadgePath.scss";
import BadgeCard from "./badge-card/BadgeCard";
import BadgeHeader from "./badge-header/BadgeHeader";
import BadgeStatus from "./badge-status/BadgeStatus";
import BadgeDetails from "./badge-status/BadgeDetails";
import { Loader } from "@/components/common";

export default function BadgePath() {
  const queryParam = useSearchParams();
  const learningProgramId = queryParam?.get("id");
  const [badgePath, setBadgePath] = useState<LearningObjectDetails>();
  const [courses, setCourses] = useState<LearningObjectDetails[]>([]);
  const subCoursesLoadedRef = useRef<boolean>(false);

  useEffect(() => {
    if (learningProgramId)
      getLearnerObjectsByIds(learningProgramId).then((res) => {
        const badgePath: LearningObjectDetails[] = constructLearningObject(
          res.data,
          true
        );
        setBadgePath(badgePath[0]);
      });
  }, [learningProgramId]);

  useEffect(() => {
    let courses: any = [];
    badgePath?.sections?.map((item) => {
      if (item.loIds.length > 1) {
        item.loIds.map((i) => {
          courses.push({
            enrollmentId: i,
            required: item.mandatory,
          });
        });
      } else {
        courses.push({
          enrollmentId: item.loIds.join(","),
          required: item.mandatory,
        });
      }
    });
    if (!subCoursesLoadedRef.current && courses.length) {
      let coursesFilter = courses?.map(
        (item: LearningObjectDetails) => item.enrollmentId
      );
      while (coursesFilter.length > 0) {
        const subIds = coursesFilter.splice(0, 10).join(",");
        getSubCourses(courses, subIds);
      }
      subCoursesLoadedRef.current = true;
    }
  }, [badgePath]);

  const getSubCourses = async (courses: any, courseIds: string) => {
    if (courseIds) {
      getLearnerObjectsByIds(courseIds).then((res: any) => {
        const formattedCourse = constructLearningObject(res.data, true);

        const data = formattedCourse.map((c) => {
          const required = courses.filter((d: any) =>
            c.enrollmentId.includes(d.enrollmentId)
          );
          return {
            ...c,
            required: required.length > 0 ? required[0].required : false,
          };
        });
        setCourses((prev: LearningObjectDetails[]) => {
          return [...prev, ...data];
        });
      });
    }
  };

  if (!badgePath) return <Loader isOverlay />;
  return (
    <>
      <BadgeHeader
        pageType="BADGE PATH"
        title={badgePath.name}
        subtitle={`Complete the following courses to earn the ${badgePath.name} badge`}
        duration={
          courses
            ? courses.reduce(
                (total: number, course: any) => total + course.duration,
                0
              )
            : 0
        }
        courseProgress={badgePath.progressPercent}
        showBadgeProgress={true}
      />
      <Grid templateColumns="3fr 1fr" gap={6} mb={20}>
        <GridItem className="grid-item" width={"80%"}>
          {courses &&
            courses.map((course) => (
              <BadgeCard
                isLocked={false}
                key={course.learnerObjectId}
                id={course.learnerObjectId}
                instanceIds={course.instanceIds}
                title={course.name}
                rating={course.rating}
                description={course.description ?? course.overview}
                author={course.author}
                duration={course.duration}
                format={course.format}
                required={course.required}
                status={course.state}
                progressPercent={course.progressPercent}
                enrollmentId={course.enrollmentId}
              />
            ))}
        </GridItem>
        <GridItem className="grid-item" alignItems={"flex-end"}>
          <BadgeStatus
            id={badgePath.learnerObjectId}
            enrollmentId={badgePath.enrollmentId}
            bookmark={badgePath.isBookmarked}
            totalCourse={courses?.length}
            shouldShowCompletion={true}
            completedCourse={
              courses?.filter((course) => course.state === "COMPLETED").length
            }
            type="badge path"
            title={badgePath.name}
          />
          <BadgeDetails
            id={badgePath.learnerObjectId}
            instanceId={badgePath.instanceIds}
            status={badgePath.state}
            progressPercent={badgePath.progressPercent}
          />
        </GridItem>
      </Grid>
    </>
  );
}
