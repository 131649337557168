"use client";
import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  ErrorAlert,
  RadioButtonGroup,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, useToast } from "@/components/ChakraUiManager";
import { useContext, useEffect, useState } from "react";
import {
  getValidationSchema,
  securitySpecificationProps,
} from "./CollateralSecurityValidation";
import { useFormik } from "formik";
import {
  CollateralComponentData,
  COMPONENT_CLASS_ENUM,
  PostSchema,
} from "@/components/product-management/model/types";
import { createComponentGeneric } from "../../product-config-client-service";
import { COMPONENT_CLASS } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { Option } from "@/utils/types";

interface CollateralSecurityProps {
  data: {
    collSecurityOption: Option<string>[];
    formData: CollateralComponentData | null;
    componentCollateralOptions: PostSchema;
  };
}
const CollateralSecuritySpecifications = (props: CollateralSecurityProps) => {
  const [validationSchema, setValidationSchema] = useState<any>(null);
  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.componentCollateralOptions
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.componentCollateralOptions]);

  // Form Submit Handler
  const onSubmit = async (values: securitySpecificationProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["collateral"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      const res = await createComponentGeneric({
        productDetails,
        formData: {
          ...props.data.formData,

          secLvl: values.secLvl,
          secPct: parseFloat(values.secPct),
        },
        toastOptions: {
          toast,
          toastId: "installment-collateral",
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
        componentClass: COMPONENT_CLASS_ENUM.componentCollateral,
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  // using useFormik hook Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      secLvl: props.data.formData?.secLvl ?? -1,
      secPct:
        props.data.formData?.secLvl === 0
          ? "0"
          : props.data.formData?.secLvl === 1
            ? "100"
            : (props.data.formData?.secPct?.toString() ?? ""),
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const toast = useToast();
  const toastId = "general-feature-toast";

  //handle Collateral_Security_Specification_Form_Data
  const _collateralFormDataHandler =
    (key: string) => (value: string | number) => {
      if (key === "secLvl") {
        if (value === 0) setFieldValue("secPct", "0");
        else if (value === 1) setFieldValue("secPct", "100");
        else setFieldValue("secPct", "");
      }
      setFieldValue(key, value);
    };
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the collateral security level?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("secLvl")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.secLvl}
            onChange={_collateralFormDataHandler("secLvl")}
            radioPropList={props.data.collSecurityOption}
            stackDirection={"column"}
            spacing={"1rem"}
          />
        </Box>

        <Box>
          <AppFormLabel
            labelName="What percentage of the loan is covered by securities?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("secPct")
                ?.tooltip_text_main
            }
          />
          <AppNumberInput
            value={values.secPct}
            isDisabled={values.secLvl === 0 || values.secLvl === 1}
            onChange={_collateralFormDataHandler("secPct")}
            valueSuffix="%"
          />
          {errors.secPct && touched.secPct && (
            <ErrorAlert>
              <span>{errors.secPct}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};
export default CollateralSecuritySpecifications;
