"use client";
import { useContext, useEffect, useState } from "react";
import { Box, Stack, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  DatePicker,
  ErrorAlert,
  FrequencyModule,
  SplitButton,
  TimeField,
} from "@/components/common";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import {
  ReviewFrequencyProps,
  reviewFrequency,
  getValidationSchema,
} from "./ReviewFrequencyValidation";
import {
  COMPONENT_CLASS_ENUM,
  InterestComponentData,
  PostSchema,
} from "../../../model/types";
import { makeDateTime, splitDateTime } from "@/utils/common";
import { createComponentGeneric } from "../../product-config-client-service";
import { COMPONENT_CLASS } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: InterestComponentData | null;
    interestComponentOptionsData: PostSchema;
  };
};

const ReviewFrequency = (props: Props) => {
  const { formData, interestComponentOptionsData } = props.data;
  const toast = useToast();

  // Form Submit Handler
  const onSubmit = async (values: ReviewFrequencyProps, actions: any) => {
    // Update only if component data exists and form has been touched
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && dirty) {
      if (productDetails) {
        const res = await createComponentGeneric({
          productDetails,
          formData: {
            ...formData,

            index: {
              ...formData.index,
              isReviewDly: values.index.isReviewDly,
              ...(values.index.indexReviewDate && values.index.isReviewDly
                ? {
                    nextReviewDtm: makeDateTime(
                      values.index.indexReviewDate,
                      values.index.indexReviewTime
                    ),
                  }
                : { nextReviewDtm: null }),
              reviewFreq:
                values.index.isReviewDly === false
                  ? values.index.reviewFreq
                  : null,
            },
          },
          toastOptions: {
            toast,
            toastId: "index-rate-loan-review-frequency-detail",
            successMessage: `${configPageTitle} updated.`,
          },

          stageName: getWorflowStatusToBeUpdated?.() ?? "",
          componentClass: COMPONENT_CLASS_ENUM.componentInt,
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      index: {
        isReviewDly:
          formData?.index?.isReviewDly ?? reviewFrequency.index.isReviewDly,
        reviewFreq:
          formData?.index?.reviewFreq ?? reviewFrequency.index.reviewFreq,
        indexReviewDate: formData?.index?.nextReviewDtm
          ? splitDateTime(formData.index.nextReviewDtm).date
          : reviewFrequency.index.indexReviewDate,
        indexReviewTime: formData?.index?.nextReviewDtm
          ? splitDateTime(formData.index.nextReviewDtm).time
          : reviewFrequency.index.indexReviewTime,
      },
    },
  });
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
    setTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]); //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Handling Form
  const reviewFrequencyFormHandler =
    (key: string) =>
    (value: string | SplitBtnInputValType | Date | boolean) => {
      if (key === "isReviewDly") {
        setFieldValue("index", { ...reviewFrequency.index, [key]: value });
      } else if (key === "reviewFrequencyModelInputValue") {
        setFieldValue("index", { ...values.index, [key]: value }).then(
          (res) => {
            setTouched({});
          }
        );
      } else {
        setFieldValue("index", { ...values.index, [key]: value });
      }
    };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is there a review of the interest rate acquired from index daily at accrual cutoff?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isReviewDly")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            onSelect={reviewFrequencyFormHandler("isReviewDly")}
            value={values.index.isReviewDly}
          />
        </Box>

        {errors.index?.isReviewDly && (
          <ErrorAlert>
            <span>{errors.index?.isReviewDly}</span>
          </ErrorAlert>
        )}
        {values.index.isReviewDly === false && (
          <Box className="app-form-field-container">
            {/* Frequency Module */}
            <FrequencyModule
              value={values.index.reviewFreq}
              onChange={reviewFrequencyFormHandler("reviewFreq")}
              frequencyQuestionTitle={
                "How often should the interest rate be reviewed?"
              }
              frequencyOutputContent="A review will occure every"
              frequencyQuestionDescription={
                tooltipFlyoutDetails?.tooltipsMap?.get("reviewFreq")
                  ?.tooltip_text_main
              }
              monthDropdownTooltip={
                tooltipFlyoutDetails?.tooltipsMap?.get("reviewFreqMon")
                  ?.tooltip_text_main
              }
              notBusinessDayTooltip={
                tooltipFlyoutDetails?.tooltipsMap?.get("reviewFreqNotBusiness")
                  ?.tooltip_text_main
              }
              notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the review occur?"
            />
            {errors.index?.reviewFreq && touched.index?.reviewFreq && (
              <ErrorAlert>
                <span>{errors.index.reviewFreq}</span>
              </ErrorAlert>
            )}
          </Box>
        )}
        {values.index.isReviewDly && (
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="When is the next index review date/time?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("nextReviewDtm")
                  ?.tooltip_text_main
              }
            />
            <Box display="flex" gap="8">
              <Stack flexBasis="50%">
                <AppFormLabel labelName="Date" />
                <DatePicker
                  ariaLabel="index-review-date"
                  value={values.index.indexReviewDate as Date}
                  onUpdate={reviewFrequencyFormHandler("indexReviewDate")}
                  onBlur={handleBlur}
                  place="auto"
                  placeholder="Select"
                />
                {errors.index?.indexReviewDate &&
                  touched.index?.indexReviewDate && (
                    <ErrorAlert>
                      <span>{errors.index?.indexReviewDate}</span>
                    </ErrorAlert>
                  )}
              </Stack>
              <Stack flexBasis="50%">
                <AppFormLabel labelName="Select time" />
                <TimeField
                  ariaLabel="index review time"
                  value={values.index.indexReviewTime}
                  onChange={reviewFrequencyFormHandler("indexReviewTime")}
                  onBlur={handleBlur}
                />

                {errors.index?.indexReviewTime &&
                  touched.index?.indexReviewDate && (
                    <ErrorAlert>
                      <span>{errors.index?.indexReviewTime}</span>
                    </ErrorAlert>
                  )}
              </Stack>
            </Box>
          </Box>
        )}
      </CardContainer>
    </form>
  );
};

export default ReviewFrequency;
