import React, { useCallback } from "react";
import CourseProfile from "../course-profile/CourseProfile";
import { getRecommendedCoursesUpdate } from "@/client-api-manager/alm-api";
import { formatDuration } from "@/utils/common";
import { formatDate } from "../../learning-util-service";
import { courseDefault } from "public/assets";
import { HeaderData } from "@/models/academy-models";
import { DATE_FORMAT } from "@/utils/constants";
import { CourseDetail } from "@/utils/types";

const RecommendedCourses = ({
  headerData,
  setReload,
  reload,
}: {
  headerData: HeaderData;
  setReload: (val: boolean) => void;
  reload: boolean;
}) => {
  const fetchData = useCallback(async (): Promise<CourseDetail[]> => {
    const response = await getRecommendedCoursesUpdate({
      limit: 10,
      sort: "-recommendationScore",
    });
    if (!response || !Array.isArray(response)) {
      throw new Error("Invalid response from Recommended Courses");
    }

    return response.map((course) => ({
      id: course.id,
      authorNames: course.attributes.authorNames,
      bannerUrl: course.attributes.bannerUrl,
      imageUrl: course.attributes.imageUrl ?? courseDefault.src,
      name: course.attributes.localizedMetadata?.[0]?.name,
      description: course.attributes.localizedMetadata?.[0]?.description,
      date: formatDate(
        course.attributes.effectiveModifiedDate,
        DATE_FORMAT.shortDate
      ),
      timeline: formatDuration(course.attributes.duration, true),
      rating: {
        averageRating: course.attributes?.rating?.averageRating,
      },
      isBookmarked: course.attributes.isBookmarked,
      loType: course.attributes.loType,
      loFormat: course.attributes.loFormat,
      isEnrolled: !!course.relationships?.enrollment,
      enrollmentId: course.relationships?.enrollment?.data?.id || "",
      relationships: course.relationships,
    }));
  }, []);

  return (
    <CourseProfile
      fetchData={fetchData}
      title={
        headerData?.headline || "Recommended learnings to elevate your career"
      }
      subtitle={
        headerData?.subhead ||
        "Acquire key badges to qualify for certification tests and propel your financial career forward"
      }
      noResultsFoundText="There are no recommendations found"
      setReload={setReload}
      reload={reload}
    />
  );
};

export default RecommendedCourses;
