import { GENERIC_ERROR } from "@/components/data/error-data";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Rate_Adjustment_Range
interface RateAdjustmentRangesProps {
  index: {
    minRate: string;
    maxRate: string;
    maxChngFirst: string,
    maxChngNext: string,
    maxChngLife: string,
    maxChngPer: string,
    reviewFreq: string,
  };
}
// Defining Intitial Value For Rate_Adjustment_Range
const rateAdjustmentRangesDetails: RateAdjustmentRangesProps = {
  index: {
    minRate: "",
    maxRate: "",
    maxChngFirst: "",
    maxChngNext: "",
    maxChngLife: "",
    maxChngPer: "",
    reviewFreq: "",
  },
};
// YUP LIBRARY :Defining Schema For validation of Rate_Adjustment_Range
const rateAdjustmentRangesDetailSchema = async (optionsSchema: PostSchema) => {
  const fields = ["index"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { RateAdjustmentRangesProps };
// Exporting Validation Schema and Intial value
export {
  rateAdjustmentRangesDetails,
  rateAdjustmentRangesDetailSchema as getValidationSchema,
};
