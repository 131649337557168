import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Foundational_Interest_Detail
interface FoundationalInterestProps {
  calcMthd: number | undefined;
  balOpt: number | undefined;
  accrMinBal: string;
  adjTerm: string;
}
// Defining Intitial Value For Foundational_Interest_Detail
const foundationalInterest: FoundationalInterestProps = {
  calcMthd: undefined,
  balOpt: undefined,
  accrMinBal: "",
  adjTerm: "",
};

// YUP LIBRARY : Defining Schema For Validation Of Foundational_Interest_Detail
const foundationalInterestSchema = async (optionsSchema: PostSchema) => {
  const fields = ["calcMthd", "balOpt", "accrMinBal", "adjTerm"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { FoundationalInterestProps };
// Exporting Validation Schema and Intial value
export {
  foundationalInterest,
  foundationalInterestSchema as getValidationSchema,
};
