"use client";

import {
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";
import { TransCodeEntries, TransCodeResponse } from "../../models";
import {
  AccordionComponent,
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CardContainer,
  CoupleRadioWithDropdown,
  CoupledFieldWithExpr,
  ErrorAlert,
  InputText,
  SplitBtnInputValType,
  SplitButton,
} from "@/components/common";
import { Box, Heading, useToast } from "@/components/ChakraUiManager";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { QUERY_PARAM_KEY } from "@/route-config/route-path";
import {
  MAIN_FORM_ID,
  SIDEBAR_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS,
  TC_STAGE_STATUS,
  TC_STAGE_ORDER,
} from "@/utils/constants";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { Option, RjsfData } from "@/utils/types";
import { addNewComponent } from "@/components/product-management/product-configuration/product-config-client-service";
import {
  constructTcEntryPayload,
  getEntryStageStatus,
  updateTransCode,
} from "../../transaction-code-api-service";
import {
  getFeeCalcDtlUiSchema,
  feeCalcDtlUnRequire,
} from "@finxact/finxact-shared-ui";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import {
  generateYupSchema,
  preventMainFormSubmitOnRJSFSubmit,
} from "@/utils/common";

interface TcEntryAdvancedSetup {
  data: {
    formData: TransCodeResponse;
    assetClassOptions: Option<number>[];
    assetIdOptions: Option<string> &
      {
        assetClass: number;
      }[];
    feeDetailsOptions: Option<string>[];
    statGroupOptions: Option<string>[];
    glSetCodeOptions: Option<string>[];
    addNewSchema: OptionsSchema;
    addNewSchemaApiEndPoint: string;
    tranCodeOptionsSchema: PostSchema;
  };
}

type TcEntryAdvFormProps = Omit<TransCodeEntries, "isContact"> & {
  isContact: SplitBtnInputValType;
};

const initialFormValues: TcEntryAdvFormProps = {
  isContact: false,
};

export default function TcEntryAdvancedSetup(props: TcEntryAdvancedSetup) {
  const {
    data: {
      formData,
      assetClassOptions,
      assetIdOptions,
      feeDetailsOptions,
      addNewSchema,
      addNewSchemaApiEndPoint,
      statGroupOptions,
      glSetCodeOptions,
      tranCodeOptionsSchema,
    },
  } = props;

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    entityId,
    navigateToNextPage,
    navigateTo,
    getQueryParam,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    entityWorkflowData,
    setSideBarCurrentIndex,
    sideBarMainMenuIndex,
    sideBarSubMenuIndex,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const toast = useToast();
  const toastId = "tc-entry-adv-details";
  const currEntriesIndex = getQueryParam?.(QUERY_PARAM_KEY.TC_ENTRY_INDEX) || 0;

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateToNextPage?.();
    }
  };

  const onSubmit = async (values: TcEntryAdvFormProps, actions: any) => {
    if (!dirty) {
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.tcConfig,
        key: formData?.trnCode!,
        stage: TC_STAGE_STATUS.entry_setup,
        status:
          entityId && !entityWorkflowData?.length
            ? ""
            : getEntryStageStatus(
                TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_advanced,
                entityWorkflowData
              ),
      });
      moveToNextStep();
      return;
    }

    const commonToastOptions = {
      toast,
      toastId,
    };

    if (values.posnNbr) {
      values.posnNbr = Number(values.posnNbr);
    }

    if (values.trnAmt) {
      values.trnAmt = Number(values.trnAmt);
    }
    if (values.seqNbr) {
      values.seqNbr = Number(values.seqNbr);
    }
    let payload = constructTcEntryPayload(
      formData,
      values,
      Number(currEntriesIndex)
    );
    const response = await updateTransCode({
      formData: {
        ...payload,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: TC_STAGE_STATUS.entry_setup,
      stageStatus:
        entityId && !entityWorkflowData?.length
          ? ""
          : getEntryStageStatus(
              TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_advanced,
              entityWorkflowData
            ),
    });

    if (response) {
      moveToNextStep();
    }
  };

  const savedCurrEntries = formData?.entries?.[currEntriesIndex as any];
  const constructExistingFormValues: TcEntryAdvFormProps = savedCurrEntries
    ? {
        ...savedCurrEntries,
        isContact: savedCurrEntries.isContact ?? undefined,
        statGroups: savedCurrEntries?.statGroups?.length
          ? (statGroupOptions
              .filter((item) =>
                savedCurrEntries?.statGroups?.includes(item.value)
              )
              ?.map((item) => item.value) as unknown as string[])
          : ([] as string[]),
      }
    : initialFormValues;

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const fields = ["entries"];
      const yupSchema = await generateYupSchema(fields, tranCodeOptionsSchema);
      setValidationSchema(yupSchema.entries.innerType);
    })();
  }, [tranCodeOptionsSchema]);

  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    initialValues: constructExistingFormValues,
    validationSchema,
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setValues,
    dirty,
  } = formikConfigs;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const schema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewSchemaApiEndPoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("trnFee", response.name);
  };

  const handleOnChange = (key: string) => (value: any) => {
    if (key === "assetClass") {
      const foundAssetId = filteredAssetIdOptions.find(
        (opt) => opt.assetClass === value
      );
      // unset assetId if it doesn't exist in selected assetClass
      if (!foundAssetId) {
        setFieldValue("assetId", "");
      }
    }
    if (key === "trnFee") {
      setFieldValue(key, value.length > 0 ? value : undefined);
    } else {
      setFieldValue(key, value);
    }
  };

  const filteredAssetIdOptions = useMemo(() => {
    return assetIdOptions.filter(
      (option) => option.assetClass === values.assetClass
    );
  }, [values.assetClass]);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  useEffect(() => {
    setSideBarCurrentIndex?.(
      TC_STAGE_ORDER.entry_setup,
      TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_advanced
    );
  }, []);

  useEffect(() => {
    if (
      sideBarSubMenuIndex ===
      TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_advanced
    ) {
      updateFormStatus?.(SIDEBAR_STATUS.in_progress);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.in_progress);
    }
  }, [sideBarSubMenuIndex]);

  const _renderError = (key: keyof TcEntryAdvFormProps) => {
    return (
      errors[key] &&
      touched[key] && (
        <ErrorAlert>
          <span>{errors[key] as string}</span>
        </ErrorAlert>
      )
    );
  };

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      noValidate
      id={MAIN_FORM_ID}
    >
      <CardContainer>
        <AccordionComponent
          accordionTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
              ?.section_title || `Position details`
          }
          isExpand={0}
        >
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the position identifier?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("posnId")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={values.posnId}
              onChange={handleOnChange("posnId")}
              onBlur={handleBlur}
              label="What is the position identifier?"
              name="posnId"
            />
            {_renderError("posnId")}
          </Box>
          <Heading as="h6" className="app-heading-box">
            Enable any of the following
          </Heading>
          <CoupledFieldWithExpr
            customClass="app-heading-box-contents"
            expOnValue={values.posnAcctNbrExpr}
            expOnFormKey={"posnAcctNbrExpr"}
            expOffValue={values.posnAcctNbr}
            expOffFormKey={"posnAcctNbr"}
            labelName={"What is the position account number?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("posnAcctNbr")
                ?.tooltip_text_main
            }
            onExpOnChange={handleOnChange("posnAcctNbrExpr")}
            onExpOffChange={handleOnChange("posnAcctNbr")}
            onExpOffBlur={handleBlur}
          />
          {_renderError("posnAcctNbrExpr")}
          {_renderError("posnAcctNbr")}
          <CoupledFieldWithExpr
            customClass="app-heading-box-contents"
            expOnValue={values.posnNbrExpr}
            expOnFormKey={"posnNbrExpr"}
            expOffValue={values.posnNbr}
            expOffFormKey={"posnNbr"}
            labelName={"What is the position sequence number?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("posnNbr")
                ?.tooltip_text_main
            }
            onExpOnChange={handleOnChange("posnNbrExpr")}
            onExpOffChange={handleOnChange("posnNbr")}
            onExpOffBlur={handleBlur}
            expOffFormType="inputNumber"
          />
          {_renderError("posnNbrExpr")}
          {_renderError("posnNbr")}
        </AccordionComponent>
      </CardContainer>
      <CardContainer>
        <AccordionComponent
          accordionTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
              ?.section_title || `Source information`
          }
          isExpand={0}
        >
          <CoupledFieldWithExpr
            expOnValue={values.assetClassExpr}
            expOnFormKey={"assetClassExpr"}
            expOffValue={values.assetClass}
            expOffFormKey={"assetClass"}
            labelName={"What is the asset class identifier?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("assetClass")
                ?.tooltip_text_main
            }
            onExpOnChange={handleOnChange("assetClassExpr")}
            onExpOffChange={handleOnChange("assetClass")}
            onExpOffBlur={handleBlur}
            dropdownList={assetClassOptions}
            expOffFormType={"singleSelect"}
          />
          {_renderError("assetClassExpr")}
          {_renderError("assetClass")}
          <CoupledFieldWithExpr
            expOnValue={values.assetIdExpr}
            expOnFormKey={"assetIdExpr"}
            expOffValue={values.assetId}
            expOffFormKey={"assetId"}
            labelName={"What is the asset identifier?"}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("assetId")
                ?.tooltip_text_main
            }
            onExpOnChange={handleOnChange("assetIdExpr")}
            onExpOffChange={handleOnChange("assetId")}
            onExpOffBlur={handleBlur}
            dropdownList={filteredAssetIdOptions}
            expOffFormType={"singleSelect"}
          />
          {_renderError("assetIdExpr")}
          {_renderError("assetId")}
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the source of funds?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("srcFunds")
                  ?.tooltip_text_main
              }
              labelFor="srcFunds"
            />
            <InputText
              value={values.srcFunds}
              onChange={handleOnChange("srcFunds")}
              onBlur={handleBlur}
              label="What is the source of funds?"
              name="srcFunds"
              id="srcFunds"
            />
            {_renderError("srcFunds")}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Is this transaction a form of customer contact?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("isContact")
                  ?.tooltip_text_main
              }
            />
            <SplitButton
              name="isDr"
              onSelect={handleOnChange("isContact")}
              leftBtnName="Yes"
              rightBtnName="No"
              value={values.isContact}
            />
            {_renderError("isContact")}
          </Box>
        </AccordionComponent>
      </CardContainer>
      <CardContainer>
        <AccordionComponent
          accordionTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
              ?.section_title || `Fee/transaction information`
          }
          isExpand={0}
        >
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the transaction fee?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("trnFee")
                  ?.tooltip_text_main
              }
              labelFor="trnFee"
            />
            <CoupleRadioWithDropdown
              primaryOptionValue={values.trnFee}
              handlePrimaryOptionSelect={handleOnChange("trnFee")}
              primaryRadioName={"Choose from existing transaction fees"}
              secondaryRadioName={"Add a new transaction fee"}
              dropdownList={feeDetailsOptions}
              onBlur={handleBlur}
              id={"trnFee"}
              schema={schema}
              uiSchema={getFeeCalcDtlUiSchema()}
              unRequire={feeCalcDtlUnRequire}
              onAddNewSubmit={addNewOption}
            />
            {_renderError("trnFee")}
          </Box>
          <CoupledFieldWithExpr
            expOnValue={values.trnAmtExpr}
            expOnFormKey={"trnAmtExpr"}
            expOffValue={values.trnAmt}
            expOffFormKey={"trnAmt"}
            labelName={"Specify the transaction amount."}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("trnAmt")
                ?.tooltip_text_main
            }
            onExpOnChange={handleOnChange("trnAmtExpr")}
            onExpOffChange={handleOnChange("trnAmt")}
            onExpOffBlur={handleBlur}
            expOffFormType={"inputNumber"}
          />
          {_renderError("trnAmtExpr")}
          {_renderError("trnAmt")}
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the transaction sequence?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("seqNbr")
                  ?.tooltip_text_main
              }
            />
            <AppNumberInput
              label="Transaction sequence"
              value={values.seqNbr!}
              onChange={handleOnChange("seqNbr")}
              onBlur={handleBlur}
              placeholder="Enter sequence"
            />
            {_renderError("seqNbr")}
          </Box>
        </AccordionComponent>
      </CardContainer>
      <CardContainer>
        <AccordionComponent
          accordionTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
              ?.section_title || `Set codes & groups`
          }
          isExpand={0}
        >
          <Box className="app-form-field-container">
            <CoupledFieldWithExpr
              expOnValue={values.glSetCodeExpr}
              expOnFormKey={"glSetCodeExpr"}
              expOffValue={values.glSetCode}
              expOffFormKey={"glSetCode"}
              labelName={"What is the set code?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("glSetCode")
                  ?.tooltip_text_main
              }
              onExpOnChange={handleOnChange("glSetCodeExpr")}
              onExpOffChange={handleOnChange("glSetCode")}
              onExpOffBlur={handleBlur}
              expOffFormType={"singleSelect"}
              dropdownList={glSetCodeOptions}
            />
            {_renderError("glSetCodeExpr")}
            {_renderError("glSetCode")}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What are the statistical groups?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("statGroups")
                  ?.tooltip_text_main
              }
              labelFor="statGroups"
            />
            <SelectDropdown
              isMulti
              dropdownList={statGroupOptions}
              value={values.statGroups}
              onChange={handleOnChange("statGroups")}
              placeholder={"Select"}
              onBlur={handleBlur}
              id="statGroups"
            />
            {_renderError("statGroups")}
          </Box>
        </AccordionComponent>
      </CardContainer>
    </form>
  );
}
