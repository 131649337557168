import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Party_Relationships_Details
interface PartyRelationshipsProps {
  maxParties: string | number;
  minParties: string | number;
  reldesc: string;
  partyRelType: number | string;
  beneficialOwnerRole: number | string;
  idx: number | string;
}
// Defining Intitial Value For Party_Relationships_Details
const defaultPartyRelationships: PartyRelationshipsProps = {
  maxParties: "",
  minParties: "",
  reldesc: "",
  partyRelType: "",
  beneficialOwnerRole: "",
  idx: "",
};
// YUP LIBRARY :Defining Schema For validation of Party_Relationships_Details
const partyRelationshipsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["rels"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { PartyRelationshipsProps };
// Exporting Validation Schema and Intial value
export {
  defaultPartyRelationships,
  partyRelationshipsSchema as getValidationSchema,
};
