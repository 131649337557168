"use client";
import React, { useContext, useEffect, useState } from "react";
import { AppPortal, CardContainer, ProductTitle } from "../common";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Heading,
  Text,
} from "@/components/ChakraUiManager";
import styles from "./CompletionScreen.module.scss";
import { completeInterstial, completionImage } from "public/assets";
import Image from "next/image";

import { useRouter, useSearchParams } from "next/navigation";
import {
  ACCOUNT_GROUP_STEPS,
  ACCOUNT_TYPE_STEPS,
  ASSET_CATALOG_STEPS,
  BANK_PARAMS_STEPS,
  FINANCIAL_CALENDAR_STEPS,
  FINANCIAL_ORGANIZATION_STEPS,
  GL_ENTITY,
  COMPLETION_SCREEN_DATA,
  IC_ENTITY,
  GL_STEPS,
} from "@/utils/constants";

import Link from "next/link";
import {
  GLWorkflowResponse,
  getAllWorkflowGeneric,
  getWorkflowGeneric,
  updateWorkflowGeneric,
} from "@/api-config/api-service";
import { setIcCopyFlagInStorage } from "../institutional-configuration/ic-utils-service";
import { setGLCopyFlagInStorage } from "../general-ledger/general-ledger-util-service/general-ledger-util-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "../context-api/product-configuration-context/ProductConfigurationReducer";

type InterstialScreenProps = {
  title: string;
  flow: "institutional-configuration" | "general-ledger";
  exitLink: string;
};

const stageNames: any = {
  account_group: ACCOUNT_GROUP_STEPS.acc_grp_advanced_config,
  asset_catalog: ASSET_CATALOG_STEPS.asset_cat_gen_ledger,
  account_type: ACCOUNT_TYPE_STEPS.acct_type_summary,
  financial_calendar: FINANCIAL_CALENDAR_STEPS.fin_cal_holiday_config,
  financial_organization: FINANCIAL_ORGANIZATION_STEPS.fin_org_specific_params,
  bank_parameters: BANK_PARAMS_STEPS.bank_params_priority_posting,
  accounting_segments: GL_STEPS.accounting_segments,
  accounts: GL_STEPS.accounts,
  system_accounts: GL_STEPS.system_accounts,
  sets: GL_STEPS.sets,
};

const CompletionScreen = ({ title, flow, exitLink }: InterstialScreenProps) => {
  const router = useRouter();
  const params = useSearchParams();
  const entityType = params?.get("entityType");
  const entityId = params?.get("entityId");
  const [systemAccountsEntry, setSystemAccountsEntry] = useState<boolean>(true);

  //context API
  const { updateSidebarMenu } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );

  function onSaveAndExitClick() {
    if (flow === "institutional-configuration") {
      //resetting sidebar
      updateSidebarMenu?.([]);
    }
    router.push(exitLink);
  }

  useEffect(() => {
    const getData = async () => {
      const entityType = params?.get("entityType") ?? "";
      const entityId = params?.get("entityId") ?? "";
      if (flow === "institutional-configuration") {
        const icWorkflowConfig = await getWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: entityType,
        }).catch((err) => console.error(err));

        if (
          icWorkflowConfig &&
          icWorkflowConfig !== undefined &&
          icWorkflowConfig.status !== stageNames[entityType]
        ) {
          await updateWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.institutionalConfig,
            key: entityId,
            stage: entityType,
            status: stageNames[entityType],
          });
        }
      } else if (flow === "general-ledger") {
        const [response] = await Promise.all([
          getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.generalLedgerConfig),
        ]);

        setSystemAccountsEntry(
          response.filter(
            (data: GLWorkflowResponse) =>
              data.stage === "system_accounts" &&
              data.status === "completed_system_accounts"
          ).length > 2
        );
      }
    };
    getData();
    setIcCopyFlagInStorage(false);
    setGLCopyFlagInStorage(false);
  }, []);

  let index = 0;
  switch (true) {
    case entityType === IC_ENTITY.account_group:
      index = 0;
      break;
    case entityType === IC_ENTITY.asset_catalog:
      index = 1;
      break;
    case entityType === IC_ENTITY.account_type:
      index = 2;
      break;
    case entityType === IC_ENTITY.financial_calendar:
      index = 3;
      break;
    case entityType === IC_ENTITY.financial_organization:
      index = 4;
      break;
    case entityType === IC_ENTITY.bank_parameters:
      index = 5;
      break;
    case entityType === GL_ENTITY.accounting_segments:
      index = 0;
      break;
    case entityType === GL_ENTITY.accounts:
      index = 1;
      break;
    case entityType === GL_ENTITY.system_accounts:
      index = 2;
      break;
    case entityType === GL_ENTITY.sets:
      index = 3;
      break;
  }

  return (
    <>
      <ProductTitle title={title} isFullScreen={true} />
      <Box className={styles["completion-main-container"]}>
        <Image src={completionImage} alt="Completion image" />
        <CardContainer customClass={styles["completion-main-card-container"]}>
          <Heading as={"h3"} className={styles["completion-main-heading"]}>
            {COMPLETION_SCREEN_DATA[flow][index].heading}
          </Heading>
          <Text className={styles["completion-main-desc"]}>
            Return to the {title.toLowerCase()} landing page to view or update
            your configuration
          </Text>
          <Divider className={styles["heading-content-divider"]} mx={-9.6} />
          {COMPLETION_SCREEN_DATA[flow][index].label.map(
            (data: string, index: number) => (
              <>
                <Box className={styles["completed-item"]}>
                  <Image src={completeInterstial} alt="check" />
                  <Heading className={styles["completed-item-text"]} as={"h4"}>
                    {data}
                  </Heading>
                </Box>
                <Divider
                  className={styles["completed-item-line"]}
                  orientation="vertical"
                />
              </>
            )
          )}
        </CardContainer>
      </Box>
      <AppPortal domId="#appFooter">
        <Box className={styles["interstial-screen-footer"]}>
          <Button
            // Disabling the button in system accounts has 2 entry
            isDisabled={
              !systemAccountsEntry && entityType === GL_ENTITY.system_accounts
            }
            className="app-btn-link"
            onClick={() => {
              router.push(
                `${COMPLETION_SCREEN_DATA[flow][index].back}?entityId=${-1}&entityType=${entityType}`
              );
            }}
          >
            {`Create another ${COMPLETION_SCREEN_DATA[flow][index].createBtnLabel}`}
          </Button>
          <ButtonGroup className={styles["interstial-screen-btn-container"]}>
            <Link
              href={`${COMPLETION_SCREEN_DATA[flow][index].back}?entityId=${entityId}&entityType=${entityType}`}
            >
              <Button mr={6} className="app-btn-reg-secondary-transparent">
                Back
              </Button>
            </Link>
            <Button
              className="app-btn-inverse-secondary"
              onClick={onSaveAndExitClick}
            >
              Save & Exit
            </Button>
          </ButtonGroup>
        </Box>
      </AppPortal>
    </>
  );
};

export default CompletionScreen;
