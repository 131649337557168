"use client";
import {
  AppFormLabel,
  AppModal,
  CardContainer,
  StaticText,
  HoverToolTip,
  TopBannerContainer,
  BannerNotification,
  WrappedLink,
} from "@/components/common";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  ArrowBackIcon,
  AddIcon,
  Box,
  CheckIcon,
  Image,
  Divider,
  Button,
  ButtonGroup,
  Text,
  AlertStatus,
  useToast,
  Flex,
} from "@/components/ChakraUiManager";
import "./AdditionalFeatures.scss";
import { AppPortal } from "@/components/common";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { binIcon } from "public/assets";
import {
  AddedComponentType,
  initialState,
  reducer,
} from "./AdditionalFeatureReducer";
import {
  ProductComponentType,
  ProductType,
  getIfxProdType,
  updateProductInSummary,
} from "..";
import {
  DASHBOARD_ACCORDION_STATUS,
  IFX_ACCT_PRODUCT_TYPE,
  IFX_PRODUCT_SUB_TYPE_VAL,
  IFX_PRODUCT_TYPE_VAL,
  PRODUCT_CLASS_NAME_WITH_COMPONENT,
  RECOMENDED_PRODUCT_FEATURES,
} from "@/utils/constants";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  ADDITIONAL_TEMPLATED_FEATURES_DEPOSIT_MAPPING,
  ADDITIONAL_TEMPLATED_FEATURES_LOAN_MAPPING,
  getCompleteAttributeList,
} from "../product-summary/product-summary-config";
import {
  createComponent,
  createWorkflowGeneric,
  deleteWorkflowStage,
} from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PRODUCT_CATALOG } from "@/data/product-catalog-data";
import AddComponentInput, {
  AddedComponentTypeValue,
  ExistingComponentMap,
} from "../add-component-input/AddComponentInput";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { useRouter } from "next/navigation";
import { GENERAL_SETUP_STAGE_NAME_MAP } from "@/data/status-sidebar-menu-data";
import { getConstructedFeatureNameWithComponent } from "@/utils/common";
import { deepCopy } from "@finxact/finxact-shared-ui";

type AdditionalFeaturesProps = {
  data: ExistingComponentMap;
};

const AdditionalFeatures = ({ data }: AdditionalFeaturesProps) => {
  const { productDetails, productWorkflowData, setProductRefetchState } =
    useContext<ProductConfigurationContextType>(productConfigurationContext);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    allExistingComponents: data,
  });
  const [recommendedFeatureList, setRecommendedFeatureList] =
    useState<ProductComponentType[]>();
  const [componentToRemove, setComponentToRemove] =
    useState<AddedComponentType | null>(null);
  const deleteModalRef = useRef<any>();
  const toast = useToast();
  const { unsavedChangesConfig, clearUnsavedChanges, setUnsavedChanges } =
    useUnsavedChanges();
  const [compErrorState, setErrorState] = useState(initErrorState);
  const router = useRouter();
  const PRODUCT_SUMMARY_PATH = `${ROUTE_PATH.PRODUCT_SUMMARY_FULLPATH}${ROUTE_PATH.PRODUCT_NAME_QUERY}${productDetails?.name}`;

  useEffect(() => {
    // Fetch list of available components after product details are fetched
    if (productDetails) {
      const payload =
        IFX_ACCT_PRODUCT_TYPE[productDetails.ifxAcctType!] ===
        IFX_PRODUCT_TYPE_VAL.loan
          ? ADDITIONAL_TEMPLATED_FEATURES_LOAN_MAPPING[
              productDetails.prodSubType?.toLowerCase() ||
                IFX_PRODUCT_SUB_TYPE_VAL.personal
            ]
          : ADDITIONAL_TEMPLATED_FEATURES_DEPOSIT_MAPPING[
              productDetails.prodSubType?.toLowerCase() ||
                IFX_PRODUCT_SUB_TYPE_VAL.personal
            ];
      dispatch({
        type: "SET_COMPONENTS_FOR_PROD_TYPE",
        payload: {
          data: payload,
        },
      });
    }
  }, [productDetails, dispatch]);

  const accordionBtn = useCallback((compClass: string) => {
    return document.getElementById(`accordion-button-${compClass}`);
  }, []);

  const showModal = (component: AddedComponentType) => {
    deleteModalRef?.current?.openModal();
    setComponentToRemove(component);
  };

  const onClickSubmit = () => {
    if (componentToRemove) {
      const accordion = accordionBtn(componentToRemove.componentClass);
      _setUnsavedChanges();
      dispatch({
        type: "REMOVE_FROM_COMPONENTS",
        payload: componentToRemove,
      });
      // reset the error state of deleted component
      setErrorState((prevState) => ({
        ...prevState,
        [componentToRemove.componentClass]: false,
      }));
      deleteModalRef?.current?.closeModal();
      // Collapse the component accordion if it's expanded
      if (accordion?.getAttribute("aria-expanded") === "true") {
        accordion?.click();
      }
    }
  };

  function initErrorState() {
    return Object.keys(PRODUCT_CLASS_NAME_WITH_COMPONENT).reduce<
      Record<string, boolean>
    >((acc, compClass) => {
      acc[compClass] = false;
      return acc;
    }, {});
  }

  async function saveAndExit() {
    const compClassKeys = Object.keys(state.prodComponents);
    const compsWithError: string[] = [];
    const productData = deepCopy(productDetails)!;
    const updatedErrorMap = { ...compErrorState };
    if (!unsavedChangesConfig?.hasUnsavedChanges) {
      navigateToProdSummary();
      return;
    }
    try {
      // Show error if any of the component is not defined
      for (const compClass in state.prodComponents) {
        const accordion = accordionBtn(compClass);
        const component = state.prodComponents[compClass];
        if (!component.componentName || component.version < 1) {
          updatedErrorMap[compClass] = true;
          showToast(
            `component-not-defined-${compClass}`,
            `${PRODUCT_CLASS_NAME_WITH_COMPONENT[compClass]} not defined, select from an existing, or create a new component`,
            "error"
          );
          // Open the component accordion if it's closed
          if (accordion?.getAttribute("aria-expanded") === "false") {
            accordion?.click();
          }
        }
      }
      setErrorState(updatedErrorMap);
      // If any component has error, bail out from calling any create/update apis
      if (Object.values(updatedErrorMap).some((isError) => isError)) {
        return;
      }

      const componentCreatePromises = compClassKeys.map((compClass) => {
        const component = state.prodComponents[compClass];
        return !component.isExisting
          ? createComponent({
              url: `/${compClass}`,
              body: JSON.stringify({
                componentName: component.componentName,
                version: component.version,
              }),
            }).catch((err) => {
              const errMsg =
                err?.errors?.[0]?.errorDesc ||
                `Unable to create component ${component.componentName}`;
              setErrorState((prevState) => ({
                ...prevState,
                [compClass]: true,
              }));
              showToast(
                `error-creating-component-${compClass}`,
                errMsg,
                "error"
              );
              compsWithError.push(compClass);
              throw err;
            })
          : null;
      });
      await Promise.allSettled(componentCreatePromises.filter(Boolean)).then(
        (values) => {
          if (values.some((val) => val.status === "rejected")) {
            throw "Error creating component";
          }
        }
      );

      // Attach created components to the product
      productData.components = Object.values(state.prodComponents);
      updateExistingProduct(productData);
    } catch (err) {
      console.error(err);
      // Update product with only the components that were successfully created
      productData.components = Object.values(state.prodComponents).filter(
        (comp) => !compsWithError.includes(comp.componentClass)
      );
      updateExistingProduct(productData, true);
    }
  }

  function updateExistingProduct(
    updatedProductData: ProductType,
    withError?: boolean
  ) {
    updateProductInSummary(updatedProductData)
      .then(async (res: ProductType) => {
        if (res.name) {
          updateWorkflow(updatedProductData, withError).then(() => {
            showToast(
              "updated-additional-feature-success",
              "Updated the product successfully",
              "success"
            );
            clearUnsavedChanges();
            // refetch the product
            setProductRefetchState(true);
          });
        }
      })
      .catch((err) => {
        const errMsg =
          err?.errors?.[0]?.errorDesc || "Unable to update the product";
        showToast("updated-additional-feature-error", errMsg, "error");
      });
  }

  async function updateWorkflow(
    updatedProductData: ProductType,
    withError?: boolean
  ) {
    const updatedComponents = Object.values(state.prodComponents).filter(
      (comp) => {
        const foundComp = productDetails!.components?.find(
          (currentComp) =>
            `${currentComp.componentName}-${currentComp.componentClass}` ===
            `${comp.componentName}-${comp.componentClass}`
        );
        return !Boolean(foundComp);
      }
    );

    // Components that were removed
    const removedComponents = productDetails!.components?.filter((comp) => {
      const foundComp = updatedProductData.components?.find(
        (updatedComp) =>
          `${updatedComp.componentName}-${updatedComp.componentClass}` ===
          `${comp.componentName}-${comp.componentClass}`
      );
      return !Boolean(foundComp);
    });

    if (productWorkflowData?.length) {
      // Delete workflows for removed components
      await Promise.all(
        removedComponents?.map((component) => {
          deleteWorkflowStage({
            productName: updatedProductData.name,
            stage: component.componentClass,
          });
        }) ?? []
      );
      // Create workflows for newly added components,
      await Promise.all(
        updatedComponents.map(({ componentClass, isExisting }) => {
          createWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.product,
            key: updatedProductData.name,
            stage: componentClass,
            status: isExisting
              ? DASHBOARD_ACCORDION_STATUS.completed
              : GENERAL_SETUP_STAGE_NAME_MAP[componentClass],
          });
        })
      );
      if (!withError) {
        navigateToProdSummary();
      }
    } else if (updatedComponents.length) {
      createWorkflowForNewComponents(updatedProductData, withError);
      return;
    }
  }

  /**
   *
   * @param updatedProductData
   * For completed products there is no workflow data, creating in-progress workflows for net new components,
   * and completed workflow statuses for rest of them (existing components and product attributes)
   */
  async function createWorkflowForNewComponents(
    updatedProductData: ProductType,
    withError?: boolean
  ) {
    const updatedComponents = Object.values(state.prodComponents);

    const prodAttributeData = getCompleteAttributeList(
      updatedProductData.ifxAcctType!
    );
    prodAttributeData.forEach((attributeData) => {
      // set workflow as completed for all product attribute steps
      attributeData.status = DASHBOARD_ACCORDION_STATUS.completed;
    });

    updatedProductData.components?.forEach((item) => {
      const updatedComp = updatedComponents.find(
        (upComp) => upComp.componentClass === item.componentClass
      );
      // set workflow as completed for existing components added to the product
      item.status = DASHBOARD_ACCORDION_STATUS.completed;
      // If it's a net new component, set workflow status as the general setup step
      if (!updatedComp?.isExisting) {
        item.status = GENERAL_SETUP_STAGE_NAME_MAP[item.componentClass];
      }
    });

    const componentList = [
      ...(updatedProductData.components ?? []),
      ...prodAttributeData,
    ];

    Promise.all(
      componentList.map(async (component) => {
        await createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: updatedProductData.name,
          stage: component.componentClass,
          status: component.status!,
        });
      })
    )
      .then(() => {
        if (!withError) {
          navigateToProdSummary();
        }
      })
      .catch((err) => {
        const errMsg =
          err?.errors?.[0]?.errorDesc ||
          "Unable to create workflow data, please try again.";
        showToast("error-creating-workflows", errMsg, "error");
      });
  }

  function navigateToProdSummary() {
    setErrorState(initErrorState());
    router.push(PRODUCT_SUMMARY_PATH);
  }

  function showToast(toastId: string, msg: string, status: AlertStatus) {
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        description: msg,
        status: status,
        duration: 5000,
      });
    }
  }

  async function initAdditionalFeature(prodDetails: ProductType) {
    // Set the recommended components for the product type
    if (prodDetails.ifxAcctType) {
      const featureList = RECOMENDED_PRODUCT_FEATURES[prodDetails.ifxAcctType];
      setRecommendedFeatureList(featureList);
    }
    // Set components map for the product
    if (prodDetails.components?.length) {
      const componentMap = prodDetails.components.reduce<
        Record<string, AddedComponentTypeValue>
      >((acc, comp) => {
        acc[comp.componentClass] = {
          componentName: comp.componentName!,
          componentClass: comp.componentClass,
          version: comp.version!,
          isExisting: true,
        };
        return acc;
      }, {});

      // Update map with component data for any components with errors
      for (const compClass in state.prodComponents) {
        const component = state.prodComponents[compClass];
        if (compErrorState[compClass]) {
          componentMap[compClass] = {
            ...component,
          };
        }
      }
      dispatch({
        type: "SET_PRODUCT_COMPONENTS",
        payload: {
          data: componentMap,
        },
      });
    }
  }

  const getRecomProdTooltipDesc = (componentClass: string) => {
    const products =
      productDetails?.prodSubType === IFX_PRODUCT_SUB_TYPE_VAL.personal
        ? PRODUCT_CATALOG.retailProducts
        : PRODUCT_CATALOG.commercialProducts;

    const product = products.productList.find(
      (item) =>
        item.ifxAcctType === productDetails?.ifxAcctType &&
        item.prodSubType === productDetails?.prodSubType
    );

    return product?.componentConfigurationList.find(
      (comp: any) => comp.componentClass === componentClass
    )?.configDesc;
  };

  const _setUnsavedChanges = () => {
    if (!unsavedChangesConfig?.hasUnsavedChanges) {
      setUnsavedChanges({ hasUnsavedChanges: true, withoutSave: true });
    }
  };

  useEffect(() => {
    if (productDetails) {
      initAdditionalFeature(productDetails);
    }
  }, [productDetails]);

  const _handleChange =
    (compClass: string) => (value: AddedComponentType | null) => {
      _setUnsavedChanges();
      dispatch({
        type: "UPDATE_COMPONENT",
        payload: {
          addedComponent: value ?? {
            componentClass: compClass,
            componentName: "",
          },
        },
      });
      // Reset error state for a component if all required fields are populated
      if (value?.componentName && value.version > 0) {
        setErrorState((prevState) => ({ ...prevState, [compClass]: false }));
      }
    };

  return (
    <>
      <Box className="notification-banner-wrapper-add-features">
        <BannerNotification />
      </Box>
      <Box className="additional-features-outer-container">
        <TopBannerContainer domId="#consoleTopBanner" backgroundImage={true}>
          <div className="title-container">
            <div className="title">
              <WrappedLink href={PRODUCT_SUMMARY_PATH}>
                <ArrowBackIcon
                  width="2.4rem"
                  height="2.4rem"
                  aria-label="back-arrow"
                />
              </WrappedLink>
              <Text ml={4} as={"span"}>
                Add components
              </Text>
            </div>
          </div>
        </TopBannerContainer>
        <div className="add-features-container">
          {/* Card container will render two JSX format data i.e "featureListing" and "featureDetails" */}
          <CardContainer customClass="features-listing">
            <Box>
              <Text as="h3">Select components for your product</Text>
              <Text>
                You can reuse an existing component from your component library
                or select a component template to create a new component for
                your product.
              </Text>

              <Box>
                <Text as="h6" className="features-list">
                  Component
                </Text>
                <Accordion allowMultiple>
                  {state.allComponentsForProductType.map((component) => {
                    const addedComponent =
                      state.prodComponents[component.componentClass];
                    const isComponentAdded = Boolean(addedComponent);
                    return (
                      <AccordionItem
                        className="fx-accordion"
                        key={component.componentName}
                        id={component.componentClass}
                      >
                        <AccordionButton
                          className="feature-container flex-acenter"
                          onClick={(e) => {
                            if (
                              !isComponentAdded &&
                              // @ts-ignore
                              e.target.id === e.currentTarget.id
                            ) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }}
                          sx={{
                            cursor: isComponentAdded ? "pointer" : "default",
                          }}
                        >
                          <div className="feature-title">
                            {isComponentAdded && (
                              <AccordionIcon
                                className="fx-accordion-icon"
                                width={20}
                                height={20}
                              />
                            )}
                            <Box
                              as="span"
                              flex="1"
                              textAlign="left"
                              className="feature-name"
                            >
                              {component.componentName}
                            </Box>
                          </div>
                          {isComponentAdded ? (
                            <Box
                              flex="1"
                              textAlign="right"
                              className="btn-added-feature"
                            >
                              <CheckIcon width={10} height={10} />
                              <span className="btn-added">Selected</span>
                            </Box>
                          ) : (
                            <Box
                              as="button"
                              flex="1"
                              textAlign="right"
                              className="btn-add-feature"
                              onClick={() => {
                                _setUnsavedChanges();
                                dispatch({
                                  type: "ADD_TO_COMPONENTS",
                                  payload: {
                                    addedComponent: {
                                      componentClass: component.componentClass,
                                      componentName: "",
                                    },
                                  },
                                });
                              }}
                            >
                              <AddIcon width={10} height={10} />
                              <span>Add</span>
                            </Box>
                          )}
                        </AccordionButton>
                        <AccordionPanel
                          pb={4}
                          className="accordian-description"
                        >
                          <Flex direction="column" rowGap={5}>
                            <AppFormLabel
                              labelName="Would you like to use an existing component or create a new one?"
                              labelFor="add-component"
                            />
                            <AddComponentInput
                              value={
                                state.prodComponents[component.componentClass]
                              }
                              onChange={_handleChange(component.componentClass)}
                              error={compErrorState[component.componentClass]}
                              componentMapOptions={
                                data[component.componentClass]
                              }
                              componentClass={component.componentClass}
                            />
                          </Flex>
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </Box>
            </Box>
          </CardContainer>
          <div className="added-features-details">
            {recommendedFeatureList?.length && productDetails?.name ? (
              <div className="required-features">
                <Text as="h3">{productDetails.name}</Text>
                <Text as="h5">Recommended components</Text>
                <div className="required-features-listing">
                  {recommendedFeatureList.map((feature, index) => (
                    <div key={`recommendedFeature${index}`} className="flex">
                      <CheckIcon width="2rem" height="1.5rem" />
                      <AppFormLabel
                        labelName={getConstructedFeatureNameWithComponent(
                          feature.componentClass,
                          getIfxProdType(productDetails)
                        )}
                        tooltipDesc={getRecomProdTooltipDesc(
                          feature.componentClass
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="added-features-container">
              <Text as="h5">Additional components</Text>
              <div className="desc">
                The following components are being added to your product:
              </div>
              <div className="added-features">
                {Object.keys(state.prodComponents).map((compClass) => {
                  const component = state.prodComponents[compClass];
                  return (
                    <Flex gap={2} className="feature" key={compClass}>
                      <Box className="feature-name">
                        {component.componentName ||
                          PRODUCT_CLASS_NAME_WITH_COMPONENT[
                            component.componentClass
                          ]}
                      </Box>
                      <div
                        onClick={() => showModal(component)}
                        className="action cp"
                      >
                        <HoverToolTip placement="top" label="Remove component">
                          <Image src={binIcon.src} alt="Delete" />
                        </HoverToolTip>
                      </div>
                    </Flex>
                  );
                })}
              </div>
              {!Object.keys(state.prodComponents).length && (
                <Box className="no-feature-added">No new components added</Box>
              )}
            </div>
          </div>
        </div>
        <AppPortal domId="#appFooter">
          <Box className="configured-product-footer">
            <ButtonGroup className="configured-product-btn-container footer-btn-configuration">
              <Button
                onClick={saveAndExit}
                className={"app-btn-inverse-secondary-add-new"}
                disabled={!unsavedChangesConfig?.hasUnsavedChanges}
              >
                Save & return
              </Button>
            </ButtonGroup>
          </Box>
        </AppPortal>

        <AppModal
          customClass="frequency-module-modal-selector"
          ref={deleteModalRef}
          modalTitle="Are you sure you want to remove this component from your product?"
          primaryBtnProp={{
            name: "Confirm",
            btnClassName: "app-btn-inverse-secondary",
          }}
          primaryBtnSelect={onClickSubmit}
          secondaryBtnProp={{
            name: "Cancel",
            btnClassName: "app-btn-reg-secondary",
            style: { marginRight: "var(--chakra-sizes-4)" },
            enableCloseBtn: true,
          }}
        >
          <div className="code-input">
            <StaticText textValue="Any customizations will be lost." />
          </div>

          <Divider width="100%" className="bottom-divider" />
        </AppModal>
      </Box>
    </>
  );
};
export default AdditionalFeatures;
