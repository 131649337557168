import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Nsft_transaction-code-specifications-setup
interface TransactionCodeSpecificationsProps {
  trnCodeExcl: string[];
}
// Defining Intitial Value For Nsft_transaction-code-specifications-setup
const transactionCodeSpecificationsDetails: TransactionCodeSpecificationsProps =
  {
    trnCodeExcl: [],
  };
// YUP LIBRARY :Defining Schema For validation of Nsft_transaction-code-specifications-setup
const transactionCodeSpecificationsDetailsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["trnCodeExcl"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { TransactionCodeSpecificationsProps };
// Exporting Validation Schema and Intial value
export {
  transactionCodeSpecificationsDetails,
  transactionCodeSpecificationsDetailsSchema as getValidationSchema,
};
