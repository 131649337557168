import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { additionalAttributesZeroBalanceDaysError } from "@/components/data/error-data";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { limitConfig } from "@/components/institutional-configuration/account-types/add-limit/AddLimitValidation";
import { AccumulatedLimitDetailsProps } from "@/components/institutional-configuration/model";
import { deepCopy } from "@finxact/finxact-shared-ui";
import * as Yup from "yup";

export interface AccumulatedLimitDefaultFormProps
  extends AccumulatedLimitDetailsProps {
  idx?: number | string;
  selectLimitType?: string;
  expTime?: string;
  feeForViolationOption?: string;
  feeViolated?: SplitBtnInputValType;
  alertId?: string;
  needTempLimits?: SplitBtnInputValType;
}

export interface AccumulatedLimitNewFormProps
  extends AccumulatedLimitDefaultFormProps {
  temporaryLimit: AccumulatedLimitDefaultFormProps;
}

export const accumulatedNewLimits: AccumulatedLimitNewFormProps = {
  ...limitConfig,
  needTempLimits: undefined,
  temporaryLimit: deepCopy({ ...limitConfig, expDtm: "", expTime: "" }),
};

const tempLimitSchema = Yup.array(
  Yup.object().shape({
    statGroup: Yup.string().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) => schema.required(ERROR_MESSAGE),
    }),
    name: Yup.string().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) => schema.required(ERROR_MESSAGE),
    }),
    definedBy: Yup.string().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) => schema.required(ERROR_MESSAGE),
    }),
    expDtm: Yup.string().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) => schema.required(ERROR_MESSAGE),
    }),
    expTime: Yup.string().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) => schema.required(ERROR_MESSAGE),
    }),
    crAmt: Yup.number().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) => schema.required(ERROR_MESSAGE).min(0, ERROR_MESSAGE),
    }),
    crCnt: Yup.number().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) =>
        schema
          .required(ERROR_MESSAGE)
          .min(0, ERROR_MESSAGE)
          .max(65535, additionalAttributesZeroBalanceDaysError.max),
    }),
    drAmt: Yup.number().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) => schema.required(ERROR_MESSAGE).min(0, ERROR_MESSAGE),
    }),
    drCnt: Yup.number().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) =>
        schema
          .required(ERROR_MESSAGE)
          .min(0, ERROR_MESSAGE)
          .max(65535, additionalAttributesZeroBalanceDaysError.max),
    }),
    totAmt: Yup.number().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) => schema.required(ERROR_MESSAGE).min(0, ERROR_MESSAGE),
    }),
    totCnt: Yup.number().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) =>
        schema
          .required(ERROR_MESSAGE)
          .min(0, ERROR_MESSAGE)
          .max(65535, additionalAttributesZeroBalanceDaysError.max),
    }),
    violationAct: Yup.string().when("needTempLimits", {
      //dropdown
      is: "selectLeft",
      then: (schema) => schema.required(ERROR_MESSAGE),
    }),
    feeViolated: Yup.string().when("needTempLimits", {
      is: "selectLeft",
      then: (schema) => schema.required(ERROR_MESSAGE),
    }),
    violationFee: Yup.string().when("feeViolated", {
      is: "selectLeft",
      then: (schema) => schema.required(ERROR_MESSAGE),
    }),
    feeForViolationOption: Yup.string().when("feeForViolation", {
      is: "PRIMARY",
      then: (schema) => schema.required(ERROR_MESSAGE),
    }),
  })
);

const needTempLimits = Yup.array(
  Yup.object().shape({
    needTempLimits: Yup.boolean(),
  })
);

const schema = tempLimitSchema.concat(needTempLimits);

export const limitConfigSchema = Yup.object().shape({});
