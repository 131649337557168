"use client";
import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  ErrorAlert,
  ProductTitle,
  StaticText,
} from "@/components/common";
import SplitButton, {
  SplitBtnInputValType,
} from "@/components/common/split-button/SplitButton";
import { Box, CardBody } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { RegulatoryAdherenceLimitationFormProps } from "../regulatory-adherence-limitation-wrapper/RegulatoryAdherenceLimitationValidation";
import { RegAdhLimProps } from "../regulatory-adherence-limitation-wrapper/RegulatoryAdherenceLimitationWrapper";
import { updateProduct, updateWorkflowGeneric } from "@/api-config/api-service";
import { useFormik } from "formik";
import { ProductType } from "@/components/product-management/model/product-models";
import {
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS,
  DEFAULT_CONSTS,
  IFX_ACCT_PRODUCT_TYPE,
  IFX_PRODUCT_TYPE_VAL,
} from "@/utils/constants";
import { PRODUCT_STATUS } from "@/components/data/form-data";
import { ROUTE_PATH } from "@/route-config/route-path";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useToast } from "@/components/ChakraUiManager";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

const RevolvingInstallmentRegulatoryAdherenceLimitation = (
  props: RegAdhLimProps
) => {
  const toast = useToast();
  const { formData } = props.data;

  const handleRegulatoryAdherenceData =
    (key: string) => (value: string | SplitBtnInputValType) => {
      if (key === "maxLvtPercentageButton" && !value) {
        setFieldValue("maxLtvPct", "");
        setFieldValue(key, value);
      } else {
        setFieldValue(key, value);
      }
    };

  // Update product
  const onSubmit = async (values: RegulatoryAdherenceLimitationFormProps) => {
    if (!dirty) {
      routeToNextPage();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage:
            ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS[
              "regulatoryAdherenceLimitations"
            ],
          status: PRODUCT_STATUS.completed,
        });
      return;
    }

    if (formData) {
      const res = await updateProduct({
        productName: formData.name,
        body: JSON.stringify({
          // NOTE: _vn is required to do a PATCH update
          _vn: formData?._vn,
          // PATCH fields
          maxLtvPct: values.maxLtvPct ? values.maxLtvPct : null,
          isFedExempt: values.isFedExempt,
          isStateExempt: values.isStateExempt,
          isNraExempt: values.isNraExempt,
        }),
      });
      if (res && productDetails?.name) {
        if (!toast.isActive("regulatory-adherence-limitations")) {
          toast({
            id: "regulatory-adherence-limitations",
            description: `${configPageTitle} updated.`,
            status: "success",
          });
        }
        getExistingProduct?.(productDetails.name, true);
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails.name,
          stage:
            ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS[
              "regulatoryAdherenceLimitations"
            ],
          status: PRODUCT_STATUS.completed,
        });
        routeToNextPage();
      }
    }
  };

  function routeToNextPage() {
    if (
      IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType! || ""] ===
      IFX_PRODUCT_TYPE_VAL.loan
    ) {
      navigateToNextPage?.();
    } else {
      // reset checkFormStatus
      checkFormStatus?.(false);
      navigateWithProductNameParam(
        `${ROUTE_PATH["PRODUCT_CONFIG_INTERSTITIAL_SCREEN"]}`
      );
    }
  }

  const formik = useFormik({
    onSubmit,
    validationSchema: props.data.prodBkOptionsSchema,
    initialValues: {
      maxLvtPercentageButton:
        formData?.isFedExempt !== undefined
          ? Boolean(formData?.maxLtvPct)
          : undefined,
      maxLtvPct: formData?.maxLtvPct?.toString() ?? "",
      isFedExempt: formData?.isFedExempt ?? undefined,
      isStateExempt: formData?.isStateExempt ?? undefined,
      isNraExempt: formData?.isNraExempt ?? undefined,
    },
  });
  const { values, handleSubmit, errors, touched, setFieldValue, dirty } =
    formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    navigateWithProductNameParam,
    tooltipFlyoutDetails,
    getWorflowStatusToBeUpdated,
    checkFormStatus,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <CardContainer>
        <Box className="app-form-field-container">
          <StaticText
            className="additional-description-area"
            textValue={
              "Determine the regulatory rules the product must follow and set specific constraints to ensure your product remains compliant"
            }
          />
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel labelName="Add a maximum LTV percentage?" />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.maxLvtPercentageButton}
            onSelect={handleRegulatoryAdherenceData("maxLvtPercentageButton")}
          />
          {errors.maxLvtPercentageButton && touched.maxLvtPercentageButton && (
            <ErrorAlert>
              <span>{errors.maxLvtPercentageButton}</span>
            </ErrorAlert>
          )}
        </Box>
        {values.maxLvtPercentageButton && (
          <Box className="app-form-field-container">
            <AppNumberInput
              value={
                values.maxLtvPct === undefined ||
                values.maxLtvPct === "undefined"
                  ? "0%"
                  : values.maxLtvPct
              }
              onChange={handleRegulatoryAdherenceData("maxLtvPct")}
              labelName="What is the maximum LTV percentage?"
              placeholder={DEFAULT_CONSTS.percentage0}
              valueSuffix="%"
            />
            {errors.maxLtvPct && touched.maxLtvPct && (
              <ErrorAlert>
                <span>{errors.maxLtvPct}</span>
              </ErrorAlert>
            )}
          </Box>
        )}
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is federal withholding exempt?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isFedExempt")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isFedExempt}
            onSelect={handleRegulatoryAdherenceData("isFedExempt")}
          />
          {errors.isFedExempt && touched.isFedExempt && (
            <ErrorAlert>
              <span>{errors.isFedExempt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is state withholding exempt?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isStateExempt")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isStateExempt}
            onSelect={handleRegulatoryAdherenceData("isStateExempt")}
          />
          {errors.isStateExempt && touched.isStateExempt && (
            <ErrorAlert>
              <span>{errors.isStateExempt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is this an NRA withholding exempt product?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isNraExempt")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isNraExempt}
            onSelect={handleRegulatoryAdherenceData("isNraExempt")}
          />
          {errors.isNraExempt && touched.isNraExempt && (
            <ErrorAlert>
              <span>{errors.isNraExempt}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default RevolvingInstallmentRegulatoryAdherenceLimitation;
