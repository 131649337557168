import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";

import * as Yup from "yup";

//Defining Interface For Index_Rate_Review_Frequency
interface ReviewFrequencyProps {
  index: {
    isReviewDly: SplitBtnInputValType;
    reviewFreq: string;
    indexReviewDate: Date | null;
    indexReviewTime: string;
  };
}
// Defining Intitial Value For Index_Rate_Review_Frequency
const reviewFrequency: ReviewFrequencyProps = {
  index: {
    isReviewDly: undefined,
    reviewFreq: "",
    indexReviewDate: null,
    indexReviewTime: "",
  },
};
// YUP LIBRARY :Defining Schema For validation of Index_Rate_Review_Frequency
const reviewFrequencySchema = async (optionsSchema: PostSchema) => {
  const fields = ["index"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { ReviewFrequencyProps };
// Exporting Validation Schema and Intial value
export { reviewFrequency, reviewFrequencySchema as getValidationSchema };
