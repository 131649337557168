import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";
//Defining Interface For Tax_Specifications
interface TaxSpecificationsProps {
  tinType: number | undefined;
  tin: string;
}
// Defining Intitial Value For Tax_Specifications
const taxSpecificationsInformation: TaxSpecificationsProps = {
  tinType: undefined,
  tin: "",
};
// YUP LIBRARY :Defining Schema For validation of Tax_Specifications
const taxSpecificationsSchema= async (optionsSchema: PostSchema) => {
  const fields = ["tinType"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { TaxSpecificationsProps };
// Exporting Validation Schema and Intial value
export {
  taxSpecificationsInformation,
  taxSpecificationsSchema as getValidationSchema,
};
