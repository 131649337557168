"use client";

import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  SplitButton,
} from "@/components/common";
import "./AdvancedConfigurations.scss";
import { useFormik } from "formik";
import {
  AdvancedConfigProps,
  defaultAdvancedConfig,
  getValidationSchema,
} from "./AdvancedConfigurationsValidations";
import { Box, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { AccountGroupData } from "../../model";
import { updateAccountGroup } from "../../ic-config-api-service";
import { ROUTE_PATH } from "@/route-config/route-path";
import { useSearchParams } from "next/navigation";
import { IC_ENTITY } from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { shouldUpdateWorkflowStatus } from "@/utils/common";

type AdvancedConfigurationProps = {
  data: {
    formData: AccountGroupData | null;
    accountGroupOptionsData: PostSchema;
  };
};

const AdvancedConfigurations = (props: AdvancedConfigurationProps) => {
  const toast = useToast();
  const advancedConfig = props.data?.formData ?? null;
  const params = useSearchParams();

  const { accountGroupOptionsData } = props.data;

  const moveToNextStep = (paramsArg: string) => {
    if (!canCheckFormStatus) {
      if (params?.get("initialFlow")) {
        navigateTo(ROUTE_PATH.IC_INTERSTITIAL_SCREEN);
      } else {
        navigateTo(
          `${ROUTE_PATH.IC_COMPLETION_SCREEN}?entityId=${paramsArg}&entityType=${IC_ENTITY.account_group}`
        );
      }
    }
  };

  const onSubmit = async (values: AdvancedConfigProps, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?.acctGroup!,
          stage: IC_ENTITY.account_group,
          status: currentStage,
        });
      }
      moveToNextStep(`${props.data.formData?.acctGroup}`);
      return;
    }
    const res = await updateAccountGroup({
      formData: {
        _vn: props.data.formData?._vn,
        acctGroup: props.data.formData?.acctGroup,
        // PATCH Feilds
        isOffBalSheet: values.isOffBalSheet,
        isRtPosnUpd: values.isRtPosnUpd,
        isChkPosn: values.isChkPosn,
      },
      toastOptions: {
        toast: toast,
        toastId: "advanced-configurations",
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (res) {
      moveToNextStep(`${props.data.formData?.acctGroup}`);
    }
  };
  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(accountGroupOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [accountGroupOptionsData]);
  // using useFormik hook from Formik Library
  const { values, handleSubmit, errors, touched, dirty, setFieldValue } =
    useFormik({
      onSubmit,
      validationSchema,
      initialValues: advancedConfig
        ? {
            isOffBalSheet: advancedConfig?.isOffBalSheet ?? undefined,
            isRtPosnUpd: advancedConfig?.isRtPosnUpd ?? undefined,
            isChkPosn: advancedConfig?.isChkPosn ?? undefined,
          }
        : defaultAdvancedConfig,
    });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Check position?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isChkPosn")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="isChkPosn"
            onSelect={handleOnChange("isChkPosn")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isChkPosn}
          />
          {errors.isChkPosn && touched.isChkPosn && (
            <ErrorAlert>
              <span>{errors.isChkPosn}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Off balance sheet?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("offBalanceSheet")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="isOffBalSheet"
            onSelect={handleOnChange("isOffBalSheet")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isOffBalSheet}
          />
          {errors.isOffBalSheet && touched.isOffBalSheet && (
            <ErrorAlert>
              <span>{errors.isOffBalSheet}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Do you want real-time updates to the account balances?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("realtimeUpdate")
                ?.tooltip_text_main
            }
            isRequired
          />
          <SplitButton
            name="randomAccntNumberGeneration"
            onSelect={handleOnChange("isRtPosnUpd")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isRtPosnUpd}
          />
          {errors.isRtPosnUpd && touched.isRtPosnUpd && (
            <ErrorAlert>
              <span>{errors.isRtPosnUpd}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default AdvancedConfigurations;
