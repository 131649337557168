"use client";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { CardContainer } from "@/components/common";
import TeamTab from "./team-tab/TeamTab";
import ProfileTemplateTab from "./profile-template-tab/ProfileTemplateTab";
import CustomRolesTab from "./custom-roles-tab/CustomRolesTab";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useRouter, useSearchParams } from "next/navigation";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { TABS, TAB_NAMES } from "@/utils/constants";

interface Props {
  data: {
    formData: {
      accounts: any;
      profileTemplates: any;
      roles: any;
    };
  };
}

const AccessManagementLandingPage = (props: Props) => {
  const router = useRouter();
  const params = useSearchParams();
  const getCurrentTabVal = params?.get("tab") as string;
  const tab =
    TABS.indexOf(getCurrentTabVal) < 0 ? TAB_NAMES.team : getCurrentTabVal;
  const { isSaveDisabled, onSaveDisabled } =
    useContext<ProductConfigurationContextType>(productConfigurationContext);
  const tabNameIndex =
    TABS.indexOf(getCurrentTabVal) > -1 ? TABS.indexOf(getCurrentTabVal) : 0;
  const {
    data: { formData },
  } = props;
  const [tabIndex, setTabIndex] = useState<number>(tabNameIndex);

  useEffect(() => {
    if (isSaveDisabled) {
      onSaveDisabled?.(false);
    }
  }, [isSaveDisabled]);
  return (
    <CardContainer customClass="access-management-table-container">
      <Tabs
        index={tabIndex}
        isManual
        isLazy={true}
        lazyBehavior={"keepMounted"}
      >
        <TabList mb="3.8rem">
          <Tab
            onClick={() => {
              setTabIndex(0),
                router.push(
                  `${ROUTE_BASE_URL.ACCESS_MANAGEMENT}?tab=${TAB_NAMES.team}`
                );
            }}
          >
            Team
          </Tab>
          <Tab
            onClick={() => {
              setTabIndex(1),
                router.push(
                  `${ROUTE_BASE_URL.ACCESS_MANAGEMENT}?tab=${TAB_NAMES.profileTemplates}`
                );
            }}
          >
            Profile templates
          </Tab>
          <Tab
            onClick={() => {
              setTabIndex(2),
                router.push(
                  `${ROUTE_BASE_URL.ACCESS_MANAGEMENT}?tab=${TAB_NAMES.roles}`
                );
            }}
          >
            Custom roles
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TeamTab tableData={formData?.accounts} />
          </TabPanel>
          <TabPanel>
            <ProfileTemplateTab tableData={formData?.profileTemplates} />
          </TabPanel>
          <TabPanel>
            <CustomRolesTab tableData={formData?.roles} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </CardContainer>
  );
};

export default AccessManagementLandingPage;
