"use client";

import { useEffect, useState } from "react";
import { MultiTieredSideMenuDataModel } from "../common/MultiTieredSideMenu/MultiTieredSideMenuDataModel";
import { useSelector } from "react-redux";
import {
  getAppConfig,
  getEnv,
  getEnvName,
  getEnvStatus,
  getSelectedOrg,
} from "@/store";
import { usePathname } from "next/navigation";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { Menu } from "@finxact/finxact-shared-ui";
import { deepCopy, useIsPermitted } from "@/utils/common";
import Link from "next/link";
import { getProj } from "@/store/slices/project";
import "../../styles/color-variables.scss";

const GlobalSideMenu = () => {
  const pathName: any = usePathname()?.replace(/\/$/, "") ?? null;
  const isPermitted = useIsPermitted();
  const appConfig = useSelector(getAppConfig);
  const organization = useSelector(getSelectedOrg);
  const { envId, envType, imageTag, version, endpoint, internal } =
    useSelector(getEnv);
  const project = useSelector(getProj);
  const envName = useSelector(getEnvName);
  const envStatus = useSelector(getEnvStatus);
  const [envData, setEnvData] = useState<any>();

  useEffect(() => {
    const currentEnv = envStatus.find((env) => env.id === envId);
    const sliceVerIndex = version ? version.indexOf("-") : -1;
    const minifiedVersion =
      sliceVerIndex >= 0 ? version?.substring(0, sliceVerIndex) : null;
    setEnvData(
      deepCopy({
        name: envName,
        version: minifiedVersion,
        serviceEndpoint: currentEnv?.serviceEndpoint ?? "",
      })
    );
  }, [envStatus, envId, envName, version]);

  const handleSelection = (
    locData: MultiTieredSideMenuDataModel[],
    selectedUID: string | null
  ) => {
    locData.map((item: MultiTieredSideMenuDataModel) => {
      if (item.uid === selectedUID) {
        item.isSelected = true;
      } else {
        handleSelection(item.childrens, selectedUID);
        item.isSelected = item.childrens.filter((i) => i.isSelected).length > 0;
      }
    });
    return locData;
  };

  const env =
    envType === "env" && envId
      ? {
          id: envId,
          name: envName,
          endpoint,
          internal,
        }
      : undefined;

  const proj =
    envType === "proj" && envId
      ? {
          id: envId,
          name: envName,
          status: project?.status,
        }
      : undefined;

  return (
    <Menu
      consoleConfig={{
        prodEnv: appConfig?.prodEnv ?? false,
        releaseCalendarConfigured:
          appConfig?.releaseCalendarConfigured ?? false,
      }}
      coreVersion={envData?.version}
      environment={env}
      imageTag={imageTag ?? undefined}
      organization={{
        id: organization.id,
        name: organization.name,
        implRepoURL: organization.implRepoURL,
        serviceTicketURL: organization.serviceTicketURL,
        implementationUrl: organization.implementationUrl,
      }}
      isPermitted={isPermitted}
      location={pathName}
      parent="fast"
      project={proj}
      serviceEndpoint={envData?.serviceEndpoint}
      nextRouterLinkComponent={Link}
    />
  );
};

export default GlobalSideMenu;
