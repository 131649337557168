"use client";

import { CustomTable } from "../../../common";
import { Box, Button, Text } from "@chakra-ui/react";
import Image from "next/image";
import "./ProductLaunchPadQuickStartModal.scss";
import { LAUNCH_QUICK_START_KEY } from "../../../common/data/custom-table-constants";
import { chevRightIcon, chewRightWhiteIcon } from "public/assets";
import { ProductType } from "../..";
import { useContext } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { ROUTE_PATH } from "@/route-config/route-path";
import { QUICKSTART_CONFIG_TABLE_HEADER_COLUMNS } from "@/components/data/form-data";
import { useQueryParams } from "@/utils/common";

type ProductLaunchPadQuickStartModalProps = {
  productName: string;
  tableDataList: ProductType[];
};
const ProductLaunchPadQuickStartModal = (
  props: ProductLaunchPadQuickStartModalProps
) => {
  const { productName, tableDataList } = props;
  //context API
  const { setProductDetails, setSummaryQuickStartFlag, navigateTo } =
    useContext<ProductConfigurationContextType>(productConfigurationContext);
  const bespokeRouteUrl = ROUTE_PATH["PRODUCT_WALKTHROUGH_FULLPATH"];
  const updateQueryParams = useQueryParams();

  const renderDataCell = (value: any, key: string, product: ProductType) => {
    switch (key) {
      case LAUNCH_QUICK_START_KEY: {
        return (
          <Button
            onClick={() => navigateToProductSummary(product)}
            className="app-btn-reg-secondary"
          >
            <Box mr={"0.8rem"}>Launch Quickstart</Box>
            <Image src={chevRightIcon} alt="right arrow" height={6} width={6} />
          </Button>
        );
      }
      default: {
        return value;
      }
    }
  };

  function navigateToProductSummary(product: ProductType) {
    const productName = product.name;
    setProductDetails?.({ ...product, name: "" });
    setSummaryQuickStartFlag?.(true);
    //Adding ifxAccType in query param for quick start flow
    if (product?.name) {
      navigateTo(
        `${bespokeRouteUrl}${ROUTE_PATH["PRODUCT_NAME_QUERY"]}${product.name}&${updateQueryParams()}`
      );
    } else {
      navigateTo(`${bespokeRouteUrl}?${updateQueryParams()}`);
    }
  }

  function launchBespoke() {
    setProductDetails?.(null);
    setSummaryQuickStartFlag?.(false);
    navigateTo(`${bespokeRouteUrl}?${updateQueryParams()}`);
  }

  return (
    <>
      <Box className="product-launchpad-start-confif-primary-container">
        <Text className="primary-heading">{`Build your own ${productName} product`}</Text>
        <Text className="sub-text">
          Customize every aspect from start to finish, creating a product unique
          to your institution
        </Text>
        <Button onClick={launchBespoke} className="app-btn-inverse-secondary">
          Launch Bespoke Configuration
          <Image
            src={chewRightWhiteIcon}
            alt="right arrow"
            width={16}
            height={15}
          />
        </Button>
      </Box>

      <Box mt={13} mb={1} className="product-launchpad-config-divider">
        <div className="line"></div>
        <Text>OR</Text>
        <div className="line"></div>
      </Box>
      <Box className="product-launchpad-secondary-container">
        <Text className="primary-heading">Quickstart configuration</Text>
        <Text className="sub-text">
          Begin with a pre-configured solution and refine it to your
          institutions needs
        </Text>
      </Box>
      <Box mt={1}>
        <CustomTable
          data={tableDataList}
          columns={QUICKSTART_CONFIG_TABLE_HEADER_COLUMNS}
          renderDataCell={renderDataCell}
          isPagination={true}
        />
      </Box>
    </>
  );
};

export default ProductLaunchPadQuickStartModal;
