import {
  Box,
  CheckCircleIcon,
  Flex,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  TimeIcon,
  Spacer,
  Button,
  Divider,
} from "@/components/ChakraUiManager";
import { BsCircle } from "react-icons/bs";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {
  Badges,
  CarouselButtonGroup,
  CommonTitle,
  ProgressBar,
} from "@/components/common";
import "./LearningProgress.scss";
import React, { useEffect, useState, useRef } from "react";
import {
  getLearnerObjectsByIds,
  getListData,
  getUserEnrollments,
} from "@/client-api-manager/alm-api";
import {
  LearningObjectDetails,
  HeaderData,
  LearningPathOrderData,
} from "@/models/academy-models";
import {
  ALM_ENROLLMENT_STATE,
  constructLearningObject,
  getBadgeByState,
  sortLearningPaths1,
} from "../../learning-util-service";
import { noCatalogFound, rightRotateYellow } from "public/assets";
import { formatDuration } from "@/utils/common";
import Image from "next/image";
import { ChevronRightIcon } from "@chakra-ui/icons";
import Link from "next/link";
import { ROUTE_PATH } from "@/route-config/route-path";
import styleVars from "../../../../styles/_export.module.scss";

const { fontSize2rem, fontSizeReg, fontSizeSmall } = styleVars;

export const LearningProgress = ({
  learningPathsOrder,
  headerData,
}: {
  learningPathsOrder: LearningPathOrderData[];
  headerData: HeaderData;
}) => {
  const [learningPaths, setLearningPaths] = useState<LearningObjectDetails[]>(
    []
  );
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [currentLearningPathCourses, setCurrentLearningPathCourses] = useState<
    LearningObjectDetails[]
  >([]);
  const currentLearningPath: LearningObjectDetails = learningPaths[activeIndex];
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const learningPathDataLoadedRef = useRef<boolean>(false);

  const getLearningPathsOrder = () => {
    return learningPathsOrder?.flatMap((item: any) => item.learningpathname);
  };

  const getLearningPaths = async (url?: string) => {
    let enrollments = null;
    if (url) enrollments = await getListData(url);
    else enrollments = await getUserEnrollments("learningProgram");
    if (enrollments?.data?.data?.length) {
      const data = enrollments.data;
      let learningPathsNew: LearningObjectDetails[] = constructLearningObject(
        data,
        false
      );
      setLearningPaths((prevLearningPaths) => {
        return sortLearningPaths1(
          [...prevLearningPaths, ...learningPathsNew],
          getLearningPathsOrder()
        );
      });
      if (enrollments.data.links?.next)
        getLearningPaths(enrollments.data.links?.next);
    }
  };

  const getSubCourses = async () => {
    let subIds: string[] =
      currentLearningPath?.sections?.flatMap((section: any) => section.loIds) ??
      [];
    while (subIds.length > 0) {
      const idsToFetch = subIds.splice(0, 10);
      const newCourses: any = await getLearnerObjectsByIds(
        idsToFetch.join(",")
      );
      if (newCourses?.data?.data?.length) {
        let coursesObjects = constructLearningObject(newCourses.data, true);
        setCurrentLearningPathCourses((prevCourses) => [
          ...prevCourses,
          ...coursesObjects,
        ]);
      }
    }
  };
  useEffect(() => {
    if (currentLearningPath) {
      setCurrentLearningPathCourses([]);
      getSubCourses();
    }
  }, [activeIndex]);

  useEffect(() => {
    if (!learningPathDataLoadedRef.current && learningPathsOrder) {
      getLearningPaths();
      learningPathDataLoadedRef.current = true;
    }
  }, [learningPathsOrder]);

  useEffect(() => {
    if (learningPaths.length) {
      setActiveIndex(0);
      setSlideIndex(0);
    }
  }, [learningPaths]);

  const containerActiveArrow = {
    content: "''",
    position: "absolute",
    left: `${98 + slideIndex * 145}px`,
  };

  return (
    <>
      <Box className="learning-progress-container">
        <CommonTitle
          depositName={
            headerData?.maintitle || "THE FINXACT CONFIGURATION JOURNEY"
          }
          versionTextStyleClass="configuration-journey-title"
          featureName=""
          version=""
          mainTitle={headerData?.headline || "Your Learning Progress"}
          subText={
            headerData?.subhead ||
            "At a glance, here’s what you’ve accomplished so far."
          }
        />
        {learningPaths.length ? (
          <>
            <Box px={16} w={"100%"}>
              <LearningPathList
                learningPaths={learningPaths}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                setSlideIndex={setSlideIndex}
              />
            </Box>
            {currentLearningPath && (
              <Box
                className="learning-path-deatils-container"
                _before={containerActiveArrow}
              >
                <Flex
                  px={13}
                  py={10}
                  className="learning-path-deatils-header"
                  justifyContent={"space-between"}
                  my={8}
                  flexWrap={"wrap"}
                  rowGap={10}
                >
                  <Heading as="h2" fontSize={fontSize2rem} w={"70%"}>
                    {currentLearningPath?.name} badge path
                  </Heading>
                  <HStack
                    justifyContent={"space-between"}
                    w={"28%"}
                    minW={"377px"}
                  >
                    <Box w={"90%"}>
                      <ProgressBar
                        value={currentLearningPath?.progressPercent ?? 0}
                        hoverEffect={false}
                      />
                    </Box>
                    <Text>{currentLearningPath?.progressPercent ?? 0}%</Text>
                  </HStack>
                </Flex>
                <VStack
                  className="learning-path-deatils-section"
                  alignItems={"start"}
                  gap={0}
                >
                  {currentLearningPathCourses.map(
                    (course: any, index: number) => (
                      <React.Fragment key={index}>
                        <LearningPathCourseItem
                          course={course}
                          index={index}
                          size={currentLearningPathCourses.length}
                        />
                      </React.Fragment>
                    )
                  )}
                </VStack>
              </Box>
            )}
          </>
        ) : (
          <VStack w={"100%"} justifyContent={"center"} mt={20} gap={6}>
            <Image src={noCatalogFound} alt={"No learning paths found"} />
            <Heading as="h3" fontSize={24} mt={4}>
              No badge paths enrolled
            </Heading>
          </VStack>
        )}
      </Box>
    </>
  );
};

export const LearningPathList = ({
  learningPaths,
  activeIndex,
  setActiveIndex,
  setSlideIndex,
}: {
  learningPaths: LearningObjectDetails[];
  activeIndex: number | undefined;
  setActiveIndex: any;
  setSlideIndex: any;
}) => {
  const carouselRef = useRef<any>();
  const carouselResponsiveSettings = {
    desktop: {
      breakpoint: { max: 3000, min: 1440 },
      items: 9,
      slidesToSlide: 9,
    },
    desktopmin: {
      breakpoint: { max: 1440, min: 1200 },
      items: 7,
      slidesToSlide: 7,
    },
    tablet: {
      breakpoint: { max: 1200, min: 464 },
      items: 4,
      slidesToSlide: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const slideAfterChangeHandler = (prevSlideIndex: any, slide: any) => {
    const { currentSlide } = slide;
    setActiveIndex(currentSlide);
    setSlideIndex(0);
  };
  const handleLearningPathSelect = (index: number) => {
    setActiveIndex(index);
    const { currentSlide } = carouselRef.current.state;
    setSlideIndex(index - currentSlide);
  };
  return (
    <>
      {/* @ts-ignore there's issue with the typings for the component https://github.com/YIZHUANG/react-multi-carousel/issues/346 */}
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={carouselResponsiveSettings}
        infinite={false}
        keyBoardControl={false}
        itemClass="carousel-item"
        containerClass="carousel-container"
        ref={carouselRef}
        afterChange={slideAfterChangeHandler}
        renderButtonGroupOutside={true}
        arrows={false}
        customButtonGroup={<CarouselButtonGroup />}
      >
        {learningPaths.map((learningPath: any, index: number) => (
          <HStack
            key={`learningPath-${index}`}
            data-status={activeIndex === index ? "active" : ""}
            onClick={() => handleLearningPathSelect(index)}
          >
            <VStack w={"100%"} gap={5}>
              <HStack justifyContent={"center"} gap={0} w={"100%"}>
                {index > 0 && activeIndex !== index && (
                  <Divider
                    orientation="horizontal"
                    border={"2px dashed"}
                    className={
                      index < 2 ? "transparent-border" : "visible-border"
                    }
                  />
                )}
                {(learningPath.state === ALM_ENROLLMENT_STATE.STARTED ||
                  learningPath.state === ALM_ENROLLMENT_STATE.COMPLETED) &&
                  learningPath.progressPercent === 100 && (
                    <CheckCircleIcon
                      boxSize={10}
                      className="completed-status-icon"
                      cursor={"pointer"}
                    />
                  )}
                {(learningPath.state === ALM_ENROLLMENT_STATE.STARTED ||
                  learningPath.state === ALM_ENROLLMENT_STATE.COMPLETED) &&
                  learningPath.progressPercent !== 100 && (
                    <Image
                      src={rightRotateYellow}
                      alt="inprogress"
                      className="in-progress-learningpath"
                    />
                  )}
                {learningPath.state === ALM_ENROLLMENT_STATE.ENROLLED && (
                  <Icon
                    boxSize={10}
                    as={BsCircle}
                    cursor={"pointer"}
                    className="enrolled-status-icon"
                  />
                )}
                {index !== 0 && activeIndex !== index && (
                  <Divider
                    orientation="horizontal"
                    border={"2px dashed"}
                    className={
                      index === learningPaths.length - 1
                        ? "transparent-border"
                        : "visible-border"
                    }
                  />
                )}
              </HStack>
              <Text textAlign={"center"} px={5}>
                {learningPath.name}
              </Text>
            </VStack>
          </HStack>
        ))}
      </Carousel>
    </>
  );
};

export const LearningPathCourseItem = ({
  course,
  index,
  size,
}: {
  course: LearningObjectDetails;
  index: number;
  size: number;
}) => {
  return (
    <Flex
      gap={5}
      alignItems={"center"}
      w={"100%"}
      className="learning-path-course-item"
    >
      <VStack justifyContent={"end"} gap={0} className="divider-container">
        <Divider
          orientation="vertical"
          h={"46px"}
          className={index !== 0 ? "visible-border" : "transparent-border"}
        />
        {course.state === ALM_ENROLLMENT_STATE.COMPLETED && (
          <CheckCircleIcon boxSize={"24px"} className="completed-status-icon" />
        )}
        {course.state === ALM_ENROLLMENT_STATE.STARTED && (
          <Image src={rightRotateYellow} alt="inprogress" />
        )}
        {course.state === ALM_ENROLLMENT_STATE.ENROLLED && (
          <Icon
            boxSize={"24px"}
            as={BsCircle}
            className="enrolled-status-icon"
          />
        )}
        <Divider
          orientation="vertical"
          h={"46px"}
          className={
            index !== size - 1 ? "visible-border" : "transparent-border"
          }
        />
      </VStack>
      <VStack gap={1} alignItems={"start"}>
        <Text fontSize={fontSizeReg}>{course.name}</Text>
        <Box fontSize={fontSizeSmall}>
          <TimeIcon mr={2} />
          {formatDuration(course.duration ?? 0, true)}
        </Box>
        <Text fontSize={fontSizeSmall}>{course.description}</Text>
      </VStack>
      <Spacer />
      <HStack gap={"16px"}>
        <Badges
          size="lg"
          {...getBadgeByState({
            status: course.state,
            progressPercent: course.progressPercent,
          })}
        />
        <Link
          href={`${ROUTE_PATH.ACADEMY_CATALOG_COURSE}?id=${course.learnerObjectId}&from=my-learning`}
        >
          <Button
            className={
              course.state === ALM_ENROLLMENT_STATE.STARTED
                ? "app-btn-inverse-secondary"
                : "app-btn-reg-secondary-transparent"
            }
          >
            {course.state === ALM_ENROLLMENT_STATE.STARTED ? "Resume" : "View"}
            <Icon as={ChevronRightIcon} boxSize={"23px"} />
          </Button>
        </Link>
      </HStack>
    </Flex>
  );
};
