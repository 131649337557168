import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Grace-Periods-Extension
interface GeneralFeatureSetupProps {
  rollDrGrace: string;
  rollCrGrace: string;
  crTermExt: string;
  rollDrGraceAdj: number | undefined;
}
// Defining Intitial Value For Grace-Periods-Extension
const generalFeatureSetupDetails: GeneralFeatureSetupProps = {
  rollDrGrace: "",
  rollCrGrace: "",
  crTermExt: "",
  rollDrGraceAdj: undefined,
};

const generalFeatureSetupDetailsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["rollDrGrace", "rollCrGrace", "crTermExt", "rollDrGraceAdj"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { GeneralFeatureSetupProps };
// Exporting Validation Schema and Intial value
export {
  generalFeatureSetupDetails,
  generalFeatureSetupDetailsSchema as getValidationSchema,
};
