import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Repayment-Payment-configuration
interface repaymentPaymentConfigurationProps {
  pmtDur: string;
  pmtFreq: string;
  pmtOffset: string;
  minPmtAmt: string;
  payoffVarianceAmt: string;
  gracePeriod: string;
  tolerancePct: string;
  paymentToleranceType: string;
  isRevolving: SplitBtnInputValType;
  pmtApplAdvance: number | undefined;
  payApplMatrix: string;
}

// Defining Intitial Value For  Repayment-Payment-configuration
const repaymentPrepaymentConfigurationDetails: repaymentPaymentConfigurationProps =
  {
    pmtFreq: "",
    pmtDur: "",
    pmtOffset: "",
    minPmtAmt: "",
    payoffVarianceAmt: "",
    gracePeriod: "",
    tolerancePct: "",
    paymentToleranceType: "",
    isRevolving: undefined,
    pmtApplAdvance: undefined,
    payApplMatrix: "",
  };
// YUP LIBRARY :Defining Schema For validation of  Repayment-Payment-configuration
const repaymentPrepaymentConfigurationDetailsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = [
    "pmtDur",
    "pmtFreq",
    "minPmtAmt",
    "payoffVarianceAmt",
    "gracePeriod",
    "isRevolving",
    "pmtApplAdvance",
    "toleranceAmt",
    "tolerancePct",
    "pmtOffset",
    "payApplMatrix",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { repaymentPaymentConfigurationProps };
// Exporting Validation Schema and Intial value
export {
  repaymentPrepaymentConfigurationDetails,
  repaymentPrepaymentConfigurationDetailsSchema as getValidationSchema,
};
