import { ERROR_MESSAGE } from "@/components/data/form-data";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { Option } from "@/utils/types";
import * as Yup from "yup";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";

//Defining Interface 
interface RatesCurrenciesIdentifiersProps {
  fedWthRt: string | number;
  dfltNraWthRt: string | number;
  assetClass: string;
  assetId: string;
}
// Defining Intitial Value 
const defaultRatesCurrenciesIdentifiers: RatesCurrenciesIdentifiersProps = {
  fedWthRt: "",
  dfltNraWthRt: "",
  assetClass: "",
  assetId: "",
};

const ratesCurrenciesIdentifiersSchema = async (optionsSchema: PostSchema) => {
  const fields = ["fedWthRt", "dfltNraWthRt", "assetClass", "assetId"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { RatesCurrenciesIdentifiersProps };
// Exporting Validation Schema and Intial value
export {
  defaultRatesCurrenciesIdentifiers,
  ratesCurrenciesIdentifiersSchema as getValidationSchema,
};
