"use client";

import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
} from "@/components/common";
import "./PartyRelationships.scss";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  PartyRelationshipsProps,
  defaultPartyRelationships,
  getValidationSchema,
} from "./PartyRelationshipsValidation";
import { Box, useToast } from "@/components/ChakraUiManager";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { CRT_ENTITY, CRT_STEPS, SIDEBAR_STATUS } from "@/utils/constants";
import { ROUTE_PATH } from "@/route-config/route-path";
import { updateCustomerRelationshipType } from "../../crt-config-api-service";
import { createWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useSearchParams } from "next/navigation";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { useSelector } from "react-redux";
import { getEnvIsDisable } from "@/store";
import { useQueryParams } from "@/utils/common";

const PartyRelationshipsDetails = (props: any) => {
  const partyRelationshipsFormData = props.data?.formData ?? null;
  const toast = useToast();
  const toastId = "crt-details-toast";
  const { partyRelationshipsDetailsOptionsData } = props.data;

  const updateQueryParams = useQueryParams();
  const params = useSearchParams();
  const isEnvDisabled = useSelector(getEnvIsDisable);

  const changeWorkFlow = async () => {
    await createWorkflowGeneric({
      model: API_ROUTE_CONFIGURATION.crtConfig,
      key: props.data.formData?.custRelType,
      stage: CRT_ENTITY.party_relationships,
      status: CRT_STEPS.party_relationships,
    });
  };

  const moveToNextStep = (custRelType: string | undefined) => {
    if (isEnvDisabled) {
      navigateTo(ROUTE_PATH.CRT_LANDING_PAGE);
      return;
    }
    const isCrtWorkflowCompleted = entityWorkflowData?.find(
      (ele: any) => ele.status == CRT_STEPS.party_relationships
    );
    if (!canCheckFormStatus) {
      if (isCrtWorkflowCompleted) {
        updateFormStatus?.(SIDEBAR_STATUS.completed);
        updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
        if (!params?.get("entityType")) {
          navigateTo(ROUTE_PATH.CRT_LANDING_PAGE);
        } else {
          navigateTo(
            `${ROUTE_PATH.CRT_INTERSTITAL_SCREEN}?${updateQueryParams("entityId", custRelType)}`
          );
        }
      } else {
        navigateTo(
          `${ROUTE_PATH.CRT_INTERSTITAL_SCREEN}?${updateQueryParams("entityId", custRelType)}`
        );
      }
    }
  };

  const onSubmit = async (values: any, actions: any) => {
    if (!dirty) {
      changeWorkFlow();
      moveToNextStep(props.data?.formData?.custRelType);
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };

    response = await updateCustomerRelationshipType({
      formData: {
        _vn: props.data.formData?._vn,
        custRelType: props.data.formData?.custRelType,
        desc: props.data.formData?.desc,

        rels: values.rels.map((value: any) => ({
          maxParties: value.maxParties ? Number(value.maxParties) : null,
          minParties: value.minParties ? Number(value.minParties) : null,
          reldesc: String(value.reldesc),
          partyRelType: String(value.partyRelType),
          beneficialOwnerRole: String(value.beneficialOwnerRole),
        })),
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: CRT_STEPS.party_relationships,
    });

    if (response) {
      const changeWorkFlow = async () => {
        await createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.crtConfig,
          key: props.data.formData?.custRelType,
          stage: CRT_ENTITY.party_relationships,
          status: CRT_STEPS.party_relationships,
        });
      };
      changeWorkFlow();
      moveToNextStep(props.data?.formData?.custRelType);
    }
  };
  // Handler for all the form fields
  const handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`rels.${index}.${key}`, value);
  };

  const handleOnBlur = (key: string, index: number) => () => {
    setFieldTouched(`rels.${index}.${key}`, true);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        partyRelationshipsDetailsOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [partyRelationshipsDetailsOptionsData]);

  let initialFields: PartyRelationshipsProps[] =
    partyRelationshipsFormData?.rels &&
    partyRelationshipsFormData.rels.length > 0
      ? partyRelationshipsFormData?.rels.map((field: any, idx: number) => ({
          idx: idx,
          maxParties: field.maxParties ?? "",
          minParties: field.minParties ?? "",
          reldesc: field.reldesc ?? "",
          partyRelType: field.partyRelType ? Number(field.partyRelType) : "",
          beneficialOwnerRole: field.beneficialOwnerRole
            ? Number(field.beneficialOwnerRole)
            : "",
        }))
      : ([] as PartyRelationshipsProps[]);
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: { rels: initialFields },
  });
  const {
    values,
    handleSubmit,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    navigateToNextPage,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      navigateToNextPage?.();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} id="finxact-form" noValidate>
        <FieldArray
          name="rels"
          render={({ insert, remove, push }) => (
            <Box className="app-form-field-array-container">
              {values.rels?.map(
                (field: PartyRelationshipsProps, index: any) => (
                  <CardContainer key={field.idx}>
                    <AccordionComponent
                      accordionTitle={`Party relationship ${index + 1}`}
                      deleteIcon
                      onDeleteHandler={() => remove(index)}
                      isExpand={values.rels.length !== index + 1 ? 1 : 0}
                    >
                      <Box className="app-form-field-container">
                        <AppFormLabel
                          labelName="What is the relationship type?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get(
                              "partyRelType"
                            )?.tooltip_text_main
                          }
                        />

                        <RadioButtonGroup
                          isBoxedRadio={true}
                          value={field.partyRelType}
                          onChange={handleOnChange("partyRelType", index)}
                          radioPropList={props.data.partyRelType}
                          stackDirection={"column"}
                          spacing={4}
                        />
                        {touched.rels?.[index]?.partyRelType &&
                          (errors.rels?.[index] as PartyRelationshipsProps)
                            ?.partyRelType && (
                            <ErrorAlert>
                              <span>
                                {
                                  (
                                    errors.rels?.[
                                      index
                                    ] as PartyRelationshipsProps
                                  ).partyRelType
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>

                      <Box className="app-form-field-container">
                        <AppFormLabel
                          labelName="What is the beneficial owner role?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get(
                              "beneficialOwnerRole"
                            )?.tooltip_text_main
                          }
                        />
                        <RadioButtonGroup
                          isBoxedRadio={true}
                          value={field.beneficialOwnerRole}
                          onChange={handleOnChange(
                            "beneficialOwnerRole",
                            index
                          )}
                          radioPropList={props.data.beneficialOwnerRole}
                          stackDirection={"column"}
                          spacing={4}
                        />
                        {touched.rels?.[index]?.beneficialOwnerRole &&
                          (errors.rels?.[index] as PartyRelationshipsProps)
                            ?.beneficialOwnerRole && (
                            <ErrorAlert>
                              <span>
                                {
                                  (
                                    errors.rels?.[
                                      index
                                    ] as PartyRelationshipsProps
                                  ).beneficialOwnerRole
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                      <Box className="app-form-field-container">
                        <AppFormLabel
                          labelName="What is the description?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get("reldesc")
                              ?.tooltip_text_main
                          }
                        />
                        <InputText
                          value={field.reldesc}
                          onChange={handleOnChange("reldesc", index)}
                          onBlur={handleOnBlur("reldesc", index)}
                          label={`reldesc${index}`}
                        />
                        {touched.rels?.[index]?.reldesc &&
                          (errors.rels?.[index] as any)?.reldesc && (
                            <ErrorAlert>
                              <span>
                                {(errors.rels?.[index] as any).reldesc}
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                      <Box className="app-form-field-container">
                        <AppFormLabel
                          labelName="What is the minimum parties?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get("minParties")
                              ?.tooltip_text_main
                          }
                        />
                        <Box className="input-width-small">
                          <AppNumberInput
                            value={field.minParties}
                            onChange={handleOnChange("minParties", index)}
                            onBlur={handleOnBlur("minParties", index)}
                            label={`minParties${index}`}
                          />
                        </Box>
                        {touched.rels?.[index]?.minParties &&
                          (errors.rels?.[index] as PartyRelationshipsProps)
                            ?.minParties && (
                            <ErrorAlert>
                              <span>
                                {
                                  (
                                    errors.rels?.[
                                      index
                                    ] as PartyRelationshipsProps
                                  ).minParties
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                      <Box>
                        <AppFormLabel
                          labelName="What is the maximum parties?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get("maxParties")
                              ?.tooltip_text_main
                          }
                        />
                        <Box className="input-width-small">
                          <AppNumberInput
                            value={field.maxParties}
                            onChange={handleOnChange("maxParties", index)}
                            onBlur={handleOnBlur("maxParties", index)}
                            label={`maxParties${index}`}
                          />
                        </Box>
                        {touched.rels?.[index]?.maxParties &&
                          (errors.rels?.[index] as PartyRelationshipsProps)
                            ?.maxParties && (
                            <ErrorAlert>
                              <span>
                                {
                                  (
                                    errors.rels?.[
                                      index
                                    ] as PartyRelationshipsProps
                                  ).maxParties
                                }
                              </span>
                            </ErrorAlert>
                          )}
                      </Box>
                    </AccordionComponent>
                  </CardContainer>
                )
              )}
              <Box marginTop={12}>
                <AddNewButton
                  label={
                    !values.rels.length
                      ? "Add party relationship"
                      : "Add another party relationship"
                  }
                  className="add-new-button-relationship"
                  onClick={() => {
                    push({
                      ...defaultPartyRelationships,
                      idx: Math.random(),
                    });
                  }}
                />
              </Box>
            </Box>
          )}
        ></FieldArray>
      </form>
    </FormikProvider>
  );
};

export default PartyRelationshipsDetails;
