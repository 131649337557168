"use client";

import {
  Box,
  Button,
  CloseIcon,
  Divider,
  Text,
  useToast,
} from "@/components/ChakraUiManager";
import { CardContainer } from "@/components/common";
import { warningIcon, solidRightArrow } from "public/assets";
import Image from "next/image";
import "./TransactionCodeSummaryLanding.scss";
import { useState, useEffect } from "react";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import {
  ENTITLEMENT_STATUS,
  SUMMARY_LANDING_SCREEN_STATUS,
  TC_SUMMARY_LANDING_SCREEN_STATUS,
} from "@/utils/constants";
import CompletedPage from "./completed-page/CompletedPage";
import Link from "next/link";
import { getHelpfulResourcesConfig } from "@/prismic-api/prismic-api-service";
import { ResourceSectionProps } from "@/models/global-models";
import { HELPFUL_RESOURCES_SLUG } from "@/utils/constants";
import { buildURL } from "@/prismic-api/common-service";
import { ENTITLEMENT_MESSAGE } from "@/components/data/error-data";
import { getAppPrismicClient, getEntitlement } from "@/store";
import { useSelector } from "react-redux";
import UnmetPreRequisites from "@/components/institutional-configuration/ic-summary-landing-page/unmet-pre-requisites-page/UnmetPreRequisites";

export type TransCodeType = {
  trnCode: string;
  desc: string;
};

const Resources = ({
  props,
  resources,
}: {
  props: any;
  resources: ResourceSectionProps;
}) => {
  return (
    <Box
      className={`${props.status === SUMMARY_LANDING_SCREEN_STATUS.in_progress ? "" : "product-image"}`}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Divider className="bottom-divider" />
        <Text as="h4" className="resource-heading">
          {resources?.sectionTitle || "Helpful transaction code resources"}
        </Text>
        {resources?.resourcesLinks?.length &&
          resources?.resourcesLinks.map((res: any, index: number) => (
            <CardContainer
              key={`resourceContainer${index}`}
              customClass="resource-container"
            >
              <div>
                <Text className="resource-title">{res.link_title}</Text>
                <Text className="resource-caption">{res.link_caption}</Text>
              </div>
              <Box className="resource-img">
                {res.link_type === "prismic link" ? (
                  <Link
                    href={buildURL(
                      res?.prismic_link?.type,
                      res?.prismic_link?.uid
                    )}
                  >
                    <Image src={solidRightArrow} alt="Right arrow" />
                  </Link>
                ) : res.link_type === "academy" ? (
                  <Link
                    href={
                      `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}/?id=` +
                      `${res?.academy_course_link}`
                    }
                  >
                    <Image src={solidRightArrow} alt="Right arrow" />
                  </Link>
                ) : (
                  <a
                    href={
                      res?.external_link?.link_type === "Document"
                        ? buildURL(
                            res?.external_link?.type,
                            res?.external_link?.uid
                          )
                        : res?.external_link?.url
                    }
                  >
                    <Image src={solidRightArrow} alt="Right arrow" />
                  </a>
                )}
              </Box>
            </CardContainer>
          ))}
      </Box>
    </Box>
  );
};

const TransactionCodeSummaryLanding = (props: any) => {
  const prismicClient = useSelector(getAppPrismicClient);
  const unMetStatus = props.status === TC_SUMMARY_LANDING_SCREEN_STATUS.unmet;
  const completeStatus =
    props.status === TC_SUMMARY_LANDING_SCREEN_STATUS.completed;
  const [showWarning, setShowWarning] = useState<boolean>(true);
  const [resources, setResources] = useState<ResourceSectionProps>({});
  const toast = useToast();
  const entitlement = useSelector(getEntitlement);

  const UnmetPreRequisitesWarning = () => {
    return (
      <CardContainer customClass="toast-conatiner">
        <Box className="toast-icon">
          <Image src={warningIcon} alt="warning" />
        </Box>
        <Box className="toast-text">
          <Text as="h5">Outstanding pre-requisites</Text>
          <Text>Complete your general ledger to proceed below</Text>
        </Box>

        <Box className="toast-link">
          <Link href={ROUTE_BASE_URL["DASHBOARD_PATH"]}>
            <Button className="app-btn-link">Update</Button>
          </Link>
        </Box>

        <Box className="toast-close">
          <CloseIcon
            onClick={() => setShowWarning(false)}
            className="close-icon"
          />
        </Box>
      </CardContainer>
    );
  };
  async function getTcSummaryResources() {
    const resourceData = await getHelpfulResourcesConfig(
      prismicClient,
      HELPFUL_RESOURCES_SLUG.tc
    );
    await Promise.all([resourceData]).then((values) => {
      setResources(values[0] as ResourceSectionProps);
    });
  }

  useEffect(() => {
    if (!resources?.resourcesLinks?.length) {
      getTcSummaryResources();
    }
  }, []);

  //Check for entitlement
  useEffect(() => {
    const userEntitlement = entitlement;
    if (userEntitlement?.tc === ENTITLEMENT_STATUS.noAccess) {
      if (!toast.isActive("entitlement"))
        toast({
          id: "entitlement",
          description: ENTITLEMENT_MESSAGE.noAccess,
          status: "error",
          duration: null,
        });
    } else {
      if (toast.isActive("entitlement")) toast.close("entitlement");
    }
  }, [entitlement]);

  return (
    <Box className={"trans-code-landing-page"}>
      {showWarning && unMetStatus && <UnmetPreRequisitesWarning />}
      {unMetStatus && (
        <UnmetPreRequisites
          configurationProps={{
            title: "Transaction Code Setup",
            leftBadgeLabel: "General Ledger Setup",
            rightBadgeLabel: "Networks Setup",
            headingText:
              "Come back after finishing General Ledger setup to start on transaction",
            paragraphText:
              "Once you’re ready, you’ll be able to set up transaction code as necessary or review the pre-populated list below",
          }}
        />
      )}
      {completeStatus && <CompletedPage />}
      <Resources props={props} resources={resources} />
    </Box>
  );
};

export default TransactionCodeSummaryLanding;
