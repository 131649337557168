"use client";

import {
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  ModalSelector,
} from "@/components/common";
import { Box, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import {
  GeneralLedgerDetailsProps,
  generalLedgerDetails,
  getValidationSchema,
} from "./GeneralLedgerDetailsValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useContext, useEffect, useState } from "react";
import { shouldUpdateWorkflowStatus } from "@/utils/common";
import { AssetCatalogData } from "../../model";
import { updateAssetCatalog } from "../../ic-config-api-service";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { useSearchParams } from "next/navigation";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { IC_ENTITY } from "@/utils/constants";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { Option } from "@/utils/types";

type GeneralLedgerProps = {
  data: {
    accountGroup: Option<string>[];
    accountNumber: Option<string>[];
    formData: AssetCatalogData | null;
    assetCatalogueOptionsData: PostSchema;
  };
};
const GeneralLedgerDetails = (props: GeneralLedgerProps) => {
  const generalLedgerData = props.data?.formData ?? null;
  const [accountNumber, setAccountNumber] = useState<any>();
  const toast = useToast();
  const toastId = "asset-catalog-toast";
  const selectedModalOptions = (option: string) => {
    setAccountNumber(option);
    setFieldValue("exchGlAcctNbr", option);
  };
  const params = useSearchParams();
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.assetCatalogueOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.assetCatalogueOptionsData]);
  const moveToNextStep = (paramsArg: string) => {
    if (!canCheckFormStatus) {
      if (params?.get("initialFlow")) {
        navigateTo(ROUTE_PATH.IC_INTERSTITIAL_SCREEN);
      } else {
        navigateTo(
          `${ROUTE_PATH.IC_COMPLETION_SCREEN}?entityId=${paramsArg}&entityType=${IC_ENTITY.asset_catalog}`
        );
      }
    }
  };

  const onSubmit = async (values: GeneralLedgerDetailsProps, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: `${props.data.formData?.assetClass}~${props.data.formData?.assetId}`,
          stage: IC_ENTITY.asset_catalog,
          status: currentStage,
        });
      }
      moveToNextStep(
        `${props.data.formData?.assetClass}~${props.data.formData?.assetId}`
      );
      return;
    }
    const res = await updateAssetCatalog({
      formData: {
        _vn: generalLedgerData?._vn,
        assetClass: generalLedgerData?.assetClass,
        assetId: generalLedgerData?.assetId,
        // PATCH Fields
        exchGlAcctGroup: values.exchGlAcctGroup,
        exchGlAcctNbr: values.exchGlAcctNbr || null,
        unrealGainAcctGroup: values.unrealGainAcctGroup,
        unrealGainAcctNbr: values.unrealGainAcctNbr,
        unrealLossAcctGroup: values.unrealLossAcctGroup,
        unrealLossAcctNbr: values.unrealLossAcctNbr,
      },
      toastOptions: {
        toast: toast,
        toastId: "general-ledger-details",
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (res) {
      moveToNextStep(
        `${props.data.formData?.assetClass}~${props.data.formData?.assetId}`
      );
    }
  };
  const handleOnChange = (key: string) => (value: string | number) => {
    if (key === "exchGlAcctGroup") {
      selectedModalOptions("");
    }
    setFieldValue(key, value);
  };

  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: generalLedgerData
      ? {
          exchGlAcctGroup: generalLedgerData?.exchGlAcctGroup,
          exchGlAcctNbr: generalLedgerData?.exchGlAcctNbr ?? "",
          unrealGainAcctGroup: generalLedgerData?.unrealGainAcctGroup,
          unrealGainAcctNbr: generalLedgerData?.unrealGainAcctNbr,
          unrealLossAcctGroup: generalLedgerData?.unrealLossAcctGroup,
          unrealLossAcctNbr: generalLedgerData?.unrealLossAcctNbr,
        }
      : generalLedgerDetails,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);
  useEffect(() => {
    if (values.unrealGainAcctGroup !== generalLedgerData?.unrealGainAcctGroup) {
      values.unrealGainAcctNbr = "";
    }
  }, [values.unrealGainAcctGroup]);
  useEffect(() => {
    if (values.unrealLossAcctGroup !== generalLedgerData?.unrealLossAcctGroup) {
      values.unrealLossAcctNbr = "";
    }
  }, [values.unrealLossAcctGroup]);
  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the asset exchange General Ledger account group?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("exchGlAcctGroup")
                ?.tooltip_text_main
            }
            labelFor="account-group"
          />
          <SelectDropdown
            value={values.exchGlAcctGroup!}
            onChange={handleOnChange("exchGlAcctGroup")}
            dropdownList={props.data.accountGroup}
            onBlur={handleBlur}
            id="account-group"
          />
          {errors.exchGlAcctGroup && touched.exchGlAcctGroup && (
            <ErrorAlert>
              <span>{errors.exchGlAcctGroup}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the asset exchange General Ledger account number?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("exchGlAcctNbr")
                ?.tooltip_text_main
            }
          />
          <ModalSelector
            linkName="+ Select an Asset Exchange GL Account Number"
            modalTitle="Select an Asset Exchange GL Account Number"
            optionList={props.data.accountNumber.filter(
              (number: any) => number.acctGroup === values.exchGlAcctGroup
            )}
            isClearable={false}
            onChange={selectedModalOptions}
            value={values.exchGlAcctNbr}
            showSelection="description"
          />

          {errors.exchGlAcctNbr &&
            touched.exchGlAcctNbr &&
            typeof errors.exchGlAcctNbr === "string" && (
              <ErrorAlert>
                <span>{errors.exchGlAcctNbr}</span>
              </ErrorAlert>
            )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the unrealized gain GL account group?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("unrealGainAcctGroup")
                ?.tooltip_text_main
            }
            labelFor="gain-account-group"
          />
          <SelectDropdown
            value={values.unrealGainAcctGroup!}
            onChange={handleOnChange("unrealGainAcctGroup")}
            dropdownList={props.data.accountGroup}
            onBlur={handleBlur}
            id="gain-account-group"
          />
          {errors.unrealGainAcctGroup && touched.unrealGainAcctGroup && (
            <ErrorAlert>
              <span>{errors.unrealGainAcctGroup}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the unrealized gain GL account number?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("unrealGainAcctNbr")
                ?.tooltip_text_main
            }
            labelFor="gain-account-number"
          />
          <SelectDropdown
            value={values.unrealGainAcctNbr}
            onChange={handleOnChange("unrealGainAcctNbr")}
            dropdownList={props.data.accountNumber?.filter(
              (number: any) => number.acctGroup === values.unrealGainAcctGroup
            )}
            onBlur={handleBlur}
            id="gain-account-number"
          />
          {errors.unrealGainAcctNbr && touched.unrealGainAcctNbr && (
            <ErrorAlert>
              <span>{errors.unrealGainAcctNbr}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the unrealized loss GL account group?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("unrealLossAcctGroup")
                ?.tooltip_text_main
            }
            labelFor="loss-account-group"
          />
          <SelectDropdown
            value={values.unrealLossAcctGroup!}
            onChange={handleOnChange("unrealLossAcctGroup")}
            dropdownList={props.data.accountGroup}
            onBlur={handleBlur}
            id="loss-account-group"
          />
          {errors.unrealLossAcctGroup && touched.unrealLossAcctGroup && (
            <ErrorAlert>
              <span>{errors.unrealLossAcctGroup}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the unrealized loss GL account number?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("unrealLossAcctNbr")
                ?.tooltip_text_main
            }
            labelFor="loss-account-number"
          />
          <SelectDropdown
            value={values.unrealLossAcctNbr}
            onChange={handleOnChange("unrealLossAcctNbr")}
            dropdownList={props.data.accountNumber?.filter(
              (number: any) => number.acctGroup === values.unrealLossAcctGroup
            )}
            onBlur={handleBlur}
            id="loss-account-number"
          />
          {errors.unrealLossAcctNbr && touched.unrealLossAcctNbr && (
            <ErrorAlert>
              <span>{errors.unrealLossAcctNbr}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default GeneralLedgerDetails;
