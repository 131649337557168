"use client";

import {
  AbsoluteCenter,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  CheckIcon,
  Divider,
  Flex,
  Heading,
  Text,
} from "../ChakraUiManager";
import { AppModal, AppPortal } from "../common";
import "./StatusSideBarMenu.scss";
import Image from "next/image";
import {
  SideBarMenuStatusType,
  StatusSideBarMainMenu,
  StatusSideBarMenuDefaultProps,
} from "@/models/global-models";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useContext, useEffect, useRef, useState } from "react";
import {
  productConfigurationContext,
  ProductConfigurationContextType,
} from "../context-api/product-configuration-context/ProductConfigurationReducer";
import { rightRotate } from "public/assets";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import {
  MAIN_FORM_ID,
  SIDEBAR_STATUS,
  NEW_IC_ENTITY_ID,
  IFX_ACCT_PRODUCT_TYPE,
  FooterButtonType,
  DASHBOARD_ACCORDION_STATUS,
} from "@/utils/constants";
import { getWorkflowGeneric } from "@/api-config/api-service";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "../context-api/unsaved-changes-provider/UnsavedChangesProvider";
import {
  checkReadOnlyEntitlement,
  isIncorrectWorkflowStep,
  nullCheckAndGetIndexVal,
  removeTrailingSlash,
} from "@/utils/common";

const validBackBtnRoutesList = [
  ROUTE_PATH.GENERAL_PARTY_PARAMETER_PARTY_ADDRESS_PARAMETER_DETAIL,
  ROUTE_PATH.GENERAL_PARTY_PARAMETER_LOCATION_CONTACT_INFORMATION,
  ROUTE_PATH.GENERAL_PARTY_PARAMETER_GENERAL_PARTY_SPECIFICATION,
  ROUTE_PATH.GENERAL_PARTY_PARAMETER_TAX_SPECIFICATION,
  ROUTE_PATH.GENERAL_PARTY_PARAMETER_INTERNATIONAL_TRANSFER_INTENT,
  ROUTE_PATH.GENERAL_PARTY_PARAMETER_PARTY_EMAILS_DETAILS,
  ROUTE_PATH.GENERAL_PARTY_PARAMETER_PARTY_PHONE_DETAILS,
];

const validFinishConfigBtnRoutesList = [
  ROUTE_PATH.IC_ACCOUNT_GROUP_ADVANCED_CONFIGURATION,
  ROUTE_PATH.IC_ASSET_GENERAL_LEDGER_DETAILS,
  ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_SUMMARY,
  ROUTE_PATH.IC_FINANCIAL_CALENDER_HOLIDAY_CONFIGURATION,
  ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_RULES,
  ROUTE_PATH.IC_BANK_PARAMS_PRIORITY_POSTING,
];

const StatusStepperBar = ({
  status = SIDEBAR_STATUS.not_started,
}: {
  status: SideBarMenuStatusType;
}) => {
  return (
    <Box position="absolute" className="side-bar-main-menu-stepper">
      <Divider orientation="vertical" />
      <AbsoluteCenter>
        <Box
          className={`side-bar-main-menu-stepper-indicator ${
            status === SIDEBAR_STATUS.in_progress
              ? "side-bar-stepper-status-inprogress"
              : ""
          } ${
            status === "COMPLETED" ? "side-bar-stepper-status-completed" : ""
          }`}
        >
          {status === SIDEBAR_STATUS.in_progress ? (
            <Image
              className="side-bar-stepper-image-inprogress"
              src={rightRotate}
              alt={"rotate icon"}
            />
          ) : (
            ""
          )}
          {status === "COMPLETED" ? (
            <CheckIcon
              color="white"
              className="side-bar-stepper-image-completed"
            />
          ) : (
            ""
          )}
        </Box>
      </AbsoluteCenter>
    </Box>
  );
};

type RouteConfig = {
  href: string;
  mainMenuIndex: number;
  subMenuIndex: number | null;
  status: SideBarMenuStatusType;
  entityStep?: string;
};

type StatusSideBarMainMenuProps = {
  prefixDynamicRouteUrl?: string;
  formParentUrl?: string;
  saveExitRouteUrl?: string;
  type: "prod-config" | "ic-config" | "gl-config" | "tc-config" | "crt-config";
  disableNav?: boolean;
};

export default function StatusSideBarMenu(props: StatusSideBarMainMenuProps) {
  const {
    prefixDynamicRouteUrl = "",
    formParentUrl = "",
    saveExitRouteUrl = "",
    type,
    disableNav,
  } = props;

  const {
    sideBarMenuList: mainMenuList = [],
    sideBarMainMenuIndex,
    sideBarSubMenuIndex,
    isGoingBack,
    updateGoingBackState,
    canNavigate,
    canCheckFormStatus,
    entityWorkflowData,
    selectedBtnType,
    checkFormStatus,
    setSideBarCurrentIndex,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateToNextPage,
    navigateTo,
    navigateWithProductNameParam,
    getWorflowStatusToBeUpdated,
    productDetails,
    setProductWorkflowData,
    navigateWithEntityParam,
    saveAndExit,
    setSaveAndExit,
    getCurrentStageStatusName,
    setSelectedBtnType,
    getCurrentProductAttributeWorkflowStatus,
    productWorkflowData,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const pathName = usePathname();
  const router = useRouter();
  const [upcomingRouteConfigList, setUpcomingRouteConfigList] = useState<
    RouteConfig[]
  >([]);
  const [upcomingRouteConfig, setUpcomingRouteConfig] = useState<RouteConfig>();
  const [currentRouteIndexInConfig, setCurrentRouteIndex] = useState<number>(0);
  const [accordionIndex, setAccordionIndex] = useState<number[]>([
    sideBarMainMenuIndex,
  ]);
  const searchParams = useSearchParams();
  const isEnvDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);
  const { clearUnsavedChanges } = useUnsavedChanges();
  const entityType = searchParams?.get("entityType");
  const modalRef = useRef<any>();
  const preventDefaultBack_RouteList = [
    ROUTE_PATH.GENERAL_PARTY_PARAMETER,
    ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY,
    ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM,
  ];

  // Exit to given url and close the incorrect workflow warning modal
  const _handleExit = (refType: any) => {
    router.replace(saveExitRouteUrl);
    refType?.current?.closeModal();
  };

  // Show modal when user lands on an incorrect step in the workflow
  useEffect(() => {
    if (
      entityWorkflowData &&
      isIncorrectWorkflowStep(
        entityWorkflowData,
        getCurrentStageStatusName(),
        props.type
      )
    ) {
      //Open modal
      modalRef?.current?.openModal();
    }
  }, [entityWorkflowData, props.type]);

  function handleMainMenu(
    mainMenuIndex: number,
    path: string,
    status: SideBarMenuStatusType,
    subMenuList: StatusSideBarMenuDefaultProps[] | [],
    id?: string
  ) {
    if (
      path &&
      (!subMenuList || subMenuList?.length === 0) &&
      status === SIDEBAR_STATUS.completed
    ) {
      setUpcomingPathAndIndex(mainMenuIndex, null, path, status, undefined, id);
    }
  }

  const handleSubMenuItemClick = (
    mainMenuIndex: number,
    subMenuIndex: number,
    href: string,
    subMenuStatus: SideBarMenuStatusType,
    id?: string
  ) => {
    if (type === "prod-config" && subMenuIndex >= 0) {
      if (
        subMenuStatus === SIDEBAR_STATUS.completed ||
        mainMenuList[mainMenuIndex].subMenuList
          ?.slice(0, subMenuIndex)
          ?.every((item) => item.status === "COMPLETED")
      ) {
        setUpcomingPathAndIndex(
          mainMenuIndex,
          subMenuIndex,
          href!,
          subMenuStatus,
          undefined,
          id
        );
        return;
      } else {
        return;
      }
    }
    setUpcomingPathAndIndex(
      mainMenuIndex,
      subMenuIndex,
      href!,
      subMenuStatus,
      undefined,
      id
    );
  };
  const UrlModal = (
    <AppModal
      customClass="app-input-with-dropdown-modal"
      ref={modalRef}
      modalTitle="Incorrect workflow step!"
      primaryBtnProp={{
        name: "Back",
        btnClassName: "app-btn-inverse-secondary-add-new",
      }}
      primaryBtnSelect={_handleExit.bind(modalRef)}
      showCloseIcon={false}
      isCentered
    >
      <Text>
        Oops! Unfortunately, the URL you have entered cannot be accessed before
        you complete the current step in the configuration journey. Completing
        this step to move forward.
      </Text>
    </AppModal>
  );

  function setUpcomingPathAndIndex(
    mainMenuIndex: number,
    subMenuIndex: number | null,
    path: string,
    status: SideBarMenuStatusType,
    buttonType?: FooterButtonType,
    id?: string
  ) {
    if (path) {
      setUpcomingRouteConfig({
        href: path,
        mainMenuIndex,
        subMenuIndex,
        status,
        ...(id ? { entityStep: id } : {}),
      });
      //prefetch the upcoming route to reduce the navigation duration
      const routePath = constructRouteUrl(path);

      if (routePath) {
        const subRoute = getSubRoute();
        const queryString = window.location.search;
        isEnvDisabled || checkEntitlement
          ? router.prefetch(`${subRoute ? subRoute : routePath}${queryString}`)
          : router.prefetch(routePath);
      }

      // when env is locked navigate on continue click
      if (isEnvDisabled || checkEntitlement) {
        navigateToNextPage?.();
        setSelectedBtnType?.(buttonType!);
        return;
      }

      // When user selects a different step from the sidebar
      if (!buttonType) {
        navigateToNextPage?.("sidebar");
        setSelectedBtnType?.("sidebar");
        checkFormStatus?.(true);
        return;
      }
    }
    if (buttonType === "back") {
      const currentRouteConfig = upcomingRouteConfigList.at(
        currentRouteIndexInConfig
      );

      updateGoingBackState?.(true);
      updateFormStatus?.(SIDEBAR_STATUS.not_started);
      if (type === "prod-config") {
        updateProductCurrStatusInSidebar();
      }
      // Skip default navigation if current page is a substep of Fin Org steps (route is part of preventDefaultBack_RouteList),
      // back navigation is handled at page level
      if (
        !preventDefaultBack_RouteList.includes(currentRouteConfig?.href ?? "")
      ) {
        navigateToNextPage?.("back");
      }
    } else if (buttonType !== "continue") {
      checkFormStatus?.(true);
    }
  }

  //check the status for sidebar
  function updateProductCurrStatusInSidebar() {
    let currStatus = SIDEBAR_STATUS.not_started;
    if (nullCheckAndGetIndexVal(sideBarSubMenuIndex)) {
      currStatus = getWorflowStatusToBeUpdated?.()
        ? SIDEBAR_STATUS.not_started
        : SIDEBAR_STATUS.completed;
    } else {
      currStatus =
        getCurrentProductAttributeWorkflowStatus?.() ===
          DASHBOARD_ACCORDION_STATUS.completed ||
        productDetails?.status === DASHBOARD_ACCORDION_STATUS.completed
          ? SIDEBAR_STATUS.completed
          : SIDEBAR_STATUS.not_started;
    }
    updateStatusInSideBarMenuList?.(currStatus);
  }

  function navToNextPage() {
    if (
      upcomingRouteConfig &&
      Object.keys(upcomingRouteConfig)?.length &&
      !pathName?.includes(upcomingRouteConfig.href)
    ) {
      if (selectedBtnType !== "back") {
        updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
      }

      type === "prod-config" &&
        getWorflowStatusToBeUpdated?.() &&
        getWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
        }).then((data) => {
          setProductWorkflowData?.(data);
        });

      setSideBarCurrentIndex?.(
        upcomingRouteConfig.mainMenuIndex,
        upcomingRouteConfig.subMenuIndex
      );
      //updating default form status
      updateFormStatus?.(upcomingRouteConfig.status);

      // reset back btn state
      if (isGoingBack) {
        updateGoingBackState?.(false);
      }

      let routePath = constructRouteUrl();
      if (routePath) {
        if (type === "ic-config" || type === "gl-config") {
          if (canCheckFormStatus) {
            if (preventDefaultBack_RouteList.includes(routePath)) {
              const REROUTE_MAP = {
                [ROUTE_PATH.GENERAL_PARTY_PARAMETER]:
                  ROUTE_PATH.GENERAL_PARTY_PARAMETER_IDENTIFICATION,
                [ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY]:
                  ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY_IDENTIFICATION,
                [ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM]:
                  ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_FIN_ORG_IDENTIFICATION,
              };
              routePath = REROUTE_MAP[routePath];
            }
            const entityType = searchParams?.get("entityType");
            // If mode is: updating/creating individual entity
            if (entityType) {
              router.push(`${routePath}?${searchParams?.toString()}`);
            } else {
              /**
               * During initial IC/GL setup, when user navigates by clicking any step on the sidebar (canCheckFormStatus = true & entityType = undefined),
               * we need to fetch the correct entityId/entityType to update in the query params while routing the user.
               * */
              const entryIndex = searchParams?.get("entryIndex");
              const indexParams = entryIndex ? `&entryIndex=${entryIndex}` : "";
              router.push(
                `${routePath}?entityId=${getEntityIdFromUpcomingConfig()}&entityType=${upcomingRouteConfig.entityStep}${indexParams}`
              );
            }
          } else {
            router.push(`${routePath}?${searchParams?.toString()}`);
          }
        }
        // TC & CRT flows always set up single entities, so above logic to find correct entityId is not applicable
        else if (type === "tc-config" || type === "crt-config") {
          router.push(`${routePath}?${searchParams?.toString()}`);
        } else {
          // this will add query param in the route
          navigateWithProductNameParam(`${routePath}`);
        }
      }
      // reset checkFormStatus
      checkFormStatus?.(false);
    }
  }

  // Get the correct entityId from workflow data to route the user to
  const getEntityIdFromUpcomingConfig = () => {
    const currentEntityId = entityWorkflowData?.find(
      (entity) => entity.stage === upcomingRouteConfig?.entityStep
    );
    return currentEntityId?.modelKey ?? NEW_IC_ENTITY_ID;
  };

  function getSubRoute() {
    const currentPath = window.location.pathname;
    switch (currentPath) {
      case ROUTE_PATH["IC_FINANCIAL_CALENDER_HOLIDAY_CONFIGURATION"]:
        return ROUTE_PATH["GENERAL_PARTY_PARAMETER_IDENTIFICATION"];
      case ROUTE_PATH["GENERAL_PARTY_PARAMETER_IDENTIFICATION"]:
        return ROUTE_PATH[
          "GENERAL_PARTY_PARAMETER_PARTY_ADDRESS_PARAMETER_DETAIL"
        ];
      case ROUTE_PATH["GENERAL_PARTY_PARAMETER_PARTY_ADDRESS_PARAMETER_DETAIL"]:
        return ROUTE_PATH["GENERAL_PARTY_PARAMETER_PARTY_EMAILS_DETAILS"];
      case ROUTE_PATH["GENERAL_PARTY_PARAMETER_PARTY_EMAILS_DETAILS"]:
        return ROUTE_PATH["GENERAL_PARTY_PARAMETER_PARTY_PHONE_DETAILS"];
      case ROUTE_PATH["GENERAL_PARTY_PARAMETER_PARTY_PHONE_DETAILS"]:
        return ROUTE_PATH["GENERAL_PARTY_PARAMETER_TAX_SPECIFICATION"];
      case ROUTE_PATH["GENERAL_PARTY_PARAMETER_TAX_SPECIFICATION"]:
        return ROUTE_PATH[
          "GENERAL_PARTY_PARAMETER_GENERAL_PARTY_SPECIFICATION"
        ];
      case ROUTE_PATH["GENERAL_PARTY_PARAMETER_GENERAL_PARTY_SPECIFICATION"]:
        return ROUTE_PATH[
          "GENERAL_PARTY_PARAMETER_LOCATION_CONTACT_INFORMATION"
        ];
      case ROUTE_PATH["GENERAL_PARTY_PARAMETER_LOCATION_CONTACT_INFORMATION"]:
        return ROUTE_PATH[
          "GENERAL_PARTY_PARAMETER_INTERNATIONAL_TRANSFER_INTENT"
        ];
      case ROUTE_PATH["GENERAL_PARTY_PARAMETER_INTERNATIONAL_TRANSFER_INTENT"]:
        return ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_IDENTIFICATION"];

      case ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_IDENTIFICATION"]:
        return ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_TAX_SPECIFICATION"];
      case ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_TAX_SPECIFICATION"]:
        return ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_SPECIFICATION"];
      case ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_SPECIFICATION"]:
        return ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_LOCATION_AND_CONTACT"];
      case ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_LOCATION_AND_CONTACT"]:
        return ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_IDENTIFICATION"];
      case ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_IDENTIFICATION"]:
        return ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_OTHER_IDENTIFICATION"];
      case ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_OTHER_IDENTIFICATION"]:
        return ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_SPECIFICATIONS"];
      case ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_SPECIFICATIONS"]:
        return ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_IDENTIFICATION"];
      case ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_IDENTIFICATION"]:
        return ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_DESTINATION_NETWORK"];
      case ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_DESTINATION_NETWORK"]:
        return ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_ORIGIN_NETWORK"];
      case ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_ORIGIN_NETWORK"]:
        return ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_SUMMARY"];
      case ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_SUMMARY"]:
        return ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_RULES"];
    }
    return "";
  }

  /**
   * set sidebar initial state and flattned the array to easy out navigation
   * @param menuList
   */
  function setInitialMainAndSubMenuIndex(
    menuList: StatusSideBarMainMenu[] = []
  ) {
    let mainIndex = 0,
      subMenuIndex = null,
      routeConfigList: RouteConfig[] = [];
    if (menuList?.length) {
      menuList?.forEach((item: StatusSideBarMainMenu, mainI: number) => {
        if (item?.href && pathName?.includes(item.href)) {
          mainIndex = mainI;
          item.status =
            item.status === SIDEBAR_STATUS.not_started
              ? SIDEBAR_STATUS.in_progress
              : item.status;
        }
        if (item?.subMenuList?.length) {
          item?.subMenuList.forEach(
            (subItem: StatusSideBarMainMenu, subIndex: number) => {
              if (subItem?.href && pathName?.includes(subItem.href)) {
                mainIndex = mainI;
                subMenuIndex = subIndex;
                subItem.status =
                  subItem.status === SIDEBAR_STATUS.not_started
                    ? SIDEBAR_STATUS.in_progress
                    : subItem.status;
                item.status =
                  item.status === SIDEBAR_STATUS.not_started
                    ? SIDEBAR_STATUS.in_progress
                    : item.status;
              }
              routeConfigList.push({
                mainMenuIndex: mainI,
                subMenuIndex: subIndex,
                status: subItem.status || SIDEBAR_STATUS.not_started,
                href: subItem?.href || "",
              });
            }
          );
        } else {
          routeConfigList.push({
            mainMenuIndex: mainI,
            subMenuIndex: null,
            status: item.status || SIDEBAR_STATUS.not_started,
            href: item?.href || "",
          });
        }
      });
    }

    setSideBarCurrentIndex?.(mainIndex, subMenuIndex);
    setUpcomingRouteConfigList([...routeConfigList]);
    searchCurrentRoute(routeConfigList);
  }

  function searchCurrentRoute(routeConfigList: RouteConfig[]) {
    if (routeConfigList?.length) {
      let currRouteIndex = routeConfigList.findIndex((item) => {
        if (item.href !== "") {
          return pathName?.includes(item.href);
        }
      });
      if (currRouteIndex < 0) {
        currRouteIndex = 0;
      }
      setCurrentRouteIndex(currRouteIndex);
      //set default form navigation if it is not there
      checkForDefaultNavigation(routeConfigList, currRouteIndex);
    }
  }

  function onFooterNavClick(buttonType: FooterButtonType) {
    // Reset unsaved changes context
    clearUnsavedChanges();

    // reset the save and exit status
    if (buttonType === "continue" || buttonType === "back") {
      setSaveAndExit(false, "");
      setSelectedBtnType?.(buttonType);
    }
    if (buttonType === "finish") {
      setSelectedBtnType?.("finish");
      // If finish button clicked, routing is handled on specific pages
      if (isEnvDisabled || checkEntitlement) {
        router.push(saveExitRouteUrl);
      }
      return;
    }

    const nextRouteConfig =
      buttonType === "back"
        ? upcomingRouteConfigList.at(currentRouteIndexInConfig - 1)
        : upcomingRouteConfigList.at(currentRouteIndexInConfig + 1);

    if (nextRouteConfig && Object.keys(nextRouteConfig).length) {
      setUpcomingPathAndIndex(
        nextRouteConfig.mainMenuIndex,
        nextRouteConfig.subMenuIndex,
        nextRouteConfig.href,
        nextRouteConfig.status,
        buttonType
      );
    }
  }

  function constructRouteUrl(href?: string) {
    const loc = href ? href : upcomingRouteConfig?.href;
    return prefixDynamicRouteUrl
      ? `${prefixDynamicRouteUrl}${loc || ""}`
      : `${loc || ""}`;
  }

  function checkForDefaultNavigation(
    routeConfigList: RouteConfig[],
    routeIndex: number
  ) {
    if (pathName) {
      if (formParentUrl && routeConfigList?.length) {
        const pageUrl = routeConfigList[routeIndex]?.href;
        const routeUrl = `${prefixDynamicRouteUrl}${pageUrl}`;
        if (pageUrl && !searchParams?.get(QUERY_PARAM_KEY.FIELD_NAME))
          navigateWithProductNameParam(routeUrl);
      }
    }
  }

  useEffect(() => {
    if (upcomingRouteConfigList?.length) {
      searchCurrentRoute(upcomingRouteConfigList);
    }
  }, [pathName]);

  useEffect(() => {
    navToNextPage();
  }, [canNavigate]);

  useEffect(() => {
    if (!upcomingRouteConfigList.length && mainMenuList?.length) {
      setInitialMainAndSubMenuIndex([...mainMenuList]);
    }
  }, [mainMenuList]);

  useEffect(() => {
    if (
      (type === "ic-config" || type === "gl-config") &&
      mainMenuList?.length
    ) {
      setInitialMainAndSubMenuIndex([...mainMenuList]);
    }
    // Reset going back + checkFormStatus form state after route change
    if (isGoingBack) {
      updateGoingBackState?.(false);
    }
  }, [pathName]);

  useEffect(() => {
    //closing the rest of the accordion incase of navigation
    if (sideBarMainMenuIndex !== null) {
      setAccordionIndex([sideBarMainMenuIndex]);
    }
  }, [sideBarMainMenuIndex, pathName]);

  const showFinishConfigBtn = () => {
    if (type !== "ic-config") {
      return upcomingRouteConfigList?.length - 1 === currentRouteIndexInConfig;
    } else {
      return (
        upcomingRouteConfigList?.length - 1 === currentRouteIndexInConfig &&
        validFinishConfigBtnRoutesList.includes(
          removeTrailingSlash(pathName || "")
        ) &&
        mainMenuList.length - 1 === sideBarMainMenuIndex
      );
    }
  };

  const showContinueBtn = () => {
    if (type !== "ic-config") {
      return upcomingRouteConfigList?.length - 1 !== currentRouteIndexInConfig;
    } else if (type !== "ic-config" && !entityType) {
      return (
        upcomingRouteConfigList?.length - 1 === currentRouteIndexInConfig &&
        validFinishConfigBtnRoutesList.includes(
          removeTrailingSlash(pathName || "")
        ) &&
        mainMenuList.length - 1 === sideBarMainMenuIndex
      );
    } else {
      return (
        upcomingRouteConfigList?.length - 1 !== currentRouteIndexInConfig ||
        !validFinishConfigBtnRoutesList.includes(
          removeTrailingSlash(pathName || "")
        )
      );
    }
  };

  const _handleBackBtnClick = () => {
    if (type === "tc-config" && searchParams?.get(QUERY_PARAM_KEY.FIELD_NAME)) {
      onFooterNavClick("back");
      return;
    }
    // if user on 1st step of any config or if user is on Fin org gen party params identification, back btn should exit to given url
    if (currentRouteIndexInConfig === 0) {
      if (pathName?.includes(ROUTE_PATH.GENERAL_PARTY_PARAMETER)) {
        if (
          pathName.includes(ROUTE_PATH.GENERAL_PARTY_PARAMETER_IDENTIFICATION)
        ) {
          router.push(saveExitRouteUrl);
          return;
        }
      } else {
        router.push(saveExitRouteUrl);
        return;
      }
    }
    onFooterNavClick("back");
  };

  function onAccordionChange(expandedIndex: number[]) {
    setAccordionIndex(expandedIndex);
  }

  return (
    <>
      <Box className="status-side-bar-container">
        <>
          <Accordion
            index={accordionIndex}
            className="status-side-bar-menu-container"
            allowMultiple
            onChange={onAccordionChange}
          >
            {mainMenuList?.length
              ? mainMenuList?.map(
                  (
                    {
                      id: entityStep,
                      name: mainMenuName,
                      status: mainMenuStatus,
                      href: mainMenuHref,
                      subMenuList,
                      sectionName,
                      sectionNameTooltipDesc,
                    },
                    mainMenuIndex
                  ) => (
                    <Box key={mainMenuName}>
                      {sectionName ? (
                        <Heading
                          key={`sideBarHeading${mainMenuIndex}`}
                          className="side-bar-section-name"
                          as="h3"
                        >
                          <Text as="span" mr="var(--chakra-space-2)">
                            {sectionName}
                          </Text>
                        </Heading>
                      ) : (
                        ""
                      )}

                      <AccordionItem
                        key={`accordionItem${mainMenuIndex}`}
                        className={`${
                          mainMenuIndex === sideBarMainMenuIndex
                            ? "status-side-bar-menu-item"
                            : ""
                        }`}
                      >
                        {() => (
                          <Box
                            key={`accordionBoxItem${mainMenuIndex}`}
                            onClick={() =>
                              handleMainMenu(
                                mainMenuIndex,
                                mainMenuHref!,
                                mainMenuStatus,
                                subMenuList || [],
                                entityStep
                              )
                            }
                          >
                            <AccordionButton
                              key={`accordionItemBtn${mainMenuIndex}`}
                              className={`status-side-bar-menu status-side-bar-menu-btn ${
                                !subMenuList?.length &&
                                mainMenuHref &&
                                pathName?.includes(mainMenuHref)
                                  ? "side-bar-menu-selected"
                                  : ""
                              }`}
                            >
                              <StatusStepperBar status={mainMenuStatus} />
                              <Box
                                className={`side-bar-main-menu ${
                                  mainMenuIndex === sideBarMainMenuIndex
                                    ? "active-main-menu"
                                    : ""
                                }`}
                              >
                                {mainMenuName}
                              </Box>
                            </AccordionButton>
                            <AccordionPanel
                              key={`accordionPanel${mainMenuIndex}`}
                              className="status-side-bar-menu-panel"
                            >
                              {subMenuList?.length
                                ? subMenuList.map(
                                    (
                                      {
                                        name: subMenuName,
                                        status: subMenuStatus,
                                        href = "",
                                      },
                                      subMenuIndex
                                    ) => (
                                      <Box
                                        key={`sideBarSubMenuContainer${subMenuIndex}`}
                                        className={`status-side-bar-menu status-side-bar-submenu ${
                                          href && pathName?.includes(href)
                                            ? "side-bar-menu-selected"
                                            : ""
                                        }`}
                                      >
                                        <StatusStepperBar
                                          key={`sideBarStepper${subMenuIndex}`}
                                          status={subMenuStatus}
                                        />
                                        <Box
                                          key={`sideBarSubMenu${mainMenuIndex}`}
                                          onClick={() => {
                                            if (
                                              !disableNav ||
                                              subMenuStatus ===
                                                SIDEBAR_STATUS.completed
                                            ) {
                                              handleSubMenuItemClick(
                                                mainMenuIndex,
                                                subMenuIndex,
                                                href!,
                                                subMenuStatus,
                                                entityStep
                                              );
                                            }
                                          }}
                                          className={`side-bar-main-submenu-text`}
                                        >
                                          {subMenuName}
                                        </Box>
                                      </Box>
                                    )
                                  )
                                : ""}
                            </AccordionPanel>
                          </Box>
                        )}
                      </AccordionItem>
                    </Box>
                  )
                )
              : ""}
          </Accordion>
        </>
        {/* this is a dummy button to handle default behaviour of form when user clicks on enter */}
        {showContinueBtn() ? (
          <Button
            key="main-submit"
            type="submit"
            form={MAIN_FORM_ID}
            onClick={() => onFooterNavClick("continue")}
            className="app-btn-inverse-secondary"
            overflow="hidden"
            display="none"
          >
            Continue
          </Button>
        ) : (
          ""
        )}
        {saveExitRouteUrl ? (
          <Flex mt="var(--chakra-space-10)" justifyContent={"center"}>
            <Button
              type={isEnvDisabled || checkEntitlement ? "button" : "submit"}
              form={MAIN_FORM_ID}
              className="app-btn-link"
              onClick={() => {
                clearUnsavedChanges();
                !isEnvDisabled && !checkEntitlement
                  ? setSaveAndExit(true, saveExitRouteUrl)
                  : router.push(saveExitRouteUrl);
              }}
            >
              {checkEntitlement || isEnvDisabled ? "Exit" : "Save & exit"}
            </Button>
          </Flex>
        ) : (
          ""
        )}
      </Box>
      <AppPortal domId="#appFooter">
        <Box className="configured-product-footer">
          {upcomingRouteConfigList?.length ||
          validBackBtnRoutesList?.includes(
            removeTrailingSlash(pathName || "")
          ) ? (
            <ButtonGroup className="configured-product-btn-container footer-btn-configuration">
              <Button
                onClick={() => {
                  _handleBackBtnClick();
                }}
                mr="var(--chakra-space-6)"
                className="app-btn-reg-secondary-transparent"
              >
                Back
              </Button>

              {showContinueBtn() ? (
                <Button
                  key="main-submit"
                  type={isEnvDisabled || checkEntitlement ? "button" : "submit"}
                  form={MAIN_FORM_ID}
                  onClick={() => onFooterNavClick("continue")}
                  className="app-btn-inverse-secondary"
                >
                  Continue
                </Button>
              ) : (
                ""
              )}
              {showFinishConfigBtn() ? (
                <Button
                  type={isEnvDisabled || checkEntitlement ? "button" : "submit"}
                  form={MAIN_FORM_ID}
                  onClick={() => onFooterNavClick("finish")}
                  className="app-btn-inverse-secondary"
                >
                  Finish Configuration
                </Button>
              ) : (
                ""
              )}
            </ButtonGroup>
          ) : (
            ""
          )}
        </Box>
        {/* Render modal for wrong flow */}
        {UrlModal}
      </AppPortal>
    </>
  );
}
