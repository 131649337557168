import { API_ROUTE_CONFIGURATION, ApiClient } from "@/api-config";
import {
  TransCodeApiResponse,
  TransCodeEntries,
  TransCodeResponse,
} from "./models";
import {
  WorkflowResponseUnion,
  createComponent,
  createTransCodeTemplate,
  createWorkflowGeneric,
  deleteTransCodeTemplate,
  getCoreModelEndpoint,
  updateComponentWithPut,
  updateTransCodeTemplate,
  updateWorkflowGeneric,
} from "@/api-config/api-service";
import { InstitutionalConfigAPIPayload } from "../institutional-configuration/model";
import {
  DASHBOARD_ACCORDION_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS,
  TC_STAGE_LIST,
  TC_STAGE_STATUS,
} from "@/utils/constants";
import { deepCopy } from "@finxact/finxact-shared-ui";

export interface TransCodeCreateApiConfig
  extends InstitutionalConfigAPIPayload {
  formData: Partial<TransCodeResponse>;
  stageStatus: string;
}

export interface TcTemplatesConfig extends InstitutionalConfigAPIPayload {
  entryIndex: number;
  code: string;
  stageStatus: string;
  templatesToBeDeleted: any;
  templatesToBeCreated: any;
  templatesToBeUpdated: any;
}

// Create, update, get for Accounting Segments
export const getTranscodeDetails = async (
  entityId: string
): Promise<TransCodeApiResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return entityId
    ? api.get(`${API_ROUTE_CONFIGURATION.transCode}/${entityId}`)
    : null;
};

// Create Trans code
export const createTransCode = async (config: TransCodeCreateApiConfig) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    stageStatus,
  } = config;
  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.transCode}`,
    body: JSON.stringify(formData),
  })
    .then(
      async (res: Pick<TransCodeCreateApiConfig["formData"], "trnCode">) => {
        if (!toast.isActive(toastId)) {
          toast({
            id: toastId,
            description: successMessage,
            status: "success",
          });
        }
        if (stageName) {
          //Creating the workflow for all the tc steps
          let workFlowList = TC_STAGE_LIST.map((item) =>
            createWorkflowGeneric({
              model: API_ROUTE_CONFIGURATION.tcConfig,
              key: res.trnCode!,
              stage: item,
              status:
                item === TC_STAGE_STATUS.foundational_details
                  ? DASHBOARD_ACCORDION_STATUS.completed
                  : DASHBOARD_ACCORDION_STATUS.notStarted,
            })
          );
          await Promise.all(workFlowList);
        }
        return res;
      }
    )
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const updateTransCode = async (config: TransCodeCreateApiConfig) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    stageStatus,
  } = config;

  return updateComponentWithPut({
    url: `${API_ROUTE_CONFIGURATION.transCode}/${formData.trnCode}`,
    body: JSON.stringify(formData),
  })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageStatus) {
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.tcConfig,
          key: res.trnCode,
          stage: stageName,
          status: stageStatus,
        });
      }
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const modifyTransCodeTemplate = async (config: TcTemplatesConfig) => {
  const {
    code,
    entryIndex,
    templatesToBeDeleted,
    templatesToBeCreated,
    templatesToBeUpdated,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    stageStatus,
  } = config;

  const deletePromise = templatesToBeDeleted.length
    ? templatesToBeDeleted.map((template: any) =>
        deleteTransCodeTemplate({
          code: code,
          index: entryIndex,
          channel: template.channel,
          language: template.language,
          templateName: template.templateName,
        })
      )
    : [Promise.resolve("success")];

  const createPromise = templatesToBeCreated.length
    ? templatesToBeCreated.map((template: any) =>
        createTransCodeTemplate({
          code: code,
          index: entryIndex,
          body: {
            ...template,
            trnCode: code,
          },
        })
      )
    : [Promise.resolve("success")];

  const updatePromise = templatesToBeUpdated.length
    ? templatesToBeUpdated.map((template: any) =>
        updateTransCodeTemplate({
          code: code,
          index: entryIndex,
          body: {
            ...template,
            trnCode: code,
          },
        })
      )
    : [Promise.resolve("success")];

  return Promise.all([...deletePromise, ...createPromise, ...updatePromise])
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      if (stageStatus) {
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.tcConfig,
          key: code ?? "",
          stage: stageName,
          status: stageStatus,
        });
      }
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export function constructTcEntryPayload(
  existingData: TransCodeResponse,
  formValues: any,
  currEntriesIndex: number
): TransCodeResponse {
  const existingFormData = deepCopy(existingData);
  if (existingFormData?.entries?.length) {
    let existingEntries = existingFormData.entries[currEntriesIndex];
    existingEntries = {
      ...existingEntries,
      ...(formValues as TransCodeEntries),
    };
    existingFormData.entries[currEntriesIndex] = existingEntries;
  } else {
    existingFormData.entries = [{ ...formValues } as TransCodeEntries];
  }
  return existingFormData;
}

export function getEntryStageStatus(
  stageStatus: keyof typeof TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS,
  entityWorkflowData: WorkflowResponseUnion[] | null
) {
  if (entityWorkflowData?.length) {
    const currentWorkflowStage = entityWorkflowData?.filter(
      (wfd) => wfd.stage === TC_STAGE_STATUS.entry_setup
    )?.[0]?.status;
    if (currentWorkflowStage === DASHBOARD_ACCORDION_STATUS.completed) {
      return DASHBOARD_ACCORDION_STATUS.completed;
    } else {
      const currentWorkflowStageOrder: any =
        TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER[currentWorkflowStage as any];
      const currentEntityStageOrder =
        TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER[stageStatus];
      if (currentEntityStageOrder < currentWorkflowStageOrder) {
        return currentWorkflowStage;
      }
    }
  }
  return stageStatus;
}
