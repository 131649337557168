import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Promotional_Rate_Foundational_Details
interface GLExtractFileFormatsProps {
  glExtractFileFmt: { fmtName: string }[];
  isGlExtractSummary: SplitBtnInputValType;
  glExtractFileList?: GLExtractFileFormatsProps[];
}
// Defining Intitial Value For Promotional_Rate_Foundational_Details
const GLExtractFileFormatsDetails: GLExtractFileFormatsProps = {
  glExtractFileFmt: [],
  isGlExtractSummary: undefined,
};


const GLExtractFileFormatsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["glExtractFileFmt", "isGlExtractSummary"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { GLExtractFileFormatsProps };
// Exporting Validation Schema and Intial value
export {
  GLExtractFileFormatsDetails,
  GLExtractFileFormatsSchema as getValidationSchema,
};
