"use client";
import {
  Box,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  CommonTitle,
  DatePicker,
  ErrorAlert,
  SplitButton,
  StaticText,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import {
  USSpecificationFinancialOrganizationParameter,
  validationSchema,
} from "./USSpecificationFinancialOrganizationIdentificationValidation";
import "./USSpecificationFinancialOrganizationIdentification.scss";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import moment from "moment";
import { FIN_ORG_SUB_STEPS, IC_ENTITY } from "@/utils/constants";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { ROUTE_PATH } from "@/route-config/route-path";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useQueryParams, shouldUpdateWorkflowStatus } from "@/utils/common";
import { Option } from "@/utils/types";

type Props = {
  data: {
    employeeStatusOptions: string[];
    naicsCode: Option<number>[];
    formData: {
      financialOrg: FinancialInstitutionData;
    } | null;
  };
};

const USSpecificationFinancialOrganizationIdentification = (props: Props) => {
  const toast = useToast();
  const toastId = "financial-organization";
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const { employeeStatusOptions, naicsCode, formData } = props.data;
  const updateQueryParams = useQueryParams();

  // Form Submit Handler
  const onSubmit = async (values: any, actions: any) => {
    const currentStage = FIN_ORG_SUB_STEPS.party_us_spec_fin_org_spec;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?.financialOrg._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      navigateTo(
        `${ROUTE_PATH["PARTY_US_FEDWIRE_FORM"]}?${updateQueryParams()}`
      );
      return;
    }

    const commonToastOptions = {
      toast,
      toastId,
    };
    if (formData) {
      const { financialOrg } = formData;
      const response = await Promise.all([
        updateFinOrg({
          formData: {
            _vn: financialOrg._vn,
            _Id: financialOrg._Id,
            name: financialOrg.name,
            //Patch calls
            partyUSBankInfo: {
              bNotice1stDt: `${moment(new Date(values.firstNoticeDate)).format(
                "YYYY-MM-DD"
              )}`,

              bNotice2ndDt: `${moment(new Date(values.secondNoticeDate)).format(
                "YYYY-MM-DD"
              )}`,
              employeeStatus: values.employeeStatus,
              isElectronicBill: values.electronicLoanBill === "selectLeft",
              isElectronicStmt: values.electronicStatement === "selectLeft",
              isFiledW8: values.isW8filled === "selectLeft",
              isFiledW9: values.isW9filled === "selectLeft",
              isPaperBill: values.paperLoanBill === "selectLeft",
              isPaperStmt: values.paperStatement === "selectLeft",
              isWthFed: values.federalWithHolding === "selectLeft",
              isWthNra: values.nraWithHolding === "selectLeft",
              isWthState: values.stateWithHolding === "selectLeft",
              naics: values.naicsCode,
              w8Dt: `${moment(new Date(values.w8receivedDate)).format(
                "YYYY-MM-DD"
              )}`,
              w9Dt: `${moment(new Date(values.w9receivedDate)).format(
                "YYYY-MM-DD"
              )}`,
              irsEConsent: values.irsConsent === "selectLeft",
              irsEConsentEndDate: `${moment(
                new Date(values.w9receivedDate)
              ).format("YYYY-MM-DD")}`,
            },
          },
          toastOptions: {
            ...commonToastOptions,
            successMessage: "Party US Bank Information - 1 of 2 updated.",
          },
          stageName: shouldUpdateWorkflowStatus(
            entityWorkflowData!,
            currentStage
          )
            ? currentStage
            : "",
        }),
      ]);
      if (response) {
        navigateTo(
          `${ROUTE_PATH["PARTY_US_FEDWIRE_FORM"]}?${updateQueryParams()}`
        );
      }
    }
  };
  // Handler for all the form fields
  const handleOnChange = (key: string) => (value: any) => {
    if (key === "federalWithHolding" && value === "selectLeft") {
      setFieldValue("nraWithHolding", "selectRight");
    } else if (key === "federalWithHolding" && value === "selectRight") {
      setFieldValue("nraWithHolding", "selectLeft");
    }
    if (key === "nraWithHolding" && value === "selectLeft") {
      setFieldValue("federalWithHolding", "selectRight");
    } else if (key === "nraWithHolding" && value === "selectRight") {
      setFieldValue("federalWithHolding", "selectLeft");
    }
    setFieldValue(key, value);
  };

  // using useFormik hook from Formik Library

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
    setFieldTouched,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: USSpecificationFinancialOrganizationParameter,
  });

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);
  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_RULES}?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <Box className="us-specific-box-default-width">
        <CommonTitle
          depositName="FINANCIAL ORGANIZATION"
          featureName=""
          version=""
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
            "Party US bank information - 1 of 2"
          }
          subText={tooltipFlyoutDetails?.pageHeaderDetails?.subHeading || ""}
        />
        <CardContainer>
          <Heading as="h3" className="heading-styles-h3">
            {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
              ?.section_title ||
              "US-specific financial organization identification"}
          </Heading>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"What is the party identifier?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("_Id")?.tooltip_text_main
              }
              isRequired
            />
            <StaticText textValue={formData?.financialOrg._Id} />
          </Box>
        </CardContainer>
        <CardContainer>
          <Heading as="h3" className="heading-styles-h3">
            {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
              ?.section_title ||
              "US-specific financial organization specifications"}
          </Heading>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={
                "If you have a received a B notice from the IRS, what is the first B notice date?"
              }
              isRequired
            />
            <Stack direction="row" spacing="32px">
              <Box width={"48%"}>
                <AppFormLabel
                  labelName="Date"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("bNotice1stDt")
                      ?.tooltip_text_main
                  }
                />
                <DatePicker
                  value={values.firstNoticeDate}
                  onUpdate={handleOnChange("firstNoticeDate")}
                  onBlur={handleBlur}
                  ariaLabel="firstNoticeDate"
                  placeholder="Select"
                  place="right"
                />
                {errors.firstNoticeDate && touched.firstNoticeDate && (
                  <ErrorAlert>
                    <span>{errors.firstNoticeDate}</span>
                  </ErrorAlert>
                )}
              </Box>
            </Stack>
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={
                "If you have a received a second B notice from the IRS, what is the second B notice date?"
              }
              isRequired
            />
            <Stack direction="row" spacing="32px">
              <Box className="date-box-width-us-specific">
                <AppFormLabel
                  labelName="Date"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("bNotice2ndDt")
                      ?.tooltip_text_main
                  }
                />
                <DatePicker
                  value={values.secondNoticeDate}
                  onUpdate={handleOnChange("secondNoticeDate")}
                  onBlur={handleBlur}
                  ariaLabel="secondNoticeDate"
                  placeholder="Select"
                  place="right"
                />
                {errors.secondNoticeDate && touched.secondNoticeDate && (
                  <ErrorAlert>
                    <span>{errors.secondNoticeDate}</span>
                  </ErrorAlert>
                )}
              </Box>
            </Stack>
          </Box>
          <hr className="financial-specification-hr" />
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"What is the employee status?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("employeeStatus")
                  ?.tooltip_text_main
              }
              isRequired
              labelFor="employeeStatus"
            />
            <SelectDropdown
              value={values.employeeStatus}
              onChange={handleOnChange("employeeStatus")}
              dropdownList={employeeStatusOptions}
              placeholder="Select"
              id="employeeStatus"
            />
            {errors.employeeStatus && touched.employeeStatus && (
              <ErrorAlert>
                <span>{errors.employeeStatus}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"What is the NAICS Code?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("naics")
                  ?.tooltip_text_main
              }
              isRequired
              labelFor="naicsCode"
            />
            <SelectDropdown
              value={values.naicsCode}
              onChange={handleOnChange("naicsCode")}
              dropdownList={naicsCode}
              placeholder="Select"
              id="naicsCode"
            />
            {errors.naicsCode && touched.naicsCode && (
              <ErrorAlert>
                <span>{errors.naicsCode}</span>
              </ErrorAlert>
            )}
          </Box>
        </CardContainer>
        <CardContainer>
          <Heading as="h2" fontSize={"24px"} mt={"1.5rem"} mb={"3rem"}>
            {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
              ?.section_title || "Tax specifications"}
          </Heading>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Did the party provide a signed W8 form?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("isFiledW8")
                  ?.tooltip_text_main
              }
              isRequired
            />
            <SplitButton
              name="isW8filled"
              onSelect={handleOnChange("isW8filled")}
              leftBtnName="Yes"
              rightBtnName="No"
              value={values.isW8filled}
            />
            {errors.isW8filled && touched.isW8filled && (
              <ErrorAlert>
                <span>{errors.isW8filled}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel labelName={"When was the W8 received?"} isRequired />
            <Stack direction="row" spacing="32px">
              <Box className="date-box-width-us-specific">
                <AppFormLabel
                  labelName="Date"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("w8Dt")
                      ?.tooltip_text_main
                  }
                />
                <DatePicker
                  value={values.w8receivedDate}
                  onUpdate={handleOnChange("w8receivedDate")}
                  onBlur={handleBlur}
                  ariaLabel="secondNoticeDate"
                  placeholder="Select"
                  place="right"
                />
                {errors.w8receivedDate && touched.w8receivedDate && (
                  <ErrorAlert>
                    <span>{errors.w8receivedDate}</span>
                  </ErrorAlert>
                )}
              </Box>
            </Stack>
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Did the party provide a signed W9 form?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("isFiledW9")
                  ?.tooltip_text_main
              }
              isRequired
            />
            <SplitButton
              name="isW9filled"
              onSelect={handleOnChange("isW9filled")}
              leftBtnName="Yes"
              rightBtnName="No"
              value={values.isW9filled}
            />
            {errors.isW9filled && touched.isW9filled && (
              <ErrorAlert>
                <span>{errors.isW9filled}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel labelName={"When was the W9 received?"} isRequired />
            <Stack direction="row" spacing="32px">
              <Box className="date-box-width-us-specific">
                <AppFormLabel
                  labelName="Date"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("w9Dt")
                      ?.tooltip_text_main
                  }
                />
                <DatePicker
                  value={values.w9receivedDate}
                  onUpdate={handleOnChange("w9receivedDate")}
                  onBlur={handleBlur}
                  ariaLabel="w9receivedDate"
                  placeholder="Select"
                  place="right"
                />
                {errors.w9receivedDate && touched.w9receivedDate && (
                  <ErrorAlert>
                    <span>{errors.w9receivedDate}</span>
                  </ErrorAlert>
                )}
              </Box>
            </Stack>
          </Box>
          <Box className="app-form-field-container">
            <Table className="table" variant="unstyled" borderRadius="2xl">
              <Thead className="custom-table-header">
                <Tr className="custom-table-tr">
                  <Th className="custom-table-th">
                    <Text fontSize="xl">Enable any of the following</Text>
                  </Th>
                  <Th className="custom-table-th-right">
                    <Text className="custom-table-right-header">
                      header-right
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr className="custom-table-data-row-us-specific">
                  <Td className="custom-table-padding">
                    <AppFormLabel
                      labelName="Is there federal withholding?"
                      isRequired
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("isWthFed")
                          ?.tooltip_text_main
                      }
                    />
                  </Td>
                  <Td className="custom-table-padding-splitButton">
                    <SplitButton
                      name="federalWithHolding"
                      onSelect={handleOnChange("federalWithHolding")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.federalWithHolding}
                    />
                    {errors.federalWithHolding &&
                      touched.federalWithHolding && (
                        <ErrorAlert>
                          <span>{errors.federalWithHolding}</span>
                        </ErrorAlert>
                      )}
                  </Td>
                </Tr>
                <Tr className="custom-table-data-row-us-specific">
                  <Td className="custom-table-padding">
                    <AppFormLabel
                      labelName="Is there NRA withholding?"
                      isRequired
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("isWthNra")
                          ?.tooltip_text_main
                      }
                    />
                  </Td>
                  <Td className="custom-table-padding-splitButton">
                    <SplitButton
                      name="nraWithHolding"
                      onSelect={handleOnChange("nraWithHolding")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.nraWithHolding}
                    />
                    {errors.nraWithHolding && touched.nraWithHolding && (
                      <ErrorAlert>
                        <span>{errors.nraWithHolding}</span>
                      </ErrorAlert>
                    )}
                  </Td>
                </Tr>
                <Tr className="custom-table-data-last-row">
                  <Td className="custom-table-padding">
                    <AppFormLabel
                      labelName="Is there state withholding?"
                      isRequired
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("isWthState")
                          ?.tooltip_text_main
                      }
                    />
                  </Td>
                  <Td className="custom-table-padding-splitButton">
                    <SplitButton
                      name="stateWithHolding"
                      onSelect={handleOnChange("stateWithHolding")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.stateWithHolding}
                    />
                    {errors.stateWithHolding && touched.stateWithHolding && (
                      <ErrorAlert>
                        <span>{errors.stateWithHolding}</span>
                      </ErrorAlert>
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </CardContainer>
        <CardContainer>
          <Heading as="h3" className="heading-styles-h3">
            {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
              ?.section_title || " Bill & statement preferences"}
          </Heading>
          <Box className="app-form-field-container">
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="IRS electronic consent"
                isRequired
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("irsEConsent")
                    ?.tooltip_text_main
                }
              />
              <SplitButton
                name="electronicLoanBill"
                onSelect={handleOnChange("irsConsent")}
                leftBtnName="Yes"
                rightBtnName="No"
                value={values.irsConsent}
              />
              {errors.irsConsent && touched.irsConsent && (
                <ErrorAlert>
                  <span>{errors.irsConsent}</span>
                </ErrorAlert>
              )}
            </Box>
            {values.irsConsent && (
              <Box className="date-box-width-us-specific-irs">
                <AppFormLabel
                  labelName="IRS Electronic Consent End Date"
                  isRequired
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("irsEConsentEndDate")
                      ?.tooltip_text_main
                  }
                />
                <DatePicker
                  value={values.irsConsentDate}
                  onUpdate={handleOnChange("irsConsentDate")}
                  onBlur={handleBlur}
                  ariaLabel="irsConsentDate"
                  placeholder="Select"
                  place="right"
                />

                {errors.irsConsentDate && touched.irsConsentDate && (
                  <ErrorAlert>
                    <span>{ERROR_MESSAGE}</span>
                  </ErrorAlert>
                )}
              </Box>
            )}
            <Table className="table" variant="unstyled" borderRadius="2xl">
              <Thead className="custom-table-header">
                <Tr className="custom-table-tr">
                  <Th className="custom-table-th">
                    <Text fontSize="xl">Enable any of the following</Text>
                  </Th>
                  <Th className="custom-table-th-right">
                    <Text className="custom-table-right-header">
                      header-right
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr className="custom-table-data-row-us-specific">
                  <Td className="custom-table-padding-appLabel">
                    <AppFormLabel
                      labelName="Is there an electronic loan bill?"
                      isRequired
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get(
                          "isElectronicBill"
                        )?.tooltip_text_main
                      }
                    />
                  </Td>
                  <Td className="custom-table-padding-splitButton">
                    <SplitButton
                      name="electronicLoanBill"
                      onSelect={handleOnChange("electronicLoanBill")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.electronicLoanBill}
                    />
                    {errors.electronicLoanBill &&
                      touched.electronicLoanBill && (
                        <ErrorAlert>
                          <span>{errors.electronicLoanBill}</span>
                        </ErrorAlert>
                      )}
                  </Td>
                </Tr>
                <Tr className="custom-table-data-row-us-specific">
                  <Td className="custom-table-padding-appLabel">
                    <AppFormLabel
                      labelName="Is there an electronic statement?"
                      isRequired
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get(
                          "isElectronicStmt"
                        )?.tooltip_text_main
                      }
                    />
                  </Td>
                  <Td className="custom-table-padding-splitButton">
                    <SplitButton
                      name="electronicStatement"
                      onSelect={handleOnChange("electronicStatement")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.electronicStatement}
                    />
                    {errors.electronicStatement &&
                      touched.electronicStatement && (
                        <ErrorAlert>
                          <span>{errors.electronicStatement}</span>
                        </ErrorAlert>
                      )}
                  </Td>
                </Tr>
                <Tr className="custom-table-data-row-us-specific">
                  <Td className="custom-table-padding-appLabel">
                    <AppFormLabel
                      labelName="Is there a paper loan bill?"
                      isRequired
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("isPaperBill")
                          ?.tooltip_text_main
                      }
                    />
                  </Td>
                  <Td className="custom-table-padding-splitButton">
                    <SplitButton
                      name="paperLoanBill"
                      onSelect={handleOnChange("paperLoanBill")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.paperLoanBill}
                    />
                    {errors.paperLoanBill && touched.paperLoanBill && (
                      <ErrorAlert>
                        <span>{errors.paperLoanBill}</span>
                      </ErrorAlert>
                    )}
                  </Td>
                </Tr>
                <Tr className="custom-table-data-last-row">
                  <Td className="custom-table-padding-appLabel">
                    <AppFormLabel
                      labelName="Is there a paper statement?"
                      isRequired
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("isPaperStmt")
                          ?.tooltip_text_main
                      }
                    />
                  </Td>
                  <Td className="custom-table-padding-splitButton">
                    <SplitButton
                      name="paperStatement"
                      onSelect={handleOnChange("paperStatement")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.paperStatement}
                    />
                    {errors.paperStatement && touched.paperStatement && (
                      <ErrorAlert>
                        <span>{errors.paperStatement}</span>
                      </ErrorAlert>
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </CardContainer>
      </Box>
    </form>
  );
};

export default USSpecificationFinancialOrganizationIdentification;
