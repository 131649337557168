import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface 
interface NumberingIdentificationProps {
  isProdPrefix: SplitBtnInputValType;
  isAcctNbrRandom: SplitBtnInputValType;
  chkDigitMthd: string;
}
// Defining Intitial Value 
const defaultNumberingIdentification: NumberingIdentificationProps = {
  isProdPrefix: undefined,
  isAcctNbrRandom: undefined,
  chkDigitMthd: "",
};
// YUP LIBRARY :Defining Schema For validation
const numberingIdentificationSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "isProdPrefix",
    "isAcctNbrRandom",
    "chkDigitMthd",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { NumberingIdentificationProps };
// Exporting Validation Schema and Intial value
export {
  defaultNumberingIdentification,
  numberingIdentificationSchema as getValidationSchema,
};
