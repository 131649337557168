"use client";
import { IFX_ACCT_TYPE } from "@/utils/constants";
import RegulatoryAdherenceLimitation from "../regulatory-adherence-limitation/RegulatoryAdherenceLimitation";
import CheckingRegulatoryAdherenceLimitation from "../checking-regulatory-adherence-limitation/CheckingRegulatoryAdherenceLimitation";
import CdRegulatoryAdherenceLimitations from "../cd-regulatory-adherence-limitations/CdRegulatoryAdherenceLimitations";
import RevolvingInstallmentRegulatoryAdherenceLimitation from "../revolving-Installment-regulatory-adherence-limitations/RevolvingInstallmentRegulatoryAdherenceLimitation";
import { ProductType } from "@/components/product-management/model/product-models";
import { PostSchema } from "@/components/product-management/model/types";
import { useEffect, useState } from "react";
import { getValidationSchema } from "./RegulatoryAdherenceLimitationValidation";

export type RegulatoryAdherenceLimitationProps = {
  data: {
    formData: ProductType;
    redDTransferOptions?: { label: string; value: string }[];
    prodBkOptionsData: PostSchema;
  };
};

export type RegAdhLimProps = {
  data: Omit<RegulatoryAdherenceLimitationProps["data"], "prodBkOptionsData"> & {
    prodBkOptionsSchema: Awaited<ReturnType<typeof getValidationSchema>>
  }
}

const RegulatoryAdherenceLimitationWrapper = (
  props: RegulatoryAdherenceLimitationProps
) => {
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(props.data.prodBkOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [props.data.prodBkOptionsData]);

  const { formData } = props.data;
  switch (formData?.ifxAcctType) {
    case IFX_ACCT_TYPE.SDA:
      return (
        <RegulatoryAdherenceLimitation
          data={{ ...props.data, prodBkOptionsSchema: validationSchema }}
        />
      );
    case IFX_ACCT_TYPE.DDA:
      return (
        <CheckingRegulatoryAdherenceLimitation
          data={{ ...props.data, prodBkOptionsSchema: validationSchema }}
        />
      );
    case IFX_ACCT_TYPE.CDA:
      return (
        <CdRegulatoryAdherenceLimitations
          data={{ ...props.data, prodBkOptionsSchema: validationSchema }}
        />
      );
    case IFX_ACCT_TYPE.LOC:
      return (
        <RevolvingInstallmentRegulatoryAdherenceLimitation
          data={{ ...props.data, prodBkOptionsSchema: validationSchema }}
        />
      );
    case IFX_ACCT_TYPE.ILA:
      return (
        <RevolvingInstallmentRegulatoryAdherenceLimitation
          data={{ ...props.data, prodBkOptionsSchema: validationSchema }}
        />
      );
    default:
      return null;
  }
};

export default RegulatoryAdherenceLimitationWrapper;
