import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

interface LocationContactInformationValueProps {
  cntry: string | undefined;
  contactPref: number | undefined;
  lstContactReason: number | undefined;
  lstContactDate?: Date | null;
}
const locationContactInformation: LocationContactInformationValueProps = {
  cntry: "",
  contactPref: undefined,
  lstContactReason: undefined,
  lstContactDate: new Date(),
};

const locationContactInformationSchema = async (optionsSchema: PostSchema) => {
  const fields = ["contactPref", "lstContactReason", "cntry"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { LocationContactInformationValueProps };
// Exporting Validation Schema and Intial value
export {
  locationContactInformation,
  locationContactInformationSchema as getValidationSchema,
};
