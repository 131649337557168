import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface
interface AdditionalSpecificationsProps {
  tenureDur: string;
  garnishOffsetDur: string;
  userRole: string;
  depChrgOffCloseReason: number;
}
// Defining Intitial Value
const AdditionalSpecificationsDetails: AdditionalSpecificationsProps = {
  tenureDur: "",
  garnishOffsetDur: "",
  userRole: "",
  depChrgOffCloseReason: -1,
};

const additionalSpecificationsSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "tenureDur",
    "garnishOffsetDur",
    "userRole",
    "depChrgOffCloseReason",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { AdditionalSpecificationsProps };
// Exporting Validation Schema and Intial value
export {
  AdditionalSpecificationsDetails,
  additionalSpecificationsSchema as getValidationSchema,
};
