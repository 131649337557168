"use client";
import { ReactNode, useEffect, useState } from "react";

import "./TopMenuManager.scss";

import { useUserAuthenticated, useUserInfo } from "@/app/providers";
import { usePathname, useSearchParams } from "next/navigation";
import { useSelector } from "react-redux";
import {
  getSelectedOrg,
  getAppConfig,
  getOrganizations,
  getEnv,
  getEnvName,
  getEnvStatus,
} from "@/store";
import { getProj } from "@/store/slices/project";
import Link from "next/link";

import {
  vector,
  vectorSelected,
  closeIconMobile,
  hamburger,
} from "public/assets";
import { finxactLogoWhite } from "public/assets";
import OrgEnvDropdownWrapper from "../common/OrgEnvSelector/OrgEnvDropdownWrapper";
import { deepCopy, Header } from "@finxact/finxact-shared-ui";

import { getDocsMenu, getApiMenu } from "@/store/slices/menuData";
import { useIsPermitted } from "@/utils/common";

export default function TopMenuManager() {
  const { user } = useUserInfo();
  const userAuthenticated = useUserAuthenticated();
  const selectedOrg = useSelector(getSelectedOrg);
  const userOrgs = useSelector(getOrganizations);
  const docsMenu = useSelector(getDocsMenu);
  const apisMenu = useSelector(getApiMenu);
  const { envId, envType, version, endpoint, internal } = useSelector(getEnv);
  const project = useSelector(getProj);
  const envName = useSelector(getEnvName);
  const envStatus = useSelector(getEnvStatus);
  const org = useSelector(getSelectedOrg);
  const [envData, setEnvData] = useState<any>();

  useEffect(() => {
    const currentEnv = envStatus.find((env) => env.id === envId);
    const sliceVerIndex = version ? version.indexOf("-") : -1;
    const minifiedVersion =
      sliceVerIndex >= 0 ? version?.substring(0, sliceVerIndex) : null;
    setEnvData(
      deepCopy({
        name: envName,
        version: minifiedVersion,
        serviceEndpoint: currentEnv?.serviceEndpoint ?? "",
      })
    );
  }, [envStatus, envId, envName, version]);

  const env =
    envType === "env" && envId
      ? {
          id: envId,
          name: envName,
          endpoint,
          internal,
        }
      : undefined;

  const proj =
    envType === "proj" && envId
      ? {
          id: envId,
          name: envName,
          status: project?.status,
        }
      : undefined;

  const isPermitted = useIsPermitted();
  const appConfig = useSelector(getAppConfig);
  const pathName = usePathname();

  const isTeaser = !!(
    pathName &&
    (pathName === "" ||
      pathName === "/" ||
      pathName.startsWith("/solutions") ||
      pathName.startsWith("/profile-setup"))
  );

  if (!appConfig) {
    return null;
  }
  return (
    <Header
      project={proj}
      coreVersion={envData?.version}
      org={{
        id: org.id,
        name: org.name,
        implRepoURL: org.implRepoURL,
        serviceTicketURL: org.serviceTicketURL,
        implementationUrl: org.implementationUrl,
      }}
      environment={env}
      chatClosedIcon={vector.src}
      chatOpenIcon={vectorSelected.src}
      hamburgerIcon={hamburger.src}
      closeIcon={closeIconMobile.src}
      consoleConfig={appConfig}
      pathName={pathName ?? ""}
      isPermitted={isPermitted}
      defaultLogo={finxactLogoWhite.src}
      isTeaser={isTeaser}
      organization={selectedOrg}
      orgEnvSelector={<OrgEnvDropdownWrapper />}
      primaryOrg={
        userAuthenticated && userOrgs[user.organization]
          ? userOrgs[user.organization]
          : undefined
      }
      menuData={{ docsMenu, apisMenu }}
      user={userAuthenticated ? user : undefined}
      parent="fast"
      nextRouterLinkComponent={Link}
      serviceEndpoint={envData?.serviceEndpoint}
    />
  );
}
