"use client";
import { Box, useToast, Heading } from "@/components/ChakraUiManager";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CardContainer,
  CoupleRadioWithDropdown,
  DurationInput,
  ErrorAlert,
  FrequencyModule,
  RadioButtonGroup,
} from "@/components/common";

import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  RewardConfigurationProps,
  getValidationSchema,
} from "./RewardConfigurationValidation";
import { useFormik } from "formik";
import {
  addNewComponent,
  createComponentGeneric,
} from "../../product-config-client-service";

import { Option, RjsfData } from "@/utils/types";

import "./RewardConfiguration.scss";

import {
  COMPONENT_CLASS,
  MAIN_FORM_ID,
  PAYOUT_OPTION_PROGRAM_TYPE,
  REWARD_CONFIGURATION_DATA,
  REWARD_CONFIG_RADIO_BTN_DATA,
  TRANSCODE_FLOW_NAME,
} from "@/utils/constants";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import {
  COMPONENT_CLASS_ENUM,
  OptionsSchema,
  PostSchema,
  RewardComponentData,
} from "@/components/product-management/model/types";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  matrixTypeUiSchema,
  prodProgramGroupUiSchema,
  prodProgramUiSchema,
} from "@finxact/finxact-shared-ui";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import {
  useEntityIdFromParam,
  useFieldNameFromParam,
  preventMainFormSubmitOnRJSFSubmit,
} from "@/utils/common";

interface ProdProgramGroupOption extends Option<string> {
  prodProgram: string;
}

interface RewardConfigurationDataProps {
  data: {
    formData: RewardComponentData | null;
    programTypeOptions: Option<number>[];
    productProgramOptions: string[];
    productProgramGroupOptions: ProdProgramGroupOption[];
    rewardAmountOptions: string[];
    rewardInterestOptions: string[];
    transactionCodes: { value: string; label: string }[];
    prodProgramGroupAddNewSchema: OptionsSchema;
    prodProgramGroupAddNewOptionEndpoint: string;
    prodProgramAddNewSchema: OptionsSchema;
    productProgramEndpoint: string;
    addMatrixSchema: OptionsSchema;
    addNewOptionEndpointMatrixType: string;
    addNewSchemaTranCode: OptionsSchema;
    addNewOptiontransactionCodesEndpoint: string;
    rewardConfigurationsOptionsData: PostSchema;
  };
}

const RewardConfiguration = (props: RewardConfigurationDataProps) => {
  const toast = useToast();
  const [validationSchema, setValidationSchema] = useState<any>(null);
  const entityId = useEntityIdFromParam();
  const fieldName = useFieldNameFromParam();

  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.rewardConfigurationsOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.rewardConfigurationsOptionsData]);

  const containsPayoutOption = (payOut: { value: number }[], value: number) => {
    return payOut.some((p) => p.value === value);
  };

  const getInitialRadioButtonValue = (
    goalTerm: string | null,
    payOut: { programType: number }[] | null,
    trnRewardProgram: any
  ) => {
    if (fieldName === "payoutTrnCode" && entityId) {
      return REWARD_CONFIGURATION_DATA.transactionReward;
    }
    if (goalTerm) {
      return REWARD_CONFIGURATION_DATA.savingsGoalReward;
    }
    if (payOut && payOut.length > 0) {
      switch (true) {
        case payOut[0].programType ===
          PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestRate.value:
          return REWARD_CONFIGURATION_DATA.relationshipReward;
        case payOut[0].programType ===
          PAYOUT_OPTION_PROGRAM_TYPE.addBonusRate.value:
          return REWARD_CONFIGURATION_DATA.rolloverReward;
        default:
          return "";
      }
    }
    if (
      trnRewardProgram &&
      Object.values(trnRewardProgram).some((field) => Boolean(field))
    ) {
      return REWARD_CONFIGURATION_DATA.transactionReward;
    }
    return "";
  };

  // Form Submit Handler
  const onSubmit = async (values: RewardConfigurationProps) => {
    if (!dirty && !entityId) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["reward"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      const res = await createComponentGeneric({
        productDetails,
        formData: {
          ...props.data.formData,
          // PATCH Feilds

          payoutOpt:
            values.configuration === REWARD_CONFIGURATION_DATA.savingsGoalReward
              ? values.programType.map((option) => ({
                  programType: option?.value,
                }))
              : values.configuration ===
                  REWARD_CONFIGURATION_DATA.relationshipReward
                ? values.programType.map((option) => ({
                    programType: option?.value,
                  }))
                : values.configuration ===
                    REWARD_CONFIGURATION_DATA.rolloverReward
                  ? values.programType.map((option) => ({
                      programType: option?.value,
                    }))
                  : null,
          goalAmt:
            values.configuration === REWARD_CONFIGURATION_DATA.savingsGoalReward
              ? values.goalAmt
              : null,
          goalTerm:
            values.configuration === REWARD_CONFIGURATION_DATA.savingsGoalReward
              ? values.goalTerm
              : null,

          depInterval:
            containsPayoutOption(
              values.savingGoalRewardProgramType,
              PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestAccrued.value
            ) &&
            values.configuration === REWARD_CONFIGURATION_DATA.savingsGoalReward
              ? values.depInterval
              : null,

          intervalAmt:
            containsPayoutOption(
              values.savingGoalRewardProgramType,
              PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestAccrued.value
            ) &&
            values.configuration === REWARD_CONFIGURATION_DATA.savingsGoalReward
              ? values.intervalAmt
              : "",

          reward:
            values.savingGoalRewardProgramType.some(
              (e) =>
                e.value >
                  PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestAccrued.value &&
                e.value < PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestRate.value
            ) &&
            values.configuration === REWARD_CONFIGURATION_DATA.savingsGoalReward
              ? values.reward
              : null,

          rewardIntPrem:
            (containsPayoutOption(
              values.savingGoalRewardProgramType,
              PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestAccrued.value
            ) &&
              values.configuration ===
                REWARD_CONFIGURATION_DATA.savingsGoalReward) ||
            (containsPayoutOption(
              values.relationshipRewardProgramType,
              PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestRate.value
            ) &&
              values.configuration ===
                REWARD_CONFIGURATION_DATA.relationshipReward) ||
            (containsPayoutOption(
              values.rolloverRewardProgramType,
              PAYOUT_OPTION_PROGRAM_TYPE.addBonusRate.value
            ) &&
              values.configuration === REWARD_CONFIGURATION_DATA.rolloverReward)
              ? values.rewardIntPrem
              : null,

          ...(values.configuration ===
            REWARD_CONFIGURATION_DATA.relationshipReward && values.program
            ? {
                relationshipProgram: {
                  program: values.program,
                  subGroups: values?.subGroups,
                },
              }
            : { relationshipProgram: null }),

          ...(values.configuration ===
            REWARD_CONFIGURATION_DATA.relationshipReward &&
          containsPayoutOption(
            values.relationshipRewardProgramType,
            PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestRate.value
          )
            ? {
                rewardOffset: {
                  reviewFreq: values?.reviewFreq ?? "",
                },
              }
            : { rewardOffset: null }),

          ...(values.configuration ===
          REWARD_CONFIGURATION_DATA.transactionReward
            ? {
                trnRewardProgram: {
                  payoutFreq: values?.payoutFreq ?? "",
                  payoutTrnCode: values.payoutTrnCode,
                },
              }
            : { trnRewardProgram: null }),
        },
        toastOptions: {
          toast: toast,
          toastId: "reward-config",
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
        componentClass: COMPONENT_CLASS_ENUM.componentReward,
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  // using useFormik hook Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
    setTouched,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      programType: props.data.programTypeOptions?.filter((item) =>
        props.data?.formData?.payoutOpt?.some(
          (type) => item.value === type.programType
        )
      ),
      goalTerm: props.data.formData?.goalTerm ?? "",
      goalAmt: props.data.formData?.goalAmt ?? "",
      depInterval: props.data.formData?.depInterval ?? "",
      intervalAmt: props.data.formData?.intervalAmt ?? "",
      reward: props.data.formData?.reward ?? null,
      rewardIntPrem: props.data.formData?.rewardIntPrem ?? null,
      program: props.data.formData?.relationshipProgram?.program ?? null,
      subGroups: props.data.formData?.relationshipProgram?.subGroups ?? [],
      reviewFreq:
        props.data?.formData?.rewardOffset !== undefined
          ? (props.data?.formData?.rewardOffset?.reviewFreq ?? "")
          : "",
      rewardReviewFrequencyModelInputValue: "",
      rewardReviewModalLink: false,
      payoutFreq:
        props.data?.formData?.trnRewardProgram !== undefined
          ? (props.data?.formData?.trnRewardProgram?.payoutFreq ?? "")
          : "",
      modalLink: false,
      rewardPayoutFrequencyModelInputValue: "",
      payoutTrnCode:
        fieldName === "payoutTrnCode" && entityId
          ? entityId
          : (props.data?.formData?.trnRewardProgram?.payoutTrnCode ?? ""),
      configuration:
        props.data.formData !== undefined
          ? getInitialRadioButtonValue(
              props.data.formData?.goalTerm as string,
              props.data.formData?.payoutOpt as { programType: number }[],
              props.data.formData?.trnRewardProgram
            )
          : "",
      savingGoalRewardProgramType: props.data.programTypeOptions?.filter(
        (item) =>
          props.data?.formData?.payoutOpt?.some(
            (type) =>
              item.value === type.programType &&
              item.value < PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestRate.value
          )
      ),
      relationshipRewardProgramType: props.data.programTypeOptions?.filter(
        (item) =>
          props.data?.formData?.payoutOpt?.some(
            (type) =>
              item.value === type.programType &&
              item.value === PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestRate.value
          )
      ),
      rolloverRewardProgramType: props.data.programTypeOptions?.filter((item) =>
        props.data?.formData?.payoutOpt?.some(
          (type) =>
            item.value === type.programType &&
            item.value === PAYOUT_OPTION_PROGRAM_TYPE.addBonusRate.value
        )
      ),
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    productDetails,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const paymentOptionProgramTypeHandler = (val: Option<number>[]) => {
    setFieldValue("savingGoalRewardProgramType", [...val]).then((res) => {
      setFieldValue("programType", [...val]).then((res) => {
        setTouched({});
      });
    });
  };

  const paymentOptionProgramTypeHandler1 = (val: Option<number>[]) => {
    setFieldValue("relationshipRewardProgramType", [...val]).then((res) => {
      setFieldValue("programType", [...val]).then((res) => {
        setTouched({});
      });
    });
  };

  const paymentOptionProgramTypeHandler2 = (val: Option<number>[]) => {
    setFieldValue("rolloverRewardProgramType", [...val]).then((res) => {
      setFieldValue("programType", [...val]).then((res) => {
        setTouched({});
      });
    });
  };
  //Handling Form
  const rewardConfigurationFormHandler =
    (key: string) =>
    (value: string | number | SplitBtnInputValType | boolean | string[]) => {
      if (key === "program") {
        setFieldValue(key, value).then((res) => {
          setFieldValue("subGroups", []);
        });
      } else {
        setFieldValue(key, value);
      }
    };

  const handleOnChange = (key: string) => (value: string) => {
    setFieldValue(key, value).then((res) => {
      setFieldValue("programType", []).then((res) => {
        setTouched({});
      });
    });
  };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  const rewardAmountSchema = useMemo(() => {
    const { actions, name } = props.data.addMatrixSchema;
    return { name, ...actions.POST };
  }, [props.data.addMatrixSchema]);

  const addNewRewardAmountOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.addNewOptionEndpointMatrixType,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("reward", response.matrixName);
  };

  //Transaction
  const transactionCodeSchema = useMemo(() => {
    const { actions, name } = props.data.addNewSchemaTranCode;
    return { name, ...actions.POST };
  }, [props.data.addNewSchemaTranCode]);

  const transactionCodeAddNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.addNewOptiontransactionCodesEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("payoutTrnCode", response.trnCode);
  };

  //Product program group schema
  const prodProgramGroupSchema = useMemo(() => {
    const { actions, name } = props.data.prodProgramGroupAddNewSchema;
    return { name, ...actions.POST };
  }, [props.data.prodProgramGroupAddNewSchema]);

  //Product program schema
  const prodProgramSchema = useMemo(() => {
    const { actions, name } = props.data.prodProgramAddNewSchema;
    return { name, ...actions.POST };
  }, [props.data.prodProgramAddNewSchema]);

  const addNewProdProgramSubGroup = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.prodProgramGroupAddNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    /**
     * If new subGroup falls under the current prodProgram, append to the list of subGroups,
     * else set the new subgroup and set the current prodProgram = prodProgram of the new subGroup
     */
    if (response.prodProgram === values.program) {
      setFieldValue("subGroups", [...values.subGroups, response.subGroup]);
    } else {
      setFieldValue("subGroups", [response.subGroup]).then(() => {
        setFieldValue("program", response.prodProgram);
      });
    }
  };

  const addNewProdProgramGroup = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.productProgramEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    // When new prodProgram is created, reset the subgroup list
    setFieldValue("program", response.prodProgram).then(() => {
      setFieldValue("subGroups", []);
    });
  };

  //Reward Intererst
  const rewardInterestSchema = useMemo(() => {
    const { actions, name } = props.data.addMatrixSchema;
    return { name, ...actions.POST };
  }, [props.data.addMatrixSchema]);

  const addNewRewardInterestOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.addNewOptionEndpointMatrixType,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("rewardIntPrem", response.matrixName);
  };

  const _renderForm = () => {
    switch (values.configuration) {
      case REWARD_CONFIGURATION_DATA.relationshipReward:
        return (
          <>
            <CardContainer>
              <Box className="app-form-field-container">
                <Heading as="h3">{"Payout Options Specifications"}</Heading>
              </Box>

              <Box>
                <AppFormLabel
                  labelName="What is the payout option program type?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "relationshipRewardProgramType"
                    )?.tooltip_text_main
                  }
                  labelFor="relationship_payout_option_program_type"
                />
                <SelectDropdown
                  isMulti
                  valueType="object"
                  dropdownList={props.data.programTypeOptions.filter(
                    (data) =>
                      data.value ===
                      PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestRate.value
                  )}
                  value={values.relationshipRewardProgramType}
                  onChange={paymentOptionProgramTypeHandler1}
                  onBlur={handleBlur}
                  id="relationship_payout_option_program_type"
                />
                {errors.relationshipRewardProgramType &&
                  touched.relationshipRewardProgramType && (
                    <ErrorAlert>
                      <span>{GENERIC_ERROR.required}</span>
                    </ErrorAlert>
                  )}
              </Box>
            </CardContainer>

            {containsPayoutOption(
              values.relationshipRewardProgramType,
              PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestRate.value
            ) && (
              <CardContainer>
                <Box className="app-form-field-container">
                  <Heading as="h3">
                    {"Relationship Reward Specifications"}
                  </Heading>
                </Box>
                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="What is the product program?"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("program")
                        ?.tooltip_text_main
                    }
                    labelFor="relationship_product_program"
                  />
                  <CoupleRadioWithDropdown
                    primaryOptionValue={values.program ?? ""}
                    primaryRadioName={"Select existing product program"}
                    secondaryRadioName={"Add a new product program"}
                    handlePrimaryOptionSelect={rewardConfigurationFormHandler(
                      "program"
                    )}
                    placeHolderName="Select"
                    dropdownList={props.data.productProgramOptions}
                    onBlur={handleBlur}
                    schema={prodProgramSchema}
                    uiSchema={prodProgramUiSchema}
                    onAddNewSubmit={addNewProdProgramGroup}
                    id="relationship_product_program"
                    modelViewUrl={API_ROUTE_CONFIGURATION.productProgram}
                  />
                  {errors.program && touched.program && (
                    <ErrorAlert>
                      <span>{ERROR_MESSAGE}</span>
                    </ErrorAlert>
                  )}
                </Box>
                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="What is the product program group?"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("subGroups")
                        ?.tooltip_text_main
                    }
                    labelFor="relationship_program_subgroup"
                  />
                  <CoupleRadioWithDropdown
                    id="relationship_program_subgroup"
                    isMulti
                    multiselectValue={values.subGroups ?? []}
                    primaryRadioName="Select existing program subgroup"
                    secondaryRadioName="Add new program subgroup"
                    handlePrimaryOptionSelect={rewardConfigurationFormHandler(
                      "subGroups"
                    )}
                    placeHolderName="Select existing program subgroup"
                    dropdownList={props.data.productProgramGroupOptions.filter(
                      (option) => option.prodProgram === values.program
                    )}
                    onBlur={handleBlur}
                    schema={prodProgramGroupSchema}
                    uiSchema={prodProgramGroupUiSchema}
                    onAddNewSubmit={addNewProdProgramSubGroup}
                    modelViewUrl={API_ROUTE_CONFIGURATION.productProgramGroup}
                  />
                  {errors.subGroups && touched.subGroups && (
                    <ErrorAlert>
                      <span>{ERROR_MESSAGE}</span>
                    </ErrorAlert>
                  )}
                </Box>
                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="What is the reward interest premium percentage?"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "relationshipRewardIntPrem"
                      )?.tooltip_text_main
                    }
                    labelFor="relationship_reward_interest_premium_percentage_matrix"
                  />
                  <CoupleRadioWithDropdown
                    primaryOptionValue={values.rewardIntPrem ?? ""}
                    primaryRadioName={
                      "Use an existing reward interest premium percentage matrix"
                    }
                    secondaryRadioName={
                      "Add a new interest premium percentage matrix"
                    }
                    handlePrimaryOptionSelect={rewardConfigurationFormHandler(
                      "rewardIntPrem"
                    )}
                    placeHolderName="Select interest premium percentage"
                    dropdownList={props.data.rewardInterestOptions}
                    onBlur={handleBlur}
                    schema={rewardInterestSchema}
                    uiSchema={matrixTypeUiSchema}
                    onAddNewSubmit={addNewRewardInterestOption}
                    id="relationship_reward_interest_premium_percentage_matrix"
                    modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
                  />
                  {errors.rewardIntPrem && touched.rewardIntPrem && (
                    <ErrorAlert>
                      <span>{errors.rewardIntPrem}</span>
                    </ErrorAlert>
                  )}
                </Box>
                <Box>
                  <FrequencyModule
                    value={values.reviewFreq}
                    onChange={rewardConfigurationFormHandler("reviewFreq")}
                    frequencyQuestionTitle="What is the reward review frequency?"
                    frequencyOutputContent="A review will occur every"
                    frequencyQuestionDescription={
                      tooltipFlyoutDetails?.tooltipsMap?.get("reviewFreq")
                        ?.tooltip_text_main
                    }
                    monthDropdownLabel="What day of the month should this fall?"
                    monthDropdownTooltip={
                      tooltipFlyoutDetails?.tooltipsMap?.get("reviewFreqMon")
                        ?.tooltip_text_main
                    }
                    notBusinessDayTooltip={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "reviewFreqNotBusiness"
                      )?.tooltip_text_main
                    }
                    notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the review occur?"
                  />
                  {errors.reviewFreq && touched.reviewFreq && (
                    <ErrorAlert>
                      <span>{errors.reviewFreq}</span>
                    </ErrorAlert>
                  )}
                </Box>
              </CardContainer>
            )}
          </>
        );
      case REWARD_CONFIGURATION_DATA.savingsGoalReward:
        return (
          <>
            <CardContainer>
              <Box className="app-form-field-container">
                <Heading as="h3">{"Payout options specifications"}</Heading>
              </Box>

              <Box>
                <AppFormLabel
                  labelName="What is the payout option program type?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "savingGoalRewardProgramType"
                    )?.tooltip_text_main
                  }
                  labelFor="savings_payout_option_program_type"
                />
                <SelectDropdown
                  isMulti
                  valueType="object"
                  dropdownList={props.data.programTypeOptions.slice(0, 4)}
                  value={values.savingGoalRewardProgramType}
                  onChange={paymentOptionProgramTypeHandler}
                  onBlur={handleBlur}
                  id="savings_payout_option_program_type"
                />
                {errors.savingGoalRewardProgramType &&
                  touched.savingGoalRewardProgramType && (
                    <ErrorAlert>
                      <span>{GENERIC_ERROR.required}</span>
                    </ErrorAlert>
                  )}
              </Box>
              {containsPayoutOption(
                values.savingGoalRewardProgramType,
                PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestAccrued.value
              ) && (
                <>
                  <Box
                    mt={12}
                    className={"app-form-field-container custom-duration-input"}
                  >
                    <DurationInput
                      labelName={"What is the deposit interval?"}
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("depInterval")
                          ?.tooltip_text_main
                      }
                      value={values.depInterval}
                      onChange={rewardConfigurationFormHandler("depInterval")}
                      modalLinkName={"Enter code manually"}
                      modalTitle={"Enter Deposit Interval"}
                      modalLabelName={"What is the deposit interval?"}
                      modalLabelTooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("depInterval")
                          ?.tooltip_text_main
                      }
                    />

                    {errors.depInterval && touched.depInterval && (
                      <ErrorAlert>
                        <span>{errors.depInterval}</span>
                      </ErrorAlert>
                    )}
                  </Box>
                  <Box className="reward-number-input">
                    <AppNumberInput
                      value={values.intervalAmt}
                      labelName="What is the interval amount?"
                      onChange={rewardConfigurationFormHandler("intervalAmt")}
                      valuePrefix="$"
                      onBlur={handleBlur}
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("intervalAmt")
                          ?.tooltip_text_main
                      }
                    />
                    {errors.intervalAmt && touched.intervalAmt && (
                      <ErrorAlert>
                        <span>{errors.intervalAmt}</span>
                      </ErrorAlert>
                    )}
                  </Box>
                </>
              )}
            </CardContainer>
            <CardContainer>
              <Box className="app-form-field-container">
                <Heading as="h3">
                  {"Savings goal reward specifications"}
                </Heading>
              </Box>

              <Box className="app-form-field-container custom-duration-input">
                <DurationInput
                  labelName="What is the goal term?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("goalTerm")
                      ?.tooltip_text_main
                  }
                  value={values.goalTerm}
                  onChange={rewardConfigurationFormHandler("goalTerm")}
                  modalLinkName={"Enter code manually"}
                  modalTitle={"Enter Goal Term"}
                  modalLabelName="What is the goal term?"
                  modalLabelTooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("goalTerm")
                      ?.tooltip_text_main
                  }
                />

                {errors.goalTerm && touched.goalTerm && (
                  <ErrorAlert>
                    <span>{errors.goalTerm}</span>
                  </ErrorAlert>
                )}
              </Box>

              <Box className="reward-number-input">
                <AppNumberInput
                  labelName="What is the savings goal amount?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("goalAmt")
                      ?.tooltip_text_main
                  }
                  value={values.goalAmt}
                  onChange={rewardConfigurationFormHandler("goalAmt")}
                  valuePrefix={"$"}
                  onBlur={handleBlur}
                />
                {errors.goalAmt && touched.goalAmt && (
                  <ErrorAlert>
                    <span>{errors.goalAmt}</span>
                  </ErrorAlert>
                )}
              </Box>

              {values.savingGoalRewardProgramType.some(
                (e) =>
                  e.value >
                    PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestAccrued.value &&
                  e.value < PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestRate.value
              ) && (
                <>
                  <Box mt={12}>
                    <AppFormLabel
                      labelName="What is the reward amount?"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("reward")
                          ?.tooltip_text_main
                      }
                      labelFor="savings_reward_amount_matrix"
                    />
                    <CoupleRadioWithDropdown
                      primaryOptionValue={values.reward ?? ""}
                      primaryRadioName={"Use an existing rewards amount matrix"}
                      secondaryRadioName={"Add a new rewards amount matrix"}
                      handlePrimaryOptionSelect={rewardConfigurationFormHandler(
                        "reward"
                      )}
                      placeHolderName="Select reward amount matrix"
                      dropdownList={props.data.rewardAmountOptions}
                      onBlur={handleBlur}
                      schema={rewardAmountSchema}
                      uiSchema={matrixTypeUiSchema}
                      onAddNewSubmit={addNewRewardAmountOption}
                      id="savings_reward_amount_matrix"
                    />

                    {errors.reward && touched.reward && (
                      <ErrorAlert>
                        <span>{errors.reward}</span>
                      </ErrorAlert>
                    )}
                  </Box>
                </>
              )}
              {containsPayoutOption(
                values.savingGoalRewardProgramType,
                PAYOUT_OPTION_PROGRAM_TYPE.adjustInterestAccrued.value
              ) && (
                <>
                  <Box mt={12}>
                    <AppFormLabel
                      labelName="What is the reward interest premium percentage?"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("rewardIntPrem")
                          ?.tooltip_text_main
                      }
                      labelFor="savings_reward_interest_premium_percentage_matrix"
                    />
                    <CoupleRadioWithDropdown
                      primaryOptionValue={values.rewardIntPrem ?? ""}
                      primaryRadioName={
                        "Use an existing reward interest premium percentage matrix"
                      }
                      secondaryRadioName={
                        "Add a new interest premium percentage matrix"
                      }
                      handlePrimaryOptionSelect={rewardConfigurationFormHandler(
                        "rewardIntPrem"
                      )}
                      placeHolderName="Select interest premium percentage"
                      dropdownList={props.data.rewardInterestOptions}
                      onBlur={handleBlur}
                      schema={rewardInterestSchema}
                      uiSchema={matrixTypeUiSchema}
                      onAddNewSubmit={addNewRewardInterestOption}
                      id="savings_reward_interest_premium_percentage_matrix"
                      modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
                    />
                    {errors.rewardIntPrem && touched.rewardIntPrem && (
                      <ErrorAlert>
                        <span>{errors.rewardIntPrem}</span>
                      </ErrorAlert>
                    )}
                  </Box>
                </>
              )}
            </CardContainer>
          </>
        );
      case REWARD_CONFIGURATION_DATA.rolloverReward:
        return (
          <>
            <CardContainer>
              <Box className="app-form-field-container">
                <Heading as="h3">{"Payout Options Specifications"}</Heading>
              </Box>

              <Box>
                <AppFormLabel
                  labelName="What is the payout option program type?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "rolloverRewardProgramType"
                    )?.tooltip_text_main
                  }
                  labelFor="rollover_payout_option_program_type"
                />
                <SelectDropdown
                  isMulti
                  valueType="object"
                  dropdownList={props.data.programTypeOptions.filter(
                    (data) =>
                      data.value ===
                      PAYOUT_OPTION_PROGRAM_TYPE.addBonusRate.value
                  )}
                  value={values.rolloverRewardProgramType}
                  onChange={paymentOptionProgramTypeHandler2}
                  onBlur={handleBlur}
                  id="rollover_payout_option_program_type"
                />
                {errors.rolloverRewardProgramType &&
                  touched.rolloverRewardProgramType && (
                    <ErrorAlert>
                      <span>{GENERIC_ERROR.required}</span>
                    </ErrorAlert>
                  )}
              </Box>
            </CardContainer>

            {containsPayoutOption(
              values.rolloverRewardProgramType,
              PAYOUT_OPTION_PROGRAM_TYPE.addBonusRate.value
            ) && (
              <CardContainer>
                <Box className="app-form-field-container">
                  <Heading as="h3">{"Rollover Reward Specifications"}</Heading>
                </Box>
                <Box>
                  <AppFormLabel
                    labelName="What is the reward interest premium percentage?"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "rolloverRewardIntPrem"
                      )?.tooltip_text_main
                    }
                    labelFor="rollover_reward_interest_premium_percentage_matrix"
                  />
                  <CoupleRadioWithDropdown
                    primaryOptionValue={values.rewardIntPrem ?? ""}
                    primaryRadioName={
                      "Use an existing reward interest premium percentage matrix"
                    }
                    secondaryRadioName={
                      "Add a new interest premium percentage matrix"
                    }
                    handlePrimaryOptionSelect={rewardConfigurationFormHandler(
                      "rewardIntPrem"
                    )}
                    placeHolderName="Select interest premium percentage"
                    dropdownList={props.data.rewardInterestOptions}
                    onBlur={handleBlur}
                    schema={rewardInterestSchema}
                    uiSchema={matrixTypeUiSchema}
                    onAddNewSubmit={addNewRewardInterestOption}
                    id="rollover_reward_interest_premium_percentage_matrix"
                    modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
                  />
                  {errors.rewardIntPrem && touched.rewardIntPrem && (
                    <ErrorAlert>
                      <span>{errors.rewardIntPrem}</span>
                    </ErrorAlert>
                  )}
                </Box>
              </CardContainer>
            )}
          </>
        );
      case REWARD_CONFIGURATION_DATA.transactionReward:
        return (
          <>
            <CardContainer>
              <Box className="app-form-field-container">
                <Heading as="h3">{"Transaction Reward Specifications"}</Heading>
              </Box>
              <Box className="app-form-field-container">
                <FrequencyModule
                  value={values.payoutFreq}
                  onChange={rewardConfigurationFormHandler("payoutFreq")}
                  frequencyQuestionTitle="How often do you want to pay out the transaction reward?"
                  frequencyOutputContent="Transaction rewards will be paid out every"
                  frequencyQuestionDescription={
                    tooltipFlyoutDetails?.tooltipsMap?.get("payoutFreq")
                      ?.tooltip_text_main
                  }
                  monthDropdownLabel="What date during the period should this happen on?"
                  onBlur={handleBlur}
                  monthDropdownTooltip={
                    tooltipFlyoutDetails?.tooltipsMap?.get("payoutFreqMon")
                      ?.tooltip_text_main
                  }
                  notBusinessDayTooltip={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "payoutFreqNotBusiness"
                    )?.tooltip_text_main
                  }
                  notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the payout be applied?"
                />
                {errors.payoutFreq && touched.payoutFreq && (
                  <ErrorAlert>
                    <span>{errors.payoutFreq}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box>
                <AppFormLabel
                  labelName="What is the transaction reward payout transaction code?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("payoutTrnCode")
                      ?.tooltip_text_main
                  }
                  labelFor="transaction_reward_payout_transaction_code"
                />
                <CoupleRadioWithDropdown
                  primaryOptionValue={values.payoutTrnCode}
                  primaryRadioName="Select from existing transaction code"
                  secondaryRadioName="Create new transaction code"
                  dropdownList={props.data.transactionCodes}
                  handlePrimaryOptionSelect={rewardConfigurationFormHandler(
                    "payoutTrnCode"
                  )}
                  setFieldValue={setFieldValue}
                  placeHolderName="Transaction Code"
                  onBlur={handleBlur}
                  schema={transactionCodeSchema}
                  onAddNewSubmit={transactionCodeAddNewOption}
                  id="transaction_reward_payout_transaction_code"
                  addNewFieldType="routeToTransCode"
                  addNewFieldQueryParam={`${QUERY_PARAM_KEY["PRODUCT_NAME_KEY"]}=${productDetails?.name}&${ROUTE_PATH.FLOW_NAME_QUERY}${TRANSCODE_FLOW_NAME.rewardConfig}&${QUERY_PARAM_KEY.FIELD_NAME}=payoutTrnCode`}
                  modelViewUrl={ROUTE_PATH.TC_FOUNDATIONAL_DETAILS}
                  withDescription
                />
                {errors.payoutTrnCode && touched.payoutTrnCode && (
                  <ErrorAlert>
                    <span>{errors.payoutTrnCode}</span>
                  </ErrorAlert>
                )}
              </Box>
            </CardContainer>
          </>
        );
    }
  };

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      noValidate
      id={MAIN_FORM_ID}
    >
      <CardContainer>
        <Box>
          <AppFormLabel
            labelName="What kind of reward would you like to configure?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("configuration")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.configuration}
            onChange={handleOnChange("configuration")}
            radioPropList={REWARD_CONFIG_RADIO_BTN_DATA}
            stackDirection={"column"}
            spacing={"1rem"}
          />
          {errors.configuration && touched.configuration && (
            <ErrorAlert>
              <span>{errors.configuration}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>

      {_renderForm()}
    </form>
  );
};
export default RewardConfiguration;
