"use client";
import {
  AppFormLabel,
  CardContainer,
  InputText,
  ErrorAlert,
} from "@/components/common";
import { Box, useToast } from "@/components/ChakraUiManager";
import { useContext, useEffect, useState } from "react";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import {
  GeneralParty,
  generalPartyParameter,
  getValidationSchema,
} from "./GeneralPartyIdentificationValidation";
import { ROUTE_PATH } from "@/route-config/route-path";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import {
  createFinOrg,
  updateFinOrg,
} from "@/components/institutional-configuration/ic-config-api-service";
import {
  DUPLICATE_CONFIG,
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  NEW_IC_ENTITY_ID,
} from "@/utils/constants";
import {
  checkIfICWorflowNeedsToBeCreated,
  getIcCopyFlagInStorage,
  setIcCopyFlagInStorage,
} from "@/components/institutional-configuration/ic-utils-service";
import { createWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { useQueryParams, useEntityId } from "@/utils/common";

type GenPartyIdentTyp = {
  data: {
    formData: FinancialInstitutionData | null;
    partyOrgFinistOptionsData: PostSchema;
  };
};
const GeneralPartyIdentification = (props: GenPartyIdentTyp) => {
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const toast = useToast();
  const toastId = "general-party-identification";
  const updateQueryParams = useQueryParams();
  const entityId = useEntityId();
  const isDuplicateConfig = getIcCopyFlagInStorage();
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.partyOrgFinistOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.partyOrgFinistOptionsData]);
  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_PARTY_EMAILS_DETAILS"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  // Submit form
  const onSubmit = async (values: GeneralParty, actions: any) => {
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.financial_organization
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: IC_ENTITY.financial_organization,
          status: FIN_ORG_SUB_STEPS.gen_party_identification,
        });
      }
      moveToNextStep();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };
    if (!props.data.formData || getIcCopyFlagInStorage()) {
      response = await createFinOrg({
        formData: {
          ...props.data?.formData,
          _Id: "",
          name: values.name,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: "Financial institution created.",
        },
        stageName: isDuplicateConfig
          ? DUPLICATE_CONFIG
          : FIN_ORG_SUB_STEPS.gen_party_identification,
      });
    } else {
      response = await updateFinOrg({
        formData: {
          _vn: props.data.formData._vn,
          _Id: values.partyIdentifier,
          name: values.name,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${configPageTitle} updated.`,
        },
        // Don't udpate workflow for first step of each entity as it will be handled while creating the Entity
        stageName: "",
      });
    }
    if (response) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.financial_organization
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: IC_ENTITY.financial_organization,
          status: FIN_ORG_SUB_STEPS.gen_party_identification,
        });
      }
      setIcCopyFlagInStorage(false);
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_PARTY_EMAILS_DETAILS"]
        }?${updateQueryParams("entityId", response._Id)}`
      );
    }
  };

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    initialValues: {
      name: getIcCopyFlagInStorage()
        ? generalPartyParameter.name
        : (props?.data?.formData?.name ?? generalPartyParameter.name),
    },
    validationSchema,
    onSubmit,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  const handleInputChange = (value: string) => {
    setFieldValue("name", value);
  };

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      const backEntityId = entityWorkflowData?.find(
        (entity) => entity.stage === IC_ENTITY.financial_calendar
      )?.modelKey;
      navigateTo(
        `${ROUTE_PATH.IC_FINANCIAL_CALENDER_HOLIDAY_CONFIGURATION}?entityId=${backEntityId ?? NEW_IC_ENTITY_ID}`
      );
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Box>
          <AppFormLabel
            labelName="What is the party name?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("partyName")
                ?.tooltip_text_main
            }
            isRequired
          />
          <InputText
            name="featureName"
            value={values.name}
            onChange={handleInputChange}
            onBlur={handleBlur}
            placeholder="Enter party name"
            disabled={
              Boolean(props.data.formData?.name) && !getIcCopyFlagInStorage()
            }
          />
          {errors.name && touched.name && (
            <ErrorAlert>
              <span>{errors.name}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};
export default GeneralPartyIdentification;
