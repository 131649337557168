import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Limit_Account_Balance_Configuration
interface AccountBalanceConfigurationProps {
  isMinBalRequired: SplitBtnInputValType;
  minBal: string;
  minToOpen: string;
  maxBal: string;
  deminimisAmt: string;
  minBalFee: string;
  minBalOpt: number | undefined;
}
// Defining Intitial Value For Limit_Account_Balance_Configuration
const accountBalanceConfiguration: AccountBalanceConfigurationProps = {
  isMinBalRequired: undefined,
  minBal: "",
  minToOpen: "",
  maxBal: "",
  deminimisAmt: "",
  minBalFee: "",
  minBalOpt: undefined,
};

//Error_Message
const accountConfigError = {
  minRequBalance: "Please enter the minimum required balance",
  minAmountOpen: "Please enter minimum amount to open",
  maxBalance: "Please enter  maximum balance",
  deminAmount: "Please enter  deminimis amount",
};

// YUP LIBRARY : Defining Schema For Validation Of Limit_Account_Balance_Configuration
const accountBalanceConfigurationSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "minBal",
    "minToOpen",
    "maxBal",
    "deminimisAmt",
    "minBalFee",
    "minBalOpt",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

export type { AccountBalanceConfigurationProps };
export {
  accountBalanceConfiguration,
  accountConfigError,
  accountBalanceConfigurationSchema as getValidationSchema,
};
