"use client";

import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  InputText,
  SplitButton,
  AddNewButton,
  AccordionComponent,
  AppNumberInput,
  SelectDropdown,
  SplitBtnInputValType,
} from "@/components/common";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  RepayPastDueConfigProps,
  repayPastDueConfigAndTracking,
  getValidationSchema,
} from "./RepaymentPastDueConfigurationsAndTrackingValidation";
import { Box, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { COMPONENT_CLASS } from "@/utils/constants";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { createComponentGeneric } from "../../product-config-client-service";
import {
  COMPONENT_CLASS_ENUM,
  PostSchema,
  RepaymentComponentData,
} from "@/components/product-management/model/types";
import { Option } from "@/utils/types";

type Props = {
  data: {
    formData: RepaymentComponentData | null;
    repayPaymentOptionsData: PostSchema;
    calcMthdAPIData: Option<string>[];
  };
};

const RepaymentPastDueConfigurationsAndTracking = (props: Props) => {
  const { formData } = props.data;
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.repayPaymentOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.repayPaymentOptionsData]);

  const toast = useToast();
  // Form Submit Handler
  const onSubmit = async (values: RepayPastDueConfigProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["repay"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && productDetails) {
      const res = await createComponentGeneric({
        productDetails,
        formData: {
          ...formData,

          dueItems: values.dueItems.map((dueItem: any, index: number) => ({
            calcMthd: dueItem.calcMthd,
            description: dueItem.description,
            isReceivable: dueItem.isReceivable,
            itemName: dueItem.itemName,
            subBal: dueItem.subBal,
            toleranceAmt: dueItem.toleranceAmt,
            tolerancePct: dueItem.tolerancePct,
          })),
        },
        toastOptions: {
          toast,
          toastId: "repay-foundational-pastdue-config-detail",
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
        componentClass: COMPONENT_CLASS_ENUM.componentRepay,
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      dueItems:
        props.data.formData?.dueItems?.map((item, idx) => ({
          ...item,
          idx,
        })) ?? [],
    },
  });
  // using useFormik hook from Formik Library
  const { values, handleSubmit, errors, touched, setFieldValue, dirty } =
    formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);
  //context API
  const {
    isGoingBack,
    productDetails,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  // Handler for all the form fields
  const handleOnChange =
    (key: string, index: number) =>
    (value: string | number | boolean | SplitBtnInputValType) => {
      setFieldValue(`dueItems.${index}.${key}`, value);
    };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  //Form_Error_Handler
  const _errorHandler = (key: string, index: number) => {
    return (
      <>
        {touched.dueItems &&
          touched.dueItems[index] &&
          (touched.dueItems[index] as any)[key] &&
          errors.dueItems &&
          errors.dueItems[index] &&
          (errors.dueItems[index] as any)[key] && (
            <ErrorAlert>
              <span>{(errors.dueItems[index] as any)[key]}</span>
            </ErrorAlert>
          )}
      </>
    );
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} id="finxact-form" noValidate>
        <FieldArray
          name="dueItems"
          render={({ insert, remove, push }) => (
            <>
              {values.dueItems.map((item, index) => (
                <CardContainer key={item.idx}>
                  <AccordionComponent
                    accordionTitle={`Due item ${index + 1}`}
                    deleteIcon
                    onDeleteHandler={() => remove(index)}
                    isExpand={0}
                  >
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the name of the Due item?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("pastDue")
                            ?.tooltip_text_main
                        }
                        labelFor="dueItem-name_input"
                      />
                      <InputText
                        value={item.itemName}
                        onChange={handleOnChange("itemName", index)}
                        id="dueItem-name"
                        label={"dueItemDescription"}
                        name={"item.itemName"}
                      />
                      {_errorHandler("itemName", index)}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the Due item description?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("desc")
                            ?.tooltip_text_main
                        }
                        labelFor="dueItem-description_input"
                      />
                      <InputText
                        value={item.description}
                        onChange={handleOnChange("description", index)}
                        id="dueItem-description"
                        label={"dueItemDescription"}
                        name={"item.description"}
                      />
                      {_errorHandler("description", index)}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What are the sub balance details?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("subBal")
                            ?.tooltip_text_main
                        }
                        labelFor="dueItem-subBal_input"
                      />
                      <InputText
                        value={item.subBal}
                        onChange={handleOnChange("subBal", index)}
                        id="dueItem-subBal"
                        label={"dueItemSubBal"}
                        name={"item.subBal"}
                      />
                      {_errorHandler("subBal", index)}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppNumberInput
                        labelName="What are the tolerance percentage details?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("tolerancePct")
                            ?.tooltip_text_main
                        }
                        value={item.tolerancePct ?? ""}
                        onChange={handleOnChange("tolerancePct", index)}
                        valueSuffix="%"
                      />
                    </Box>
                    <Box className="app-form-field-container">
                      <AppNumberInput
                        labelName="What is the tolerance amount?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("toleranceAmt")
                            ?.tooltip_text_main
                        }
                        value={item.toleranceAmt ?? ""}
                        onChange={handleOnChange("toleranceAmt", index)}
                        valuePrefix="$"
                      />
                      {_errorHandler("toleranceAmt", index)}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="Should this due item be generated as a standalone receivable?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("isReceivable")
                            ?.tooltip_text_main
                        }
                        labelFor="dueItem-subBal_input"
                      />
                      <SplitButton
                        name="isReceivable"
                        onSelect={handleOnChange("isReceivable", index)}
                        leftBtnName="Yes"
                        rightBtnName="No"
                        value={item.isReceivable}
                      />
                      {_errorHandler("isReceivable", index)}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the calculation method?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("calcMthd")
                            ?.tooltip_text_main
                        }
                        labelFor="dueItem-calcMthd"
                      />
                      <SelectDropdown
                        id="dueItem-calcMthd"
                        value={item.calcMthd!}
                        onChange={handleOnChange("calcMthd", index)}
                        dropdownList={props.data.calcMthdAPIData}
                      />
                      {_errorHandler("calcMthd", index)}
                    </Box>
                  </AccordionComponent>
                </CardContainer>
              ))}
              <Box>
                <AddNewButton
                  label={
                    !values.dueItems?.length
                      ? "Add a due item"
                      : "Add another due item"
                  }
                  onClick={() => {
                    push({
                      ...repayPastDueConfigAndTracking.dueItems[0],
                      idx: Math.random(),
                    });
                  }}
                />
              </Box>
            </>
          )}
        />
      </form>
    </FormikProvider>
  );
};

export default RepaymentPastDueConfigurationsAndTracking;
