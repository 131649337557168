import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { GENERIC_ERROR } from "@/components/data/error-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Account_Type_Relationship_Additional_Selections

interface AccountTypeRelationshipAdditionalSelectionsValueProps {
  hasAccessRights: SplitBtnInputValType;
  isBillSuppressed: SplitBtnInputValType;
  isStmtSuppressed: SplitBtnInputValType;
  isTaxOwner: SplitBtnInputValType;
}

// Defining Intitial Value For Account_Type_Relationship_Additional_Selections
const accountTypeRelationshipAdditionalSelections: AccountTypeRelationshipAdditionalSelectionsValueProps =
  {
    hasAccessRights: undefined,
    isBillSuppressed: undefined,
    isStmtSuppressed: undefined,
    isTaxOwner: undefined,
  };

// YUP LIBRARY :Defining Schema For validation of Account_Type_Relationship_Additional_Selections

const accountTypeRelationshipAdditionalSelectionsSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = ["rels"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { AccountTypeRelationshipAdditionalSelectionsValueProps };
// Exporting Validation Schema and Intial value
export {
  accountTypeRelationshipAdditionalSelections,
  accountTypeRelationshipAdditionalSelectionsSchema as getValidationSchema,
};
