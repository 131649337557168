"use client";
import { Box, Button, CardBody, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  DurationInput,
  ErrorAlert,
  InputText,
  StaticText,
} from "@/components/common";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  AdditionalProductFormProps,
  getValidationSchema,
} from "./AdditionalProductFormValidation";

import { updateProduct, updateWorkflowGeneric } from "@/api-config/api-service";
import { ProductType } from "@/components/product-management/model/product-models";
import {
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS,
  DASHBOARD_ACCORDION_STATUS,
} from "@/utils/constants";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { isProductTypeIsLoan } from "@/components/product-management/product-management-util-service";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: ProductType;
    prodBkOptionsData: PostSchema;
  };
};

const initialServiceConfig = {
  svcName: "",
};

const AdditionalProductForm = (props: Props) => {
  const toast = useToast();
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    tooltipFlyoutDetails,
    getWorflowStatusToBeUpdated,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const { formData } = props.data;

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(props.data.prodBkOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [props.data.prodBkOptionsData]);

  const onSubmit = async (values: AdditionalProductFormProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage:
            ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS["additionalProductFeatures"],
          status: DASHBOARD_ACCORDION_STATUS.completed,
        });
      return;
    }

    if (formData) {
      const payload = {
        fundTerm: values.fundTerm,
        zeroBalAlertDays: parseInt(values.zeroBalAlertDays),
        zeroBalCloseDays: parseInt(values.zeroBalCloseDays),
        apy: parseFloat(values.apy),
        svcs: values?.svcs?.length
          ? values?.svcs.filter((item) => item.svcName)
          : [],
      };
      const res = await updateProduct({
        productName: formData.name,
        body: JSON.stringify({
          // NOTE: _vn, componentName, version is required to do a PATCH update
          _vn: formData?._vn,
          // PATCH fields
          ...payload,
        } as ProductType),
      });

      if (res && productDetails?.name) {
        if (!toast.isActive("additional-product-components")) {
          toast({
            id: "additional-product-components",
            description: `${configPageTitle} updated.`,
            status: "success",
          });
        }

        getExistingProduct?.(productDetails.name, true);
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails.name,
          stage:
            ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS["additionalProductFeatures"],
          status: DASHBOARD_ACCORDION_STATUS.completed,
        });
      }
    }
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      fundTerm: formData?.fundTerm ?? "",
      zeroBalAlertDays: formData?.zeroBalAlertDays?.toString() ?? "",
      zeroBalCloseDays: formData?.zeroBalCloseDays?.toString() ?? "",
      apy: formData?.apy?.toString() ?? "",
      svcs: formData?.svcs?.length ? formData.svcs : [],
    },
  });
  const {
    values,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    dirty,
    handleBlur,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]); // Handle form field changes
  const handleFormData =
    (key: string, index?: number) => (value: string | number) => {
      if (key === "svcName" && index !== undefined) {
        setFieldValue(`svcs.${index}.${key}`, value);
      }
      setFieldValue(key, value);
    };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (navigateToNextPage) navigateToNextPage();
  }, [canCheckFormStatus]);

  return (
    <FormikProvider value={formik}>
      <form id="finxact-form" onSubmit={handleSubmit} noValidate>
        <CardContainer>
          <Box className="app-form-field-container">
            <StaticText
              className="additional-description-area"
              textValue={"Define additional attributes for your product"}
            />
          </Box>
          {!isProductTypeIsLoan(productDetails) ? (
            <>
              <Box className="app-form-field-container">
                <DurationInput
                  labelName={"What is the funding term?"}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("fundTerm")
                      ?.tooltip_text_main
                  }
                  value={values.fundTerm}
                  onChange={handleFormData("fundTerm")}
                  modalLinkName={"Enter code manually"}
                  modalTitle={"Enter Funding Term"}
                  modalLabelName={"What is funding term?"}
                  modalLabelTooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("fundTerm")
                      ?.tooltip_text_main
                  }
                  showDashedLeftBorder
                />
                {errors.fundTerm && touched.fundTerm && (
                  <ErrorAlert>
                    <span>{errors.fundTerm}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What are the zero balance close days?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("zeroBalCloseDays")
                      ?.tooltip_text_main
                  }
                />
                <StaticText textValue="How long should an account stay at a zero balance before closing?" />
                <AppNumberInput
                  value={values.zeroBalCloseDays ? values.zeroBalCloseDays : ""}
                  onChange={handleFormData("zeroBalCloseDays")}
                />
                {errors.zeroBalCloseDays && touched.zeroBalCloseDays && (
                  <ErrorAlert>
                    <span>{errors.zeroBalCloseDays}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What are the zero balance alert days?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("zeroBalAlertDays")
                      ?.tooltip_text_main
                  }
                />
                <StaticText textValue="How many days before closing should an alert be sent out?" />
                <AppNumberInput
                  value={values.zeroBalAlertDays ? values.zeroBalAlertDays : ""}
                  onChange={handleFormData("zeroBalAlertDays")}
                />
                {errors.zeroBalAlertDays && touched.zeroBalAlertDays && (
                  <ErrorAlert>
                    <span>{errors.zeroBalAlertDays}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel labelName="What is the annual percentage yield (APY)?" />
                <AppNumberInput
                  value={values.apy}
                  onChange={handleFormData("apy")}
                  valueSuffix="%"
                  placeholder="0%"
                />
                {errors.apy && touched.apy && (
                  <ErrorAlert>
                    <span>{errors.apy}</span>
                  </ErrorAlert>
                )}
              </Box>
            </>
          ) : (
            ""
          )}
          <FieldArray
            name="svcs"
            render={({ push }) => (
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What are the services offered with this product?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("svcName")
                      ?.tooltip_text_main
                  }
                />
                {values.svcs?.map((service, index) => (
                  <Box key={`additionAttributeServiceBox${index}`} mb={6}>
                    <InputText
                      key={`additionAttributeServiceInput${index}`}
                      value={service.svcName}
                      onChange={handleFormData("svcName", index)}
                      onBlur={handleBlur}
                      label="What are the services offered with this product?"
                      name="svcName"
                      placeholder="Enter service name"
                    />
                  </Box>
                ))}
                <Button
                  mt={5}
                  className="app-btn-link"
                  onClick={() => {
                    push({
                      ...initialServiceConfig,
                    });
                  }}
                >
                  + Add new service
                </Button>
              </Box>
            )}
          />
        </CardContainer>
      </form>
    </FormikProvider>
  );
};

export default AdditionalProductForm;
