import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface
interface TaxPartyEmailContactProps {
  emailType: number | undefined;
  data: string;
  label: string;
  isPreferred: SplitBtnInputValType;
  emailValidFromDate: Date | null;
  emailValidFromTime: string;
  emailValidThroughDate: Date | null;
  emailValidThroughTime: string;
  emailVerifiedOnDate: Date | null;
  emailVerifiedOnTime: string;
}
interface TaxPartyEmailContactArrayProps {
  name: string;
  emails?: TaxPartyEmailContactProps[];
}
// Defining Intitial Value
const TaxPartyEmailContactDetails: TaxPartyEmailContactProps = {
  emailType: undefined,
  data: "",
  label: "",
  isPreferred: undefined,
  emailValidFromDate: null,
  emailValidFromTime: "",
  emailValidThroughDate: null,
  emailValidThroughTime: "",
  emailVerifiedOnDate: null,
  emailVerifiedOnTime: "",
};
const taxPtyEmlCntctDtl: TaxPartyEmailContactArrayProps = {
  name: "",
  emails: [TaxPartyEmailContactDetails],
};

const TaxPartyEmailContactSchema = async (optionsSchema: PostSchema) => {
  const fields = ["name", "taxPartyContact"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};

// Exporting Interface
export type { TaxPartyEmailContactProps, TaxPartyEmailContactArrayProps };
// Exporting Validation Schema and Intial value
export {
  TaxPartyEmailContactDetails,
  taxPtyEmlCntctDtl,
  TaxPartyEmailContactSchema as getValidationSchema,
};
