"use client";

import {
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  ErrorAlert,
  InputText,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, Heading, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  LocationContactInfoProps,
  getValidationSchema,
} from "./LocationContactInfoValidation";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  SIDEBAR_STATUS,
} from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useQueryParams, shouldUpdateWorkflowStatus } from "@/utils/common";

type Props = {
  data: {
    formData: FinancialInstitutionData;
    regionCodeOptions: { ctryCode: string; label: any; value: any }[];
    partyOrgFininstOptionsData: PostSchema;
  };
};

const LocationContactInfo = ({ data }: Props) => {
  const toast = useToast();
  const toastId = "general-organization-location-contact";
  const updateQueryParams = useQueryParams();
  const { partyOrgFininstOptionsData } = data;

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_IDENTIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  const onSubmit = async (values: LocationContactInfoProps, actions: any) => {
    const currentStage = FIN_ORG_SUB_STEPS.gen_org_party_location_contact_info;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }

    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: data.formData._vn,
        _Id: data.formData._Id,
        name: data.formData.name,
        webSiteURL: values.webSiteURL,
        emailDomain: values.emailDomain,
        region: values.region === "" ? null : values.region,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      updateFormStatus?.(SIDEBAR_STATUS.completed);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
      moveToNextStep();
      return;
    }
  };
  const handleOnChange = (key: string) => (value: string) => {
    if (key === "region") {
      const filterRegion: any =
        data?.regionCodeOptions?.find(
          (obj: { label: string; value: number }) => obj.label === value
        ) || [];
      setFieldValue(key, filterRegion?.value || "");
      return;
    }
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(partyOrgFininstOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [partyOrgFininstOptionsData]);
  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      region: data.formData?.region ?? "",
      webSiteURL: data.formData?.webSiteURL ?? "",
      emailDomain: data.formData?.emailDomain ?? "",
    },
  });

  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_SPECIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form noValidate onSubmit={handleSubmit} id="finxact-form">
      <CardContainer>
        <Heading className="app-form-field-container">
          Location & contact information
        </Heading>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the region code?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("region")
                ?.tooltip_text_main
            }
            labelFor="region-code_input"
          />
          <SelectDropdown
            value={
              data?.regionCodeOptions?.find(
                (obj: { label: string; value: string }) =>
                  obj.value === values.region
              )?.label || ""
            }
            onChange={handleOnChange("region")}
            dropdownList={
              data?.regionCodeOptions
                ?.filter((d) => d.ctryCode === data?.formData.cntry)
                .map((d: { label: string }) => d.label)
                .sort() || []
            }
            onBlur={handleBlur}
            id="region-code"
          />
          {errors.region && touched.region && (
            <ErrorAlert>
              <span>{errors.region}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the website URL?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("webSiteURL")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.webSiteURL}
            onChange={handleOnChange("webSiteURL")}
            onBlur={handleBlur}
          />
          {errors.webSiteURL && touched.webSiteURL && (
            <ErrorAlert>
              <span>{errors.webSiteURL}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the email domain?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("emailDomain")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.emailDomain}
            onChange={handleOnChange("emailDomain")}
            onBlur={handleBlur}
          />
          {errors.emailDomain && touched.emailDomain && (
            <ErrorAlert>
              <span>{errors.emailDomain}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default LocationContactInfo;
