import { CoupleRadioOptionType } from "@/components/common/form-fields/couple-radio-with-dropdown/CoupleRadioWithDropdown";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Transaction-code-specification
interface TransactionCodeSpecificationProps {
  trnCode: string | null;
  chargedOffTransactionMatrix: CoupleRadioOptionType;
  trnCodeMatrix: string | null;
  transactionCodeValue: string;
}
// Defining Intitial Value For Transaction-code-specification
const chargeOffTimingThresholdsDetails: TransactionCodeSpecificationProps = {
  trnCode: "",
  chargedOffTransactionMatrix: "",
  trnCodeMatrix: "",
  transactionCodeValue: "",
};

// YUP LIBRARY :Defining Schema For validation of Transaction-code-specification
const chargeOffTimingThresholdsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["trnCode", "trnCodeMatrix"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { TransactionCodeSpecificationProps };
// Exporting Validation Schema and Intial value
export {
  chargeOffTimingThresholdsDetails,
  chargeOffTimingThresholdsSchema as getValidationSchema,
};
