import { ERROR_MESSAGE } from "@/components/data/form-data";

import * as Yup from "yup";

//Defining Interface For Index_Rate_Review_Frequency
export type ProfileTemplateFormProps = {
  id?: number;
  name?: string;
  orgId?: any;
  type?: string;
  environments?: Environment[];
  roles?: any[];
};

export type ProfileTemplateProps = {
  coreRoles: any[];
  consoleRoles: any[];
  environments: any[];
  formData: ProfileTemplateFormProps;
  supplementalRoles: any[];
  envsSpecificCoreRoles: any;
};

export type Environment = {
  envID: number | null;
  coreRoles: string[];
  roles: any[];
};

export type ProfileTemplateForm = {
  name: string;
  roles: string[];
  environments: Environment[];
};

export const initialFormValues: ProfileTemplateForm = {
  name: "",
  roles: [],
  environments: [],
};
export const initialEnvironmentValues: Environment = {
  envID: null,
  coreRoles: [],
  roles: [],
};
export const initialProfileTemplateValues: ProfileTemplateFormProps = {
  id: 0,
  orgId: -1,
  type: "org",
};
const profileTemplateSchema = Yup.object().shape({
  name: Yup.string().required(ERROR_MESSAGE),
  environments: Yup.array(
    Yup.object({
      coreRoles: Yup.array()
        .required("Mininmum one core role should be selected")
        .min(1, "Mininmum one core role should be selected"),
    })
  ),
});

export { profileTemplateSchema as validationSchema };
