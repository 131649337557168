import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For General-Feature-Setup
interface GeneralFeatureSetupProps {
  componentName: string;
  term: string;
}
// Defining Intitial Value For General-Feature-Setup
const generalFeatureSetupDetails: GeneralFeatureSetupProps = {
  componentName: "",
  term: "",
};
// YUP LIBRARY :Defining Schema For validation of General-Feature-Setup
const generalFeatureSetupDetailsSchema = async (optionsSchema: PostSchema) => {
  const fields = ["componentName", "term"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { GeneralFeatureSetupProps };
// Exporting Validation Schema and Intial value
export {
  generalFeatureSetupDetails,
  generalFeatureSetupDetailsSchema as getValidationSchema,
};
