"use client";
import { Box, CardBody, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  ErrorAlert,
  RadioButtonGroup,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

import { useContext, useEffect, useMemo, useState } from "react";
import {
  TransactionCodeSpecificationProps,
  getValidationSchema,
} from "./ChargeOffTransactionCodeSpecificationsValidation";
import { useFormik } from "formik";
import {
  addNewComponent,
  createComponentGeneric,
} from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  MAIN_FORM_ID,
  TRANSCODE_FLOW_NAME,
} from "@/utils/constants";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { matrixTypeUiSchema } from "@finxact/finxact-shared-ui";
import { CHARGE_OFF_TRANSACTION_CODE } from "@/components/data/data";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import {
  ChargeOffComponentData,
  COMPONENT_CLASS_ENUM,
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";
import {
  useEntityIdFromParam,
  useFieldNameFromParam,
  preventMainFormSubmitOnRJSFSubmit,
} from "@/utils/common";
import { Option, RjsfData } from "@/utils/types";

type Props = {
  data: {
    formData: ChargeOffComponentData;
    componentDepChrgOffSchema: PostSchema;
    chargeOffOptions: Option<string>[];
    transactionCodes: Option<string>[];
    matrixSchema: OptionsSchema;
    addNewMatrixEndpoint: string;
  };
};

const ChargeOffTransactionCodeSpecifications = (props: Props) => {
  const toast = useToast();

  const [validationSchema, setValidationSchema] = useState<any>(null);
  const entityId = useEntityIdFromParam();
  const fieldName = useFieldNameFromParam();

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.componentDepChrgOffSchema
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.componentDepChrgOffSchema]);
  const onSubmit = async (
    values: TransactionCodeSpecificationProps,
    actions: any
  ) => {
    if (!dirty && !entityId) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["chargeOff"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      const res = await createComponentGeneric({
        productDetails,
        formData: {
          ...props.data.formData,

          trnCode:
            values.transactionCodeValue == "1"
              ? values.trnCode?.length! > 0
                ? values.trnCode
                : null
              : null,
          trnCodeMatrix:
            values.transactionCodeValue == "2"
              ? values.trnCodeMatrix?.length! > 0
                ? values.trnCodeMatrix
                : null
              : null,
        },
        toastOptions: {
          toast,
          toastId: "chargeOff-config",
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
        componentClass: COMPONENT_CLASS_ENUM.componentDepChrgOff,
      });

      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  const schema = useMemo(() => {
    const { actions, name } = props.data.matrixSchema;
    return { name, ...actions.POST };
  }, [props.data.matrixSchema]);

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.addNewMatrixEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue(`trnCodeMatrix`, response.matrixName);
  };

  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      transactionCodeValue:
        props.data.formData?.trnCode || (fieldName === "trnCode" && entityId)
          ? "1"
          : props.data.formData?.trnCodeMatrix
            ? "2"
            : "",
      trnCode:
        fieldName === "trnCode" && entityId
          ? entityId
          : (props.data.formData?.trnCode ?? ""),
      trnCodeMatrix: props.data.formData?.trnCodeMatrix ?? "",
      chargedOffTransactionMatrix:
        props.data.formData?.trnCodeMatrix !== "" ? "PRIMARY" : "",
    },
  });

  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  //Handling Form
  const chargeOffTransactionFormHandler =
    (key: string) => (value: string | string[]) => {
      setFieldValue(key, value);
    };
  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id={MAIN_FORM_ID}
    >
      <CardContainer>
        <Box className={"app-form-field-container"}>
          <AppFormLabel
            labelName="What is the charge-off transaction code?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("trnCode")
                ?.tooltip_text_main
            }
          />

          <RadioButtonGroup
            value={values.transactionCodeValue}
            onChange={chargeOffTransactionFormHandler("transactionCodeValue")}
            radioPropList={CHARGE_OFF_TRANSACTION_CODE}
            stackDirection={"row"}
            spacing={"0.8rem"}
            isBoxedRadio={true}
            onBlur={handleBlur}
          />
          {errors.trnCode && touched.trnCode && (
            <ErrorAlert>
              <span>{errors.trnCode}</span>
            </ErrorAlert>
          )}
        </Box>
        {values.transactionCodeValue == "1" && (
          <>
            <Box className={"app-form-field-container"}>
              <AppFormLabel
                labelName="Select charge off transaction code field?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("selectTrnField")
                    ?.tooltip_text_main
                }
                labelFor="charge_off_tran_code"
              />
              <CoupleRadioWithDropdown
                primaryRadioName={"Select from existing transaction code"}
                primaryOptionValue={values.trnCode!}
                secondaryRadioName={"Create new transaction code"}
                handlePrimaryOptionSelect={
                  chargeOffTransactionFormHandler("trnCode") as any
                }
                dropdownList={props.data.transactionCodes}
                placeHolderName="Select transaction Code"
                onBlur={handleBlur}
                addNewFieldType="routeToTransCode"
                addNewFieldQueryParam={`${QUERY_PARAM_KEY["PRODUCT_NAME_KEY"]}=${productDetails?.name}&${ROUTE_PATH.FLOW_NAME_QUERY}${TRANSCODE_FLOW_NAME.chargeOffTransCode}&${QUERY_PARAM_KEY.FIELD_NAME}=trnCode`}
                id="charge_off_tran_code"
                withDescription
                modelViewUrl={ROUTE_PATH.TC_FOUNDATIONAL_DETAILS}
              />
              {errors.trnCode && touched.trnCode && (
                <ErrorAlert>
                  <span>{errors.trnCode}</span>
                </ErrorAlert>
              )}
            </Box>
          </>
        )}
        {values.transactionCodeValue == "2" && (
          <Box>
            <AppFormLabel
              labelName="Select charge off transaction code matrix?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("selectTrnCode")
                  ?.tooltip_text_main
              }
              labelFor="charge_off_tran_code_matrix"
            />
            <CoupleRadioWithDropdown
              primaryRadioName={"Select from existing transaction code matrix"}
              primaryOptionValue={values.trnCodeMatrix!}
              secondaryRadioName={"Create new transaction code matrix"}
              handlePrimaryOptionSelect={
                chargeOffTransactionFormHandler("trnCodeMatrix") as any
              }
              onRadioValueChange={chargeOffTransactionFormHandler(
                "chargedOffTransactionMatrix"
              )}
              dropdownList={props.data.chargeOffOptions}
              placeHolderName="Select transaction Code"
              onBlur={handleBlur}
              schema={schema}
              uiSchema={matrixTypeUiSchema}
              onAddNewSubmit={addNewOption}
              id="charge_off_tran_code_matrix"
              modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
            />
            {errors.chargedOffTransactionMatrix &&
              touched.chargedOffTransactionMatrix && (
                <ErrorAlert>
                  <span>{errors.chargedOffTransactionMatrix}</span>
                </ErrorAlert>
              )}
            {errors.trnCodeMatrix && touched.trnCodeMatrix && (
              <ErrorAlert>
                <span>{errors.trnCodeMatrix}</span>
              </ErrorAlert>
            )}
          </Box>
        )}
      </CardContainer>
    </form>
  );
};

export default ChargeOffTransactionCodeSpecifications;
