"use client";
import { ApiClient, API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  getCoreModelEndpoint,
  getAllWorkflowGeneric,
  getWorkflowGeneric,
} from "@/api-config/api-service";
import {
  ConfigurationStatusComponent,
  CardContainer,
  Badges,
  CustomTable,
} from "@/components/common";
import "./CompletedPage.scss";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  SUMMARY_LANDING_SCREEN_STATUS,
  CRT_TABLE_COLUMN_DEF,
  DASHBOARD_MODEL_KEY,
  ENTITLEMENT_STATUS,
  CRT_CONFIG_ORDER,
  ORG_PHASE,
  NO_WORKFLOW_ORG_PHASES,
} from "@/utils/constants";
import { AddIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Flex,
  HStack,
  TableContainer,
  Box,
  Text,
  Button,
  Divider,
  VStack,
  Icon,
  Spacer,
  Heading,
} from "@chakra-ui/react";
import { useRouter } from "next/navigation";
import { kebabIcon, rightRotateYellow, noCatalogFound } from "public/assets";
import { useState, useEffect } from "react";
import Image from "next/image";
import { useSelector } from "react-redux";
import { getEnvIsDisable, getSelectedOrg, getEntitlement } from "@/store";

const CompletedPage = () => {
  const router = useRouter();
  const [crtCodes, setCrtCodes] = useState<any>();
  const currOrg = useSelector(getSelectedOrg);
  const isEnvDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const [shouldRender, updateRenderState] = useState(false);

  const renderDataCell = (value: any, key: string, row: any) => {
    if (key === "action")
      return (
        <Box textAlign={"end"}>
          {entitlement?.crt !== ENTITLEMENT_STATUS.noAccess && (
            <Menu>
              <MenuButton
                border={0}
                className="kebab-btn"
                as={IconButton}
                aria-label="Options"
                icon={<Image src={kebabIcon} alt="image" />}
                variant="outline"
              />
              <MenuList className="menu-list-container">
                <MenuItem
                  onClick={() => {
                    router.push(row.url);
                  }}
                  p={4}
                >
                  {isEnvDisabled ||
                  entitlement.crt === ENTITLEMENT_STATUS.readOnly ? (
                    <span className="menu-text">View</span>
                  ) : (
                    <span className="menu-text">Edit</span>
                  )}
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Box>
      );
    return value;
  };

  const processCRTs = (crtData: any[], workflowData: any[]) => {
    return crtData.reduce<{
      completed: any[];
      inProgress: any[];
    }>(
      (ret, entityData) => {
        const identifier = entityData.custRelType;
        const crtConfigForEntity = workflowData.filter(
          (wfd) => wfd.modelKey === identifier
        );
        const url = `${crtConfigForEntity.length === CRT_CONFIG_ORDER.general_setup ? ROUTE_PATH.CRT_PARTY_RELATIONSHIPS : ROUTE_PATH.CRT_GENERAL_SETUP}?entityId=${identifier}`;

        /**
         *  Add entry to completed list when org in prospect phase
         *  OR if org is in production/uat phase and no workflow exists for the entity
         *  OR if workflow is completed
         */
        if (
          (!crtConfigForEntity.length &&
            NO_WORKFLOW_ORG_PHASES.includes(currOrg.phase)) ||
          currOrg?.phase === ORG_PHASE.prospect ||
          crtConfigForEntity.length === CRT_CONFIG_ORDER.party_relationships
        ) {
          ret["completed"].push({
            ...entityData,
            url,
          });
        } else {
          ret["inProgress"].push({
            ...entityData,
            url,
          });
        }

        return ret;
      },
      {
        completed: [],
        inProgress: [],
      }
    );
  };

  useEffect(() => {
    (async () => {
      const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
      const res = await Promise.all([
        api.get(API_ROUTE_CONFIGURATION.custRelType),
        getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.crtConfig),
        getWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.dashboard,
          key: DASHBOARD_MODEL_KEY.foundationalSetup,
        }),
      ]);
      const crtCodedata = processCRTs(res[0]?.data, res[1]);
      setCrtCodes(crtCodedata);
      updateRenderState(true);
    })();
  }, []);

  const InProgressCard = ({ crtCode }: any) => {
    return (
      <Box className="in-progress-container" w={"93%"} m={4}>
        <HStack>
          <HStack alignItems={"start"} gap={4}>
            <Image src={rightRotateYellow} alt="in-progress" />
            <VStack className="in-progress-desc" alignItems="start" gap={3}>
              <Heading as="h3">{crtCode?.custRelType}</Heading>
              <Text>{crtCode?.desc}</Text>
              <Badges
                size="lg"
                variant="subtle"
                label="IN-PROGRESS"
                type="warning"
              />
            </VStack>
          </HStack>
          <Spacer />
          <HStack gap={10}>
            <Button
              className="app-btn-reg-secondary-transparent"
              onClick={() => {
                router.push(crtCode?.url);
              }}
              isDisabled={entitlement?.crt === ENTITLEMENT_STATUS.noAccess}
            >
              {entitlement.crt === ENTITLEMENT_STATUS.readOnly || isEnvDisabled
                ? "View"
                : "Resume"}
              <Icon as={ChevronRightIcon} boxSize={8} ml={3} />
            </Button>
          </HStack>
        </HStack>
      </Box>
    );
  };
  return (
    <>
      <ConfigurationStatusComponent
        title="Customer Relationship Types"
        status={SUMMARY_LANDING_SCREEN_STATUS.prod_completed}
        leftBadgeLabel="Networks"
        leftBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.completed}
        rightBadgeLabel="Product Configuration"
        rightBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.completed}
      />
      <Flex direction={"column"} align={"center"} mx={60} mt={32}>
        <Box width="60%" mx="auto" textAlign="center">
          <Text className="heading-text" as="h3">
            Review and create your customer relationship type(s)
          </Text>
          <Text className="paragraph">
            Configure additional customer relationship types as needed or review
            existing ones below. These notate records of key relationships
            between parties and customers.
          </Text>
        </Box>
        <Box w={"100%"} mt={12.8}>
          <CardContainer customClass="transaction-codes-container">
            {crtCodes?.inProgress?.length > 0 &&
            entitlement.crt !== ENTITLEMENT_STATUS.noAccess ? (
              <>
                <Text className="in-progress-header">Jump Back in</Text>
                <VStack>
                  {crtCodes?.inProgress.map((crtCode: any) => (
                    <InProgressCard
                      key={crtCode.custRelType}
                      crtCode={crtCode}
                    />
                  ))}
                </VStack>
              </>
            ) : null}
            <Divider my={13} className="detail-item-divider" />
            <Flex justifyContent={"space-between"} m={4} px={9}>
              <Badges
                size="lg"
                variant="subtle"
                label={`${crtCodes?.completed?.length || 0} SETUP`}
                type="primary"
              />
              <HStack gap={8}>
                {entitlement.crt === ENTITLEMENT_STATUS.allAccess &&
                !isEnvDisabled ? (
                  <Button
                    className="app-btn-inverse-secondary"
                    onClick={() => {
                      router.push(`${ROUTE_PATH.CRT_WALKTHROUGH}`);
                    }}
                  >
                    <AddIcon boxSize={11} mr={4} />
                    Add new
                  </Button>
                ) : null}
              </HStack>
            </Flex>
            {shouldRender ? (
              <>
                {crtCodes?.completed.length ? (
                  <TableContainer
                    className="transaction-codes-table"
                    m={4}
                    px={9}
                  >
                    <CustomTable
                      data={crtCodes?.completed}
                      columns={CRT_TABLE_COLUMN_DEF}
                      renderDataCell={renderDataCell}
                      className="my-product-table"
                      isPagination={true}
                      pageSizeData={20}
                    />
                  </TableContainer>
                ) : (
                  <VStack
                    w={"100%"}
                    justifyContent={"center"}
                    mt={20}
                    gap={6}
                    className="no-transactions-found"
                  >
                    <Image
                      src={noCatalogFound}
                      alt={"No customer relationship type found"}
                    />
                    <Heading as="h3" mt={4}>
                      No customer relationship type found
                    </Heading>
                  </VStack>
                )}
              </>
            ) : null}
          </CardContainer>
        </Box>
      </Flex>
    </>
  );
};

export default CompletedPage;
